package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.poc.game.GameEngine
import xim.poc.game.GameState
import xim.poc.game.configuration.SkillType
import xim.poc.ui.AoeIndicatorColor
import xim.poc.ui.AoeIndicators
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.resource.AoeType
import xim.resource.table.MobSkillInfoTable
import xim.resource.table.MobSkillNameTable
import xim.util.Fps

class CastMobSkillStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val mobSkillId: Int,
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val mobSkill = MobSkillInfoTable[mobSkillId]
        val castTime = GameState.getGameMode().getSkillCastTime(actorState, SkillType.MobSkill, mobSkillId)

        val castingState = CastingState.mobSkill(castTime = castTime, sourceId = sourceId, targetId = targetId, mobSkill = mobSkill)
        actorState.setCastingState(castingState)

        val skillName = MobSkillNameTable[mobSkillId]
        if (skillName != ".") { ChatLog.addLine("${actorState.name} readies $skillName.", ChatLogColor.Action) }

        if (castTime > 0f) {
            ActorManager[sourceId]?.readySkill()
        }

        val rangeInfo = GameEngine.getRangeInfo(actorState, SkillType.MobSkill, mobSkillId)
        if (castTime > Fps.millisToFrames(100) && rangeInfo.type != AoeType.None) {
            AoeIndicators.claim(sourceId, targetId, rangeInfo, AoeIndicatorColor.Red) { !actorState.isCasting() }
        }

        return emptyList()
    }

}