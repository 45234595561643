package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.game.*
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluatorContext
import xim.poc.game.event.*
import xim.resource.InventoryItems
import xim.resource.TargetFlag
import xim.resource.table.AbilityInfoTable
import xim.resource.table.MobSkillInfoTable
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import xim.util.PI_f

typealias BehaviorId = Int

class ActorDamagedContext(
    val damageAmount: Int,
    val attacker: ActorState,
    val skill: Skill?,
    val actionContext: AttackContext?,
    val damageType: AttackDamageType,
)

class AutoAttackState(val actorState: ActorState, val attackRange: Pair<Float, Float> = (0.5f to 4.5f)) {

    companion object {
        private val filter = ActionTargetFilter(TargetFlag.Enemy.flag)
    }

    private var timeUntilAttack = 0f

    fun update(elapsedFrames: Float) {
        timeUntilAttack -= elapsedFrames

        val targetState = ActorStateManager[actorState.targetState.targetId]

        if (targetState == null || !canAutoAttack(targetState)) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.secondsToFrames(2))
        } else if (!isInAttackRange(targetState)) {
            timeUntilAttack = timeUntilAttack.coerceAtLeast(Fps.millisToFrames(100))
        }
    }

    fun isReadyToAutoAttack(): Boolean {
        val targetState = ActorStateManager[actorState.targetState.targetId] ?: return false
        return timeUntilAttack <= 0f && canAutoAttack(targetState) && isInAttackRange(targetState)
    }

    fun resetAutoAttack() {
        timeUntilAttack = GameEngine.getAutoAttackRecast(actorState)
    }

    private fun canAutoAttack(targetState: ActorState): Boolean {
        if (!filter.targetFilter(actorState, targetState)) { return false }
        if (!GameEngine.canBeginAction(actorState)) { return false }
        return actorState.isEngaged() && !actorState.isDead() && !actorState.isCasting()
    }

    private fun isInAttackRange(targetState: ActorState): Boolean {
        if (!actorState.isFacingTowards(targetState, halfAngle = PI_f/3f)) { return false }

        val distance = Vector3f.distance(actorState.position, targetState.position)

        return if (actorState.isPlayer()) {
            distance >= attackRange.first && distance <= attackRange.second
        } else {
            distance <= attackRange.second
        }
    }

}


interface ActorBehaviorController {

    fun onInitialized(): List<Event> = emptyList()

    fun update(elapsedFrames: Float): List<Event>

    fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) { }

    fun onDamaged(context: ActorDamagedContext): List<Event> = emptyList()

    fun onDefeated(): List<Event> = emptyList()

    fun onSkillExecuted(primaryTargetContext: TargetEvaluatorContext): List<Event> = emptyList()

}

class NoOpBehaviorController: ActorBehaviorController {
    override fun update(elapsedFrames: Float): List<Event> {
        return emptyList()
    }
}

class AutoAttackController(val actorState: ActorState, val attackRange: Pair<Float, Float> = (0f to 4f)): ActorBehaviorController {

    private val autoAttackState = AutoAttackState(actorState, attackRange = attackRange)

    override fun update(elapsedFrames: Float): List<Event> {
        autoAttackState.update(elapsedFrames)
        if (!autoAttackState.isReadyToAutoAttack()) { return emptyList() }

        autoAttackState.resetAutoAttack()
        return listOf(AutoAttackEvent(actorState.id))
    }

    fun reset() {
        autoAttackState.resetAutoAttack()
    }

}

open class BasicMonsterController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        if (eligibleToUseSkill()) {
            val chosenSkillEvents = useSkill()
            if (chosenSkillEvents.isNotEmpty()) { return chosenSkillEvents }
        }

        val output = autoAttackDelegate.update(elapsedFrames)
        if (output.isEmpty()) { return emptyList() }

        val autoAttackOverride = onReadyToAutoAttack()
        return if (autoAttackOverride.isNotEmpty()) { autoAttackOverride } else { output }
    }

    open fun selectSkill(): Skill? {
        val defn = MonsterDefinitions[actorState.monsterId] ?: return null

        val mobSkill = defn.mobSkills
            .filter { GameEngine.canBeginUseMobSkill(actorState.id, it) }
            .randomOrNull()
            ?: return null

        return Skill(SkillType.MobSkill, mobSkill)
    }

    open fun onReadyToAutoAttack(): List<Event> {
        return emptyList()
    }

    open fun shouldStartUsingSkill(): Boolean {
        val hpp = actorState.getHpp()
        val tpp = actorState.getTpp()
        return if (hpp > 0.75f) { tpp > 0.6f } else if (hpp > 0.5f) { tpp > 0.45f } else { tpp > 0.3f }
    }

    open fun eligibleToUseSkill(): Boolean {
        if (!actorState.isIdleOrEngaged()) { return false }
        return shouldStartUsingSkill()
    }

    private fun useSkill(): List<Event> {
        val skill = selectSkill() ?: return emptyList()

        val targetFlags = (when (skill.skillType) {
            SkillType.Spell -> SpellInfoTable[skill.id].targetFlags
            SkillType.MobSkill -> MobSkillInfoTable[skill.id]?.targetFlag
            SkillType.Ability -> AbilityInfoTable[skill.id].targetFlags
            SkillType.Item -> InventoryItems[skill.id].targetFlags
        }) ?: return emptyList()

        val targetFilter = ActionTargetFilter(targetFlags)
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return emptyList()

        val targetState = ActorStateManager[targetId] ?: return emptyList()

        val rangeInfo = GameState.getGameMode().getSkillRangeInfo(actorState, skill.skillType, skill.id)
        if (Vector3f.distance(actorState.position, targetState.position) > rangeInfo.maxTargetDistance) {
            return emptyList()
        }

        val event = when (skill.skillType) {
            SkillType.Spell -> CastSpellStart(actorState.id, targetId, skill.id)
            SkillType.MobSkill -> CastMobSkillStart(actorState.id, targetId, skill.id)
            SkillType.Ability -> CastAbilityStart(actorState.id, targetId, skill.id)
            SkillType.Item -> {
                val item = actorState.inventory.inventoryItems.firstOrNull { it.id == skill.id } ?: return emptyList()
                CastItemStart(actorState.id, targetId, item.internalId)
            }
        }

        return listOf(event)
    }

}

object ActorBehaviors {

    const val NoAction: BehaviorId = 0
    const val AutoAttackOnly: BehaviorId = 1
    const val BasicMonsterController: BehaviorId = 2

    private val behaviors = HashMap<BehaviorId, (ActorState) -> ActorBehaviorController>()

    init {
        register(NoAction) { NoOpBehaviorController() }
        register(AutoAttackOnly) { AutoAttackController(it) }
        register(BasicMonsterController) { BasicMonsterController(it) }
    }

    fun register(behaviorId: BehaviorId, factory: (ActorState) -> ActorBehaviorController): BehaviorId {
        check(behaviors[behaviorId] == null) { "BehaviorId $behaviorId was already defined" }
        behaviors[behaviorId] = factory
        return behaviorId
    }

    fun createController(behaviorId: BehaviorId, actorState: ActorState): ActorBehaviorController {
        val factory = behaviors[behaviorId] ?: return NoOpBehaviorController()
        return factory.invoke(actorState)
    }

}