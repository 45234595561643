package xim.poc.game.configuration.v0.tower

import xim.poc.ActorAssociation
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.NoOpActorController
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.FloorEntity
import xim.poc.game.configuration.v0.GameV0
import xim.poc.game.configuration.v0.zones.BattleLocation
import xim.poc.game.event.InitialActorState
import xim.util.Fps


class FloorEntrance(val location: BattleLocation): FloorEntity {

    private var promise = spawn()

    override fun update(elapsedFrames: Float) { }

    override fun cleanup() {
        promise.onReady { GameEngine.submitDeleteActor(it.id) }
    }

    private fun spawn(): ActorPromise {
        return GameEngine.submitCreateActorState(
            InitialActorState(
                name = "Passage",
                type = ActorType.Object,
                position = location.entrancePosition,
                modelLook = location.entranceLook,
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
            )
        ).onReady {
            GameV0.interactionManager.registerInteraction(it.id, FloorEntranceInteraction)
        }
    }

}

object FloorEntranceInteraction : NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        UiStateHelper.openQueryMode("What will you do?", getOptions(), callback = this::handleQueryResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        options += QueryMenuOption("Return to base camp.", value = 1)
        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?): QueryMenuResponse {
        if (response == null || response.value < 0) { return QueryMenuResponse.pop }

        if (response.value == 1) {
            enterCamp()
        }

        return QueryMenuResponse.pop
    }

    private fun enterCamp() {
        val playerAssociation = ActorAssociation(ActorManager.player())
        val startingPosition = GameV0.configuration.startingZoneConfig


        val script = EventScript(
            listOf(
                EffectRoutineEventItem(fileTableIndex = 0x113c8, playerAssociation, eagerlyComplete = true),
                WaitRoutine(Fps.secondsToFrames(1.9f)),
                WarpZoneEventItem(startingPosition),
            )
        )

        EventScriptRunner.runScript(script)
    }

}