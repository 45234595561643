package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.configuration.Skill
import xim.poc.game.configuration.SkillType

class MobSheepBehavior(actorState: ActorState) : BasicMonsterController(actorState) {

    override fun selectSkill(): Skill {
        val skillId = (if (actorState.hasStatusEffect(StatusEffect.Berserk)) {
            listOf(4, 6)
        } else {
            listOf(4, 5, 5, 5, 6, 8)
        }).random()

        return Skill(SkillType.MobSkill, skillId)
    }

}