package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.CombatBonusAggregate
import xim.poc.game.CombatStats
import xim.poc.gl.ByteColor
import xim.resource.Blur
import xim.resource.BlurConfig
import xim.resource.MagicType

typealias MonsterId = Int

fun standardBlurConfig(color: ByteColor, radius: Float = 70f): BlurConfig {
    val configs = ArrayList<Blur>()
    repeat(5) { configs += Blur(radius, color) }
    return BlurConfig(configs)
}

enum class MonsterAggroType {
    Sight,
    Sound,
    Magic,
}

data class MonsterAggroSetting(val type: MonsterAggroType, val range: Float, val enabled: (ActorState, ActorState) -> Boolean = { _, _ -> true })

data class MonsterAggroConfig(val settings: List<MonsterAggroSetting> = emptyList()) {
    companion object {

        val none = MonsterAggroConfig()

        val standardSightAggro = MonsterAggroConfig(listOf(
            MonsterAggroSetting(type =  MonsterAggroType.Sight, range = 10f)
        ))

        val standardSoundAggro = MonsterAggroConfig(listOf(
            MonsterAggroSetting(type =  MonsterAggroType.Sound, range = 6f)
        ))

        val standardMagicAggro = MonsterAggroConfig(listOf(
            MonsterAggroSetting(type =  MonsterAggroType.Magic, range = 15f)
        ))

        fun compose(c0: MonsterAggroConfig, c1: MonsterAggroConfig): MonsterAggroConfig {
            return MonsterAggroConfig(c0.settings + c1.settings)
        }
    }

}

data class ItemDropSlot(
    val itemId: Int?,
    val quantity: Int = 1,
    val temporary: Boolean = false,
    val augmentRankMean: Int = 0,
)

data class MonsterDefinition(
    val id: MonsterId,
    val name: String,
    val look: ModelLook,
    val behaviorId: BehaviorId = ActorBehaviors.BasicMonsterController,
    val movementControllerFn: () -> ActorController = { DefaultEnemyController() },
    val staticPosition: Boolean = false,
    val mobSkills: List<Int> = emptyList(),
    val mobSpells: List<Int> = emptyList(),
    val customModelSettings: CustomModelSettings = CustomModelSettings(),
    val baseLevel: Int = 1,
    val baseDamage: Int = 3,
    val baseDelay: Int = 180,
    val baseCombatStats: CombatStats = CombatStats(20, 20, 5, 5, 5, 5, 5, 5, 5),
    val notoriousMonster: Boolean = false,
    val baseAppearanceState: Int = 0,
    val aggroConfig: MonsterAggroConfig = MonsterAggroConfig.none,
    val rewardScale: Float = 1f,
    val baseBonusApplier: (CombatBonusAggregate) -> Unit = { },
    val onSpawn: ((ActorState) -> Unit)? = null
)

class MonsterInstance(val monsterDefinition: MonsterDefinition, val actorId: ActorId)

object MonsterDefinitions {

    private val definitions = HashMap<MonsterId, MonsterDefinition>()

    operator fun get(monsterId: MonsterId?): MonsterDefinition? {
        monsterId ?: return null
        return get(monsterId)
    }

    operator fun get(monsterId: MonsterId): MonsterDefinition {
        return definitions[monsterId] ?: throw IllegalStateException("[$monsterId] Undefined monster")
    }

    operator fun set(monsterId: MonsterId, monsterDefinition: MonsterDefinition) {
        if (definitions.containsKey(monsterId)) { throw IllegalStateException("Monster definition $monsterId is already defined by ${definitions[monsterId]}") }
        definitions[monsterId] = monsterDefinition
    }

    fun registerAll(definitions: List<MonsterDefinition>) {
        definitions.forEach { set(it.id, it) }
    }

}

object MonsterAggroHelper {

    fun getAggroTarget(source: ActorState): ActorId? {
        if (source.isEngaged() || source.monsterId == null) { return null }

        val definition = MonsterDefinitions[source.monsterId]

        val config = definition.aggroConfig
        if (config.settings.isEmpty()) { return null }

        for (setting in config.settings) {
            val nearbyEnemies = ActorStateManager.getNearbyActors(source.position, maxDistance = setting.range)
                .filter { !it.isDead() && ActionTargetFilter.areEnemies(source, it) }

            val nearestValidEnemy = when (setting.type) {
                MonsterAggroType.Sight -> nearbyEnemies.filter { source.isFacingTowards(it) }
                MonsterAggroType.Sound -> nearbyEnemies
                MonsterAggroType.Magic -> nearbyEnemies.filter { checkMagicAggro(it) }
            }.minByOrNull { Vector3f.distance(source.position, it.position) }

            if (nearestValidEnemy != null) {
                return nearestValidEnemy.id
            }
        }

        return null
    }

    private fun checkMagicAggro(targetState: ActorState): Boolean {
        val spellInfo = targetState.getCastingState()?.spellInfo ?: return false

        return when (spellInfo.magicType) {
            MagicType.WhiteMagic -> true
            MagicType.BlackMagic -> true
            MagicType.Summoning -> true
            MagicType.BlueMagic -> true
            else -> false
        }
    }

}