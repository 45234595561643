package xim.poc.game.configuration.v0

import xim.poc.game.CombatStat
import xim.poc.game.CombatStats
import xim.poc.game.InventoryItem
import xim.poc.game.ItemAugmentId
import xim.poc.game.configuration.WeightedTable
import xim.poc.game.configuration.v0.ActorSkillType.EnfeeblingMagic
import xim.poc.game.configuration.v0.ActorSkillType.Sword
import xim.poc.game.configuration.v0.Floor1AugmentPools.bronzeEquipmentAugmentPool
import xim.poc.game.configuration.v0.Floor1AugmentPools.standardBackAugments
import xim.poc.game.configuration.v0.Floor1AugmentPools.standardWaistAugments
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.critHitRateAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.qualityBonus
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.weaponDamageAugmentId
import xim.poc.game.configuration.v0.ItemBuildUpDefinitions.buildUpRequirements
import xim.poc.game.configuration.v0.ItemBuildUpDefinitions.meldCaps
import xim.poc.ui.ShiftJis
import xim.resource.InventoryItemType
import xim.resource.InventoryItems
import xim.resource.table.SpellNameTable

enum class WeaponPath {
    WeaponSkill,
    Crit,
    Magic,
    MultiAttack,
    Tp,
}

data class ItemTrust(val trustId: Int)

data class ItemCapacityAugment(val augmentId: ItemAugmentId, val potency: Int, val capacity: Int)

data class ItemBuildUpDefinition(val augmentRequirements: Map<ItemAugmentId, Int>)

data class ItemDropDefinition(
    val itemId: Int,
    val quantity: Int = 1,
    val temporary: Boolean = false,
    val augmentRankMean: Int = 0,
    val augmentRank: Int? = null,
    val augmentRankMax: Int? = augmentRank,
)

data class ItemDefinition(
    val id: Int,
    val internalLevel: Int,
    val damage: Int = 0,
    val delay: Int = 0,
    val skillTiers: Map<ActorSkillType, TierLevel> = emptyMap(),
    val combatStats: CombatStats = CombatStats(),
    val trusts: List<ItemTrust> = emptyList(),
    val ranked: Boolean = false,
    val augmentSlots: List<WeightedTable<ItemAugmentId>> = emptyList(),
    val capacityAugment: ItemCapacityAugment? = null,
    val upgradeOptions: List<Int> = emptyList(),
    val buildUpRequirements: ItemBuildUpDefinition? = null,
    val damageRange: Pair<Int, Int>? = null,
    val meldBonusCaps: Map<ItemAugmentId, Int> = emptyMap(),
) {

    fun scale(inventoryItem: InventoryItem): ItemDefinition {
        val scaledStats = combatStats * qualityBonus(inventoryItem)
        return copy(combatStats = scaledStats)
    }

    fun toDescription(inventoryItem: InventoryItem): String {
        val info = InventoryItems[id]

        val str = StringBuilder()

        if (info.itemType == InventoryItemType.Weapon) {
            val weaponDamageAugment = inventoryItem.fixedAugments?.augments?.get(weaponDamageAugmentId)
            if (weaponDamageAugment != null && damageRange != null) {
                str.appendLine("${formatDamageRange()}  Delay:$delay")
            } else {
                str.appendLine("DMG:$damage  Delay:$delay")
            }
        }

        var addedStat = false
        for (combatStat in CombatStat.values()) {
            val stat = combatStats[combatStat]
            if (stat != 0) { str.append("${combatStat.displayName}+$stat "); addedStat = true }
        }
        if (addedStat) { str.appendLine() }

        for (trust in trusts) {
            str.appendLine("Trust[${SpellNameTable[trust.trustId].first()}]")
        }

        return str.toString()
    }

    private fun formatDamageRange(): String {
        damageRange ?: return ""
        val first = "${damageRange.first/100}.${(damageRange.first%100).toString().padEnd(2, '0')}"
        val second = "${damageRange.second/100}.${(damageRange.second%100).toString().padEnd(2, '0')}"
        return "DMG:$first${ShiftJis.longTilde}$second"
    }

}

object ItemDefinitions {

    val definitionsById: Map<Int, ItemDefinition>

    val reinforcePointItems: List<ItemDefinition>

    private val verySlowDamageRange = (130 to 145)
    private val slowDamageRange = (115 to 130)
    private val mediumDamageRange = (100 to 115)
    private val fastDamageRange = (85 to 100)

    private val standardArmorAugments = listOf(
        bronzeEquipmentAugmentPool,
        bronzeEquipmentAugmentPool,
        bronzeEquipmentAugmentPool,
    )

    init {
        val definitions = ArrayList<ItemDefinition>()
        reinforcePointItems = ArrayList()

        // Snowslit Stone
        definitions += ItemDefinition(id = 8930, internalLevel = 1, capacityAugment = ItemCapacityAugment(augmentId = 29, potency = 1, capacity = 1))
        definitions += ItemDefinition(id = 8931, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 29, potency = 2, capacity = 1))

        // Leaf Stones
        definitions += ItemDefinition(id = 4033, internalLevel = 5, capacityAugment = ItemCapacityAugment(augmentId = 21, potency = 1, capacity = 5))
        definitions += ItemDefinition(id = 8933, internalLevel = 5, capacityAugment = ItemCapacityAugment(augmentId = 16, potency = 1, capacity = 5))
        definitions += ItemDefinition(id = 8942, internalLevel = 5, capacityAugment = ItemCapacityAugment(augmentId = 13, potency = 1, capacity = 5))
        definitions += ItemDefinition(id = 8951, internalLevel = 5, capacityAugment = ItemCapacityAugment(augmentId = 14, potency = 1, capacity = 5))
        definitions += ItemDefinition(id = 8960, internalLevel = 5, capacityAugment = ItemCapacityAugment(augmentId = 30, potency = 1, capacity = 5))

        // Dusk Stones
        definitions += ItemDefinition(id = 3951, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 17, potency = 2, capacity = 10))
        definitions += ItemDefinition(id = 8936, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 32, potency = 1, capacity = 10))
        definitions += ItemDefinition(id = 8945, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 31, potency = 1, capacity = 10))
        definitions += ItemDefinition(id = 8954, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 33, potency = 1, capacity = 10))
        definitions += ItemDefinition(id = 8963, internalLevel = 10, capacityAugment = ItemCapacityAugment(augmentId = 35, potency = 1, capacity = 10))

        // Eschalixers
        definitions += ItemDefinition(id = 9084, internalLevel = 1).also { reinforcePointItems += it }
        definitions += ItemDefinition(id = 9085, internalLevel = 5).also { reinforcePointItems += it }
        definitions += ItemDefinition(id = 9086, internalLevel = 10).also { reinforcePointItems += it }

        // Bronze Sword [t1]
        definitions += ItemDefinition(id = 16535, internalLevel = 1, damage = wdmg(1), delay = 210, ranked = true,
            skillTiers = mapOf(Sword to 1, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16551, 16530),
            damageRange = mediumDamageRange,
            meldBonusCaps = meldCaps(tier = 1, weaponPath = WeaponPath.Tp),
        )

        // Xiphos [t2]
        definitions += ItemDefinition(id = 16530, internalLevel = 5, damage = wdmg(2), delay = 210, ranked = true,
            damageRange = mediumDamageRange,
            skillTiers = mapOf(Sword to 2, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16536, 16552, 16558),
            meldBonusCaps = meldCaps(tier = 2, weaponPath = WeaponPath.Tp),
            buildUpRequirements = buildUpRequirements(tier = 2, weaponPath = WeaponPath.Tp),
        )

        // Iron Sword [t3]
        definitions += ItemDefinition(id = 16536, internalLevel = 10, damage = wdmg(3), delay = 210, ranked = true,
            damageRange = mediumDamageRange,
            skillTiers = mapOf(Sword to 3, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16537),
            meldBonusCaps = meldCaps(tier = 3, weaponPath = WeaponPath.Tp),
            buildUpRequirements = buildUpRequirements(tier = 3, weaponPath = WeaponPath.Tp),
        )

        // Mythril Sword [t4]
        definitions += ItemDefinition(id = 16537, internalLevel = 15, damage = wdmg(4), delay = 210, ranked = true,
            damageRange = mediumDamageRange,
            skillTiers = mapOf(Sword to 4, EnfeeblingMagic to 1),
            meldBonusCaps = meldCaps(tier = 4, weaponPath = WeaponPath.Tp),
            buildUpRequirements = buildUpRequirements(tier = 4, weaponPath = WeaponPath.Tp),
        )

        // Sequence [t11]
        definitions += ItemDefinition(id = 20695, internalLevel = 50, damage = wdmg(11), delay = 210, ranked = true,
            damageRange = mediumDamageRange,
            skillTiers = mapOf(Sword to 11, EnfeeblingMagic to 2),
            meldBonusCaps = meldCaps(tier = 11, weaponPath = WeaponPath.Tp),
            buildUpRequirements = buildUpRequirements(tier = 11, weaponPath = WeaponPath.Tp),
        )

        // Sapara [d2]
        definitions += ItemDefinition(id = 16551, internalLevel = 5, damage = wdmg(2), delay = 180, ranked = true,
            damageRange = fastDamageRange,
            skillTiers = mapOf(Sword to 2, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16552),
            meldBonusCaps = meldCaps(tier = 2, weaponPath = WeaponPath.MultiAttack),
            buildUpRequirements = buildUpRequirements(tier = 2, weaponPath = WeaponPath.MultiAttack),
        )

        // Scimitar [d3]
        definitions += ItemDefinition(id = 16552, internalLevel = 10, damage = wdmg(3), delay = 180, ranked = true,
            damageRange = fastDamageRange,
            skillTiers = mapOf(Sword to 2, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16553),
            meldBonusCaps = meldCaps(tier = 3, weaponPath = WeaponPath.MultiAttack),
            buildUpRequirements = buildUpRequirements(tier = 3, weaponPath = WeaponPath.MultiAttack),
        )

        // Tulwar [d4]
        definitions += ItemDefinition(id = 16553, internalLevel = 15, damage = wdmg(4), delay = 180, ranked = true,
            damageRange = fastDamageRange,
            skillTiers = mapOf(Sword to 3, EnfeeblingMagic to 1),
            meldBonusCaps = meldCaps(tier = 4, weaponPath = WeaponPath.MultiAttack),
            buildUpRequirements = buildUpRequirements(tier = 4, weaponPath = WeaponPath.MultiAttack),
        )

        // Tizona [d11]
        definitions += ItemDefinition(id = 18986, internalLevel = 50, damage = wdmg(11), delay = 180, ranked = true,
            damageRange = fastDamageRange,
            skillTiers = mapOf(Sword to 11, EnfeeblingMagic to 2),
            meldBonusCaps = meldCaps(tier = 11, weaponPath = WeaponPath.MultiAttack),
            buildUpRequirements = buildUpRequirements(tier = 11, weaponPath = WeaponPath.MultiAttack),
        )

        // Falchion [w3]
        definitions += ItemDefinition(id = 16558, internalLevel = 10, damage = wdmg(3), delay = 240, ranked = true,
            damageRange = slowDamageRange,
            skillTiers = mapOf(Sword to 3, EnfeeblingMagic to 1),
            upgradeOptions = listOf(17650),
            meldBonusCaps = meldCaps(tier = 3, weaponPath = WeaponPath.WeaponSkill),
            buildUpRequirements = buildUpRequirements(tier = 3, weaponPath = WeaponPath.WeaponSkill),
        )

        // Nadrs [w4]
        definitions += ItemDefinition(id = 17650, internalLevel = 15, damage = wdmg(4), delay = 240, ranked = true,
            damageRange = slowDamageRange,
            skillTiers = mapOf(Sword to 3, EnfeeblingMagic to 1),
            upgradeOptions = listOf(16559),
            meldBonusCaps = meldCaps(tier = 4, weaponPath = WeaponPath.WeaponSkill),
            buildUpRequirements = buildUpRequirements(tier = 4, weaponPath = WeaponPath.WeaponSkill),
        )

        // Darksteel Falchion [w4]
        definitions += ItemDefinition(id = 16559, internalLevel = 20, damage = wdmg(5), delay = 240, ranked = true,
            damageRange = slowDamageRange,
            skillTiers = mapOf(Sword to 3, EnfeeblingMagic to 1),
            meldBonusCaps = meldCaps(tier = 5, weaponPath = WeaponPath.WeaponSkill),
            buildUpRequirements = buildUpRequirements(tier = 5, weaponPath = WeaponPath.WeaponSkill),
        )

        // Zomorrodnegar [d11]
        definitions += ItemDefinition(id = 21633, internalLevel = 50, damage = wdmg(11), delay = 240, ranked = true,
            damageRange = slowDamageRange,
            skillTiers = mapOf(Sword to 11, EnfeeblingMagic to 2),
            meldBonusCaps = meldCaps(tier = 11, weaponPath = WeaponPath.WeaponSkill),
            buildUpRequirements = buildUpRequirements(tier = 11, weaponPath = WeaponPath.WeaponSkill),
        )

        // Fermion Sword [m11]
        definitions += ItemDefinition(id = 20694, internalLevel = 50, damage = wdmg(11), delay = 240, ranked = true,
            damageRange = slowDamageRange,
            skillTiers = mapOf(Sword to 12, EnfeeblingMagic to 2),
            meldBonusCaps = meldCaps(tier = 11, weaponPath = WeaponPath.Magic),
            buildUpRequirements = buildUpRequirements(tier = 11, weaponPath = WeaponPath.Magic),
        )

        // Almace [c11]
        definitions += ItemDefinition(id = 19399, internalLevel = 50, damage = wdmg(11), delay = 270, ranked = true,
            damageRange = verySlowDamageRange,
            skillTiers = mapOf(Sword to 11, EnfeeblingMagic to 2),
            meldBonusCaps = meldCaps(tier = 11, weaponPath = WeaponPath.Crit),
            buildUpRequirements = buildUpRequirements(tier = 11, weaponPath = WeaponPath.Crit),
        )

        // Chakram
        definitions += ItemDefinition(id = 17284, internalLevel = 1, damage = 50, delay = 180, ranked = true)

        // Bronze Attire
        definitions += ItemDefinition(id = 12448, internalLevel = 1, ranked = true, combatStats = CombatStats(vit = 1, int = 5), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12576, internalLevel = 1, ranked = true, combatStats = CombatStats(vit = 1, str = 5), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12704, internalLevel = 1, ranked = true, combatStats = CombatStats(vit = 1, dex = 5), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12832, internalLevel = 1, ranked = true, combatStats = CombatStats(vit = 1, mnd = 5), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12960, internalLevel = 1, ranked = true, combatStats = CombatStats(vit = 1, agi = 5), augmentSlots = standardArmorAugments)

        // Cherry Yukata
        definitions += ItemDefinition(id = 11316, internalLevel = 1, augmentSlots = emptyList())
        definitions += ItemDefinition(id = 11317, internalLevel = 1, augmentSlots = emptyList())

        // Leather Attire
        definitions += ItemDefinition(id = 12440, internalLevel = 3, ranked = true, combatStats = CombatStats(vit = 1, int = 7), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12568, internalLevel = 3, ranked = true, combatStats = CombatStats(vit = 2, str = 7), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12696, internalLevel = 3, ranked = true, combatStats = CombatStats(vit = 1, dex = 7), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12824, internalLevel = 3, ranked = true, combatStats = CombatStats(vit = 2, mnd = 7), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12952, internalLevel = 3, ranked = true, combatStats = CombatStats(vit = 1, agi = 7), augmentSlots = standardArmorAugments)

        // Doublet Attire
        definitions += ItemDefinition(id = 12464, internalLevel = 5, ranked = true, combatStats = CombatStats(vit = 2, int = 9), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12592, internalLevel = 5, ranked = true, combatStats = CombatStats(vit = 2, str = 9), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12720, internalLevel = 5, ranked = true, combatStats = CombatStats(vit = 2, dex = 9), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12848, internalLevel = 5, ranked = true, combatStats = CombatStats(vit = 2, mnd = 9), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12976, internalLevel = 5, ranked = true, combatStats = CombatStats(vit = 2, agi = 9), augmentSlots = standardArmorAugments)

        // Xux Attire (Colkhab)
        definitions += ItemDefinition(id = 27781, internalLevel = 6, ranked = true, combatStats = CombatStats(vit = 2, int = 10), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 28201, internalLevel = 6, ranked = true, combatStats = CombatStats(vit = 2, mnd = 10), augmentSlots = standardArmorAugments)

        // Hebenus Attire (Swimwear)
        definitions += ItemDefinition(id = 23871, internalLevel = 1, augmentSlots = emptyList())
        definitions += ItemDefinition(id = 23872, internalLevel = 1, augmentSlots = emptyList())
        definitions += ItemDefinition(id = 23873, internalLevel = 1, augmentSlots = emptyList())
        definitions += ItemDefinition(id = 23874, internalLevel = 1, augmentSlots = emptyList())

        // Shneddick Attire
        definitions += ItemDefinition(id = 27762, internalLevel = 7, ranked = true, combatStats = CombatStats(vit = 2, int = 11), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 27908, internalLevel = 7, ranked = true, combatStats = CombatStats(vit = 3, str = 11), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 28047, internalLevel = 7, ranked = true, combatStats = CombatStats(vit = 2, dex = 11), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 28189, internalLevel = 7, ranked = true, combatStats = CombatStats(vit = 3, mnd = 11), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 28328, internalLevel = 7, ranked = true, combatStats = CombatStats(vit = 2, agi = 11), augmentSlots = standardArmorAugments)


        // Wool Attire
        definitions += ItemDefinition(id = 12467, internalLevel = 10, ranked = true, combatStats = CombatStats(vit = 2, int = 14), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12595, internalLevel = 10, ranked = true, combatStats = CombatStats(vit = 3, str = 14), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12730, internalLevel = 10, ranked = true, combatStats = CombatStats(vit = 3, dex = 14), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12851, internalLevel = 10, ranked = true, combatStats = CombatStats(vit = 3, mnd = 14), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12979, internalLevel = 10, ranked = true, combatStats = CombatStats(vit = 3, agi = 14), augmentSlots = standardArmorAugments)

        // Chocaliztli Attire
        definitions += ItemDefinition(id = 27780, internalLevel = 11, ranked = true, combatStats = CombatStats(vit = 3, int = 16), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 28343, internalLevel = 11, ranked = true, combatStats = CombatStats(vit = 3, agi = 16), augmentSlots = standardArmorAugments)

        // Shade Attire
        definitions += ItemDefinition(id = 15165, internalLevel = 12, ranked = true, combatStats = CombatStats(vit = 3, int = 17), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 14426, internalLevel = 12, ranked = true, combatStats = CombatStats(vit = 4, str = 17), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 14858, internalLevel = 12, ranked = true, combatStats = CombatStats(vit = 3, dex = 17), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 14327, internalLevel = 12, ranked = true, combatStats = CombatStats(vit = 4, mnd = 17), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 15315, internalLevel = 12, ranked = true, combatStats = CombatStats(vit = 3, agi = 17), augmentSlots = standardArmorAugments)

        // Judge's Attire (Debug)
        definitions += ItemDefinition(id = 12523, internalLevel = 50, ranked = true, combatStats = CombatStats(vit = 100, int = 500), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12551, internalLevel = 50, ranked = true, combatStats = CombatStats(vit = 100, str = 500), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12679, internalLevel = 50, ranked = true, combatStats = CombatStats(vit = 100, dex = 500), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12807, internalLevel = 50, ranked = true, combatStats = CombatStats(vit = 100, mnd = 500), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 12935, internalLevel = 50, ranked = true, combatStats = CombatStats(vit = 100, agi = 500), augmentSlots = standardArmorAugments)
        definitions += ItemDefinition(id = 13606, internalLevel = 50, ranked = true, combatStats = CombatStats(maxHp = 500), augmentSlots = standardBackAugments)
        definitions += ItemDefinition(id = 13215, internalLevel = 50, ranked = true, combatStats = CombatStats(maxHp = 500), augmentSlots = standardWaistAugments)

        // Ascetic's Ring
        definitions += ItemDefinition(id = 13440, internalLevel = 1, ranked = true, trusts = listOf(ItemTrust(898)), augmentSlots = listOf(
            WeightedTable.single(10),
        ))

        // Copper Ring
        definitions += ItemDefinition(id = 13454, internalLevel = 1, ranked = true, trusts = listOf(ItemTrust(902)), augmentSlots = listOf(
            WeightedTable.single(11),
        ))

        // Hermit's Ring
        definitions += ItemDefinition(id = 13475, internalLevel = 1, ranked = true, trusts = listOf(ItemTrust(896)), augmentSlots = listOf(
            WeightedTable.single(10),
        ))

        // Back
        definitions += ItemDefinition(id = 13594, internalLevel = 1, ranked = true, combatStats = CombatStats(maxHp = 5), augmentSlots = standardBackAugments)
        definitions += ItemDefinition(id = 13583, internalLevel = 5, ranked = true, combatStats = CombatStats(maxHp = 9), augmentSlots = standardBackAugments)
        definitions += ItemDefinition(id = 13592, internalLevel = 10, ranked = true, combatStats = CombatStats(maxHp = 14), augmentSlots = standardBackAugments)
        definitions += ItemDefinition(id = 15485, internalLevel = 12, ranked = true, combatStats = CombatStats(maxHp = 17), augmentSlots = standardBackAugments)

        // Belt
        definitions += ItemDefinition(id = 13199, internalLevel = 1, ranked = true, combatStats = CombatStats(maxHp = 5), augmentSlots = standardWaistAugments)
        definitions += ItemDefinition(id = 13192, internalLevel = 5, ranked = true, combatStats = CombatStats(maxHp = 9), augmentSlots = standardWaistAugments)
        definitions += ItemDefinition(id = 13193, internalLevel = 10, ranked = true, combatStats = CombatStats(maxHp = 14), augmentSlots = standardWaistAugments)
        definitions += ItemDefinition(id = 15881, internalLevel = 12, ranked = true, combatStats = CombatStats(maxHp = 17), augmentSlots = standardWaistAugments)

        definitionsById = definitions.associateBy { it.id }
        validate()
    }

    operator fun get(inventoryItem: InventoryItem): ItemDefinition {
        return getNullable(inventoryItem) ?: throw IllegalStateException("[${inventoryItem.id}] No such item definition")
    }

    fun getNullable(inventoryItem: InventoryItem): ItemDefinition? {
        val definition = definitionsById[inventoryItem.id] ?: return null
        return definition.scale(inventoryItem)
    }

    private fun validate() {
        for ((id, def) in definitionsById) {
            val info = InventoryItems[id]
            if (info.itemType == InventoryItemType.Weapon && !info.isGrip()) {
                check(def.damage > 0) { "[$def] Damage is unset for weapon" }
                check(def.delay > 0) { "[$def] Delay is unset for weapon" }
            }
        }
    }

    fun wdmg(tier: Int): Int {
        return when (tier) {
            1 -> 50
            2 -> 90
            3 -> 140
            4 -> 220
            5 -> 350
            6 -> 540
            7 -> 840
            8 -> 1320
            9 -> 2060
            10 -> 3210
            11 -> 5000
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

}

object Floor1AugmentPools {
    val bronzeEquipmentAugmentPool = WeightedTable.uniform(12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 26, 27, 28)
    val backAdditionalAugmentPool = WeightedTable.uniform(12, 18, 22, 23, 24, 26, 27, 28)
    val waistAdditionalAugmentPool = WeightedTable.uniform(12, 18, 22, 23, 24, 26, 27, 28)

    val standardBackAugments = listOf(backAdditionalAugmentPool, backAdditionalAugmentPool, backAdditionalAugmentPool)
    val standardWaistAugments = listOf(waistAdditionalAugmentPool, waistAdditionalAugmentPool, waistAdditionalAugmentPool)
}