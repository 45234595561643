package xim.poc.game

import xim.poc.game.event.AttackAddedEffectType
import xim.poc.game.event.AutoAttackRetaliationEffect

fun Int.toMultiplier(min: Float = Float.MIN_VALUE, max: Float = Float.MAX_VALUE): Float {
    return (1f + (this/100f)).coerceIn(min, max)
}

fun Int.toPenaltyMultiplier(min: Float = 0f, max: Float = Float.MAX_VALUE): Float {
    return (1f - (this/100f)).coerceIn(min, max)
}

data class AutoAttackEffect(
    var effectPower: Int,
    var effectType: AttackAddedEffectType,
)

data class CombatBonusAggregate(
    var additiveStats: CombatStatBuilder = CombatStatBuilder(),
    var multiplicativeStats: MutableMap<CombatStat, Float> = HashMap(),
    var statusResistances: MutableMap<StatusEffect, Int> = HashMap(),
    var regen: Int = 0,
    var refresh: Int = 0,
    var regain: Int = 0,
    var magicAttackBonus: Int = 0,
    var criticalHitRate: Int = 0,
    var enemyCriticalHitRate: Int = 0,
    var haste: Int = 0,
    var spellInterruptDown: Int = 0,
    var physicalDamageTaken: Int = 0,
    var magicalDamageTaken: Int = 0,
    var tpBonus: Int = 0,
    var guardRate: Int = 0,
    var parryRate: Int = 0,
    var fastCast: Int = 0,
    var mobSkillFastCast: Int = 0,
    var storeTp: Int = 0,
    var doubleAttack: Int = 0,
    var tripleAttack: Int = 0,
    var quadrupleAttack: Int = 0,
    var dualWield: Int = 0,
    var subtleBlow: Int = 0,
    var weaponSkillDamage: Int = 0,
    var criticalHitDamage: Int = 0,
    var skillChainDamage: Int = 0,
    var conserveTp: Int = 0,
    var magicBurstDamage: Int = 0,
    var movementSpeed: Int = 0,
    var criticalTpGain: Int = 0,
    var elementalWeaponSkillDamage: Int = 0,
    var doubleDamage: Int = 0,
    var followUpAttack: Int = 0,
    var knockBackResistance: Int = 0,
    var canDualWield: Boolean = false,
    var autoAttackEffects: MutableList<AutoAttackEffect> = ArrayList(),
    var autoAttackRetaliationEffects: MutableList<AutoAttackRetaliationEffect> = ArrayList(),
)