package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.MonsterId
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.event.ActorTargetEvent
import xim.poc.game.event.BattleEngageEvent
import xim.poc.game.event.InitialActorState
import xim.poc.gl.ByteColor
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.InventoryItems
import xim.util.PI_f
import xim.util.RandHelper

data class BossSpawnerDefinition(val position: Vector3f, val requiredItemIds: List<Int>, val bossMonsterId: MonsterId)

class BossSpawner(val definition: BossSpawnerDefinition) {

    private val bossDefinition = MonsterDefinitions[definition.bossMonsterId]

    private var spawnerPromise: ActorPromise? = null
    private var bossPromise: ActorPromise? = null

    init {
        createSpawner()
    }

    fun update(elapsedFrames: Float) {
        if (bossPromise == null) { return }

        val bossState = ActorStateManager[bossPromise?.getIfReady()]
        if (bossState != null && !bossState.isDead()) { return }

        bossPromise = null

        val spawnerActor = ActorManager[spawnerPromise?.getIfReady()]
        spawnerActor?.enqueueModelRoutine(DatId("s000"))
    }

    fun cleanUp() {
        spawnerPromise?.onReady { GameEngine.submitDeleteActor(it.id) }
        bossPromise?.onReady { GameEngine.submitDeleteActor(it.id) }
    }

    fun hasActiveBoss(): Boolean {
        return bossPromise != null
    }

    fun spawnBoss() {
        val spawnerActor = ActorManager[spawnerPromise?.getIfReady()]
        spawnerActor?.enqueueModelRoutine(DatId("s001"), options = RoutineOptions(callback = this::createBossState))
    }

    private fun createSpawner() {
        spawnerPromise = GameEngine.submitCreateActorState(
            InitialActorState(
                name = "???",
                type = ActorType.Object,
                position = definition.position,
                modelLook = ModelLook.npc(0x96F),
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
            )
        ).onReady {
            GameV0.interactionManager.registerInteraction(it.id, BossSpawnerInteraction(this))
            val actor = ActorManager.getOrCreate(it)
            actor.onReadyToDraw { actor.playRoutine(DatId("s000")) }
        }
    }

    private fun createBossState() {
        if (!consumeRequiredItems(ActorStateManager.player())) { return }

        bossPromise = V0MonsterHelper.spawnMonster(
            monsterDefinition = bossDefinition,
            position = definition.position,
        ).onReady {
            GameEngine.submitEvent(BattleEngageEvent(it.id, ActorStateManager.playerId))
            GameEngine.submitEvent(ActorTargetEvent(ActorStateManager.playerId, it.id))
        }.onReady {
            val actor = ActorManager.getOrCreate(it)
            actor.renderState.effectColor = ByteColor.zero

            actor.onReadyToDraw {
                actor.transitionToIdle(0f)
                actor.playRoutine(DatId.pop)
                actor.actorModel?.customModelSettings = bossDefinition.customModelSettings
            }
        }
    }

    private fun consumeRequiredItems(sourceActor: ActorState): Boolean {
        var success = true
        for (requiredItem in definition.requiredItemIds) {
            success = sourceActor.discardNotEquippedItems(requiredItem, 1) && success
        }
        return success
    }

}

class BossSpawnerInteraction(val bossSpawner: BossSpawner): NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        if (bossSpawner.hasActiveBoss()) {
            ChatLog("Now is not the time!", ChatLogColor.Error)
            return
        }

        val itemPrompts = bossSpawner.definition.requiredItemIds.map { InventoryItems[it] }
            .joinToString(", ") { "${ShiftJis.colorItem}${it.logName}${ShiftJis.colorClear}" }

        ChatLog("You sense that [$itemPrompts] is needed...")

        val player = ActorStateManager.player()
        for (item in bossSpawner.definition.requiredItemIds) {
            if (!player.discardNotEquippedItems(item, 1, validateOnly = true)) { return }
        }

        UiStateHelper.openQueryMode(prompt = "Offer the items?", options = getOptions(), callback = this::handleResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption(text = "No", value = -1)
        options += QueryMenuOption(text = "Yes", value = 1)
        return options
    }

    private fun handleResponse(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value < 0) { return QueryMenuResponse.pop }
        GameClient.submitTargetUpdate(ActorStateManager.playerId, null)
        bossSpawner.spawnBoss()
        return QueryMenuResponse.pop
    }


}