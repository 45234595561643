package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.configuration.Skill
import xim.poc.game.configuration.SkillApplierHelper
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.Event

class MobElementalController(
    actorState: ActorState,
    val idleSpellList: List<Int>,
    val engagedSpellList: List<Int>,
): BasicMonsterController(actorState) {

    override fun eligibleToUseSkill(): Boolean {
        return actorState.getTpp() >= 0.3f
    }

    override fun selectSkill(): Skill? {
        return if (actorState.isEngaged()) {
            val spellId = engagedSpellList.randomOrNull() ?: return null
            Skill(SkillType.Spell, spellId)
        } else {
            val spellId = idleSpellList.randomOrNull() ?: return null
            Skill(SkillType.Spell, spellId)
        }
    }

    override fun onSkillExecuted(primaryTargetContext: SkillApplierHelper.TargetEvaluatorContext): List<Event> {
        actorState.setTp(0)
        return emptyList()
    }

}