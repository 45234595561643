package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AugmentHelper
import xim.poc.game.GameState
import xim.poc.game.InternalItemId
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis
import kotlin.math.ceil
import kotlin.math.min
import kotlin.math.roundToInt

class ActorReinforceItemEvent(
    val sourceId: ActorId,
    val targetItemId: InternalItemId,
    val upgradeItemId: InternalItemId,
    val upgradeItemQuantity: Int,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()

        val inventoryItem = source.inventory.getByInternalId(targetItemId) ?: return emptyList()

        val upgradeItem = source.inventory.getByInternalId(upgradeItemId) ?: return emptyList()
        if (upgradeItem.quantity < upgradeItemQuantity) { return emptyList() }

        val reinforcementValues = GameState.getGameMode().getItemReinforcementValues(inventoryItem)
        val reinforcementValue = reinforcementValues[upgradeItem.id] ?: return emptyList()

        val augment = inventoryItem.augments ?: return emptyList()

        val rpNext = AugmentHelper.getRpToNextLevel(augment) - augment.rankPoints
        val maxItemsUsable = ceil(rpNext.toFloat() / reinforcementValue.toFloat()).roundToInt()

        val itemsConsumed = min(upgradeItemQuantity, maxItemsUsable)
        source.inventory.discardItem(upgradeItemId, itemsConsumed)

        val amount = itemsConsumed * reinforcementValue

        if (source.isPlayer()) {
            ChatLog("${ShiftJis.colorAug}[${inventoryItem.info().name}] gained $amount RP!${ShiftJis.colorClear}")
        }

        return listOf(InventoryItemRpGainEvent(sourceId, targetItemId, amount))
    }

}