package xim.poc.game.configuration.v0.behaviors

import xim.poc.EnvironmentManager
import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.AutoAttackState
import xim.poc.game.configuration.SkillApplierHelper
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.CastSpellStart
import xim.poc.game.event.Event
import xim.resource.DatId
import xim.util.Fps
import xim.util.addInPlace
import kotlin.random.Random

class MobTchakkaController(val actorState: ActorState) : ActorBehaviorController {

    private val autoAttackState = AutoAttackState(actorState)

    private var verveCount = 0

    private var auraSkillsUsed = 0
    private val queuedAuraSkills = ArrayDeque<Int>()

    private val auraAbilityCooldown = Fps.secondsToFrames(3f)
    private var currentAuraAbilityCooldown = auraAbilityCooldown

    override fun update(elapsedFrames: Float): List<Event> {
        adjustWeather()

        if (!actorState.isIdleOrEngaged()) { return emptyList() }
        if (!GameEngine.canBeginAction(actorState)) { return emptyList() }

        val events = ArrayList<Event>()

        events += if (wantsToEnableAura()) {
            useVerve()
        } else if (hasAura()) {
            handleAuraState(elapsedFrames)
        } else {
            handleDefaultState(elapsedFrames)
        }

        return events
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.knockBackResistance += 100
        aggregate.statusResistances.addInPlace(StatusEffect.Sleep, 100)
        aggregate.statusResistances.addInPlace(StatusEffect.Bind, 100)
        aggregate.statusResistances.addInPlace(StatusEffect.Paralysis, 100)
        aggregate.refresh += 100
        aggregate.spellInterruptDown = 100

        if (hasAura() || wantsToEnableAura()) {
            aggregate.mobSkillFastCast += 75
            aggregate.statusResistances.addInPlace(StatusEffect.Stun, 100)
            aggregate.movementSpeed -= 100
        } else {
            aggregate.movementSpeed += 50
        }
    }

    override fun onSkillExecuted(primaryTargetContext: SkillApplierHelper.TargetEvaluatorContext): List<Event> {
        if (primaryTargetContext.skill.skillType != SkillType.MobSkill) { return emptyList() }
        if (primaryTargetContext.skillInfo.id != 2758) { return emptyList() }

        verveCount += 1
        auraSkillsUsed = 0
        actorState.appearanceState = 1
        currentAuraAbilityCooldown = auraAbilityCooldown + Fps.secondsToFrames(1f)
        return emptyList()
    }

    override fun onDefeated(): List<Event> {
        actorState.appearanceState = 0
        adjustWeather()
        return emptyList()
    }

    private fun hasAura(): Boolean {
        return actorState.appearanceState == 1
    }

    private fun wantsToEnableAura(): Boolean {
        return !hasAura() && when (verveCount) {
            0 -> actorState.getHpp() <= 0.80f
            1 -> actorState.getHpp() <= 0.40f
            else -> false
        }
    }

    private fun adjustWeather() {
        if (actorState.appearanceState == 0) {
            EnvironmentManager.switchWeather(DatId.weatherSunny)
        } else if (actorState.appearanceState == 1) {
            EnvironmentManager.switchWeather(DatId.weatherRain)
        }
    }

    private fun handleAuraState(elapsedFrames: Float): List<Event> {
        if (auraSkillsUsed >= 9) {
            actorState.appearanceState = 0
            return emptyList()
        }

        currentAuraAbilityCooldown -= elapsedFrames
        if (currentAuraAbilityCooldown > 0f) { return emptyList() }

        currentAuraAbilityCooldown = auraAbilityCooldown

        val target = ActorStateManager[actorState.getTargetId()] ?: return emptyList()

        if (queuedAuraSkills.isEmpty()) { populateAuraSkills() }
        val skill = queuedAuraSkills.removeFirst()

        auraSkillsUsed += 1

        return if (skill == 830 || skill == 838) {
            listOf(CastSpellStart(actorState.id, target.id, skill))
        } else {
            listOf(CastMobSkillStart(actorState.id, target.id, skill))
        }
    }

    private fun populateAuraSkills() {
        queuedAuraSkills += 2760
        val magicSkill = if (GameEngine.canBeginCastSpell(actorState.id, 830)) { 830 } else { 838 }

        if (Random.nextBoolean()) {
            queuedAuraSkills += 2759
            queuedAuraSkills += magicSkill
        } else {
            queuedAuraSkills += magicSkill
            queuedAuraSkills += 2759
        }
    }

    private fun handleDefaultState(elapsedFrames: Float): List<Event> {
        if (actorState.getTp() >= 500) {
            actorState.setTp(0)
            return useWeaponSkillAbility()
        }

        autoAttackState.update(elapsedFrames)
        if (autoAttackState.isReadyToAutoAttack()) {
            autoAttackState.resetAutoAttack()
            return useAutoAttackAbility()
        }

        return emptyList()
    }

    private fun useAutoAttackAbility(): List<Event> {
        val aa = listOf(2752, 2753, 2754).random()
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = aa))
    }

    private fun useWeaponSkillAbility(): List<Event> {
        val ws = listOf(2755, 2756, 2757).random()
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = ws))
    }

    private fun useVerve(): List<Event> {
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = actorState.id, mobSkillId = 2758))
    }

}
