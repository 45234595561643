package xim.resource.table

import xim.poc.ActionTargetFilter
import xim.poc.browser.DatLoader
import xim.resource.ByteReader
import xim.resource.TargetFlag
import xim.resource.table.AdditionalMobAnimations.additionalAnimations

val MobSkillNameTable = BasicStringTable("ROM/27/80.DAT", 0x80.toByte())

data class MobSkillInfo(val id: Int, val animationId: Int, val type: Int) {

    val targetFlag: Int = validTargetsToTargetFlag(type)
    val targetFilter = ActionTargetFilter(targetFlag)

    private fun validTargetsToTargetFlag(validTargets: Int): Int {
        return when (validTargets) {
            1 -> TargetFlag.Self.flag
            2 -> TargetFlag.Party.flag
            3 -> TargetFlag.Self.flag or TargetFlag.Party.flag
            4 -> TargetFlag.Enemy.flag
            5 -> TargetFlag.Self.flag or TargetFlag.Player.flag
            // 16 -> pup?
            else -> 0
        }
    }

}

// https://raw.githubusercontent.com/LandSandBoat/server/base/sql/mob_skills.sql
// with some overrides & additions listed below
object MobSkillInfoTable: LoadableResource {

    private lateinit var animInfos: Map<Int, MobSkillInfo>
    private var preloaded = false

    override fun preload() {
        if (preloaded) { return }
        preloaded = true
        loadTable()
    }

    override fun isFullyLoaded() : Boolean {
        return this::animInfos.isInitialized
    }

    operator fun get(skillId: Int): MobSkillInfo? {
        return animInfos[skillId]
    }

    fun getAnimationPath(info: MobSkillInfo): String? {
        return getAnimationPath(info.animationId)
    }

    fun getAnimationPath(animationId: Int): String? {
        val fileTableOffset = if (animationId < 0x200) {
            0x0F3C
        } else if (animationId < 0x600) {
            0xC1EF
        } else if (animationId < 0x800) {
            0xE739
        } else {
            0x14B07
        }

        val fileTableIndex = fileTableOffset + animationId
        return FileTableManager.getFilePath(fileTableIndex)
    }

    private fun loadTable() {
        DatLoader.load("landsandboat/MobSkillsTable.DAT").onReady { parseTable(it.getAsBytes()) }
    }

    private fun parseTable(byteReader: ByteReader) {
        val skills = HashMap<Int, MobSkillInfo>()
        while (byteReader.hasMore()) {
            val skill = MobSkillInfo(id = byteReader.next16(), animationId = byteReader.next16(), type = byteReader.next16())
            skills[skill.id] = skill
        }
        this.animInfos = skills + additionalAnimations()
    }

}

private object AdditionalMobAnimations {

    fun additionalAnimations(): Map<Int, MobSkillInfo> {
        return listOf(
            MobSkillInfo(id = 270, animationId = 0x36B, type = 1), // Snoll:Berserk
            MobSkillInfo(id = 271, animationId = 0x36C, type = 4), // Snoll:Freeze Rush
            MobSkillInfo(id = 272, animationId = 0x36D, type = 4), // Snoll:Cold Wave
            MobSkillInfo(id = 273, animationId = 0x36E, type = 4), // Snoll:Hypothermal Combustion

            MobSkillInfo(id = 315, animationId = 0x366, type = 4), // Cluster:Self-Destruct (3->2)
            MobSkillInfo(id = 316, animationId = 0x367, type = 4), // Cluster:Self-Destruct (3->0)
            MobSkillInfo(id = 317, animationId = 0x368, type = 4), // Cluster:Self-Destruct (2->1)
            MobSkillInfo(id = 318, animationId = 0x369, type = 4), // Cluster:Self-Destruct (2->0)
            MobSkillInfo(id = 319, animationId = 0x36A, type = 4), // Cluster:Self-Destruct (1->0)

            MobSkillInfo(id = 342, animationId = 0x374, type = 1), // Snoll Tzar:Berserk
            MobSkillInfo(id = 343, animationId = 0x375, type = 4), // Snoll Tzar:Arctic Impact
            MobSkillInfo(id = 344, animationId = 0x376, type = 4), // Snoll Tzar:Cold Wave
            MobSkillInfo(id = 345, animationId = 0x377, type = 4), // Snoll Tzar:Hiemal Storm
            MobSkillInfo(id = 346, animationId = 0x378, type = 4), // Snoll Tzar:Hypothermal Combustion

            MobSkillInfo(id = 965, animationId = 0x346, type = 4), // Weeper:Memories of Fire
            MobSkillInfo(id = 966, animationId = 0x347, type = 4), // Weeper:Memories of Ice
            MobSkillInfo(id = 967, animationId = 0x348, type = 4), // Weeper:Memories of Wind
            MobSkillInfo(id = 968, animationId = 0x349, type = 4), // Weeper:Memories of Light
            MobSkillInfo(id = 969, animationId = 0x34A, type = 4), // Weeper:Memories of Earth
            MobSkillInfo(id = 970, animationId = 0x34B, type = 4), // Weeper:Memories of Lightning
            MobSkillInfo(id = 971, animationId = 0x34C, type = 4), // Weeper:Memories of Water
            MobSkillInfo(id = 972, animationId = 0x34D, type = 4), // Weeper:Memories of Darkness

            MobSkillInfo(id = 1582, animationId = 0x4C5, type = 5), // Mine:Mine Blast

            MobSkillInfo(id = 2694, animationId = 0x851, type = 4), // Twitherym:Tempestuous Upheaval
            MobSkillInfo(id = 2695, animationId = 0x852, type = 4), // Twitherym:Slice'n'Dice
            MobSkillInfo(id = 2696, animationId = 0x853, type = 4), // Twitherym:Black Out
            MobSkillInfo(id = 2697, animationId = 0x854, type = 4), // Twitherym:Smouldering Swarm

            MobSkillInfo(id = 2743, animationId = 0x855, type = 4), // Bztavian:Auto-Attack 0
            MobSkillInfo(id = 2744, animationId = 0x856, type = 4), // Bztavian:Auto-Attack 1
            MobSkillInfo(id = 2745, animationId = 0x857, type = 4), // Bztavian:Auto-Attack 2
            MobSkillInfo(id = 2746, animationId = 0x858, type = 4), // Bztavian:Mandibular Lashing
            MobSkillInfo(id = 2747, animationId = 0x859, type = 4), // Bztavian:Vespine Hurricane
            MobSkillInfo(id = 2748, animationId = 0x85A, type = 4), // Bztavian:Stinger Volley
            MobSkillInfo(id = 2749, animationId = 0x85B, type = 4), // Bztavian:Droning Whirlwind
            MobSkillInfo(id = 2750, animationId = 0x85C, type = 4), // Bztavian:Incisive Denouement
            MobSkillInfo(id = 2751, animationId = 0x85D, type = 4), // Bztavian:Incisive Apotheosis

            MobSkillInfo(id = 2698, animationId = 0x881, type = 4), // Craklaw:Auto-Attack 0 (Triple)
            MobSkillInfo(id = 2699, animationId = 0x882, type = 4), // Craklaw:Auto-Attack 1 (Swipe)
            MobSkillInfo(id = 2700, animationId = 0x883, type = 4), // Craklaw:Auto-Attack 2 (Spin)
            MobSkillInfo(id = 2701, animationId = 0x884, type = 1), // Craklaw:Impenetrable Carapace
            MobSkillInfo(id = 2702, animationId = 0x885, type = 4), // Craklaw:Rending Deluge
            MobSkillInfo(id = 2703, animationId = 0x886, type = 4), // Craklaw:Sundering Snip
            MobSkillInfo(id = 2704, animationId = 0x887, type = 4), // Craklaw:Viscid Spindrift
            MobSkillInfo(id = 2705, animationId = 0x888, type = 4), // Craklaw:Riptide Eupnea

            MobSkillInfo(id = 2752, animationId = 0x891, type = 4), // Rockfin:Auto-Attack 0 (Bite)
            MobSkillInfo(id = 2753, animationId = 0x892, type = 4), // Rockfin:Auto-Attack 1 (Charge)
            MobSkillInfo(id = 2754, animationId = 0x893, type = 4), // Rockfin:Auto-Attack 2 (Spin)
            MobSkillInfo(id = 2755, animationId = 0x894, type = 4), // Rockfin:Protolithic Puncture
            MobSkillInfo(id = 2756, animationId = 0x895, type = 4), // Rockfin:Aquatic Lance
            MobSkillInfo(id = 2757, animationId = 0x896, type = 4), // Rockfin:Pelagic Cleaver
            MobSkillInfo(id = 2758, animationId = 0x897, type = 1), // Rockfin:Carcharian Verve
            MobSkillInfo(id = 2759, animationId = 0x898, type = 4), // Rockfin:Tidal Guillotine
            MobSkillInfo(id = 2760, animationId = 0x899, type = 4), // Rockfin:Marine Mayhem

            MobSkillInfo(id = 2813, animationId = 0x8A6, type = 4), // Waktza:Auto-Attack 0
            MobSkillInfo(id = 2814, animationId = 0x8A7, type = 4), // Waktza:Auto-Attack 1
            MobSkillInfo(id = 2815, animationId = 0x8A8, type = 4), // Waktza:Auto-Attack 2
            MobSkillInfo(id = 2816, animationId = 0x8A9, type = 4), // Waktza:Crashing Thunder
            MobSkillInfo(id = 2817, animationId = 0x8AA, type = 4), // Waktza:Reverberating Cry
            MobSkillInfo(id = 2818, animationId = 0x8AB, type = 4), // Waktza:Brown Out
            MobSkillInfo(id = 2819, animationId = 0x8AC, type = 4), // Waktza:Reverse Current
            MobSkillInfo(id = 2820, animationId = 0x8AD, type = 4), // Waktza:Sparkstorm
            MobSkillInfo(id = 2821, animationId = 0x8AE, type = 4), // Waktza:Static Prison
        ).associateBy { it.id }
    }

}
