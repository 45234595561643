package xim.poc.game.configuration.v0.tower

import xim.poc.ActorAssociation
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.NoOpActorController
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.FloorEntity
import xim.poc.game.configuration.v0.GameTower
import xim.poc.game.configuration.v0.GameV0
import xim.poc.game.configuration.v0.zones.BattleLocation
import xim.poc.game.event.InitialActorState
import xim.poc.gl.ByteColor
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.util.Fps
import kotlin.time.Duration.Companion.seconds

class FloorExit(val floorConfiguration: FloorConfiguration, val location: BattleLocation, val monsterSpawnerInstance: MonsterSpawnerInstance): FloorEntity {

    private var promise = spawn()

    override fun update(elapsedFrames: Float) { }

    override fun cleanup() {
        promise.onReady { GameEngine.submitDeleteActor(it.id) }
    }

    private fun spawn(): ActorPromise {
        return GameEngine.submitCreateActorState(
            InitialActorState(
                name = "Ethereal Ingress",
                type = ActorType.Object,
                position = location.exitPosition,
                modelLook = location.exitLook,
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
            )
        ).onReady {
            GameV0.interactionManager.registerInteraction(it.id, FloorExitInteraction(this))
        }
    }

}

private class FloorExitInteraction(val floorExit: FloorExit) : NpcInteraction {

    private val floorConfiguration = floorExit.floorConfiguration
    private val monsterSpawnerInstance = floorExit.monsterSpawnerInstance

    override fun onInteraction(npcId: ActorId) {
        val totalDefeated = monsterSpawnerInstance.getTotalDefeated()

        if (totalDefeated < floorConfiguration.defeatRequirement) {
            val remaining = floorConfiguration.defeatRequirement - totalDefeated
            val word = if (remaining > 1) { "enemies" } else { "enemy" }
            ChatLog("Defeat $remaining more $word to proceed...", ChatLogColor.Info)
            return
        }

        UiStateHelper.openQueryMode(
            prompt = "What will you do?",
            options = getOptions(),
        ) { handleQueryResponse(it) }
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        options += QueryMenuOption("Proceed to next floor.", value = 1)
        options += QueryMenuOption("Repeat this floor.", value = 2)
        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?): QueryMenuResponse {
        if (response == null || response.value < 0) { return QueryMenuResponse.pop }

        if (response.value == 2 && !monsterSpawnerInstance.hasDefeatedAllMonsters()) {
            ChatLog("Defeat all monsters in order to repeat this floor.", ChatLogColor.Info)
            return QueryMenuResponse.pop
        }

        val repeat = response.value == 2
        nextFloor(repeat)

        return QueryMenuResponse.pop
    }

    private fun nextFloor(repeat: Boolean) {
        val playerAssociation = ActorAssociation(ActorManager.player())

        val (nextFloorDefinition, nextFloorNumber) = if (repeat) {
            GameTower.getCurrentFloorDefinition() to GameTower.getCurrentFloor()
        } else {
            GameTower.getNextFloorDefinition() to GameTower.advanceTowerFloor()
        }

        val script = EventScript(
            listOf(
                EffectRoutineEventItem(fileTableIndex = 0x114CB, playerAssociation, eagerlyComplete = true),
                WaitRoutine(Fps.secondsToFrames(1.9f)),
                FadeOutEvent(1.seconds),
                WarpZoneEventItem(nextFloorDefinition.battleLocation.startingPosition, fade = false),
                RunOnceEventItem {
                    GameTower.emitFloorNumber()
                    GameV0.setupBattle(nextFloorNumber, repeated = repeat)
                    ActorManager.player().renderState.effectColor = ByteColor.half
                },
                FadeInEvent(1.seconds),
            )
        )

        EventScriptRunner.runScript(script)
    }

}