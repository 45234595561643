package xim.poc.game

import xim.poc.ActorContext
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.RoutineOptions
import xim.poc.browser.DatLoader
import xim.resource.*
import xim.resource.table.*
import xim.util.OnceLogger

object EffectDisplayer {

    fun displaySpell(spellInfo: SpellInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val animationId = SpellAnimationTable[spellInfo]
        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Spell has no resource? $spellInfo")
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    fun displayAbility(abilityInfo: AbilityInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val source = ActorStateManager[sourceId] ?: return
        val race = source.getCurrentLook().race

        val animationId = if (abilityInfo.index == AbilityId.DoubleUp.id) {
            val linkedAbility = source.getStatusEffect(StatusEffect.DoubleUpChance)?.linkedAbilityId ?: return
            AbilityTable.getAnimationId(AbilityInfoTable[linkedAbility], race)
        } else {
            AbilityTable.getAnimationId(abilityInfo, race)
        }

        if (abilityInfo.type == AbilityType.PetAbility || abilityInfo.type == AbilityType.PetWard) {
            displayPetAbility(source, primaryTargetId, abilityInfo)
        }

        if (animationId == null) {
            OnceLogger.warn("No animation for: ${AbilityNameTable.first(abilityInfo.index)}")
            return
        }

        val datPath = FileTableManager.getFilePath(animationId) ?: throw IllegalStateException("Ability has no resource? ${abilityInfo.index}")
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    fun displayMobSkill(mobSkillInfo: MobSkillInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        // Some mob-skills don't invoke [stnm], causing the charging effect to continue indefinitely.
        // Is there another op-code that has a side effect of doing the same thing?
        val source = ActorManager[sourceId] ?: return
        source.playRoutine(DatId("stnm"))

        val datPath = MobSkillInfoTable.getAnimationPath(mobSkillInfo) ?: return
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    private fun displayPetAbility(actor: ActorState, targetId: ActorId, abilityInfo: AbilityInfo) {
        val pet = ActorStateManager[actor.pet] ?: return
        val petAnimation = PetSkillTable.getAnimationId(abilityInfo) ?: return
        val petDatPath = FileTableManager.getFilePath(petAnimation) ?: return
        displayMain(datPath = petDatPath, sourceId = pet.id, primaryTargetId = targetId, attackContext = AttackContext())
    }

    fun displayItemAnimation(inventoryItemInfo: InventoryItemInfo, sourceId: ActorId, primaryTargetId: ActorId, allTargetIds: List<ActorId>, actionContext: AttackContexts) {
        val source = ActorManager[sourceId]
        val animPath = ItemAnimationTable.getAnimationPath(inventoryItemInfo)

        if (animPath == null) {
            source?.transitionToIdle(10f)
            return
        }

        displayMain(datPath = animPath, sourceId = sourceId, primaryTargetId = primaryTargetId, allTargets = allTargetIds, attackContexts = actionContext)
    }

    fun displayMain(datPath: String, sourceId: ActorId, primaryTargetId: ActorId, attackContext: AttackContext) {
        displayMain(datPath = datPath, sourceId = sourceId, primaryTargetId = primaryTargetId, attackContexts = AttackContexts.single(primaryTargetId, attackContext))
    }

    private fun displayMain(datPath: String, sourceId: ActorId, primaryTargetId: ActorId, allTargets: List<ActorId> = listOf(primaryTargetId), attackContexts: AttackContexts) {
        val source = ActorManager[sourceId] ?: return
        val target = ActorManager[primaryTargetId] ?: return

        val context = ActorContext(originalActor = source.id, primaryTargetId = target.id, allTargetIds = allTargets, attackContexts = attackContexts)
        val datWrapper = DatLoader.load(datPath)

        source.enqueueRoutine(context, options = RoutineOptions(highPriority = true)) {
            datWrapper.getAsResourceIfReady()?.getNullableChildRecursivelyAs(DatId.main, EffectRoutineResource::class)
        }
    }

}