package xim.poc.game.configuration.assetviewer

import xim.poc.SynthesisType
import xim.poc.game.configuration.SynthesisRecipe
import xim.poc.game.configuration.SynthesisRecipes

object AssetViewerSynthesisRecipes {

    val recipes = listOf(
        SynthesisRecipe(0x0001, SynthesisType.Fire, output = 650, input = listOf(642, 640, 640, 640)),
        SynthesisRecipe(0x0002, SynthesisType.Fire, output = 13465, input = listOf(650)),
        SynthesisRecipe(0x0003, SynthesisType.Fire, output = 13444, input = listOf(13465)),
        SynthesisRecipe(0x0004, SynthesisType.Water, output = 4422, input = listOf(4392, 4392, 4392, 4392)),
    )

    init {
        recipes.forEach { SynthesisRecipes.register(it) }
    }

}