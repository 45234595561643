package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine
import xim.poc.game.configuration.GatheringConfiguration
import xim.poc.game.configuration.GatheringNodeSpawner
import xim.poc.game.configuration.MonsterSpawnerDefinition
import xim.poc.game.configuration.MonsterSpawnerInstance
import xim.poc.game.configuration.v0.events.ActorLearnSpellEvent
import xim.poc.game.configuration.v0.tower.FloorConfiguration
import xim.poc.game.configuration.v0.tower.FloorEntrance
import xim.poc.game.configuration.v0.tower.FloorExit
import xim.poc.game.configuration.v0.tower.TowerConfiguration
import xim.poc.game.configuration.v0.zones.BattleLocation
import xim.util.Fps
import kotlin.math.atan2

class FloorDefinition(
    val gatheringPoints: List<GatheringConfiguration>,
    val monsterSpawnerDefinitions: List<MonsterSpawnerDefinition>,
    val bossSpawners: List<BossSpawnerDefinition>,
    val location: BattleLocation,
    val configuration: FloorConfiguration,
) {

    companion object {

        fun fromFloor(floorNumber: Int): FloorDefinition {
            val floor = TowerConfiguration[floorNumber]
            val location = floor.battleLocation

            val spawnerChest = SpawnerChestDefinition(
                position = location.treasureChestPosition,
                rotation = getRotation(location.startingPosition.startPosition!! - location.treasureChestPosition),
                dropSlots = floor.chestTable,
                treasureChestLook = TreasureChestLook.Red,
            )
            val spawner = MonsterSpawnerDefinition(
                spawnArea = location.spawnerArea,
                maxMonsters = floor.maxActive,
                maxSpawnCount = floor.maxSpawnCount,
                spawnDelay = Fps.secondsToFrames(15),
                chestDefinition = spawnerChest,
                providerFactory = floor.monsterProviderFactory,
            )

            return FloorDefinition(
                gatheringPoints = emptyList(),
                monsterSpawnerDefinitions = listOf(spawner),
                bossSpawners = emptyList(),
                location = floor.battleLocation,
                configuration = floor,
            )
        }

        private fun getRotation(direction: Vector3f): Float {
            val horizontal = direction.normalize().withY(0f)
            if (horizontal.magnitudeSquare() < 1e-5f) { return 0f }

            val dir = horizontal.normalize()
            return -atan2(dir.z, dir.x)
        }
    }

    fun newInstance(): FloorInstance {
        val floorInstance = FloorInstance(this)

        for (gatheringPoint in gatheringPoints) {
            floorInstance.addGatheringNodeSpawner(gatheringPoint)
        }

        for (monsterSpawnerDef in monsterSpawnerDefinitions) {
            floorInstance.addMonsterSpawner(monsterSpawnerDef)
        }

        for (bossSpawnerDef in bossSpawners) {
            floorInstance.addBossSpawner(bossSpawnerDef)
        }

        floorInstance.addFloorExit(configuration, location)

        return floorInstance
    }

}

class FloorInstance(val definition: FloorDefinition): ZoneLogic {

    private val monsterSpawners = ArrayList<MonsterSpawnerInstance>()
    private val gatheringPointSpawners = ArrayList<GatheringNodeSpawner>()
    private val treasureChests = ArrayList<SpawnerChest>()
    private val bossSpawners = ArrayList<BossSpawner>()
    private val floorEntities = ArrayList<FloorEntity>()

    private var cleared = false
    private var fullCleared = false

    init {
        floorEntities += FloorEntrance(definition.location)
    }

    override fun update(elapsedFrames: Float) {
        val playerPosition = ActorStateManager.player().position
        definition.location.boundaries.forEach { it.apply(playerPosition) }

        bossSpawners.forEach { it.update(elapsedFrames) }
        gatheringPointSpawners.forEach { it.update(elapsedFrames) }
        monsterSpawners.forEach { it.update(elapsedFrames) }
        treasureChests.forEach { it.update(elapsedFrames) }
        floorEntities.forEach { it.update(elapsedFrames) }

        val primarySpawner = monsterSpawners.firstOrNull()
        if (!cleared && primarySpawner != null && primarySpawner.getTotalDefeated() >= definition.configuration.defeatRequirement) {
            cleared = true
            definition.location.onCleared?.invoke()
            GameTower.onClearedFloor(definition.configuration.floorNumber)
        }

        if (!fullCleared && primarySpawner != null && primarySpawner.hasDefeatedAllMonsters()) {
            fullCleared = true
            GameV0Helpers.learnSpells(ActorStateManager.player(), definition.configuration.blueMagicReward)
        }

    }

    override fun cleanUp() {
        monsterSpawners.forEach { it.clear() }
        gatheringPointSpawners.forEach { it.clear() }
        bossSpawners.forEach { it.cleanUp() }
        treasureChests.forEach { it.clear() }
        floorEntities.forEach { it.cleanup() }
    }

    fun addMonsterSpawner(monsterSpawnerDefinition: MonsterSpawnerDefinition) {
        val instance = MonsterSpawnerInstance(monsterSpawnerDefinition)
        monsterSpawners += instance

        if (monsterSpawnerDefinition.chestDefinition != null && monsterSpawnerDefinition.chestDefinition.dropSlots.isNotEmpty()) {
            treasureChests += SpawnerChest(monsterSpawnerDefinition.chestDefinition, instance)
        }
    }

    fun addGatheringNodeSpawner(gatheringConfiguration: GatheringConfiguration) {
        gatheringPointSpawners += GatheringNodeSpawner(gatheringConfiguration)
    }

    fun addBossSpawner(bossSpawnerDefinition: BossSpawnerDefinition) {
        bossSpawners += BossSpawner(bossSpawnerDefinition)
    }

    fun addFloorExit(configuration: FloorConfiguration, location: BattleLocation) {
        val spawner = monsterSpawners.firstOrNull() ?: return
        floorEntities += FloorExit(configuration, location, spawner)
    }

}