package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.ui.ChatLog

enum class AutoAttackType {
    Main,
    Sub,
    H2H,
    Ranged,
}

enum class AttackAddedEffectType(val contextDisplay: Int?, val damageResource: ActorResourceType) {
    Fire(1, ActorResourceType.HP),
    Ice(2, ActorResourceType.HP),
    Wind(3, ActorResourceType.HP),
    Earth(4, ActorResourceType.HP),
    Lightning(5, ActorResourceType.HP),
    Water(6, ActorResourceType.HP),
    Light(7, ActorResourceType.HP),
    Dark(8, ActorResourceType.HP),
    Aspir(21, ActorResourceType.MP),
    Drain(22, ActorResourceType.HP),
}

enum class AttackRetaliationEffectType(val contextDisplay: Int?, val damageResource: ActorResourceType) {
    IceSpikes(2, ActorResourceType.HP),
}

data class AutoAttackStatus(
    val statusEffect: StatusEffect,
    val statusPotency: Int,
)

data class AutoAttackAddedEffect(
    val damage: Int,
    val type: AttackAddedEffectType,
)

data class AutoAttackRetaliationEffect(
    val damage: Int,
    val type: AttackRetaliationEffectType,
    val statusEffect: AttackStatusEffect? = null,
)

data class AutoAttackResult(
    val context: AttackContext,
    val tpGained: Int,
    val damageDone: Int,
    val targetId: ActorId,
    val targetTpGained: Int,
    val type: AutoAttackType,
    val addedEffects: List<AutoAttackAddedEffect> = emptyList(),
    val retaliationEffects: List<AutoAttackRetaliationEffect> = emptyList(),
)

class AutoAttackEvent(
    val sourceId: ActorId,
    val targetId: ActorId? = null,
    val rangedAttack: Boolean = false,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        if (sourceState.isDead()) { return emptyList() }

        val targetState = ActorStateManager[targetId ?: sourceState.targetState.targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        if (GameEngine.checkParalyzeProc(sourceState)) {
            ChatLog.paralyzed(sourceState.name)
            return emptyList()
        }

        val actor = ActorManager[sourceId]
        val outputEvents = ArrayList<Event>()

        val results = if (rangedAttack) {
            GameState.getGameMode().getRangedAttackResult(sourceState, targetState)
        } else {
            GameState.getGameMode().getAutoAttackResult(sourceState, targetState)
        }

        for (result in results) {
            outputEvents += ActorAutoAttackedEvent(
                sourceId = sourceId,
                targetId = result.targetId,
                damageAmount = result.damageDone,
                damageType = AttackDamageType.Physical,
                autoAttackEffects = result.addedEffects,
                retaliationEffects = result.retaliationEffects,
                sourceTpGain = result.tpGained,
                targetTpGain = result.targetTpGained,
                actionContext = result.context,
            )
        }

        results.forEach { actor?.displayAutoAttack(it, targetState, totalAttacksInRound = results.size) }
        return outputEvents
    }

}