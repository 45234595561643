package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.EnvironmentManager
import xim.poc.SceneManager
import xim.poc.SynthesisType
import xim.poc.audio.BackgroundMusicResponse
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.DamageCalculator.getAutoAttackTypeResult
import xim.poc.game.configuration.v0.DamageCalculator.getWeaponPowerAndDelay
import xim.poc.game.configuration.v0.GameV0Helpers.generateMonsterInventory
import xim.poc.game.configuration.v0.GameV0Helpers.generateRandomAugmentRank
import xim.poc.game.configuration.v0.GameV0Helpers.getAbilityRange
import xim.poc.game.configuration.v0.GameV0Helpers.getAbilityRecast
import xim.poc.game.configuration.v0.GameV0Helpers.getActorSkillTiers
import xim.poc.game.configuration.v0.GameV0Helpers.getBaseAugmentPointRankGain
import xim.poc.game.configuration.v0.GameV0Helpers.getCastingRangeInfo
import xim.poc.game.configuration.v0.GameV0Helpers.getItemCastTime
import xim.poc.game.configuration.v0.GameV0Helpers.getItemDescriptionInternal
import xim.poc.game.configuration.v0.GameV0Helpers.getItemLevel
import xim.poc.game.configuration.v0.GameV0Helpers.getItemRange
import xim.poc.game.configuration.v0.GameV0Helpers.getMobSkillCastTime
import xim.poc.game.configuration.v0.GameV0Helpers.getMobSkillRange
import xim.poc.game.configuration.v0.GameV0Helpers.getPlayerLevelStatMultiplier
import xim.poc.game.configuration.v0.GameV0Helpers.getRemainingWeaponMeldPotential
import xim.poc.game.configuration.v0.GameV0Helpers.getSkillCost
import xim.poc.game.configuration.v0.GameV0Helpers.getSpellCastTime
import xim.poc.game.configuration.v0.GameV0Helpers.getSpellRecast
import xim.poc.game.configuration.v0.GameV0Helpers.getTrustStats
import xim.poc.game.configuration.v0.GameV0Helpers.getUsedSkillCost
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.weaponDamageAugmentId
import xim.poc.game.configuration.v0.events.ActorLearnSpellEvent
import xim.poc.game.configuration.v0.tower.TowerConfiguration
import xim.poc.game.configuration.v0.zones.BaseCamp
import xim.poc.game.configuration.v0.zones.BaseCampLogic
import xim.poc.game.configuration.v0.zones.SimpleZoneOverrides
import xim.poc.game.event.*
import xim.poc.tools.ZoneConfig
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.InventoryItemDescription
import xim.poc.ui.ShiftJis
import xim.resource.*
import xim.resource.Skill
import xim.resource.table.AbilityInfoTable
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import xim.resource.table.ZoneSettingsTable
import xim.util.Fps
import xim.util.RandHelper
import kotlin.math.*
import kotlin.random.Random

private val gameConfiguration = GameConfiguration(
    gameModeId = "GameV0",
    startingZoneConfig = ZoneConfig(zoneId = BaseCamp.definition.zoneId, startPosition = Vector3f(x=-40.01f,y=1.34f,z=33.87f)),
    debugControlsEnabled = true
)

object GameV0: GameLogic {

    override val configuration = gameConfiguration

    val interactionManager = DynamicNpcInteractionManager()
    private var zoneLogic: ZoneLogic? = null

    override fun setup(): List<Event> {
        ZoneDefinitionManager += BaseCamp.definition
        SimpleZoneOverrides.definitions.forEach { ZoneDefinitionManager += it }

        MonsterDefinitions.registerAll(V0MonsterDefinitions.definitions)

        CommonSkillAppliers.registerTrustAppliers()

        V0AbilityDefinitions.register()
        V0MobSkillDefinitions.register()
        V0SpellDefinitions.register()
        V0ItemDefinitions.register()

        return emptyList()
    }

    override fun update(elapsedFrames: Float) {
        zoneLogic?.update(elapsedFrames)
        interactionManager.update(elapsedFrames)
        GameV0SaveStateHelper.autoSave()
        CombatBonusAggregator.clear()
    }

    override fun onChangedZones(zoneConfig: ZoneConfig) {
        if (zoneConfig.zoneId == BaseCamp.definition.zoneId) {
            ActorStateManager.player().inventory.discardTemporaryItems()
            resetFloor(BaseCampLogic().also { it.setup() })
        } else if (zoneLogic == null) {
            val floor = TowerConfiguration.getAll()
                .filter { it.value.battleLocation.startingPosition.zoneId == zoneConfig.zoneId }
                .minByOrNull { Vector3f.distance(ActorStateManager.player().position, it.value.battleLocation.startingPosition.startPosition!!) }

            if (floor != null) {
                GameTower.resetTowerState(floor.key)
                ActorStateManager.player().position.copyFrom(floor.value.battleLocation.startingPosition.startPosition!!)
                setupBattle(floor = floor.key)
            }
        }
    }

    override fun getNpcInteraction(actorId: ActorId): NpcInteraction? {
        return interactionManager.getInteraction(actorId)
    }

    override fun getActorSpellList(actorId: ActorId): List<SpellInfo> {
        val actor = ActorStateManager[actorId] ?: return emptyList()
        return actor.learnedSpells.equippedSpells.map { SpellInfoTable[it] }.filter { it.index != 0 }
    }

    override fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        val tiers = getActorSkillTiers(actorId)
        return tiers.entries.flatMap { ActorSkillTiers.getAbilityIds(it.key, it.value) }
            .map { AbilityInfoTable[it] }
            .filter { it.type == abilityType }
    }

    override fun canDualWield(actorState: ActorState): Boolean {
        return GameTower.hasClearedFloor(10)
    }

    override fun getActorCombatStats(actorId: ActorId): CombatStats {
        val actor = ActorStateManager[actorId] ?: return CombatStats.defaultBaseStats
        val bonuses = CombatBonusAggregator[actor]

        var stats = if (actor.type == ActorType.Pc) {
            val scaledStats = CombatStats.defaultBaseStats * getPlayerLevelStatMultiplier(actor.getMainJobLevel().level)
            scaledStats.copy(maxMp = scaledStats.maxMp.coerceAtMost(100))
        } else if (actor.isGatheringNode()) {
            CombatStats.defaultBaseStats.copy(maxHp = 3)
        } else if (actor.monsterId != null) {
            MonsterDefinitions[actor.monsterId].baseCombatStats
        } else if (actor.trustId != null) {
            val owner = ActorStateManager[actor.owner] ?: return CombatStats.defaultBaseStats
            getTrustStats(summoner = owner, trust = actor)
        } else {
            CombatStats.defaultBaseStats
        }

        stats += bonuses.additiveStats.build()
        for ((stat, value) in bonuses.multiplicativeStats) { stats = stats.multiply(stat, value) }

        return stats
    }

    override fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription {
        return getItemDescriptionInternal(inventoryItem = inventoryItem)
    }

    override fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        val attacks = ArrayList<AutoAttackResult>()

        attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.Main)
        if (attacker.isDualWield()) {
            attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.Sub)
        } else if (attacker.isHandToHand()) {
            attacks += getAutoAttackTypeResult(attacker, defender, AutoAttackType.H2H)
        }

        return attacks.take(8)
    }

    override fun getAutoAttackInterval(attacker: ActorState): Float {
        var totalDelay = 0f

        val (_, mainDelay) = getWeaponPowerAndDelay(attacker, type = AutoAttackType.Main) ?: (0 to 180)
        totalDelay += mainDelay

        val bonuses = CombatBonusAggregator[attacker]
        var attackSpeed = bonuses.haste

        if (attacker.isDualWield()) {
            val (_, subDelay) = getWeaponPowerAndDelay(attacker, type = AutoAttackType.Sub) ?: (0 to 0)
            totalDelay += subDelay
            attackSpeed += bonuses.dualWield
        }

        return totalDelay * 100f/(100f+attackSpeed)
    }

    override fun getRangedAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        return getAutoAttackTypeResult(attacker, defender, AutoAttackType.Ranged)
    }

    override fun getRangedAttackInterval(attacker: ActorState): Float {
        val (_, delay) = getWeaponPowerAndDelay(attacker, AutoAttackType.Ranged) ?: (0 to 180)
        return delay.toFloat()
    }

    override fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int {
        val itemLevel = getItemLevel(inventoryItem)
        val defenderLevel = defender.getMainJobLevel().level

        val rewardBonus = if (defender.monsterId != null) {
            MonsterDefinitions[defender.monsterId].rewardScale
        } else {
            1f
        }

        return (rewardBonus * getBaseAugmentPointRankGain(itemLevel = itemLevel, rpSourceLevel = defenderLevel)).roundToInt()
    }

    override fun getAugmentRankPointsNeeded(augment: ItemAugment): Int {
        val needed = AbilityInfoTable.getReinforcementPointsTable().table.entries.getOrNull(augment.rankLevel) ?: return 0
        return needed.coerceAtMost(1000)
    }

    override fun onAugmentRankUp(actorState: ActorState, inventoryItem: InventoryItem) {
        val fixedAugments = inventoryItem.fixedAugments ?: return
        val rank = inventoryItem.augments?.rankLevel ?: return

        fixedAugments.capacityRemaining += if (rank <= 10) { 3 } else if (rank <= 20) { 4 } else { 5 }

        val remainingWeaponDamagePotential = getRemainingWeaponMeldPotential(inventoryItem)
            .getOrElse(weaponDamageAugmentId) { 0 }

        if (remainingWeaponDamagePotential > 0) {
            val damageAugment = fixedAugments.getOrCreate(weaponDamageAugmentId)
            damageAugment.potency += 1
        }
    }

    override fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int {
        if (!attacker.isPlayer()) { return 0 }

        val monsterId = defender.monsterId ?: return 0

        val saveState = GameV0SaveStateHelper.getState()
        val previousValue = saveState.defeatedMonsterCounter[monsterId] ?: 0
        saveState.defeatedMonsterCounter[monsterId] = previousValue + 1

        val levelDelta = defender.getMainJobLevel().level - attacker.getMainJobLevel().level
        val levelDeltaMultiplier = ((3f + levelDelta) / 3f).coerceIn(0f, 2f)

        val monsterDefinition = MonsterDefinitions[monsterId]
        val nmBonus = if (monsterDefinition.notoriousMonster) { 5 } else { 1 }

        return (30 * levelDeltaMultiplier * nmBonus * monsterDefinition.rewardScale).roundToInt()
    }

    override fun getExperiencePointsNeeded(currentLevel: Int): Int {
        val baseValue = 10 * AbilityInfoTable.getLevelTable().table.entries[currentLevel]
        return baseValue.coerceAtMost(1000)
    }

    override fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        val bonuses = CombatBonusAggregator[actorState]
        return ActorEffectTickResult(hpDelta = bonuses.regen, mpDelta = bonuses.refresh, tpDelta = bonuses.regain)
    }

    override fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int> {
        val targetItemDefinition = ItemDefinitions[targetItem]

        return ItemDefinitions.reinforcePointItems.associate {
            it.id to getBaseAugmentPointRankGain(itemLevel = targetItemDefinition.internalLevel, rpSourceLevel = it.internalLevel)
        }
    }

    override fun getSkillRangeInfo(actorState: ActorState, skillType: SkillType, skillId: Int): SkillRangeInfo {
        return when (skillType) {
            SkillType.Spell -> getCastingRangeInfo(skillId)
            SkillType.MobSkill -> getMobSkillRange(skillId)
            SkillType.Ability -> getAbilityRange(skillId)
            SkillType.Item -> getItemRange(skillId)
        }
    }

    override fun getSkillBaseCost(skillType: SkillType, skillId: Int): AbilityCost {
        return getSkillCost(skillType, skillId).baseCost
    }

    override fun getSkillUsedCost(actorState: ActorState, skillType: SkillType, skillId: Int): AbilityCost {
        return getUsedSkillCost(actorState, skillType, skillId)
    }

    override fun getSkillCastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float {
        return when (skillType) {
            SkillType.Spell -> getSpellCastTime(caster, skillId)
            SkillType.MobSkill -> getMobSkillCastTime(caster, skillId)
            SkillType.Ability -> 0f
            SkillType.Item -> getItemCastTime(caster, skillId)
        }
    }

    override fun getSkillRecastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float {
        return when (skillType) {
            SkillType.Ability -> getAbilityRecast(caster, AbilityInfoTable[skillId])
            SkillType.Spell -> getSpellRecast(caster, SpellInfoTable[skillId])
            SkillType.MobSkill -> 0f
            SkillType.Item -> 0f
        }
    }

    override fun getSkillChainAttributes(attacker: ActorState, defender: ActorState, skillType: SkillType, skillId: Int): List<SkillChainAttribute> {
        return when (skillType) {
            SkillType.Spell -> V0SpellDefinitions.getSkillChainAttributes(skillId)
            SkillType.MobSkill -> emptyList()
            SkillType.Ability -> V0AbilityDefinitions.getSkillChainAttributes(skillId)
            SkillType.Item -> emptyList()
        }
    }

    override fun getSkillChainDamage(attacker: ActorState, defender: ActorState, skillChain: SkillChain, closingWeaponSkillDamage: Int): Int {
        val damageMultiplier = (0.5f * skillChain.attribute.level) * (1.25f.pow(skillChain.numSteps - 2)).coerceAtMost(2f)
        val bonuses = CombatBonusAggregator[attacker]
        return (closingWeaponSkillDamage * damageMultiplier * bonuses.skillChainDamage.toMultiplier()).roundToInt()
    }

    override fun getActorCombatBonuses(actorState: ActorState): CombatBonusAggregate {
        return CombatBonusAggregator[actorState]
    }

    override fun getCurrentBackgroundMusic(): BackgroundMusicResponse {
        val currentLogic = zoneLogic
        val battleLocation = if (currentLogic is FloorInstance) { currentLogic.definition.location } else { null }
        val zoneSettings = ZoneSettingsTable[SceneManager.getCurrentScene().config]

        val musicSettings = battleLocation?.musicSettings ?: zoneSettings.musicSettings
        return GameEngine.selectBgm(musicSettings)
    }

    override fun rollParry(attacker: ActorState, defender: ActorState): Boolean {
        val defenderBonuses = CombatBonusAggregator[defender]
        val parryChance = defenderBonuses.parryRate / 100f
        return Random.nextFloat() <= parryChance
    }

    override fun rollGuard(attacker: ActorState, defender: ActorState): Boolean {
        val defenderBonuses = CombatBonusAggregator[defender]
        val parryChance = defenderBonuses.guardRate / 100f
        return Random.nextFloat() <= parryChance
    }

    override fun rollSpellInterrupted(attacker: ActorState, defender: ActorState): Boolean {
        return DamageCalculator.rollSpellInterrupted(attacker, defender)
    }

    override fun generateItem(itemId: Int, quantity: Int, temporary: Boolean): InventoryItem {
        return generateItem(ItemDropDefinition(
            itemId = itemId,
            quantity = quantity,
            temporary = temporary,
            augmentRank = null,
        ))
    }

    override fun getMaxTp(actor: ActorState): Int {
        if (!actor.isPlayer()) { return 1000 }
        return if (actor.getMainJobLevel().level == 50) { 3000 } else { 1000 }
    }

    override fun spawnMonster(monsterId: MonsterId, initialActorState: InitialActorState): ActorPromise {
        val inventory = generateMonsterInventory(MonsterDefinitions[monsterId])
        val modifiedActorState = initialActorState.copy(inventory = inventory,)
        return GameEngine.submitCreateActorState(modifiedActorState)
    }

    override fun getKnownSynthesisRecipes(actorState: ActorState, type: SynthesisType): List<SynthesisRecipe> {
        return V0SynthesisRecipes.recipes.filter { it.synthesisType == type }
    }

    fun getItemPrice(vendorId: ActorId, item: InventoryItem): Pair<CurrencyType, Int>? {
        val itemDefinition = ItemDefinitions.getNullable(item) ?: return null
        val basePrice = 100 * (1.33f).pow(itemDefinition.internalLevel - 1)

        val rank = item.augments?.rankLevel ?: 1
        val rankMultiplier = 1f + 9f * rank / ItemAugmentDefinitions.maxPossibleRankLevel

        val finalCost = (basePrice * rankMultiplier).roundToInt()

        return (CurrencyType.Gil to finalCost)
    }

    fun resetFloor(newLogic: ZoneLogic?) {
        zoneLogic?.cleanUp()
        zoneLogic = newLogic
    }

    fun setupBattle(floor: Int, repeated: Boolean = false) {
        zoneLogic?.cleanUp()

        val floorDefinition = FloorDefinition.fromFloor(floor)
        val location = floorDefinition.location

        zoneLogic = floorDefinition.newInstance()
        if (repeated) { return }

        if (location.shipRoute != null) {
            SceneManager.getCurrentScene().setShipRoute(location.shipRoute)
        }

        if (location.timeOfDay != null) {
            EnvironmentManager.setCurrentHour(location.timeOfDay.hour)
            EnvironmentManager.setCurrentMinute(location.timeOfDay.minute)
        }

        location.onSetup?.invoke()

        val playerSpells = ActorStateManager.player().learnedSpells
        val newSpells = floorDefinition.configuration.blueMagicReward.filter { !playerSpells.spellIds.contains(it) }
        if (newSpells.isNotEmpty()) { ChatLog("Defeat all monsters to learn: ${newSpells.joinToString { SpellNameTable[it].first() }}", ChatLogColor.Info) }
    }

    fun generateItem(dropDefinition: ItemDropDefinition): InventoryItem {
        val item = InventoryItem(id = dropDefinition.itemId, quantity = dropDefinition.quantity, temporary = dropDefinition.temporary)
        val itemInfo = item.info()

        val itemDefinition = ItemDefinitions.getNullable(item) ?: return item
        if (!itemDefinition.ranked) { return item }

        val itemAugmentRank = dropDefinition.augmentRank ?: generateRandomAugmentRank(dropDefinition)
        val itemAugmentMaxRank = dropDefinition.augmentRankMax ?: itemAugmentRank
        item.internalQuality = ItemAugmentDefinitions.getInternalQuality(itemAugmentRank)

        val augments = ItemAugment(rankLevel = itemAugmentRank, maxRankLevel = itemAugmentMaxRank)
        item.augments = augments

        val numAugments = min(itemDefinition.augmentSlots.size, item.internalQuality)

        for (i in 0 until numAugments) {
            val augmentSlot = itemDefinition.augmentSlots.getOrNull(i) ?: continue
            val currentAugmentIds = augments.augmentIds.toSet()

            val remaining = augmentSlot.entries.filter { !currentAugmentIds.contains(it.first) }
            if (remaining.isEmpty()) { continue }

            augments.augmentIds += WeightedTable(remaining).getRandom()
        }

        if (itemInfo.skill() == Skill.Sword) {
            item.fixedAugments = CapacityAugments(capacityRemaining = 3)
                .also { it.getOrCreate(weaponDamageAugmentId, itemDefinition.damage) }

            for ((augmentId, cap) in itemDefinition.meldBonusCaps) {
                if (augmentId == weaponDamageAugmentId) { continue }

                val startingValue = floor(cap * 0.25f).roundToInt()
                if (startingValue == 0) { continue }

                item.fixedAugments?.getOrCreate(augmentId, startingValue)
            }
        }

        return item
    }

}

object GameV0Helpers {

    fun getPlayerLevelStatMultiplier(level: Int): Float {
        return if (level <= 5) {
            1f + 0.2f * (level - 1)
        } else {
            2f * (1.09298f.pow(level - 6))
        }
    }

    fun getTrustStats(summoner: ActorState, trust: ActorState): CombatStats {
        val trustLevel = getTrustLevel(summoner, trust)
        val summonerChr = summoner.combatStats.chr

        val def = TrustDefinitions.definitionsById[trust.trustId] ?: throw IllegalStateException("[${trust.name}] Definition is not set")

        val chrBonus = def.chrScaling * (summonerChr * 0.5f * 0.01f)
        return (CombatStats.defaultBaseStats * getPlayerLevelStatMultiplier(trustLevel)) + chrBonus
    }

    private fun getTrustLevel(summoner: ActorState, trust: ActorState): Int {
        var trustLevel = 1

        for ((_, item) in summoner.getEquipment()) {
            item ?: continue
            val itemDefinition = ItemDefinitions[item]

            if (itemDefinition.trusts.any { it.trustId == trust.trustId }) {
                trustLevel = max(trustLevel, getItemLevel(item))
            }
        }

        trustLevel = trustLevel.coerceAtMost(summoner.getMainJobLevel().level)

        return trustLevel
    }

    fun getItemDescriptionInternal(
        inventoryItem: InventoryItem,
        meldBonuses: Map<ItemAugmentId, Int> = emptyMap(),
        includeNeededBuildUp: Boolean = false,
        includeAllMeldCaps: Boolean = false,
    ): InventoryItemDescription {
        val baseDescription = InventoryItemDescription.toDescription(inventoryItem)
        val itemDefinition = ItemDefinitions.getNullable(inventoryItem) ?: return baseDescription
        val itemInfo = inventoryItem.info()

        var description = baseDescription.pages.toMutableList()
        var augmentPath = baseDescription.augmentPath

        if (itemInfo.type == ItemListType.Weapon || itemInfo.type == ItemListType.Armor) {
            val augmentDescription = getAugmentDescription(inventoryItem, meldBonuses, includeNeededBuildUp, includeAllMeldCaps) ?: ""
            description = mutableListOf(itemDefinition.toDescription(inventoryItem) + augmentDescription)
        } else if (itemDefinition.capacityAugment != null) {
            augmentPath = getCapacityAugmentDescription(itemDefinition.capacityAugment)
        }

        val itemLevel = getItemLevel(inventoryItem)
        val itemLevelDescription = "< Item Level: $itemLevel >"

        return baseDescription.copy(
            pages = description,
            itemLevel = itemLevelDescription,
            augmentPath = augmentPath,
        )
    }

    private fun getAugmentDescription(
        item: InventoryItem,
        meldBonuses: Map<ItemAugmentId, Int>,
        includeNeededBuildUp: Boolean,
        includeAllMeldCaps: Boolean,
    ): String? {
        if (item.augments == null && item.fixedAugments == null) { return null }

        val descriptions = StringBuilder()
        descriptions.append("${ShiftJis.colorInfo}")

        var idx = 1

        for (augmentId in item.augments?.augmentIds ?: emptyList()) {
            if (idx != 1) { descriptions.appendLine() }

            val def = ItemAugmentDefinitions[augmentId]
            val value = def.valueFn.calculate(item)
            descriptions.append("[$idx] ${def.attribute.toDescription(value)}")

            idx += 1
        }

        val itemDefinition = ItemDefinitions[item]

        val neededMelds = if (includeNeededBuildUp) { calculateNeededMeldsForWeaponUpgrade(item, item.info()) } else { emptyMap() }
        val allMeldCaps = if (includeAllMeldCaps) { itemDefinition.meldBonusCaps } else { emptyMap() }

        val fixedAugmentIds = (item.fixedAugments?.augments?.keys ?: emptySet()) + meldBonuses.keys + neededMelds.keys + allMeldCaps.keys

        for (augmentId in fixedAugmentIds) {
            val def = ItemAugmentDefinitions[augmentId]

            val baseValue = item.fixedAugments?.augments?.get(augmentId)?.potency ?: 0
            val bonusValue =  meldBonuses[augmentId]
            val buildUpMissing = neededMelds[augmentId]

            if (!includeAllMeldCaps && baseValue == 0 && (bonusValue ?: 0) == 0 && (buildUpMissing ?: 0) <= 0) { continue }

            val cap = itemDefinition.meldBonusCaps[augmentId]

            val buildUpRequirement = if (buildUpMissing != null && buildUpMissing > 0) {
                itemDefinition.buildUpRequirements?.augmentRequirements?.get(augmentId)
            } else { null }

            if (idx != 1) { descriptions.appendLine() }
            descriptions.append(def.attribute.toDescription(baseValue, bonusValue, cap, buildUpRequirement, includeAllMeldCaps))
            idx += 1
        }

        if (idx == 1) { return null }

        descriptions.append("${ShiftJis.colorClear}")
        return descriptions.toString()
    }

    private fun getCapacityAugmentDescription(capacityAugment: ItemCapacityAugment): String {
        val descriptions = StringBuilder()

        descriptions.append("${ShiftJis.colorAug}")

        val def = ItemAugmentDefinitions[capacityAugment.augmentId]
        descriptions.append("< Capacity:${capacityAugment.capacity} ${ShiftJis.rightArrow} ${def.attribute.toDescription(capacityAugment.potency)} >")

        descriptions.append("${ShiftJis.colorClear}")
        return descriptions.toString()
    }

    fun getItemLevel(inventoryItem: InventoryItem): Int {
        return ItemDefinitions[inventoryItem].internalLevel
    }

    fun getActorSkillTiers(actorId: ActorId): Map<ActorSkillType, Int> {
        val actorState = ActorStateManager[actorId] ?: return emptyMap()

        val equipment = actorState.getEquipment()
        val tiers = HashMap<ActorSkillType, Int>()

        for ((slot, item) in equipment) {
            if (item == null) { continue }
            val definition = ItemDefinitions.definitionsById[item.id] ?: continue

            for ((type, tier) in definition.skillTiers) {
                if (slot == EquipSlot.Sub && !type.subEligible) { continue }
                tiers[type] = tiers.getOrPut(type) { 0 } + tier
            }
        }

        return tiers
    }

    fun getTrustMagic(actorId: ActorId): List<SpellInfo> {
        val actorState = ActorStateManager[actorId] ?: return emptyList()

        val equipment = actorState.getEquipment()

        val trusts = HashSet<Int>()

        for ((_, item) in equipment) {
            if (item == null) { continue }
            val definition = ItemDefinitions.definitionsById[item.id] ?: continue
            trusts += definition.trusts.map { it.trustId }
        }

        return trusts.map { SpellInfoTable[it] }
    }

    fun getSkillCost(skillType: SkillType, skillId: Int): V0AbilityCost {
        return when(skillType) {
            SkillType.Spell -> V0SpellDefinitions.getCost(skillId)
            SkillType.MobSkill -> MobSkills[skillId].cost
            SkillType.Ability -> V0AbilityDefinitions.getCost(skillId)
            SkillType.Item -> V0AbilityCost(AbilityCost(AbilityCostType.Tp, 0))
        }
    }

    fun getUsedSkillCost(actorState: ActorState, skillType: SkillType, skillId: Int): AbilityCost {
        val customCost = getSkillCost(skillType, skillId)

        var cost = if (customCost.consumesAll) {
            getConsumeAllCost(actorState, customCost.baseCost.type)
        } else {
            customCost.baseCost.value
        }

        if (DamageCalculator.rollConserveTp(actorState, skillType, skillId)) {
            cost = (cost * Random.nextDouble(0.25, 0.75)).roundToInt()
        }

        return AbilityCost(customCost.baseCost.type, cost)
    }

    private fun getConsumeAllCost(actorState: ActorState, type: AbilityCostType): Int {
        return when(type) {
            AbilityCostType.Tp -> actorState.getTp()
            AbilityCostType.Mp -> actorState.getMp()
        }
    }

    fun getSpellCastTime(caster: ActorState, spellId: Int): Float {
        val spontaneity = caster.getStatusEffect(StatusEffect.Spontaneity)
        if (spontaneity != null && spontaneity.counter > 0) { return 0f }

        val spellInfo = SpellInfoTable[spellId]
        val baseCastTime = spellInfo.castTimeInFrames()

        val bonuses = CombatBonusAggregator[caster]
        val potency = bonuses.fastCast + bonuses.haste
        return baseCastTime * 100f / (100f + potency)
    }

    fun getMobSkillCastTime(caster: ActorState, skillId: Int): Float {
        val baseCastTime = Fps.millisToFrames(MobSkills[skillId].castTime.inWholeMilliseconds)

        val bonuses = CombatBonusAggregator[caster]
        val potency = bonuses.mobSkillFastCast
        return baseCastTime * 100f / (100f + potency)
    }

    fun getItemCastTime(caster: ActorState, skillId: Int): Float {
        return InventoryItems[skillId].usableItemInfo?.castTimeInFrames() ?: 0f
    }

    fun getAbilityRecast(actorState: ActorState, abilityInfo: AbilityInfo): Float {
        val delayInSeconds = if (abilityInfo.type == AbilityType.WeaponSkill) { 2.5f } else { 5f }
        return Fps.secondsToFrames(delayInSeconds)
    }

    fun getSpellRecast(actorState: ActorState, spellInfo: SpellInfo): Float {
        val customRecast = V0SpellDefinitions.getRecast(spellInfo.index)

        val customRecastTime = customRecast?.baseTime?.let { Fps.toFrames(it) }
        if (customRecastTime != null && customRecast.fixedTime) { return customRecastTime }

        val recastTime = customRecastTime ?: spellInfo.recastDelayInFrames()

        val spontaneity = actorState.getStatusEffect(StatusEffect.Spontaneity)
        if (spontaneity != null && spontaneity.counter > 0) { return 0f }

        val bonuses = CombatBonusAggregator[actorState]
        val speed = (1f * bonuses.haste.toMultiplier() * bonuses.fastCast.toMultiplier()).coerceIn(0.5f, 5f)

        return recastTime * 1f/speed
    }

    fun generateRandomAugmentRank(dropDefinition: ItemDropDefinition): Int {
        var bonus = -1
        while (bonus < 0) { bonus = RandHelper.normal(standardDeviation = 10f).roundToInt() }

        bonus += dropDefinition.augmentRankMean
        return bonus.coerceIn(1, ItemAugmentDefinitions.maxPossibleRankLevel)
    }

    fun generateMonsterInventory(monsterDefinition: MonsterDefinition): Inventory {
        val monsterInventory = Inventory()
        val monsterDropTable = V0MonsterDropTables[monsterDefinition.id]

        for (entry in monsterDropTable.dropTable) {
            val dropDefinition = entry.getDropTable().getRandom()
            if (dropDefinition.itemId == null) { continue }

            val item = GameV0.generateItem(ItemDropDefinition(
                itemId = dropDefinition.itemId,
                quantity = dropDefinition.quantity,
                augmentRankMean = dropDefinition.augmentRankMean,
            ))
            monsterInventory.addItem(item, stack = false)
        }

        return monsterInventory
    }

    fun applyWeaponMeldBonus(targetWeapon: InventoryItem, weaponUpgradeMaterial: InventoryItem) {
        val fixedAugments = targetWeapon.fixedAugments ?: return

        val meldBonuses = getWeaponMeldBonus(targetWeapon, weaponUpgradeMaterial)
        for ((itemAugmentId, value) in meldBonuses) {
            val augment = fixedAugments.getOrCreate(itemAugmentId)
            augment.potency += value
        }
    }

    fun getWeaponMeldBonus(targetWeapon: InventoryItem, weaponUpgradeMaterial: InventoryItem): Map<ItemAugmentId, Int> {
        val allBonuses = HashMap<ItemAugmentId, Int>()
        val fixedAugments = weaponUpgradeMaterial.fixedAugments?.augments

        if (fixedAugments != null) {
            allBonuses += fixedAugments.mapValues {
                (it.value.potency * if (it.key == weaponDamageAugmentId) { 0.25f } else { 0.5f }).roundToInt()
            }
        }

        val remainingPotential = getRemainingWeaponMeldPotential(targetWeapon)
        return allBonuses.mapValues { min(it.value, remainingPotential.getOrElse(it.key) { Int.MAX_VALUE }) }
    }

    fun getRemainingWeaponMeldPotential(weapon: InventoryItem): Map<ItemAugmentId, Int> {
        val definition = ItemDefinitions[weapon]

        val fixedAugments = weapon.fixedAugments?.augments ?: return definition.meldBonusCaps
        val remainingMeldPotential = HashMap<ItemAugmentId, Int>()

        for ((itemAugmentId, maxAugmentValue) in definition.meldBonusCaps) {
            val currentAugmentValue = fixedAugments[itemAugmentId]?.potency ?: 0
            remainingMeldPotential[itemAugmentId] = (maxAugmentValue - currentAugmentValue).coerceAtLeast(0)
        }

        return remainingMeldPotential
    }

    fun generateUpgradedWeapon(sourceItem: InventoryItem, itemInfo: InventoryItemInfo): InventoryItem {
        val fakeItem = GameV0.generateItem(ItemDropDefinition(
            itemId = itemInfo.itemId,
            augmentRank = 1,
            augmentRankMax = ItemAugmentDefinitions.maxPossibleRankLevel,
        ))

        val capacityAugments = fakeItem.fixedAugments ?: return fakeItem
        capacityAugments.augments.clear()

        val sourceAugments = sourceItem.fixedAugments
        if (sourceAugments != null) {
            capacityAugments.capacityRemaining += sourceAugments.capacityRemaining

            for ((augmentId, augment) in sourceAugments.augments) {
                capacityAugments.augments[augmentId] = augment.copy()
            }
        }

        return fakeItem
    }

    fun calculateNeededMeldsForWeaponUpgrade(sourceItem: InventoryItem, destinationItemInfo: InventoryItemInfo): Map<ItemAugmentId, Int> {
        val fakeItem = InventoryItem(id = destinationItemInfo.itemId)

        val buildUpRequirements = ItemDefinitions[fakeItem].buildUpRequirements?.augmentRequirements ?: return emptyMap()

        val currentAugments = sourceItem.fixedAugments?.augments ?: return buildUpRequirements
        return buildUpRequirements.mapValues { it.value - (currentAugments[it.key]?.potency ?: 0) }
    }

    fun getBaseAugmentPointRankGain(itemLevel: Int, rpSourceLevel: Int): Int {
        val levelDifference = rpSourceLevel - itemLevel

        return if (levelDifference >= 0) {
            25 * (levelDifference+1)
        } else {
            25 / (abs(levelDifference)+1)
        }
    }

    fun getScrapResult(scrapItem: InventoryItem): Pair<InventoryItemInfo, Int> {
        val scrapItemDefinition = ItemDefinitions[scrapItem]

        val lesserReinforcementItem = ItemDefinitions.reinforcePointItems
            .filter { it.internalLevel <= scrapItemDefinition.internalLevel }
            .maxBy { it.internalLevel }

        val levelDifference = scrapItemDefinition.internalLevel - lesserReinforcementItem.internalLevel
        val scrapItemRank = scrapItem.augments?.rankLevel ?: 1
        val scrapQuantity = (levelDifference + 1) * scrapItemRank

        return (InventoryItems[lesserReinforcementItem.id] to scrapQuantity)

    }

    fun getCastingRangeInfo(spellId: Int): SkillRangeInfo {
        val custom = V0SpellDefinitions.getRange(spellId)
        if (custom != null) { return custom }

        val spellInfo = SpellInfoTable[spellId]
        val spellInfoRange = spellInfo.aoeSize.toFloat()

        return if (spellInfo.aoeType == AoeType.Cone || spellInfo.aoeType == AoeType.Source) {
            SkillRangeInfo(maxTargetDistance = spellInfoRange, effectRadius = spellInfoRange, type = spellInfo.aoeType)
        } else {
            SkillRangeInfo(maxTargetDistance = 24f, effectRadius = spellInfoRange, type = spellInfo.aoeType)
        }
    }

    fun getItemRange(itemId: Int): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getAbilityRange(abilityId: Int): SkillRangeInfo {
        val abilityInfo = AbilityInfoTable[abilityId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = abilityInfo.aoeSize.toFloat(), type = abilityInfo.aoeType)
    }

    fun getMobSkillRange(skillId: Int): SkillRangeInfo {
        return MobSkills[skillId].rangeInfo
    }

    fun learnSpells(actor: ActorState, spells: List<Int>) {
        spells.map { ActorLearnSpellEvent(actor.id, it) }
            .forEach { GameEngine.submitEvent(it) }
    }

}
