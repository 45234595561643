package xim.poc.game.configuration.v0

import xim.poc.game.AbilityId
import xim.poc.game.AbilityId.*

typealias TierLevel = Int

enum class ActorSkillType(val subEligible: Boolean) {
    Sword(subEligible = false),
    Dagger(subEligible = false),
    Club(subEligible = false),
    Staff(subEligible = false),
    HealingMagic(subEligible = true),
    ElementalMagic(subEligible = true),
    EnfeeblingMagic(subEligible = true),
}

data class ActorSkillTier(
    val tier: TierLevel,
    val abilityIds: List<AbilityId> = emptyList(),
    val spellIds: List<Int> = emptyList(),
    val traits: Map<AbilityId, Int> = emptyMap(),
)

object ActorSkillTiers {

    private val tiers = HashMap<ActorSkillType, List<ActorSkillTier>>()

    init {
        tiers[ActorSkillType.Sword] = listOf(
            ActorSkillTier(tier = 1, abilityIds = listOf(FastBlade, BurningBlade, RedLotusBlade)),
            ActorSkillTier(tier = 2, abilityIds = listOf(FastBlade, BurningBlade, RedLotusBlade, ShiningBlade)),
            ActorSkillTier(tier = 3, abilityIds = listOf(FastBlade, BurningBlade, RedLotusBlade, ShiningBlade)),
            ActorSkillTier(tier = 11, abilityIds = listOf(VorpalBlade, SwiftBlade, SavageBlade, KnightsOfTheRound, Expiacion, ChantDuCygne, Requiescat, UrielBlade, GlorySlash)),
            ActorSkillTier(tier = 12, abilityIds = listOf(BurningBlade, RedLotusBlade, ShiningBlade, SeraphBlade, KnightsOfTheRound, Expiacion, ChantDuCygne, Requiescat, UrielBlade)),
        )

        tiers[ActorSkillType.Dagger] = listOf(
            ActorSkillTier(tier = 1, traits = mapOf(DualWield to 10))
        )

        tiers[ActorSkillType.Club] = listOf(
            ActorSkillTier(tier = 1)
        )

        tiers[ActorSkillType.Staff] = listOf(
            ActorSkillTier(tier = 1)
        )

        tiers[ActorSkillType.HealingMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(1, 7))
        )

        tiers[ActorSkillType.ElementalMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(159, 189))
        )

        tiers[ActorSkillType.EnfeeblingMagic] = listOf(
            ActorSkillTier(tier = 1, spellIds = listOf(549, 623, 521, 584, 596, 577, 519, 517, 530, 544, 605, 664, 513, 542, 618, 569, 701, 702, 651, 744, 745)),
            ActorSkillTier(tier = 2, spellIds = listOf(666, 709, 710, 719)),
        )

    }

    fun getAbilityIds(type: ActorSkillType, level: TierLevel): List<AbilityId> {
        val levels = tiers[type] ?: return emptyList()
        return levels.firstOrNull { it.tier == level }?.abilityIds ?: emptyList()
    }

    fun getSpellIds(type: ActorSkillType, level: TierLevel): List<Int> {
        val levels = tiers[type] ?: return emptyList()
        return levels.filter { it.tier <= level }.flatMap { it.spellIds }
    }

}