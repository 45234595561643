package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.ItemListType

class InventoryItemTransferEvent(
    val sourceId: ActorId,
    val destinationId: ActorId,
    val inventoryItemId: InternalItemId,
    val quantity: Int? = null,
    val actionContext: AttackContext? = null,
) : Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()
        val destination = ActorStateManager[destinationId] ?: return emptyList()

        val inventoryItem = source.inventory.getByInternalId(inventoryItemId) ?: return emptyList()

        if (inventoryItem.info().type == ItemListType.Currency) {
            val amount = quantity ?: inventoryItem.quantity
            source.inventory.discardItem(inventoryItemId, amount = amount)
            destination.adjustCurrency(CurrencyType.Gil, amount = amount)
        } else if (inventoryItem.info().isStackable() && quantity != null) {
            if (quantity > inventoryItem.quantity) { return emptyList() }
            source.inventory.discardItem(inventoryItemId, amount = quantity)
            destination.inventory.addItem(inventoryItem.id, quantity = quantity)
        } else {
            source.inventory.discardItem(inventoryItemId)
            destination.inventory.addItem(inventoryItem)
        }

        if (destination.isPlayer()) {
            AttackContext.compose(actionContext) {
                ChatLog.addLine("${destination.name} obtains ${getItemString(inventoryItem)}${ShiftJis.colorInfo}.", ChatLogColor.Info)
            }
        }

        return emptyList()
    }

    private fun getItemString(inventoryItem: InventoryItem): String {
        val info = inventoryItem.info()

        val displayQuantity = quantity ?: inventoryItem.quantity
        val logName = if (displayQuantity > 1) { "$displayQuantity ${info.logNamePlural}" } else { "a ${info.logName}" }

        val color = if (inventoryItem.temporary) {
            ShiftJis.colorTemporary
        } else {
            AugmentHelper.getQualityColorDisplay(inventoryItem)
        }

        return "${color}${logName}"
    }

}