package xim.poc.game.configuration.assetviewer

import xim.poc.game.StatusEffect
import xim.poc.game.configuration.*
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.releasePet
import xim.poc.game.configuration.assetviewer.AssetViewerSkillApplierHelpers.sourceHpPercentDamage
import xim.poc.game.event.*
import xim.resource.AbilityInfo
import xim.resource.AbilityType
import xim.resource.table.AbilityInfoTable

object AssetViewerAbilitySkills {

    fun register() {
        registerDefaults()

        // Mijin Gakure
        SkillAppliers += SkillApplier(skillId = 540, skillType = SkillType.Ability,
            targetEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 0.5f)),
            additionalSelfEvaluator = sourceHpPercentDamage(PotencyConfiguration(potency = 1f)),
        )

        // Provoke
        SkillAppliers += SkillApplier(skillId = 547, skillType = SkillType.Ability,
            targetEvaluator = { listOf(BattleEngageEvent(it.targetState.id, it.sourceState.id)) }
        )

        // Call Wyvern
        SkillAppliers += SkillApplier(skillId = 573, skillType = SkillType.Ability,
            targetEvaluator = { listOf(PetReleaseEvent(it.sourceState.id), PetSummonEvent(it.sourceState.id, name = "Wyvern", lookId = 0x18)) }
        )

        // Assault
        SkillAppliers += SkillApplier(skillId = 600, skillType = SkillType.Ability, targetEvaluator = {
            val petId = it.sourceState.pet
            if (petId != null) { listOf(BattleEngageEvent(petId, it.targetState.id)) } else { emptyList() }
        })

        // Retreat
        SkillAppliers += SkillApplier(skillId = 601, skillType = SkillType.Ability, targetEvaluator = {
            val petId = it.sourceState.pet
            if (petId != null) { listOf(BattleDisengageEvent(petId)) } else { emptyList() }
        })

        // Release
        SkillAppliers += SkillApplier(skillId = 602, skillType = SkillType.Ability, targetEvaluator = releasePet())

        // Fighter's Roll
        SkillAppliers += makeCorsairRoll(AbilityInfoTable[610], StatusEffect.FightersRoll)

        // Double-up
        SkillAppliers += makeCorsairDoubleUp(AbilityInfoTable[635])
    }

    private fun registerDefaults() {
        for (i in 0 until AbilityInfoTable.getAbilityCount()) {
            val info = AbilityInfoTable[i]

            SkillAppliers += if (info.type == AbilityType.WeaponSkill) {
                weaponSkillApplier(info)
            } else {
                SkillApplierHelper.makeApplier(info, targetEvaluator = SkillApplierHelper.TargetEvaluator.noop())
            }
        }
    }

    private fun makeCorsairRoll(abilityInfo: AbilityInfo, statusEffect: StatusEffect): SkillApplier {
        return SkillApplier(
            skillId = abilityInfo.index,
            skillType = SkillType.Ability,
            validEvaluator = {
                if (it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) != null) { SkillFailureReason.GenericFailure } else { null }
            },
            primaryTargetEvaluator = {
                listOf(CorsairRollEvent(abilityId = it.skillInfo.id, status = statusEffect, sourceId = it.sourceState.id, context = it.context)) },
            targetEvaluator = {
                listOf(CorsairRollTargetEvent(statusEffect = statusEffect, rollerId = it.sourceState.id, targetId = it.targetState.id, context = it.context))
            })
    }

    private fun makeCorsairDoubleUp(abilityInfo: AbilityInfo): SkillApplier {
        return SkillApplier(
            skillId = abilityInfo.index,
            skillType = SkillType.Ability,
            validEvaluator = {
                if (it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) == null) { SkillFailureReason.GenericFailure } else { null }
            },
            primaryTargetEvaluator = {
                val doubleUpState = it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) ?: return@SkillApplier emptyList()
                val linkedStatus = doubleUpState.linkedStatus ?: return@SkillApplier emptyList()
                listOf(CorsairRollEvent(abilityId = it.skillInfo.id, status = linkedStatus, sourceId = it.sourceState.id, context = it.context)) },
            targetEvaluator = {
                val doubleUpState = it.sourceState.getStatusEffect(StatusEffect.DoubleUpChance) ?: return@SkillApplier emptyList()
                val linkedStatus = doubleUpState.linkedStatus ?: return@SkillApplier emptyList()
                listOf(CorsairRollTargetEvent(statusEffect = linkedStatus, rollerId = it.sourceState.id, targetId = it.targetState.id, context = it.context))
            })
    }

    private fun weaponSkillApplier(abilityInfo: AbilityInfo): SkillApplier {
        return SkillApplier(
            skillId = abilityInfo.index,
            skillType = SkillType.Ability,
            validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = {
                listOf(
                    ActorAttackedEvent(
                        sourceId = it.sourceState.id,
                        targetId = it.targetState.id,
                        damageAmount = listOf(3),
                        damageType = AttackDamageType.Physical,
                        actionContext = it.context,
                        skill = it.skill,
                    ),
                )
            }
        )
    }

}