package xim.poc.game.configuration.v0

import xim.poc.SynthesisType
import xim.poc.game.configuration.SynthesisRecipe
import xim.poc.game.configuration.SynthesisRecipes

object V0SynthesisRecipes {

    val recipes = listOf(
        SynthesisRecipe(1, SynthesisType.Fire, output = 650, input = listOf(642, 640, 640, 640)),
        SynthesisRecipe(2, SynthesisType.Water, output = 4422, input = listOf(4392, 4392, 4392, 4392)),
    )

    init {
        recipes.forEach { SynthesisRecipes.register(it) }
    }

}