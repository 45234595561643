package xim.poc.game.configuration.v0.tower

import xim.math.Vector3f
import xim.poc.gl.ByteColor
import xim.poc.tools.SphereDrawingTool

interface Boundary {
    fun apply(actorPosition: Vector3f)
}

class EncompassingSphere(val center: Vector3f, val radius: Float): Boundary {

    override fun apply(actorPosition: Vector3f) {
        val distance = Vector3f.distance(center, actorPosition)
        if (distance <= radius) { return }

        val fix = distance - (radius - 0.01f)
        val delta = (center - actorPosition).normalizeInPlace() * fix
        actorPosition.addInPlace(delta)

        debugDraw()
    }

    private fun debugDraw() {
        SphereDrawingTool.drawSphere(center, radius, ByteColor(0, 0, 80, 30))
    }

}