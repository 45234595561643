package xim.poc.game.configuration.v0

import xim.poc.game.AugmentId
import xim.poc.game.InventoryItem
import xim.poc.game.ItemAugmentId
import xim.poc.ui.ShiftJis
import xim.resource.table.AugmentTable
import kotlin.math.ceil
import kotlin.math.pow
import kotlin.math.roundToInt

enum class AugmentSubType {
    None,
    Trust,
}

class ItemAugmentAttribute private constructor(val augment: AugmentId, val type: AugmentSubType = AugmentSubType.None) {

    companion object {
        val maxHp = ItemAugmentAttribute(augment = AugmentId.HP)
        val maxMp = ItemAugmentAttribute(augment = AugmentId.MP)
        val str = ItemAugmentAttribute(augment = AugmentId.STR)
        val dex = ItemAugmentAttribute(augment = AugmentId.DEX)
        val vit = ItemAugmentAttribute(augment = AugmentId.VIT)
        val agi = ItemAugmentAttribute(augment = AugmentId.AGI)
        val int = ItemAugmentAttribute(augment = AugmentId.INT)
        val mnd = ItemAugmentAttribute(augment = AugmentId.MND)
        val chr = ItemAugmentAttribute(augment = AugmentId.CHR)

        val criticalHitRate = ItemAugmentAttribute(augment = AugmentId.CriticalHitRate)
        val enemyCriticalHitRate = ItemAugmentAttribute(augment = AugmentId.EnemyCriticalHitRate)
        val weaponDamage = ItemAugmentAttribute(augment = AugmentId.WeaponDamage)
        val haste = ItemAugmentAttribute(augment = AugmentId.Haste)
        val spellInterruptDown = ItemAugmentAttribute(augment = AugmentId.SpellInterruptDown)
        val physicalDamageTaken = ItemAugmentAttribute(augment = AugmentId.PhysicalDamageTaken)
        val magicalDamageTaken = ItemAugmentAttribute(augment = AugmentId.MagicalDamageTaken)
        val damageTaken = ItemAugmentAttribute(augment = AugmentId.DamageTaken)
        val parryRate = ItemAugmentAttribute(augment = AugmentId.ParryingRate)
        val tpBonus = ItemAugmentAttribute(augment = AugmentId.TpBonus)
        val autoRegen = ItemAugmentAttribute(augment = AugmentId.Regen)
        val autoRefresh = ItemAugmentAttribute(augment = AugmentId.Refresh)
        val magicAttackBonus = ItemAugmentAttribute(augment = AugmentId.MagicAttackBonus)
        val fastCast = ItemAugmentAttribute(augment = AugmentId.FastCast)
        val storeTp = ItemAugmentAttribute(augment = AugmentId.StoreTp)
        val doubleAttack = ItemAugmentAttribute(augment = AugmentId.DoubleAttack)
        val tripleAttack = ItemAugmentAttribute(augment = AugmentId.TripleAttack)
        val dualWield = ItemAugmentAttribute(augment = AugmentId.DualWield)
        val subtleBlow = ItemAugmentAttribute(augment = AugmentId.SubtleBlow)
        val weaponSkillDamage = ItemAugmentAttribute(augment = AugmentId.WeaponSkillDamage)
        val criticalHitDamage = ItemAugmentAttribute(augment = AugmentId.CriticalHitDamage)
        val conserveTp = ItemAugmentAttribute(augment = AugmentId.ConserveTp)
        val magicBurstDamage = ItemAugmentAttribute(augment = AugmentId.MagicBurstDamage)
        val doubleDamage = ItemAugmentAttribute(augment = AugmentId.DoubleDamage)
        val skillChainDamage = ItemAugmentAttribute(augment = AugmentId.SkillChainDamage)
        val criticalTpGain = ItemAugmentAttribute(augment = AugmentId.CriticalTpGain)
        val elementalWeaponSkillDamage = ItemAugmentAttribute(augment = AugmentId.ElementalWeaponSkillDamage)
        val quadrupleAttack = ItemAugmentAttribute(augment = AugmentId.QuadrupleAttack)
        val followUpAttack = ItemAugmentAttribute(augment = AugmentId.FollowUpAttack)

        val blank = ItemAugmentAttribute(augment = AugmentId.Blank)

        val trustAutoRegen = ItemAugmentAttribute(augment = AugmentId.Regen, type = AugmentSubType.Trust)
        val trustAutoRefresh = ItemAugmentAttribute(augment = AugmentId.Refresh, type = AugmentSubType.Trust)
    }

    fun toDescription(potency: Int, bonusValue: Int? = null, maxValue: Int? = null, buildUpValue: Int? = null, allMeldCaps: Boolean = false): String {
        val augmentDisplay = AugmentTable.getAugmentName(augment, potency)

        val prefix = when (type) {
            AugmentSubType.None -> ""
            AugmentSubType.Trust -> AugmentTable.getAugmentName(AugmentId.AlterEgo)
        }

        val bonusDisplay = if (bonusValue != null) {
            "${ShiftJis.colorItem}(+$bonusValue)${ShiftJis.colorInfo}"
        } else {
            ""
        }

        val maxValueDisplay = if ((bonusValue != null || allMeldCaps) && maxValue != null) {
            "${ShiftJis.colorItem}[$maxValue]${ShiftJis.colorInfo}"
        } else {
            ""
        }

        val buildUpNeededDisplay = if (buildUpValue != null && buildUpValue > 0) {
            "${ShiftJis.colorInvalid}[$buildUpValue]${ShiftJis.colorInfo}"
        } else {
            ""
        }

        return "$prefix$augmentDisplay$buildUpNeededDisplay$bonusDisplay$maxValueDisplay"
    }

}

fun interface ItemAugmentValue {
    fun calculate(item: InventoryItem): Int
}

data class ItemAugmentDefinition(
    val id: ItemAugmentId,
    val attribute: ItemAugmentAttribute,
    val valueFn: ItemAugmentValue,
)

object ItemAugmentDefinitions {

    const val maxPossibleRankLevel = 30

    const val weaponDamageAugmentId: ItemAugmentId = 29

    const val doubleAttackAugmentId: ItemAugmentId = 13
    const val tripleAttackAugmentId: ItemAugmentId = 31
    const val quadrupleAttackAugmentId: ItemAugmentId = 36

    const val storeTpAugmentId: ItemAugmentId = 14
    const val conserveTpAugmentId: ItemAugmentId = 33
    const val bonusTpAugmentId: ItemAugmentId = 37

    const val weaponSkillDamageAugmentId: ItemAugmentId = 16
    const val doubleDamageAugmentId: ItemAugmentId = 32
    const val followUpAttackAugmentId: ItemAugmentId = 38

    const val magicAttackBonusAugmentId: ItemAugmentId = 30
    const val skillChainDamageAugmentId: ItemAugmentId = 35
    const val elementalWeaponSkillAugmentId: ItemAugmentId = 39

    const val critHitRateAugmentId: ItemAugmentId = 21
    const val critHitDamageAugmentId: ItemAugmentId = 17
    const val critHitTpAugmentId: ItemAugmentId = 40

    val definitions: Map<ItemAugmentId, ItemAugmentDefinition>

    init {
        val defs = ArrayList<ItemAugmentDefinition>()

        // Standard combat stats
        defs += ItemAugmentDefinition(id = 3, attribute = ItemAugmentAttribute.str) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 4, attribute = ItemAugmentAttribute.dex) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 5, attribute = ItemAugmentAttribute.vit) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 6, attribute = ItemAugmentAttribute.agi) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 7, attribute = ItemAugmentAttribute.int) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 8, attribute = ItemAugmentAttribute.mnd) { standardScaling(initialValue = 4f, it) }
        defs += ItemAugmentDefinition(id = 9, attribute = ItemAugmentAttribute.chr) { standardScaling(initialValue = 4f, it) }

        defs += ItemAugmentDefinition(id = 10, attribute = ItemAugmentAttribute.trustAutoRefresh) { 1 }
        defs += ItemAugmentDefinition(id = 11, attribute = ItemAugmentAttribute.trustAutoRegen) { 1 }

        defs += ItemAugmentDefinition(id = 12, attribute = ItemAugmentAttribute.fastCast) { rankScaling(10f, it) }
        defs += ItemAugmentDefinition(id = 13, attribute = ItemAugmentAttribute.doubleAttack) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 14, attribute = ItemAugmentAttribute.storeTp) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 15, attribute = ItemAugmentAttribute.haste) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 16, attribute = ItemAugmentAttribute.weaponSkillDamage) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 17, attribute = ItemAugmentAttribute.criticalHitDamage) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 18, attribute = ItemAugmentAttribute.physicalDamageTaken) { rankScaling(-3f, it) }
        defs += ItemAugmentDefinition(id = 19, attribute = ItemAugmentAttribute.autoRegen) { 1 }
        defs += ItemAugmentDefinition(id = 20, attribute = ItemAugmentAttribute.autoRefresh) { 1 }
        defs += ItemAugmentDefinition(id = 21, attribute = ItemAugmentAttribute.criticalHitRate) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 22, attribute = ItemAugmentAttribute.enemyCriticalHitRate) { rankScaling(-3f, it) }
        defs += ItemAugmentDefinition(id = 23, attribute = ItemAugmentAttribute.subtleBlow) { rankScaling(8f, it) }
        defs += ItemAugmentDefinition(id = 24, attribute = ItemAugmentAttribute.spellInterruptDown) { rankScaling(8f, it) }
        defs += ItemAugmentDefinition(id = 25, attribute = ItemAugmentAttribute.maxHp) { standardScaling(initialValue = 5f, it) }
        defs += ItemAugmentDefinition(id = 26, attribute = ItemAugmentAttribute.magicalDamageTaken) { rankScaling(-3f, it) }
        defs += ItemAugmentDefinition(id = 27, attribute = ItemAugmentAttribute.damageTaken) { rankScaling(-2f, it) }
        defs += ItemAugmentDefinition(id = 28, attribute = ItemAugmentAttribute.parryRate) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 29, attribute = ItemAugmentAttribute.weaponDamage) { 0 }
        defs += ItemAugmentDefinition(id = 30, attribute = ItemAugmentAttribute.magicAttackBonus) { rankScaling(5f, it) }
        defs += ItemAugmentDefinition(id = 31, attribute = ItemAugmentAttribute.tripleAttack) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 32, attribute = ItemAugmentAttribute.doubleDamage) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 33, attribute = ItemAugmentAttribute.conserveTp) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 34, attribute = ItemAugmentAttribute.magicBurstDamage) { rankScaling(6f, it) }
        defs += ItemAugmentDefinition(id = 35, attribute = ItemAugmentAttribute.skillChainDamage) { rankScaling(3f, it) }
        defs += ItemAugmentDefinition(id = 36, attribute = ItemAugmentAttribute.quadrupleAttack) { rankScaling(2f, it) }
        defs += ItemAugmentDefinition(id = 37, attribute = ItemAugmentAttribute.tpBonus) { 0 }
        defs += ItemAugmentDefinition(id = 38, attribute = ItemAugmentAttribute.followUpAttack) { 0 }
        defs += ItemAugmentDefinition(id = 39, attribute = ItemAugmentAttribute.elementalWeaponSkillDamage) { 0 }
        defs += ItemAugmentDefinition(id = 40, attribute = ItemAugmentAttribute.criticalTpGain) { 0 }
        defs += ItemAugmentDefinition(id = 1023, attribute = ItemAugmentAttribute.blank) { 0 }

        definitions = defs.associateBy { it.id }
    }

    operator fun get(augmentId: ItemAugmentId): ItemAugmentDefinition {
        return definitions[augmentId] ?: throw IllegalStateException("[$augmentId] No such augment definition")
    }

    private fun standardScaling(initialValue: Float, item: InventoryItem): Int {
        val itemLevel = ItemDefinitions[item].internalLevel
        val rawValue = ceil(initialValue * 1.084472f.pow(itemLevel)) * qualityBonus(item)
        return rawValue.roundToInt()
    }

    private fun rankScaling(initialValue: Float, item: InventoryItem): Int {
        val rawValue = initialValue * qualityBonus(item)
        return rawValue.roundToInt()
    }

    private fun rankProportion(item: InventoryItem): Float {
        val augment = item.augments ?: return 1f
        return (augment.rankLevel - 1).toFloat() / (maxPossibleRankLevel - 1).toFloat()
    }

    fun getInternalQuality(augmentRank: Int): Int {
        return if (augmentRank <= 5) {
            0
        } else if (augmentRank <= 15) {
            1
        } else if (augmentRank <= 29) {
            2
        } else {
            3
        }
    }

    fun qualityBonus(item: InventoryItem): Float {
        return 1f + 0.5f * rankProportion(item)
    }

}