package xim.poc.game.configuration.v0.behaviors

import xim.math.Vector3f
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.GameV0
import xim.poc.game.configuration.v0.V0MonsterHelper
import xim.poc.game.event.ActorDamagedEvent
import xim.poc.game.event.AttackDamageType
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.Event
import xim.util.Fps
import xim.util.addInPlace
import kotlin.math.min
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO

class MobWarmachineController(actorState: ActorState): BasicMonsterController(actorState) {

    private val spawnedBombs = ArrayList<ActorPromise>()

    private val skillTable = WeightedTable(
        382 to 0.5,
        381 to 0.25,
        383 to 0.15,
        379 to 0.05,
        380 to 0.05,
    )

    override fun selectSkill(): Skill {
        return Skill(SkillType.MobSkill, skillTable.getRandom())
    }

    override fun shouldStartUsingSkill(): Boolean {
        return actorState.getTpp() > 0.3
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.movementSpeed = -30
        aggregate.storeTp += 20
        aggregate.statusResistances.addInPlace(StatusEffect.Sleep, 100)
    }

    override fun onDefeated(): List<Event> {
        for (bomb in spawnedBombs) {
            bomb.onReady {
                it.setHp(0)
                ActorManager[it.id]?.onDisplayDeath()
            }
        }

        return emptyList()
    }

    override fun onSkillExecuted(primaryTargetContext: SkillApplierHelper.TargetEvaluatorContext): List<Event> {
        if (primaryTargetContext.skillInfo.id == 382) {
            val position = Vector3f(primaryTargetContext.targetState.position)
            AttackContext.compose(primaryTargetContext.context) { spawnBomb(position) }
        }

        return emptyList()
    }

    private fun spawnBomb(position: Vector3f) {
        val monsterDefinition = MonsterDefinitions[26]

        spawnedBombs += V0MonsterHelper.spawnMonster(
            monsterDefinition = monsterDefinition,
            position = position,
            actorType = ActorType.Npc,
        ).onReady { triggerBombProximityCheck(it) }
    }

    private fun triggerBombProximityCheck(newBomb: ActorState) {
        cleanObsoleteBombs()

        val toExplode = spawnedBombs.mapNotNull { ActorStateManager[it.getIfReady()] }
            .filter { it.id != newBomb.id }
            .filter { Vector3f.distance(newBomb.position, it.position) < 6f }
            .toMutableList()

        if (toExplode.isNotEmpty()) {
            toExplode += newBomb
        }

        for (bomb in toExplode) {
            if (bomb.behaviorController !is MobWarmachineExplosiveController) { continue }
            bomb.behaviorController.setExplosionTimer(ZERO)
        }
    }

    private fun cleanObsoleteBombs() {
        val itr = spawnedBombs.iterator()
        while (itr.hasNext()) {
            val next = itr.next()

            val bombId = next.getIfReady() ?: continue
            val actorState = ActorStateManager[bombId]

            if (actorState == null || actorState.isDead()) { itr.remove() }
        }
    }

}

class MobWarmachineExplosiveController(val actorState: ActorState): ActorBehaviorController {

    private var explodeTimer: Float = Fps.secondsToFrames(30)

    fun setExplosionTimer(duration: Duration) {
        val newTime = Fps.millisToFrames(duration.inWholeMilliseconds)
        explodeTimer = min(newTime, explodeTimer)
    }

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actorState.isIdle()) { return emptyList() }

        explodeTimer -= elapsedFrames
        return if (explodeTimer <= 0) {
            return tryExplode()
        } else {
            emptyList()
        }
    }

    override fun onDamaged(context: ActorDamagedContext): List<Event> {
        if (context.skill?.skillType != SkillType.MobSkill) { return emptyList() }

        return if (context.skill.id == 381) {
            AttackContext.compose(context.actionContext) { setExplosionTimer(ZERO) }
            emptyList()
        } else if (context.skill.id == 383) {
            disarm(context.actionContext)
        } else {
            emptyList()
        }
    }

    private fun disarm(actionContext: AttackContext?): List<Event> {
        return listOf(ActorDamagedEvent(
            sourceId = actorState.id,
            targetId = actorState.id,
            amount = actorState.getMaxHp(),
            actionContext = actionContext,
            emitDamageText = false,
            damageType = AttackDamageType.Static,
        ))
    }

    private fun tryExplode(): List<Event> {
        actorState.setTp(GameV0.getMaxTp(actorState))

        if (!GameEngine.canBeginUseMobSkill(actorState.id, mobSkillId = 1582)) {
            return emptyList()
        }

        return listOf(CastMobSkillStart(
            sourceId = actorState.id,
            targetId = actorState.id,
            mobSkillId = 1582,
        ))
    }

}