package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.AutoAttackController
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.SkillApplierHelper
import xim.poc.game.configuration.v0.V0AbilityDefinitions
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.singleStatus
import xim.poc.game.configuration.v0.WeaponSkillDamageCalculator
import xim.poc.game.event.*
import xim.resource.table.MobSkillInfoTable
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import xim.util.multiplyInPlace
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

private enum class LimuleElement {
    None,
    Fire,
    Light,
}

object MobLimuleAbilities {

    fun blazingBound(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(StatusEffect.Burn, baseDuration = 30.seconds) { se ->
                    se.statusState.potency = (it.sourceState.combatStats.int / 10f).roundToInt()
                    se.statusState.secondaryPotency = 0.8f
                }
            )

            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.int,
                defendStat = CombatStat.int
            ) { extraTp -> 2.5f + 1f * extraTp }

            V0AbilityDefinitions.weaponSkillToEvents(
                context = it,
                wsResult = wsResult,
                damageType = AttackDamageType.Magical,
                attackEffects = attackEffects,
            )
        }
    }

    fun moltingBurst(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.mnd,
                defendStat = CombatStat.mnd
            ) { extraTp -> 2f + 1f * extraTp }

            V0AbilityDefinitions.weaponSkillToEvents(it, wsResult, AttackDamageType.Magical)
        }
    }

}

class MobLimuleController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)

    private var actionCoolDown = 0f

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        actionCoolDown -= elapsedFrames

        val skillResult = if (eligibleToUseSkill()) { useMobSkill() } else { emptyList() }
        if (skillResult.isNotEmpty()) {
            actionCoolDown = Fps.secondsToFrames(10)
            autoAttackDelegate.reset()
            return skillResult
        }

        val spellResult = if (eligibleToUseSpell()) { useSpell() } else { emptyList() }
        if (spellResult.isNotEmpty()) {
            actionCoolDown = Fps.secondsToFrames(12)
            autoAttackDelegate.reset()
            return spellResult
        }

        if (!actorState.isEngaged()) {
            actorState.appearanceState = 0
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        when (getType()) {
            LimuleElement.None -> {
            }
            LimuleElement.Fire -> {
                aggregate.multiplicativeStats.multiplyInPlace(CombatStat.str, 1.5f)
                aggregate.multiplicativeStats.multiplyInPlace(CombatStat.int, 1.5f)
                aggregate.autoAttackEffects += AutoAttackEffect(effectPower = 2, effectType = AttackAddedEffectType.Fire)
            }
            LimuleElement.Light -> {
                aggregate.multiplicativeStats.multiplyInPlace(CombatStat.vit, 1.5f)
                aggregate.multiplicativeStats.multiplyInPlace(CombatStat.mnd, 1.5f)
                aggregate.autoAttackEffects += AutoAttackEffect(effectPower = 2, effectType = AttackAddedEffectType.Light)
            }
        }
    }

    private fun eligibleToUseSkill(): Boolean {
        return actionCoolDown <= 0f && actorState.isEngaged() && !actorState.hasStatusActionLock()
    }

    private fun useMobSkill(): List<Event> {
        val monsterSkills = MonsterDefinitions[actorState.monsterId]?.mobSkills ?: return emptyList()

        val randomSkillId = monsterSkills.filter { GameEngine.canBeginUseMobSkill(actorState.id, it) }
            .randomOrNull() ?: return emptyList()

        val mobSkillInfo = MobSkillInfoTable[randomSkillId] ?: return emptyList()

        val targetFilter = mobSkillInfo.targetFilter
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return emptyList()

        return listOf(CastMobSkillStart(actorState.id, targetId, randomSkillId))
    }

    private fun eligibleToUseSpell(): Boolean {
        if (actorState.appearanceState == 0) { return false }
        return actionCoolDown <= 0f && actorState.isEngaged() && !actorState.hasStatusActionLock()
    }

    private fun useSpell(): List<Event> {
        val spell = getValidSpells()
            .map { SpellInfoTable[it] }
            .filter { GameEngine.canBeginCastSpell(actorState.id, it) }
            .randomOrNull() ?: return emptyList()

        val target = if (spell.targetFilter.targetFilter(actorState, actorState)) {
            actorState.id
        } else if (spell.targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }

        if (target == null) { return emptyList() }

        return listOf(CastSpellStart(sourceId = actorState.id, targetId = target, spellId = spell.index))
    }

    private fun getValidSpells(): List<Int> {
        return when (actorState.appearanceState) {
            1 -> listOf(144)
            2 -> listOf(28)
            else -> emptyList()
        }
    }

    private fun getType(): LimuleElement {
        return when (actorState.appearanceState) {
            1 -> LimuleElement.Fire
            2 -> LimuleElement.Light
            else -> LimuleElement.None
        }
    }

}