package xim.poc.game.configuration.v0.behaviors

import xim.poc.ModelLook
import xim.poc.game.ActorState
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.event.Event
import kotlin.random.Random

class MobWandererController(actorState: ActorState): BasicMonsterController(actorState) {

    override fun onInitialized(): List<Event> {
        val looks = listOf(0x452, 0x453, 0x454, 0x456)
        actorState.setBaseLook(ModelLook.npc(looks.random()))
        actorState.appearanceState = Random.nextInt(1, 3)
        return emptyList()
    }

}