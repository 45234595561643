package xim.resource.table

import xim.resource.SpellInfo

object TrustTable {

    fun getModelId(spellInfo: SpellInfo): Int {
        return 0xBB8 + when(spellInfo.index) {
            896 -> 0x00
            897 -> 0x01
            898 -> 0x02
            899 -> 0x03
            900 -> 0x04
            901 -> 0x05
            902 -> 0x06
            903 -> 0x07
            904 -> 0x08
            905 -> 0x09
            906 -> 0x0A
            907 -> 0x0B
            908 -> 0x0C
            909 -> 0x0D
            910 -> 0x0E
            911 -> 0x0F
            912 -> 0x10
            913 -> 0x11
            914 -> 0x12
            915 -> 0x13
            916 -> 0x14
            917 -> 0x15
            918 -> 0x16
            919 -> 0x17
            920 -> 0x18
            921 -> 0x19
            922 -> 0x1A
            923 -> 0x1B
            924 -> 0x1C
            925 -> 0x1D
            926 -> 0x1E
            927 -> 0x1F
            928 -> 0x20
            929 -> 0x21
            930 -> 0x22
            931 -> 0x23
            932 -> 0x24
            933 -> 0x25
            934 -> 0x26
            935 -> 0x27
            936 -> 0x28
            937 -> 0x29
            938 -> 0x2A
            939 -> 0x2B
            940 -> 0x2C
            941 -> 0x2D
            942 -> 0x2E
            943 -> 0x2F
            944 -> 0x30
            945 -> 0x31
            946 -> 0x32
            947 -> 0x33
            948 -> 0x35
            949 -> 0x36
            950 -> 0x37
            951 -> 0x38
            952 -> 0x39
            953 -> 0x3A
            954 -> 0x3C
            955 -> 0x3D
            956 -> 0x3E
            957 -> 0x3B
            958 -> 0x43
            959 -> 0x44
            960 -> 0x45
            961 -> 0x46
            962 -> 0x47
            963 -> 0x48
            964 -> 0x49
            965 -> 0x4A
            966 -> 0x4B
            967 -> 0x4C
            968 -> 0x4D
            969 -> 0x4E
            970 -> 0x4F
            971 -> 0x50
            972 -> 0x57
            973 -> 0x58
            974 -> 0x59
            975 -> 0x5A
            976 -> 0x5B
            977 -> 0x5C
            978 -> 0x5D
            979 -> 0x5E
            980 -> 0x5F
            981 -> 0x60
            982 -> 0x62
            983 -> 0x63
            984 -> 0x64
            985 -> 0x65
            986 -> 0x67
            987 -> 0x69
            988 -> 0x6A
            989 -> 0x6B
            990 -> 0x6C
            991 -> 0x6D
            992 -> 0x71
            993 -> 0x72
            994 -> 0x73
            995 -> 0x74
            996 -> 0x75
            997 -> 0x6F
            998 -> 0x76
            999 -> 0x78
            1002 -> 0x77
            1003 -> 0x79
            1004 -> 0x34
            1005 -> 0x3F
            1006 -> 0x40
            1007 -> 0x41
            1008 -> 0x42
            1009 -> 0x51
            1010 -> 0x56
            1011 -> 0x52
            1012 -> 0x53
            1013 -> 0x54
            1014 -> 0x61
            1015 -> 0x68
            1016 -> 0x66
            1017 -> 0x55
            1018 -> 0x70
            1019 -> 0x6E
            else -> throw IllegalStateException("Unknown trust: $spellInfo")
        }
    }
}