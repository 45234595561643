package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.CastingState
import xim.poc.game.GameState
import xim.poc.game.InternalItemId
import xim.poc.game.configuration.SkillType
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis

class CastItemStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val internalItemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val item = actorState.inventory.getByInternalId(internalItemId) ?: return emptyList()
        val inventoryItemInfo = item.info()

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castTime = GameState.getGameMode().getSkillCastTime(actorState, SkillType.Item, inventoryItemInfo.itemId)

        actorState.setCastingState(CastingState.item(castTime = castTime, sourceId = sourceId, targetId = targetId, item = item))

        ChatLog("${actorState.name} uses a ${ShiftJis.colorItem}${inventoryItemInfo.logName}${ShiftJis.colorClear}.", ChatLogColor.Action)
        ActorManager[sourceId]?.startUsingItem(inventoryItemInfo, targetId)

        return emptyList()
    }

}
