package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillAppliers.writeFailureReason
import xim.poc.game.configuration.SkillFailureReason
import xim.poc.game.configuration.SkillType
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.ShiftJis
import xim.resource.AbilityCost
import xim.resource.AbilityCostType
import xim.resource.ItemListType
import xim.resource.table.AbilityNameTable
import xim.resource.table.MobSkillNameTable
import xim.resource.table.SpellNameTable

class CastingChargeCompleteEvent(
    val sourceId: ActorId
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()

        val current = actorState.getCastingState() ?: return emptyList()
        current.onExecute()

        val targetState = ActorStateManager[current.targetId]

        if (current.interrupted || targetState == null || !current.isTargetValid()) {
            return listOf(CastInterruptedEvent(sourceId))
        }

        if (GameEngine.checkParalyzeProc(actorState)) {
            return listOf(CastInterruptedEvent(sourceId, castInterruptReason = CastInterruptReason.Paralyze))
        }

        return if (current.spellInfo != null) {
            invokeSpell(actorState, targetState, current)
        } else if (current.rangedAttack) {
            invokeRangedAttack(actorState, targetState, current)
        } else if (current.item != null) {
            invokeItem(actorState, targetState, current)
        } else if (current.abilityInfo != null) {
            invokeAbility(actorState, targetState, current)
        } else if (current.mobSkillInfo != null) {
            invokeMobSkill(actorState, targetState, current)
        } else {
            emptyList()
        }
    }

    private fun invokeSpell(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val spellInfo = current.spellInfo ?: return emptyList()
        if (!GameEngine.canCastSpell(actorState.id, spellInfo)) { return listOf(CastInterruptedEvent(sourceId)) }

        val cost = GameEngine.getSkillUsedCost(actorState, SkillType.Spell, spellInfo.index)

        val applierResult = SkillAppliers.invoke(actorState, targetState, spellInfo)

        if (!applierResult.success) {
            val spellName = SpellNameTable.first(spellInfo.index)
            writeFailureReason(actorState, targetState, spellName, applierResult.failureReason)
            return listOf(CastInterruptedEvent(sourceId))
        }

        val recastDelay = GameState.getGameMode().getSkillRecastTime(actorState, SkillType.Spell, spellInfo.index)
        actorState.getRecastStates().addSpellRecastState(spellInfo, recastDelay)

        consumeCost(actorState, cost)
        actorState.consumeStatusEffectCharge(StatusEffect.Spontaneity)

        EffectDisplayer.displaySpell(spellInfo, current.sourceId, current.targetId, applierResult.allTargetIds, applierResult.contexts)
        return applierResult.events
    }

    private fun invokeRangedAttack(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        return listOf(AutoAttackEvent(actorState.id, targetId = targetState.id, rangedAttack = true))
    }

    private fun invokeItem(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val item = current.item ?: return emptyList()
        val itemInfo = item.info()

        if (actorState.inventory.getByInternalId(item.internalId) == null) {
            writeFailureReason(actorState, targetState, itemInfo.logName, SkillFailureReason.GenericFailure)
            return listOf(CastInterruptedEvent(sourceId))
        }

        val applierResult = SkillAppliers.invoke(actorState, targetState, itemInfo)

        if (!applierResult.success) {
            writeFailureReason(actorState, targetState, itemInfo.logName, applierResult.failureReason)
            return listOf(CastInterruptedEvent(sourceId))
        }

        if (itemInfo.type == ItemListType.UsableItem) {
            actorState.inventory.discardItem(item.internalId, amount = 1)
        }

        EffectDisplayer.displayItemAnimation(itemInfo, current.sourceId, current.targetId, applierResult.allTargetIds, applierResult.contexts)
        return applierResult.events
    }

    private fun invokeAbility(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val abilityInfo = current.abilityInfo ?: return emptyList()
        if (!GameEngine.canUseAbility(actorState.id, abilityInfo)) { return listOf(CastInterruptedEvent(sourceId)) }

        val cost = GameEngine.getSkillUsedCost(actorState, SkillType.Ability, abilityInfo.index)

        val applierResult = SkillAppliers.invoke(actorState, targetState, abilityInfo)

        if (!applierResult.success) {
            val abilityName = AbilityNameTable.first(abilityInfo.index)
            writeFailureReason(actorState, targetState, abilityName, applierResult.failureReason)
            return listOf(CastInterruptedEvent(sourceId))
        }

        consumeCost(actorState, cost)

        val recastDelay = GameState.getGameMode().getSkillRecastTime(actorState, SkillType.Ability, abilityInfo.index)
        actorState.getRecastStates().addAbilityRecastState(abilityInfo, recastDelay)

        EffectDisplayer.displayAbility(abilityInfo, sourceId = sourceId, primaryTargetId = current.targetId, allTargetIds = applierResult.allTargetIds, actionContext = applierResult.contexts)

        return applierResult.events
    }

    private fun invokeMobSkill(actorState: ActorState, targetState: ActorState, current: CastingState): List<Event> {
        val mobSkillInfo = current.mobSkillInfo ?: return emptyList()
        val mobSkillName = MobSkillNameTable[mobSkillInfo.id]

        val cost = GameEngine.getSkillUsedCost(actorState, SkillType.MobSkill, mobSkillInfo.id)

        val applierResult = SkillAppliers.invoke(actorState, targetState, mobSkillInfo)

        if (!applierResult.success) {
            writeFailureReason(actorState, targetState, mobSkillName, applierResult.failureReason)
            return listOf(CastInterruptedEvent(sourceId))
        }

        consumeCost(actorState, cost)
        EffectDisplayer.displayMobSkill(mobSkillInfo = mobSkillInfo, sourceId = sourceId, primaryTargetId = current.targetId, allTargetIds = applierResult.allTargetIds, actionContext = applierResult.contexts)

        if (mobSkillName != ".") { ChatLog.addLine("${actorState.name} uses $mobSkillName ${ShiftJis.rightArrow} ${targetState.name}!", ChatLogColor.Info) }

        return applierResult.events
    }

    private fun consumeCost(actorState: ActorState, cost: AbilityCost) {
        when (cost.type) {
            AbilityCostType.Tp -> actorState.consumeTp(cost.value)
            AbilityCostType.Mp -> actorState.consumeMp(cost.value)
        }
    }

}