package xim.poc.game.configuration.v0.zones

import xim.math.Vector3f
import xim.poc.ActorManager
import xim.poc.EquipmentLook
import xim.poc.ModelLook
import xim.poc.RaceGenderConfig
import xim.poc.game.*
import xim.poc.game.configuration.CustomZoneDefinition
import xim.poc.game.configuration.basicNpc
import xim.poc.game.configuration.v0.*
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.maxPossibleRankLevel
import xim.poc.game.configuration.v0.interactions.*
import xim.poc.game.configuration.v0.tower.DropTables.lvlAccessory12
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment10
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment12
import xim.poc.game.event.InitialActorState
import xim.resource.DatId
import xim.resource.InventoryItems
import xim.resource.table.MusicSettings
import xim.resource.table.ZoneNpcList
import xim.resource.table.ZoneSettings
import xim.util.PI_f

object BaseCamp {

    private const val zoneId = 283

    private val entrance = basicNpc(zoneId, npcId = 0x009, name = "Entrance", npcLook = 0x0975, position = Vector3f(x=-46.03f,y=-0.65f,z=46.81f))
    private val entranceInteraction = EntranceInteraction(destination = Vector3f(x=-87.57f,y=-4.85f,z=-560.39f))

    private val stylist = basicNpc(zoneId, npcId = 0x00A, name = "Stylist", look = ModelLook.pc(RaceGenderConfig.HumeChildF, EquipmentLook()), position = Vector3f(x=-41.27f,y=-0.64f,z=46.57f), rotation = PI_f/2f)

    private val upgrader = basicNpc(zoneId, npcId = 0x00B, name = "Upgrade Book", look = ModelLook.npc(0x928), position = Vector3f(x=-44.19f,y=1.34f,z=29.03f))
    private val reinforcementBook = basicNpc(zoneId, npcId = 0x00C, name = "Reinforcement Book", look = ModelLook.npc(0x928), position = Vector3f(x=-46.19f,y=1.34f,z=31.03f))
    private val qualityBook = basicNpc(zoneId, npcId = 0x00D, name = "Meld Book", look = ModelLook.npc(0x928), Vector3f(x=-42.19f,y=1.34f,z=31.03f))
    private val scrapBook = basicNpc(zoneId, npcId = 0x00E, name = "Scrap Book", look = ModelLook.npc(0x928), Vector3f(x=-44.19f,y=1.34f,z=33.03f))
    private val debugger = basicNpc(zoneId, npcId = 0x00F, name = "Debug", look = ModelLook.npc(0x9E2), Vector3f(x=-36.99f,y=0.34f,z=25.66f))

    private val staticNpcs = listOf(entrance, stylist, upgrader, reinforcementBook, qualityBook, debugger)

    private val staticNpcList = ZoneNpcList(
        resourceId = "",
        npcs = staticNpcs,
        npcsByDatId = emptyMap(),
    )

    val staticNpcInteractions = mapOf(
        entrance.actorId to entranceInteraction,
        stylist.actorId to StylistInteraction,
        upgrader.actorId to UpgradeWeaponInteraction,
        reinforcementBook.actorId to ItemReinforcementInteraction,
        qualityBook.actorId to MeldInteraction,
        scrapBook.actorId to ScrapInventoryItemInteraction,
        debugger.actorId to Debugger,
    )

    val definition = CustomZoneDefinition(
        zoneId = 283,
        zoneSettings = ZoneSettings(zoneId, MusicSettings(musicId = 63)),
        staticNpcList = staticNpcList,
    )

}

class BaseCampLogic : ZoneLogic {

    private lateinit var starterChest: TreasureChest
    private val dynamicNpcs = ArrayList<ActorPromise>()

    fun setup() {
        starterChest = starterGearChest()
        createShop()

        for ((npcId, interaction) in BaseCamp.staticNpcInteractions) {
            GameV0.interactionManager.registerInteraction(npcId, interaction)
        }

        val player = ActorStateManager.player()
        if (player.learnedSpells.spellIds.size >= 2) {
            createBlueMagicMoogle()
        }
    }

    override fun update(elapsedFrames: Float) {
        starterChest.update(elapsedFrames)
    }

    override fun cleanUp() {
        starterChest.cleanUp()
        dynamicNpcs.forEach { it.onReady { state -> ActorStateManager.delete(state.id) } }
    }

    private fun starterGearChest(): TreasureChest {
        return TreasureChest(
            TreasureChestDefinition(
                position = Vector3f(x=-40.00f,y=-0.64f,z=46.50f),
                rotation = PI_f/2f,
                treasureChestLook = TreasureChestLook.Gold,
                itemDefinitions = listOf(
                    ItemDropDefinition(itemId = 16535, augmentRank = 1, augmentRankMax = maxPossibleRankLevel),
                    ItemDropDefinition(itemId = 12448, augmentRank = 1),
                    ItemDropDefinition(itemId = 12576, augmentRank = 1),
                    ItemDropDefinition(itemId = 12704, augmentRank = 1),
                    ItemDropDefinition(itemId = 12832, augmentRank = 1),
                    ItemDropDefinition(itemId = 12960, augmentRank = 1),
                )
            )
        )
    }

    private fun createShop() {
        val shopInventory = Inventory()
        shopInventory.inventoryItems.clear()

        val shopItems = ArrayList<Pair<Int, Int>>()
        shopItems += listOf(16535 to 2, 8930 to 999)

        if (!GameTower.hasClearedFloor(15)) {
            shopItems += listOf(12448 to 1, 12576 to 1, 12704 to 1, 12832 to 1, 12960 to 1,)
        }

        if (GameTower.hasClearedFloor(5)) {
            shopItems += listOf(4033 to 999, 8933 to 999, 8942 to 999, 8951 to 999, 8960 to 999)
        }

        if (GameTower.hasClearedFloor(5) && !GameTower.hasClearedFloor(20)) {
            shopItems += listOf(12440 to 1, 12568 to 1, 12696 to 1, 12824 to 1, 12952 to 1,)
        }

        if (GameTower.hasClearedFloor(10) && !GameTower.hasClearedFloor(25)) {
            shopItems += listOf(13192 to 1, 13583 to 1, 12464 to 1, 12592 to 1, 12720 to 1, 12848 to 1, 12976 to 1)
        }

        if (GameTower.hasClearedFloor(15) && !GameTower.hasClearedFloor(30)) {
            shopItems += listOf(27762 to 1, 27908 to 1, 28047 to 1, 28189 to 1, 28328 to 1,)
        }

        if (GameTower.hasClearedFloor(20)) {
            shopItems += listOf(16530 to 2, 16551 to 2)
        }

        if (GameTower.hasClearedFloor(20) && !GameTower.hasClearedFloor(35)) {
            shopItems += lvlEquipment10().map { it to 1 }
        }

        if (GameTower.hasClearedFloor(25)) {
            shopItems += listOf(8931 to 999, 3951 to 999, 8936 to 999, 8945 to 999, 8954 to 999, 8963 to 999)
        }

        if (GameTower.hasClearedFloor(25) && !GameTower.hasClearedFloor(40)) {
            shopItems += lvlEquipment12().map { it to 1 }
            shopItems += lvlAccessory12().map { it to 1 }
        }

        shopItems.forEach {
            val (id, quantity) = it
            if (InventoryItems[id].isSword()) {
                repeat(times = quantity) { shopInventory.addItem(GameV0.generateItem(ItemDropDefinition(itemId = id, augmentRank = 1, augmentRankMax = maxPossibleRankLevel))) }
            } else {
                shopInventory.addItem(GameV0.generateItem(ItemDropDefinition(itemId = id, quantity = quantity)))
            }
        }

        shopInventory.sort()

        dynamicNpcs += GameEngine.submitCreateActorState(InitialActorState(
            name = "Shopkeep",
            type = ActorType.Object,
            position = Vector3f(x=-40.00f,y=1.34f,z=26.00f),
            rotation = -PI_f/2f,
            modelLook = ModelLook.npc(0x0DB),
            inventory = shopInventory,
        )).onReady {
            GameV0.interactionManager.registerInteraction(it.id, ShopInteraction)
            ActorManager.getOrCreate(it).onReadyToDraw { actor ->
                actor.loopRoutine(DatId("@scd"))
            }
        }
    }

    private fun createBlueMagicMoogle() {
        dynamicNpcs += GameEngine.submitCreateActorState(InitialActorState(
            name = "Blue Moogle",
            type = ActorType.Object,
            position = Vector3f(x=-45.56f,y=1.35f,z=43.14f),
            rotation = PI_f/2f,
            modelLook = ModelLook.npc(0x965),
        )).onReady {
            GameV0.interactionManager.registerInteraction(it.id, BlueMagicInteraction)
        }

        dynamicNpcs += GameEngine.submitCreateActorState(InitialActorState(
            name = "Green Moogle",
            type = ActorType.Object,
            position = Vector3f(x=-43.63f,y=1.35f,z=43.14f),
            rotation = PI_f/2f,
            modelLook = ModelLook.npc(0x966),
        ))
    }

}