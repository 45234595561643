package xim.poc

import xim.math.Vector3f
import xim.poc.audio.AudioManager
import xim.poc.browser.DatLoader
import xim.poc.camera.CameraReference
import xim.poc.game.*
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.MonsterId
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.poc.gl.ByteColor
import xim.poc.tools.PlayerLookTool
import xim.resource.*
import xim.resource.table.FileTableManager
import xim.resource.table.MainDll
import xim.resource.table.NpcInfo
import xim.resource.table.NpcTable
import xim.util.OnceLogger.warn
import kotlin.math.PI
import kotlin.math.abs
import kotlin.math.sign
import kotlin.time.Duration.Companion.seconds

value class ActorId(val id: Int)

private enum class EngageAnimationState {
    NotEngaged,
    Engaged,
    Engaging,
    Disengaging,
}

enum class Direction {
    None,
    Forward,
    Left,
    Right,
    Backward,
}


fun interface ReadyToDrawAction {
    fun invoke(actor: Actor)
}

class RenderState {
    var effectColor: ByteColor = ByteColor.half
    val wrapEffect = ActorWrapEffect()
}

class Actor private constructor(val id: ActorId, val state: ActorState) {

    companion object {
        fun createFrom(actorState: ActorState): Actor {
            return Actor(actorState.id, actorState).also { it.syncFromState() }
        }
    }

    private var displayDoorOpen = false
    private var displayResting = false

    private var synthesisAnimationState: SynthesisAnimationStateMachine? = null

    private val readyToDrawActions = ArrayList<ReadyToDrawAction>()
    private val routineQueue = ActorRoutineQueue(this)

    var displayFacingDir: Float = 0f
        private set

    private var turnAmount: Float = 0f
    private var strafing = false

    val displayPosition = Vector3f()
    val displayPositionOffset = Vector3f()

    var displayDead = false
    var displayAppearanceState = 0

    val currentVelocity: Vector3f = Vector3f()
    var stoppedMoving = false

    val target: ActorId?
        get() = state.targetState.targetId

    var subTarget: ActorId? = null

    private var engageAnimationState = EngageAnimationState.NotEngaged

    val renderState = RenderState()

    private var displayLook = ModelLook.blank()
    var actorModel: ActorModel? = null
        private set

    private val skeletonBoundingBox = ArrayList<BoundingBox>()

    private val hasDftIdle = DatLink<SkeletonAnimationResource>(DatId("dft0"))

    init {
        onReadyToDraw { startAutoRunParticles() }
    }

    fun syncFromState() {
        // TODO - resting, synthesis, etc
        displayFacingDir = state.rotation
        displayPosition.copyFrom(state.position)
        displayAppearanceState = state.appearanceState
        displayDead = state.isDead()

        initDisplay(state)

        update(0f)
    }

    fun update(elapsedFrames: Float) {
        updateVelocity(state.velocity)
        updateDestinationFacingDir(state.rotation)
        displayPosition.copyFrom(state.position + displayPositionOffset)

        if (readyToDrawActions.isNotEmpty() && isReadyToDraw()) {
            readyToDrawActions.forEach { it.invoke(this) }
            readyToDrawActions.clear()
        }

        routineQueue.update(elapsedFrames)

        updateModelDisplay()

        updateRestingDisplay(state)

        updateAppearanceState(state)

        updateEngageDisplay(state)

        updateDoorDisplay(state)

        updateSynthesisAnimationState(synthesisAnimationState)

        updateFacingDir(elapsedFrames)
    }

    fun getSkeletonBoundingBox(index: Int = 0): BoundingBox? {
        return skeletonBoundingBox.getOrNull(index)
    }

    fun updateSkeletonBoundingBoxes(box: List<BoundingBox>) {
        skeletonBoundingBox.clear()
        skeletonBoundingBox.addAll(box)
    }

    fun isPlayer(): Boolean {
        return id == ActorStateManager.playerId
    }

    fun getNpcInfo(): NpcInfo? {
        return state.getNpcInfo()
    }

    fun isDead(): Boolean {
        return state.isDead()
    }

    fun isDisplayedDead(): Boolean {
        return displayDead
    }

    fun setStrafing(toggle: Boolean) {
        strafing = toggle
    }

    fun isStrafing(): Boolean {
        return strafing && isDisplayEngagedOrEngaging()
    }

    fun startRangedAttack(targetId: ActorId) {
        enqueueModelRoutine(DatId("calg"), actorContext = makeStandardContext(), options = RoutineOptions(highPriority = true))
    }

    fun startCasting(spellInfo: SpellInfo, targetId: ActorId) {
        val animationId = DatId.castId(spellInfo) ?: return
        enqueueModelRoutine(animationId, actorContext = makeStandardContext(), options = RoutineOptions(highPriority = true))
    }

    fun startUsingItem(inventoryItemInfo: InventoryItemInfo, targetId: ActorId) {
        enqueueModelRoutine(DatId.castId(inventoryItemInfo), actorContext = makeStandardContext(), options = RoutineOptions(highPriority = true))
    }

    fun readySkill() {
        enqueueModelRoutine(DatId("cate"), actorContext = makeStandardContext(), options = RoutineOptions(highPriority = true))
    }

    fun isCasting(): Boolean {
        return state.isCasting()
    }

    fun transitionToIdle(transitionTime: Float) {
        actorModel?.forceTransitionToIdle(idleId = getIdleAnimationId(), transitionTime = transitionTime, animationDirs = getAllAnimationDirectories())
    }

    fun getIdleAnimationId(): DatId {
        val model = actorModel?.model
        val isChocobo = model is PcModel && model.raceGenderConfig.chocobo
        if (isChocobo || state.isMount) { return DatId("chi?") }

        val mountDef = state.mountedState?.getInfo()
        if (mountDef != null) { return DatId("${mountDef.poseType}un?") }

        if (isStaticNpc() && hasDftIdle()) {
            return DatId("dft?")
        }

        if (isDisplayedDead() && state.owner == null) {
            // TODO - confirm this is actually from the idle animation mode
            return getAnimationModeVariant(DatId("cor?"), actorModel?.idleAnimationMode) { DatId("cr${it}?") }
        }

        return if (engageAnimationState == EngageAnimationState.Engaged || engageAnimationState == EngageAnimationState.Disengaging) {
            getAnimationModeVariant(DatId("btl?"), actorModel?.battleAnimationMode) { DatId("${it}tl?") }
        } else {
            getAnimationModeVariant(DatId("idl?"), actorModel?.idleAnimationMode) { DatId("${it}dl?") }
        }
    }

    private fun hasDftIdle(): Boolean {
        if (!isReadyToDraw()) { return false }
        hasDftIdle.getOrPut { getAllAnimationDirectories().firstNotNullOfOrNull { d -> d.getNullableChildRecursivelyAs(it, SkeletonAnimationResource::class) } }
        return hasDftIdle.getIfPresent() != null
    }

    fun startSynthesis(crystalType: SynthesisType) {
        synthesisAnimationState = SynthesisAnimationStateMachine(this, crystalType)
    }

    fun endSynthesis(result: SynthesisState.SynthesisResult) {
        synthesisAnimationState?.transitionToComplete(result)
    }

    private fun updateSynthesisAnimationState(currentStateMachine: SynthesisAnimationStateMachine?) {
        currentStateMachine?.update()
        if (currentStateMachine?.isComplete() == true) { synthesisAnimationState = null }
    }

    private fun engage() {
        engageAnimationState = EngageAnimationState.Engaged
    }

    private fun disengage() {
        engageAnimationState = EngageAnimationState.NotEngaged
    }

    fun fadeAway() {
        playRoutine(DatId.disappear) { renderState.effectColor = ByteColor.zero }
    }

    fun onRevive() {
        displayDead = false
        actorModel?.clearAnimations()
        transitionToIdle(0f)
    }

    fun isFullyOutOfCombat(): Boolean {
        return engageAnimationState == EngageAnimationState.NotEngaged
    }

    fun isDisplayEngaged(): Boolean {
        return engageAnimationState == EngageAnimationState.Engaged
    }

    fun isDisplayEngagedOrEngaging(): Boolean {
        return engageAnimationState == EngageAnimationState.Engaged || engageAnimationState == EngageAnimationState.Engaging
    }

    fun getAllAnimationDirectories() : List<DirectoryResource> {
        val model = actorModel?.model ?: return emptyList()

        if (isFullyOutOfCombat()) { return model.getAnimationDirectories() }

        val main = model.getMainBattleAnimationDirectory()
        val sub = model.getSubBattleAnimationDirectory()

        return listOfNotNull(main, sub) + model.getAnimationDirectories()
    }

    fun isReadyToDraw() : Boolean {
        val model = actorModel?.model ?: return false

        val mountReady = ActorManager[getMount()?.id]?.isReadyToDraw() ?: true
        if (!mountReady) { return false }

        if (isFullyOutOfCombat()) { return model.isReadyToDraw() }

        if (model.getMainBattleAnimationDirectory() == null) { return false }
        if (isDualWield() && model.getSubBattleAnimationDirectory() == null) { return false }

        return true
    }

    fun getMovementDirection(): Direction {
        val targetDirection = if (isTargetLocked()) {
            state.getTargetDirectionVector() ?: return Direction.None
        } else if (isStrafing()) {
            CameraReference.getInstance().getViewVector()
        } else {
            return Direction.None
        }

        val movementVelocity = Vector3f().copyFrom(currentVelocity).also { it.y = 0f }
        if (movementVelocity.magnitudeSquare() <= 1e-5) {
            return Direction.None
        }

        val normalizedVelocity = movementVelocity.normalizeInPlace()
        val cosAngle = normalizedVelocity.dot(targetDirection)

        // Prefer to run forward > horizontal > backward
        return if (cosAngle >= 0.25f ) {
            return Direction.Forward
        } else if (cosAngle >= -0.75f) {
            val right = targetDirection.cross(Vector3f.UP).normalizeInPlace()
            val horizontalCosAngle = normalizedVelocity.dot(right)
            if (horizontalCosAngle >= 0f) {
                Direction.Right
            } else {
                Direction.Left
            }
        } else {
            Direction.Backward
        }
    }

    fun getMovementAnimation(): DatId {
        if (state.isWalking()) {
            return getAnimationModeVariant(DatId("wlk?"), actorModel?.runningAnimationMode) { DatId("${it}lk?") }
        }

        return when (getMovementDirection()) {
            Direction.None, Direction.Forward -> getAnimationModeVariant(DatId("run?"), actorModel?.runningAnimationMode) { DatId("${it}un?") }
            Direction.Left -> DatId("mvl?")
            Direction.Right -> DatId("mvr?")
            Direction.Backward -> DatId("mvb?")
        }
    }

    fun getJointPosition(index: Int): Vector3f {
        return actorModel?.getJointPosition(index) ?: Vector3f.ZERO
    }

    fun getWorldSpaceJointPosition(index: Int): Vector3f {
        return displayPosition + getJointPosition(index)
    }

    private fun updateDestinationFacingDir(dest: Float) {
        turnAmount = dest - displayFacingDir
        if (abs(turnAmount) > PI ) {
            turnAmount = if (sign(turnAmount) == -1f ) {
                turnAmount + 2 * PI.toFloat()
            } else {
                turnAmount - 2 * PI.toFloat()
            }
        }
    }

    private fun updateFacingDir(elapsedFrames: Float) {
        if (abs(turnAmount) == 0f) { return }

        val stepAmount = sign(turnAmount) * elapsedFrames * PI.toFloat() / 20f
        if (abs(turnAmount) < abs(stepAmount) ) {
            displayFacingDir += turnAmount
            turnAmount = 0f
            return
        }

        displayFacingDir += stepAmount
        turnAmount -= stepAmount

        if (displayFacingDir < -PI) {
            displayFacingDir += 2 * PI.toFloat()
        } else if (displayFacingDir > PI) {
            displayFacingDir -= 2 * PI.toFloat()
        }
    }

    fun isDualWield(): Boolean {
        return state.isDualWield()
    }

    fun isHandToHand(): Boolean {
        return state.isHandToHand()
    }

    private fun updateModelDisplay() {
        val stateLook = state.getCurrentLook()

        val needsNewModel = displayLook.type != stateLook.type || displayLook.modelId != stateLook.modelId

        displayLook = stateLook.copy()
        val currentModel = actorModel?.model

        if (needsNewModel) {
            if (currentModel is PcModel) { currentModel.updateEquipment(EquipmentLook()) } // Clear equipment effects
            actorModel = createActorModel()
            onReadyToDraw { transitionToIdle(0f) }
        } else if (state.type == ActorType.Pc && currentModel is PcModel) {
            val equipmentLook = PlayerLookTool.getDebugOverride(this) ?: stateLook.equipment
            currentModel.updateEquipment(equipmentLook)
        }
    }

    private fun createActorModel(): ActorModel? {
        return when (displayLook.type) {
            0 -> {
                val additionalAnimationId = FileTableManager.getFilePath(NpcTable.getAdditionalAnimationId(displayLook))
                val npcModel = NpcModel.fromNpcLook(displayLook, listOfNotNull(additionalAnimationId)) ?: return null
                ActorModel(this, npcModel)
            }
            1 -> {
                displayLook.race ?: return null
                ActorModel(this, PcModel(displayLook, this))
            }
            2, 3 -> {
                val datId = getNpcInfo()?.datId ?: return null
                val scene = SceneManager.getCurrentScene()
                ActorModel(this, ZoneObjectModel(datId, scene))
            }
            ModelLook.fileTableIndexType -> {
                val npcModel = NpcModel.fromNpcLook(displayLook) ?: return null
                ActorModel(this, npcModel)
            }
            else -> {
                null
            }
        }
    }

    fun enqueueRoutine(context: ActorContext, options: RoutineOptions = RoutineOptions(), routineProvider: () -> EffectRoutineResource?) {
        routineQueue.enqueueRoutine(context, options, routineProvider)
    }

    fun enqueueModelRoutine(routineId: DatId, actorContext: ActorContext = makeStandardContext(), options: RoutineOptions = RoutineOptions()) {
        routineQueue.enqueueRoutine(actorContext, options) { findAnimationRoutine(routineId) }
    }

    fun enqueueModelRoutineIfReady(routineId: DatId, actorContext: ActorContext = makeStandardContext(), options: RoutineOptions = RoutineOptions()) {
        routineQueue.enqueueRoutine(actorContext, options, findAnimationRoutine(routineId))
    }

    fun hasEnqueuedRoutines(): Boolean {
        return routineQueue.hasEnqueuedRoutines()
    }

    fun playRoutine(routineId: DatId, routineCompleteCallback: RoutineCompleteCallback? = null): EffectRoutineInstance? {
        val routine = findAnimationRoutine(routineId)

        return if (routine == null) {
            routineCompleteCallback?.onComplete()
            null
        } else {
            playRoutine(routine).also { it.onComplete = routineCompleteCallback }
        }
    }

    private fun playRoutine(routine: EffectRoutineResource): EffectRoutineInstance {
        return EffectManager.registerActorRoutine(this, makeStandardContext(), routine)
    }

    fun loopRoutine(routine: DatId): EffectRoutineInstance? {
        val output = playRoutine(routine)
        output?.repeatOnSequencesCompleted = true
        return output
    }

    fun playEmote(mainId: Int, subId: Int) {
        val raceGenderConfig = displayLook.race ?: return
        val fileIndexId = MainDll.getBaseEmoteAnimationIndex(raceGenderConfig) + mainId

        val filePath = FileTableManager.getFilePath(fileIndexId)
        if (filePath == null) {
            warn("Couldn't resolve emote: $mainId/$subId")
            return
        }

        DatLoader.load(filePath).onReady {
            val emoteId = DatId("em0${subId}")
            val routine = it.getAsResource().getNullableChildRecursivelyAs(emoteId, EffectRoutineResource::class)

            if (routine != null) {
                EffectManager.registerActorRoutine(this, makeStandardContext(), routine)
            }
        }
    }

    private fun findAnimationRoutine(routineId: DatId): EffectRoutineResource? {
        return actorModel?.model?.getAnimationDirectories()?.firstNotNullOfOrNull { it.getNullableChildAs(routineId, EffectRoutineResource::class) }
            ?: GlobalDirectory.directoryResource.getNullableChildRecursivelyAs(routineId, EffectRoutineResource::class)
    }

    fun onReadyToDraw(readyToDrawAction: ReadyToDrawAction) {
        readyToDrawActions += readyToDrawAction
    }

    private fun playSoundPrefixed(prefix: String) {
        val randomMatch = getAllAnimationDirectories()
            .flatMap { it.collectByType(SoundPointerResource::class) }
            .filter { it.id.id.startsWith(prefix) }
            .randomOrNull() ?: return

        AudioManager.playSoundEffect(randomMatch, ActorAssociation(this, makeStandardContext()), positionFn = { displayPosition })
    }

    fun getPetId() = state.pet

    fun getMount() = state.mountedState

    private fun startAutoRunParticles() {
        val model = actorModel?.model
        if (model !is NpcModel) { return }
        if (state.owner != null) { return }

        val association = ActorAssociation(this, context = makeStandardContext())
        model.resource.getAsResource().collectByTypeRecursive(EffectResource::class)
            .filter { pg -> pg.particleGenerator.autoRun }
            .forEach { pg -> EffectManager.registerEffect(association, pg) }
    }

    fun isStaticNpc(): Boolean {
        return state.isStaticNpc()
    }

    fun isDoor(): Boolean {
        return state.isDoor()
    }

    fun isElevator(): Boolean {
        return state.isElevator()
    }

    private fun updateRestingDisplay(state: ActorState) {
        if (displayResting && !state.isResting()) {
            displayResting = false
            enqueueModelRoutineIfReady(DatId.stopResting)
        } else if (!displayResting && state.isResting()) {
            displayResting = true
            enqueueModelRoutineIfReady(DatId.startResting)
        }
    }

    private fun updateDoorDisplay(state: ActorState) {
        if (displayDoorOpen && !state.doorState.open) {
            displayDoorOpen = false
            enqueueModelRoutineIfReady(DatId.close)
        } else if (!displayDoorOpen && state.doorState.open) {
            displayDoorOpen = true
            enqueueModelRoutineIfReady(DatId.open)
        }
    }

    private fun updateEngageDisplay(state: ActorState) {
        if (state.isDead()) { return }

        if (state.isEngaged() && engageAnimationState == EngageAnimationState.NotEngaged) {
            onEngage()
        } else if (!state.isEngaged() && engageAnimationState == EngageAnimationState.Engaged) {
            onDisengage()
        }
    }

    private fun onEngage() {
        engageAnimationState = EngageAnimationState.Engaging

        if (state.isEnemy()) {
            transitionToIdle(7.5f)
            playSoundPrefixed("idl")
        }

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w00"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w10"))

        val mainWeaponInfo = actorModel?.model?.getMainWeaponInfo()
        if (mainWeaponInfo != null && getState().isIdleOrEngaged()) {
            routineQueue.enqueueMainBattleRoutine(DatId("in ${mainWeaponInfo.weaponAnimationSubType}"), id) { engage() }
        } else {
            engage()
        }

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w01"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w11"))
    }

    private fun onDisengage() {
        engageAnimationState = EngageAnimationState.Disengaging

        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Main, DatId("!w02"))
        routineQueue.enqueueItemModelRoutine(ItemModelSlot.Sub, DatId("!w12"))

        if (isDead()) {
            disengage()
            return
        }

        if (isPlayer()) { UiStateHelper.popActionContext() }

        val mainWeaponInfo = actorModel?.model?.getMainWeaponInfo()
        val onComplete = { disengage() }

        if (mainWeaponInfo != null) {
            routineQueue.enqueueMainBattleRoutine(DatId("out${mainWeaponInfo.weaponAnimationSubType}"), id, callback = onComplete)
        } else {
            onComplete.invoke()
        }
    }

    fun onDisplayDeath() {
        if (isPlayer()) { UiStateHelper.clear() }
        var deadRoutineId = if (state.owner != null) { DatId("sdep") } else { DatId("dead") }

        val deadRoutine = findAnimationRoutine(deadRoutineId)
        if (deadRoutine == null) { deadRoutineId = DatId("dea${displayAppearanceState}") }

        enqueueModelRoutineIfReady(deadRoutineId, options = RoutineOptions { displayDead = true })
    }

    fun displayAutoAttack(autoAttackResult: AutoAttackResult, targetState: ActorState, totalAttacksInRound: Int) {
        // Assume about ~60 frames per auto-attack, with a little pause between rounds
        val autoAttackInterval = GameEngine.getAutoAttackRecast(getState())
        val framesPerAttack = (autoAttackInterval / (totalAttacksInRound + 1)).coerceAtMost(60f)
        val playbackRate = 60f / framesPerAttack

        val options = RoutineOptions(blocking = false, expiryDuration = 5.seconds, playbackRate = playbackRate)

        routineQueue.enqueueMainBattleRoutine(DatId("atk0"), id, AttackContext(), options)

        when (autoAttackResult.type) {
            AutoAttackType.Main -> onAttackMainHand(autoAttackResult.context, targetState, options)
            AutoAttackType.Sub -> onAttackSubHand(autoAttackResult.context, targetState, options)
            AutoAttackType.H2H -> onAttackH2H(autoAttackResult.context, targetState, options)
            AutoAttackType.Ranged -> onRangedAttack(autoAttackResult.context, targetState)
        }
    }

    private fun onAttackMainHand(attackContext: AttackContext, targetState: ActorState, options: RoutineOptions) {
        routineQueue.enqueueMainBattleRoutine(targetState.id, attackContext, options) {
            when(getMovementDirection()) {
                Direction.None -> actorModel?.getMainAttackIds(displayAppearanceState)?.randomOrNull() ?: DatId("ati0")
                Direction.Forward -> DatId("atf0")
                Direction.Left -> DatId("atl0")
                Direction.Right -> DatId("atr0")
                Direction.Backward -> DatId("atb0")
            }
        }
    }

    private fun onAttackSubHand(attackContext: AttackContext, targetState: ActorState, options: RoutineOptions) {
        routineQueue.enqueueSubBattleRoutine(targetState.id, attackContext, options) {
            when(getMovementDirection()) {
                Direction.None -> actorModel?.getSubAttackIds(displayAppearanceState)?.randomOrNull() ?: DatId("bti0")
                Direction.Forward -> DatId("btf0")
                Direction.Left -> DatId("btl0")
                Direction.Right -> DatId("btr0")
                Direction.Backward -> DatId("btb0")
            }
        }
    }

    private fun onAttackH2H(attackContext: AttackContext, targetState: ActorState, options: RoutineOptions) {
        routineQueue.enqueueMainBattleRoutine(targetState.id, attackContext, options) {
            // Left-punch, Right-kick, Left-kick
            listOf(DatId("bti0"), DatId("cti0"), DatId("dti0")).random()
        }
    }

    private fun onRangedAttack(attackContext: AttackContext, targetState: ActorState) {
        val context = ActorContext(id, targetState.id, attackContexts = AttackContexts.single(targetState.id, attackContext))
        enqueueModelRoutine(DatId("shlg"), context)
    }

    fun getState(): ActorState {
        return state
    }

    private fun updateVelocity(newVelocity: Vector3f) {
        stoppedMoving = currentVelocity.magnitudeSquare() > 0f && newVelocity.magnitudeSquare() == 0f
        currentVelocity.copyFrom(newVelocity)
    }

    fun isTargetLocked(): Boolean {
        return state.targetState.locked
    }

    fun isDisplayInvisible(): Boolean {
        return renderState.effectColor.a == 0
    }

    fun isMovementOrAnimationLocked(): Boolean {
        return actorModel?.isMovementLocked() == true || actorModel?.isAnimationLocked() == true
    }

    fun makeStandardContext(): ActorContext {
        val effectContext = AttackContext(appearanceState = displayAppearanceState)
        return ActorContext(id, attackContexts = AttackContexts.single(id, effectContext))
    }

    private fun updateAppearanceState(actorState: ActorState) {
        val appearanceState = actorState.appearanceState
        if (displayAppearanceState == appearanceState) { return }

        val context = AttackContext(
            appearanceState = appearanceState,
            appearanceCurrentDisplayState = displayAppearanceState,
        )

        val routineId = getInitRoutine(actorState)

        displayAppearanceState = appearanceState
        enqueueModelRoutine(routineId, ActorContext(id, attackContexts = AttackContexts.single(id, context)))
    }

    private fun getInitRoutine(actorState: ActorState): DatId {
        return when (actorState.appearanceState) {
            0 -> DatId("init")
            else -> DatId("ini${actorState.appearanceState}")
        }
    }

    private fun initDisplay(actorState: ActorState) {
        if (actorState.monsterId != null) {
            initMonsterDisplay(actorState, actorState.monsterId)
        } else if (actorState.isStaticNpc()) {
            initNpcDisplay(actorState)
        }
    }

    private fun initMonsterDisplay(actorState: ActorState, monsterId: MonsterId) {
        val monsterDefinition = MonsterDefinitions[monsterId]
        renderState.effectColor = ByteColor.zero

        onReadyToDraw {
            enqueueModelRoutineIfReady(getInitRoutine(actorState))
            enqueueModelRoutine(DatId.pop)
            actorModel?.customModelSettings = monsterDefinition.customModelSettings
        }
    }

    private fun initNpcDisplay(actorState: ActorState) {
        val npcInfo = actorState.getNpcInfo() ?: return
        val npcLook = npcInfo.look

        if (npcLook.type == 0) {
            onReadyToDraw {
                transitionToIdle(0f)
                val spawnAnimations = npcInfo.spawnAnimations ?: listOf(DatId("aper"), DatId("efon"), DatId.pop)
                spawnAnimations.forEach { anim -> it.playRoutine(anim) }
                loopRoutine(DatId("@scd"))
            }
        } else if (npcLook.type == 1) {
            onReadyToDraw { playRoutine(DatId.pop) }
        } else if (npcLook.type == 2) {
            onReadyToDraw {
                playRoutine(DatId.closed)
                playRoutine(DatId("inte"))
            }
        } else if (npcLook.type == 3) {
            // No-op
        } else {
            warn("[${actorState.name}] Unknown NPC type: ${npcLook.type}")
        }
    }

    private fun getAnimationModeVariant(defaultId: DatId, mode: Int?, nameFn: (Int) -> DatId): DatId {
        return if (mode == null || mode == 0) { defaultId } else { nameFn.invoke(mode) }
    }

}
