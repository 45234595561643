package xim.poc.game.configuration.v0

import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import xim.poc.browser.LocalStorage
import xim.poc.game.configuration.MonsterId
import xim.util.Periodically
import kotlin.time.Duration.Companion.seconds

@Serializable
data class GameV0SaveState(
    var defeatedMonsterCounter: MutableMap<Int, Int> = HashMap(),
    var highestClearedFloor: Int = 0,
)

object GameV0SaveStateHelper {

    private const val configurationKey = "GameV0"

    private val saveState: GameV0SaveState by lazy { loadState() }
    private val saveRateLimiter = Periodically(5.seconds)
    private val json = Json { ignoreUnknownKeys = true }

    fun getState(): GameV0SaveState {
        return saveState
    }

    fun autoSave() {
        if (saveRateLimiter.ready()) { writeState() }
    }

    fun forceSave() {
        writeState()
    }

    private fun writeState() {
        LocalStorage.writeCustomConfiguration(configurationKey, json.encodeToString(saveState))
    }

    private fun loadState(): GameV0SaveState {
        val savedState = LocalStorage.readCustomConfiguration(configurationKey)
        return if (savedState == null) { GameV0SaveState() } else { json.decodeFromString(savedState) }
    }

}