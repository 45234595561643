package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.AttackContext
import xim.poc.game.CombatBonusAggregate
import xim.poc.game.GameEngine
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.ActorDamagedContext
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.configuration.Skill
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.Event
import xim.util.Fps
import kotlin.math.roundToInt

class MobUragniteController(val actorState: ActorState): ActorBehaviorController {

    private val delegate = BasicMonsterController(actorState)

    private val stateTime = Fps.secondsToFrames(20)
    private var hideTime = 0f
    private var showTime = stateTime

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actorState.isEngaged()) {
            startShow()
            return emptyList()
        }

        if (!actorState.isIdleOrEngaged()) {
            return emptyList()
        }

        return if (hideTime > 0f) {
            hideTime -= elapsedFrames
            emptyList()
        } else if (hideTime <= 0f && actorState.appearanceState == 1) {
            startShow()
            return emptyList()
        } else if (showTime <= 0f && actorState.appearanceState == 0) {
            startHide()
            return emptyList()
        } else {
            showTime -= elapsedFrames
            delegate.update(elapsedFrames)
        }
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        if (actorState.appearanceState == 0) { return }

        aggregate.regen = (actorState.getMaxHp() * 0.05f).roundToInt()
        aggregate.physicalDamageTaken -= 50
        aggregate.magicalDamageTaken -= 50
        aggregate.movementSpeed -= 100
    }

    override fun onDamaged(context: ActorDamagedContext): List<Event> {
        if (actorState.appearanceState != 1) { return emptyList() }

        if (!GameEngine.canBeginUseMobSkill(actorState.id, mobSkillId = 1315)) {
            return emptyList()
        }

        if (hideTime > Fps.secondsToFrames(15f)) {
            return emptyList()
        }

        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = 1315))
    }

    private fun startHide() {
        actorState.appearanceState = 1
        hideTime = stateTime
        showTime = 0f
    }

    private fun startShow() {
        actorState.appearanceState = 0
        hideTime = 0f
        showTime = stateTime
    }

}