package xim.poc.game

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.KeyboardActorController
import xim.poc.audio.BackgroundMusicResponse
import xim.poc.browser.LocalStorage
import xim.poc.game.configuration.*
import xim.poc.game.configuration.Skill
import xim.poc.game.event.*
import xim.poc.tools.ZoneNpcTool
import xim.resource.*
import xim.resource.table.AbilityInfoTable
import xim.resource.table.MusicSettings
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import xim.util.Stack
import kotlin.math.min
import kotlin.random.Random

object GameEngine {

    private val events = Stack<Event>()
    private var setup = false

    fun setup() {
        if (setup) { return }
        setup = true

        createPlayer()
        events += GameState.getGameMode().setup()
        applyEvents()
    }

    fun tick(elapsedFrames: Float) {
        val allActors = ActorStateManager.getAll()
            .filter { !ZoneNpcTool.isForceHidden(it.key) }

        events += allActors.map { TickActorEvent(elapsedFrames, it.key) }
        applyEvents()

        EventScriptRunner.update(elapsedFrames)
        GameState.update(elapsedFrames)
    }

    fun submitEvent(event: Event) {
        events += event
    }

    fun submitEvents(event: List<Event>) {
        events += event
    }

    private fun applyEvents() {
        while (!events.isEmpty()) {
            val event = events.pop()
            events += event.apply()
        }
    }

    fun submitCreateActorState(initialActorState: InitialActorState): ActorPromise {
        val promise = ActorPromise()
        events += ActorCreateEvent(initialActorState, promise)
        return promise
    }

    fun submitDeleteActor(it: ActorId?) {
        it ?: return
        events += ActorDeleteEvent(it)
    }

    fun canBeginAction(actorState: ActorState): Boolean {
        return !actorState.hasStatusActionLock()
    }

    fun canBeginSkill(actor: ActorState, skill: Skill): Boolean {
        return when (skill.skillType) {
            SkillType.Spell -> canBeginCastSpell(actor.id, skill.id)
            SkillType.MobSkill -> canBeginUseMobSkill(actor.id, skill.id)
            SkillType.Ability -> canBeginUseAbility(actor.id, AbilityInfoTable[skill.id])
            SkillType.Item -> canBeginAction(actor)
        }
    }

    fun canBeginCastSpell(actorId: ActorId, spellId: Int): Boolean {
        return canBeginCastSpell(actorId, SpellInfoTable[spellId])
    }

    fun canBeginCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return false }
        return canCastSpell(actorId, spellInfo)
    }

    fun canCastSpell(actorId: ActorId, spellInfo: SpellInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        if (checkSilenceProc(actorState)) { return false }

        val recastDelay = actorState.getRecastDelay(spellInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        val cost = getSkillBaseCost(SkillType.Spell, spellInfo.index)
        if (!actorState.canPayAbilityCost(cost)) { return false }

        if (spellInfo.magicType == MagicType.Trust) {
            val actorParty = PartyManager[actorId]
            if (actorParty.size() >= 6) { return false }
        }

        return true
    }

    fun canBeginUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return false }
        return canUseAbility(actorId, abilityInfo)
    }

    fun canUseAbility(actorId: ActorId, abilityInfo: AbilityInfo): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val recastDelay = actorState.getRecastDelay(abilityInfo)
        if (recastDelay != null && !recastDelay.isComplete()) { return false }

        val cost = getSkillBaseCost(SkillType.Ability, abilityInfo.index)
        if (!actorState.canPayAbilityCost(cost)) { return false }

        return true
    }

    fun canBeginUseMobSkill(actorId: ActorId, mobSkillId: Int): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return false }
        return canUseMobSkill(actorId, mobSkillId)
    }

    fun canUseMobSkill(actorId: ActorId, mobSkillId: Int): Boolean {
        val actorState = ActorStateManager[actorId] ?: return false

        val cost = getSkillBaseCost(SkillType.MobSkill, mobSkillId)
        return actorState.canPayAbilityCost(cost)
    }

    fun canBeginRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)
        if (!actorState.isIdleOrEngaged() || !canBeginAction(actorState)) { return Pair(false, "You must wait longer to perform that action.") }
        return canUseRangedAttack(actorId)
    }

    fun canUseRangedAttack(actorId: ActorId): Pair<Boolean, String?> {
        val actorState = ActorStateManager[actorId] ?: return Pair(false, null)

        if (actorState.isCasting()) { return Pair(false, "You must wait longer to perform that action.") }

        return Pair(true, null)
    }

    fun getRangedAttackTargetFilter(): ActionTargetFilter {
        return ActionTargetFilter(TargetFlag.Enemy.flag)
    }

    fun getMaximumCraftable(actor: ActorId, recipe: SynthesisRecipe): Int {
        val actorState = ActorStateManager[actor] ?: return 0
        val inventory = actorState.inventory

        var max = 99

        for (input in recipe.input.distinct()) {
            val recipeCount = recipe.input.count { it == input }

            val inventoryCount = inventory.inventoryItems
                .filter { it.id == input }
                .filter { !actorState.isEquipped(it) }
                .sumOf { it.quantity }

            max = min(max, inventoryCount / recipeCount)
        }

        return max
    }

    private fun releaseTrusts(actor: ActorState): List<Event> {
        return getTrusts(actor.id).map { TrustReleaseEvent(actor.id, it.id) }
    }

    fun getTrusts(actorId: ActorId): List<ActorState> {
        return PartyManager[actorId].getAllState().filter { it.owner == actorId }
    }

    fun releaseDependents(actor: ActorState): List<Event> {
        val releaseEvents = ArrayList<Event>()
        releaseEvents += releaseTrusts(actor)
        releaseEvents += BubbleReleaseEvent(actor.id)
        releaseEvents += PetReleaseEvent(actor.id)
        return releaseEvents
    }

    fun getSpellList(actorId: ActorId): List<SpellInfo> {
        ActorStateManager[actorId] ?: return emptyList()
        return GameState.getGameMode().getActorSpellList(actorId)
    }

    fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        return GameState.getGameMode().getActorAbilityList(actorId, abilityType)
    }

    fun canDualWield(actorState: ActorState): Boolean {
        return GameState.getGameMode().canDualWield(actorState)
    }

    fun getRangeInfo(actor: ActorState, skillInfo: SkillInfo): SkillRangeInfo {
        return getRangeInfo(actor, skillInfo.type, skillInfo.id)
    }

    fun getRangeInfo(actor: ActorState, skillType: SkillType, skillId: Int): SkillRangeInfo {
        return GameState.getGameMode().getSkillRangeInfo(actor, skillType, skillId)
    }

    fun getRangedAttackRangeInfo(actor: ActorState): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 24f, effectRadius = 0f, type = AoeType.None)
    }

    fun getAbilityBaseCost(abilityId: Int): AbilityCost {
        return getSkillBaseCost(SkillType.Ability, abilityId)
    }

    fun getSkillBaseCost(skillType: SkillType, skillId: Int): AbilityCost {
        return GameState.getGameMode().getSkillBaseCost(skillType, skillId)
    }

    fun getSkillUsedCost(actor: ActorState, skillType: SkillType, skillId: Int): AbilityCost {
        return GameState.getGameMode().getSkillUsedCost(actor, skillType, skillId)
    }

    fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        return GameState.getGameMode().getActorEffectTickResult(actorState)
    }

    fun getMagicBurstBonus(source: ActorState, target: ActorState, spellId: Int): Float? {
        val spellInfo = SpellInfoTable[spellId]
        return target.skillChainTargetState.getMagicBurstBonus(spellInfo)
    }

    fun getRangedAttackTime(source: ActorState): Float {
        return GameState.getGameMode().getRangedAttackInterval(source)
    }

    fun getAutoAttackRecast(source: ActorState): Float {
        return GameState.getGameMode().getAutoAttackInterval(source)
    }

    fun hasAnyEnmity(actorId: ActorId): Boolean {
        return ActorStateManager.filter { !it.isDead() && it.isEnemy() && it.targetState.targetId == actorId }.isNotEmpty()
    }

    fun computeCombatStats(actorId: ActorId): CombatStats {
        return GameState.getGameMode().getActorCombatStats(actorId)
    }

    private fun createPlayer() {
        val config = LocalStorage.getPlayerConfiguration()
        val modelLook = config.playerLook.copy()

        val zoneSettings = ZoneSettings(zoneId = config.playerPosition.zoneId, subAreaId = config.playerPosition.subAreaId)

        submitCreateActorState(InitialActorState(
            name = "Player",
            type = ActorType.Pc,
            position = Vector3f(config.playerPosition.position),
            rotation = config.playerPosition.rotation,
            zoneSettings = zoneSettings,
            modelLook = modelLook,
            equipment = Equipment().copyFrom(config.playerEquipment),
            spells = config.playerSpells,
            movementController = KeyboardActorController(),
            presetId = ActorStateManager.playerId,
            jobSettings = JobSettings(config.playerJob.mainJob, config.playerJob.subJob),
            jobLevels = config.playerLevels,
            inventory = config.playerInventory,
        ))
    }

    fun getCombatBonusAggregate(actor: ActorState): CombatBonusAggregate {
        return GameState.getGameMode().getActorCombatBonuses(actor)
    }

    fun getMaxTp(actor: ActorState): Int {
        return GameState.getGameMode().getMaxTp(actor)
    }

    fun rollParry(attacker: ActorState, defender: ActorState): Boolean {
        return GameState.getGameMode().rollParry(attacker, defender)
    }

    fun rollGuard(attacker: ActorState, defender: ActorState): Boolean {
        return GameState.getGameMode().rollGuard(attacker, defender)
    }

    fun rollCastingInterrupt(attacker: ActorState, defender: ActorState): Boolean {
        return GameState.getGameMode().rollSpellInterrupted(attacker, defender)
    }

    fun rollStatusEffect(targetState: ActorState, attackStatusEffect: AttackStatusEffect): Boolean {
        val bonuses = getCombatBonusAggregate(targetState)
        val resistance = bonuses.statusResistances.getOrElse(attackStatusEffect.statusEffect) { 0 }

        println("${attackStatusEffect.statusEffect} -> ${attackStatusEffect.baseChance} * ${resistance.toPenaltyMultiplier()}")

        return (attackStatusEffect.baseChance * resistance.toPenaltyMultiplier() ) >= Random.nextDouble()
    }

    fun selectBgm(musicSettings: MusicSettings): BackgroundMusicResponse {
        val actor = ActorManager.player()

        return if (actor.isDisplayedDead()) {
            BackgroundMusicResponse(musicId = 111, resume = false)
        } else if (actor.isDisplayEngagedOrEngaging() && GameEngine.hasAnyEnmity(actor.id)) {
            val partySize = PartyManager[actor].getAll().size
            val musicId = if (partySize > 1) { musicSettings.battlePartyMusicId } else { musicSettings.battleSoloMusicId }
            BackgroundMusicResponse(musicId)
        } else if (actor.getMount() != null) {
            val mount = actor.getMount()!!
            val musicId = if (mount.index == 0) { 212 } else { 84 }
            BackgroundMusicResponse(musicId)
        } else {
            BackgroundMusicResponse(musicSettings.musicId)
        }
    }

    fun checkParalyzeProc(actorState: ActorState): Boolean {
        val effect = actorState.getStatusEffects().firstOrNull { it.statusEffect == StatusEffect.Paralysis } ?: return false
        return Random.nextDouble(0.0, 100.0) < effect.potency
    }

    fun checkBlindProc(actorState: ActorState): Boolean {
        val effect = actorState.getStatusEffects().firstOrNull { it.statusEffect == StatusEffect.Blind } ?: return false
        return Random.nextDouble(0.0, 100.0) < effect.potency
    }

    fun checkSilenceProc(actorState: ActorState): Boolean {
        return actorState.getStatusEffects().any { it.statusEffect == StatusEffect.Silence }
    }

}