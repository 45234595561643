package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.CombatStat
import xim.poc.game.GameEngine
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.V0AbilityDefinitions
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.singleStatus
import xim.poc.game.configuration.v0.WeaponSkillDamageCalculator
import xim.poc.game.event.*
import xim.resource.table.MobSkillInfoTable
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

object MobClusterBehavior {

    fun slingBomb(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val bombs = getBombsRemaining(it.sourceState)

            val wsResult = WeaponSkillDamageCalculator.physicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
            ) { 1f + 0.5f * bombs }

            V0AbilityDefinitions.weaponSkillToEvents(it, wsResult, AttackDamageType.Physical)
        }
    }

    fun formationAttack(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.physicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                numHits = getBombsRemaining(it.sourceState),
                ftpSpread = true,
            ) { 0.8f }

            V0AbilityDefinitions.weaponSkillToEvents(it, wsResult, AttackDamageType.Physical)
        }
    }

    fun circleOfFlames(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val bombs = getBombsRemaining(it.sourceState)

            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.int,
                defendStat = CombatStat.int
            ) { 0.5f * bombs }

            val attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Weight, baseDuration = 15.seconds) {
                    se -> se.statusState.potency = 20
                }
            )

            V0AbilityDefinitions.weaponSkillToEvents(
                context = it,
                wsResult = wsResult,
                damageType = AttackDamageType.Magical,
                attackEffects = attackEffects,
            )
        }
    }


    fun selfDestructSingle(id: Int, destState: Int, hppUsed: Float): SkillApplier {
        return SkillApplier(skillId = id, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = {
                it.sourceState.appearanceState = destState
                val dmg = listOf((it.sourceState.getHp() * hppUsed).roundToInt())
                listOf(ActorAttackedEvent(sourceId = it.sourceState.id, targetId = it.sourceState.id, damageAmount = dmg, damageType = AttackDamageType.Static, skill = it.skill, actionContext = it.context))
            },
            targetEvaluator = {
                val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                    skillInfo = it.skillInfo,
                    attacker = it.sourceState,
                    defender = it.targetState,
                    attackStat = CombatStat.int,
                    defendStat = CombatStat.int
                ) { 2f }

                V0AbilityDefinitions.weaponSkillToEvents(it, wsResult, AttackDamageType.Magical)
            }
        )
    }

    fun selfDestructFull(id: Int): SkillApplier {
        return SkillApplier(skillId = id, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = {
                val damage = listOf(it.sourceState.getHp())
                listOf(ActorAttackedEvent(sourceId = it.sourceState.id, targetId = it.sourceState.id, damageAmount = damage, damageType = AttackDamageType.Static, skill = it.skill, actionContext = it.context))
            },
            targetEvaluator = {
                val damage = listOf(it.sourceState.getHp())
                listOf(ActorAttackedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, damageAmount = damage, damageType = AttackDamageType.Magical, skill = it.skill, actionContext = it.context))
            }
        )
    }

    private fun getBombsRemaining(actorState: ActorState): Int {
        return when(actorState.appearanceState) {
            0 -> 3
            1 -> 2
            2 -> 1
            else -> 0
        }
    }

}

class MobClusterController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        if (actorState.isIdle()) {
            actorState.appearanceState = 0
        }

        if (eligibleToUseSkill()) {
            val results = useSkill()
            if (results.isNotEmpty()) { return results }
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    private fun eligibleToUseSkill(): Boolean {
        return actorState.isIdleOrEngaged() && actorState.getTpp() > 0.3f
    }

    private fun getValidSkills(): List<Int> {
        if (actorState.appearanceState == 0) {
            if (actorState.getHpp() < 0.66f) { return listOf(315) }
            if (actorState.getHpp() < 0.20f) { return listOf(316) }
        }

        if (actorState.appearanceState == 1) {
            if (actorState.getHpp() < 0.35f) { return listOf(317) }
            if (actorState.getHpp() < 0.20f) { return listOf(318) }
        }

        if (actorState.appearanceState == 2) {
            return listOf(319)
        }

        if (!actorState.hasStatusEffect(StatusEffect.Haste)) {
            return listOf(313)
        }

        if (actorState.appearanceState < 2) {
            return listOf(311, 312, 314)
        }

        return emptyList()
    }

    private fun useSkill(): List<Event> {
        val randomSkillId = getValidSkills()
            .filter { GameEngine.canBeginUseMobSkill(actorState.id, it) }
            .randomOrNull() ?: return emptyList()

        val mobSkillInfo = MobSkillInfoTable[randomSkillId] ?: return emptyList()

        val targetFilter = mobSkillInfo.targetFilter
        val targetId = (if (targetFilter.targetFilter(actorState.id, actorState.id)) {
            actorState.id
        } else if (targetFilter.targetFilter(actorState.id, actorState.targetState.targetId)) {
            actorState.targetState.targetId
        } else {
            null
        }) ?: return emptyList()

        return listOf(CastMobSkillStart(actorState.id, targetId, randomSkillId))
    }

}
