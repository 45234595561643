package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.AttackContext
import xim.poc.game.SynthesisState
import xim.poc.game.configuration.ActorDamagedContext
import xim.poc.game.configuration.Skill
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis

class ActorDamagedEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val amount: Int,
    val type: ActorResourceType = ActorResourceType.HP,
    val emitDamageText: Boolean = true,
    val actionContext: AttackContext? = null,
    val skill: Skill? = null,
    val damageType: AttackDamageType,
    val additionalEffect: Boolean = false,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        when (type) {
            ActorResourceType.HP -> targetState.consumeHp(amount)
            ActorResourceType.MP -> targetState.consumeMp(amount)
            ActorResourceType.TP -> targetState.consumeTp(amount)
        }

        val defeatedTarget = targetState.isDead()

        val outEvents = ArrayList<Event>()
        outEvents += RestingEndEvent(targetId)

        if (targetState.getSynthesisState()?.canInterrupt() == true) {
            outEvents += SynthesisCompleteEvent(targetId, SynthesisState.SynthesisResult.Break)
        }

        if (defeatedTarget) {
            outEvents += ActorDefeatedEvent(defeated = targetId, defeatedBy = sourceId, actionContext = actionContext)
        } else {
            val damageContext = ActorDamagedContext(
                damageAmount = amount,
                attacker = sourceState,
                skill = skill,
                damageType = damageType,
                actionContext = actionContext,
            )
            outEvents += targetState.behaviorController.onDamaged(damageContext)
        }

        val displayType = when (type) {
            ActorResourceType.HP -> ""
            ActorResourceType.MP -> "MP "
            ActorResourceType.TP -> "TP "
        }

        if (!emitDamageText || actionContext == null) { return outEvents }

        if (additionalEffect) {
            AttackContext.compose(actionContext) { ChatLog("Additional Effect: $amount ${displayType}damage.") }
        } else {
            AttackContext.compose(actionContext) {
                var line = "${sourceState.name}${ShiftJis.rightArrow}${targetState.name} $amount ${displayType}damage"
                line += if (actionContext.criticalHit()) { "!" } else { "." }
                if (actionContext.magicBurst) { line = "[Magic Burst!] $line"}
                if (actionContext.guarded()) { line = "[Guard!] $line" }
                ChatLog(line)
            }
        }

        return outEvents
    }

}