package xim.poc.game.configuration

import xim.poc.SynthesisType
import xim.poc.game.ActorStateManager
import xim.poc.game.GameState

typealias RecipeId = Int

data class SynthesisRecipe(val id: RecipeId, val synthesisType: SynthesisType, val output: Int, val input: List<Int>)

object SynthesisRecipes {

    private val allRecipes = HashMap<RecipeId, SynthesisRecipe>()

    fun getAvailableRecipes(filter: SynthesisType): List<SynthesisRecipe> {
        return GameState.getGameMode().getKnownSynthesisRecipes(ActorStateManager.player(), filter)
    }

    operator fun get(recipeId: RecipeId): SynthesisRecipe? {
        return allRecipes[recipeId]
    }

    fun register(recipe: SynthesisRecipe) {
        val recipeId = recipe.id
        if (allRecipes.containsKey(recipeId)) { throw IllegalStateException("Recipe $recipeId is already defined by ${allRecipes[recipeId]}") }
        allRecipes[recipeId] = recipe
    }

}