package xim.poc.game.event

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.ModelLook
import xim.poc.TrustController
import xim.poc.game.ActorPromise
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.PartyManager
import xim.poc.gl.ByteColor
import xim.resource.DatId
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import xim.resource.table.TrustTable

class TrustSummonEvent(
    val sourceId: ActorId,
    val spellId: Int,
    val behaviorId: Int = spellId,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()

        val party = PartyManager[actorState.id]
        if (party.size() >= 6) { return emptyList() }

        val spellInfo = SpellInfoTable[spellId]
        val name = SpellNameTable[spellInfo.index].first()

        val position = Vector3f().copyFrom(actorState.position)
        position += Matrix4f().rotateYInPlace(actorState.rotation).transformInPlace(Vector3f(2f, 0f, 0f))

        val lookId = TrustTable.getModelId(spellInfo)
        val npcLook = ModelLook.npc(lookId)

        val initialActorState = InitialActorState(
            name = name,
            type = ActorType.Npc,
            position = position,
            modelLook = npcLook,
            movementController = TrustController(),
            behaviorController = behaviorId,
            trustSettings = TrustSettings(trustId = spellId, ownerId = actorState.id),
        )

        val promise = ActorPromise().onReady {
            val actorDisplay = ActorManager.getOrCreate(it)
            actorDisplay.renderState.effectColor = ByteColor.zero
            actorDisplay.onReadyToDraw {
                actorDisplay.transitionToIdle(0f)
                actorDisplay.playRoutine(DatId("spop"))
            }
        }

        return listOf(ActorCreateEvent(initialActorState, promise))
    }

}