package xim.poc.game.configuration.v0

import xim.poc.game.*
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluatorContext
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillRangeInfo
import xim.poc.game.configuration.SkillType
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.basicDebuff
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.basicMagicalDamage
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.basicPhysicalDamage
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.intPotency
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.singleStatus
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.spellDamageDoT
import xim.poc.game.event.*
import xim.poc.ui.ChatLog
import xim.resource.AbilityCost
import xim.resource.AbilityCostType
import xim.resource.AoeType
import xim.resource.table.SpellInfoTable
import xim.util.Fps
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class StatusEffectContext(val status: StatusEffectState, val context: TargetEvaluatorContext)

object V0SpellDefinitions {

    private val spellMetadata = HashMap<Int, V0Skill>()

    fun register() {
        // Cure
        SkillAppliers += SkillApplier(skillId = 1, skillType = SkillType.Spell, targetEvaluator = basicHealingMagic(potency = 0.6f, maxAmount = 30))

        // Dia
        SkillAppliers += SkillApplier(skillId = 23, skillType = SkillType.Spell, targetEvaluator = applyDia(StatusEffect.Dia, duration = 30.seconds))

        // Banish
        SkillAppliers += SkillApplier(skillId = 28, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.mnd, powerOverride = 10f))

        // Fire
        SkillAppliers += SkillApplier(skillId = 144, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 12f))

        // Blizzard
        SkillAppliers += SkillApplier(skillId = 149, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 13f))

        // Stone
        SkillAppliers += SkillApplier(skillId = 159, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 8f))

        // Stone II
        SkillAppliers += SkillApplier(skillId = 160, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 16f))

        // Ice Spikes
        SkillAppliers += SkillApplier(skillId = 250, skillType = SkillType.Spell,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.IceSpikes, duration = 2.minutes) {
                it.status.potency = intPotency(it.context.sourceState, 0.1f)
            }
        )

        // Venom Shell
        spellMetadata[513] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)))
        SkillAppliers += SkillApplier(skillId = 513, skillType = SkillType.Spell, targetEvaluator = basicDebuff(
                attackEffects = singleStatus(spellDamageDoT(StatusEffect.Poison, potency = 1f, duration = 18.seconds))
            ),
        )

        // Metallic Body
        spellMetadata[517] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 8)))
        SkillAppliers += SkillApplier(skillId = 517, skillType = SkillType.Spell, targetEvaluator = applyBasicBuff(StatusEffect.Stoneskin, 5.minutes) {
            it.status.counter = (it.context.sourceState.getMaxHp() / 3).coerceAtMost(100)
        })

        // Screwdriver
        spellMetadata[519] = V0Skill(
            cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)),
            skillChainAttributes = listOf(SkillChainAttribute.Transfixion, SkillChainAttribute.Scission),
        )
        SkillAppliers += SkillApplier(skillId = 519, skillType = SkillType.Spell, targetEvaluator = basicPhysicalDamage(ftpSpread = false) { 2f })

        // MP Drainkiss
        spellMetadata[521] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 0)))
        SkillAppliers += SkillApplier(skillId = 521, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.str,
            defendStat = CombatStat.vit,
            potency = 1f,
            attackEffects = AttackEffects(absorption = 1f, damageResource = ActorResourceType.MP)
        ))

        // Refueling
        spellMetadata[530] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)))
        SkillAppliers += SkillApplier(skillId = 530, skillType = SkillType.Spell, targetEvaluator = applyBasicBuff(StatusEffect.Haste, 5.minutes) {
            it.status.potency = 10
        })

        // Cold Wave
        spellMetadata[535] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)))
        SkillAppliers += SkillApplier(skillId = 535, skillType = SkillType.Spell, targetEvaluator = basicDebuff(
            attackEffects = singleStatus(spellDamageDoT(StatusEffect.Frost, potency = 0.4f, secondaryPotency = 0.9f , duration = 60.seconds)
        )))

        // Digest
        spellMetadata[542] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 8)))
        SkillAppliers += SkillApplier(skillId = 542, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.int, potency = 1.25f, attackEffects = AttackEffects(absorption = 1f)
        ))

        // Cursed Sphere
        spellMetadata[544] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 8)))
        SkillAppliers += SkillApplier(skillId = 544, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, potency = 3f))

        // Pollen
        SkillAppliers += SkillApplier(skillId = 549, skillType = SkillType.Spell, targetEvaluator = basicHealingMagic(potency = 0.3f, maxAmount = 36))

        // Jet Stream
        spellMetadata[569] = V0Skill(
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 12)),
            skillChainAttributes = listOf(SkillChainAttribute.Impaction),
        )
        SkillAppliers += SkillApplier(skillId = 569, skillType = SkillType.Spell,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { 2f/3f },
        )

        // Foot Kick
        spellMetadata[577] = V0Skill(skillChainAttributes = listOf(SkillChainAttribute.Detonation))
        SkillAppliers += SkillApplier(skillId = 577, skillType = SkillType.Spell,
            targetEvaluator = basicPhysicalDamage(numHits = 1, ftpSpread = false) { 1.25f },
        )

        // Wild Carrot
        spellMetadata[578] = V0Skill(cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 10)))
        SkillAppliers += SkillApplier(skillId = 578, skillType = SkillType.Spell, targetEvaluator = basicHealingMagic(potency = 0.3f, maxPercentage = 0.5f, maxAmount = 100))

        // Sheep Song
        SkillAppliers += SkillApplier(skillId = 584, skillType = SkillType.Spell, targetEvaluator = basicDebuff(
            attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Sleep, baseDuration = 30.seconds)
        ))

        // Claw Cyclone
        spellMetadata[587] = V0Skill(
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 12)),
            skillChainAttributes = listOf(SkillChainAttribute.Scission),
            rangeInfo = SkillRangeInfo(maxTargetDistance = 8f, effectRadius = 8f, AoeType.Cone),
        )
        SkillAppliers += SkillApplier(skillId = 587, skillType = SkillType.Spell,
            targetEvaluator = basicPhysicalDamage(numHits = 2, ftpSpread = true) { 1f },
        )

        // Lowing
        spellMetadata[588] = V0Skill(cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 12)),)
        SkillAppliers += SkillApplier(skillId = 588, skillType = SkillType.Spell, targetEvaluator = basicDebuff(attackStatusEffect =
            AttackStatusEffect(StatusEffect.Plague, baseDuration = 1.minutes) { it.statusState.potency = 3 }
        ))

        // Pinecone Bomb
        spellMetadata[596] = V0Skill(
            skillChainAttributes = listOf(SkillChainAttribute.Liquefaction),
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 12)),
        )
        SkillAppliers += SkillApplier(skillId = 596, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.agi,
            defendStat = CombatStat.vit,
            potency = 1.25f,
            attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Sleep, baseDuration = 18.seconds)
            )
        ))

        // Geist Wall
        spellMetadata[605] = V0Skill(cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 8)),)
        SkillAppliers += SkillApplier(skillId = 605, skillType = SkillType.Spell, targetEvaluator = basicDebuff(dispelCount = 1))

        // Frost Breath
        spellMetadata[608] = V0Skill(
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 16)),
            rangeInfo = SkillRangeInfo(maxTargetDistance = 8f, effectRadius = 8f, AoeType.Cone),
        )
        SkillAppliers += SkillApplier(skillId = 608, skillType = SkillType.Spell,
            targetEvaluator = basicMagicalDamage(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(StatusEffect.Paralysis, baseDuration = 60.seconds) { it.statusState.potency = 10 }
            )) { 4f },
        )

        // Head Butt
        spellMetadata[623] = V0Skill(skillChainAttributes = listOf(SkillChainAttribute.Impaction))
        SkillAppliers += SkillApplier(skillId = 623, skillType = SkillType.Spell, targetEvaluator = applyHeadButt())

        // Blazing Bound
        spellMetadata[657] = V0Skill(V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 24)))
        SkillAppliers += SkillApplier(skillId = 657, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, potency = 3.5f))

        // Leaf Storm
        spellMetadata[663] = V0Skill(V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 24)))
        SkillAppliers += SkillApplier(skillId = 663, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, potency = 4f))

        // Blastbomb
        spellMetadata[618] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)))
        SkillAppliers += SkillApplier(skillId = 618, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.int, defendStat = CombatStat.int, potency = 3.5f, attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(StatusEffect.Bind, baseDuration = 9.seconds)
            )
        ))

        // Corrosive Ooze
        spellMetadata[651] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 16)))
        SkillAppliers += SkillApplier(skillId = 651, skillType = SkillType.Spell,
            targetEvaluator = applyBasicOffenseMagic(attackStat = CombatStat.int, potency = 4f, attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(StatusEffect.AttackDown, baseDuration = 60.seconds) { it.statusState.potency = 10 },
                AttackStatusEffect(StatusEffect.DefenseDown, baseDuration = 60.seconds) { it.statusState.potency = 10 },
            )))
        )

        // Regeneration
        spellMetadata[664] = V0Skill(cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 10)))
        SkillAppliers += SkillApplier(skillId = 664, skillType = SkillType.Spell, targetEvaluator = applyBasicBuff(StatusEffect.Regen, 1.5.minutes) {
            it.status.potency = (it.context.sourceState.getMaxHp() / 30f).roundToInt()
        })

        // Goblin Rush
        spellMetadata[666] = V0Skill(
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 15)),
            skillChainAttributes = listOf(SkillChainAttribute.Fusion, SkillChainAttribute.Impaction),
        )
        SkillAppliers += SkillApplier(skillId = 666, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.str,
            defendStat = CombatStat.vit,
            numHits = 3,
            potency = 1.5f,
        ))

        // Occultation
        spellMetadata[679] = V0Skill(V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 32)))
        SkillAppliers += SkillApplier(skillId = 679, skillType = SkillType.Spell, targetEvaluator = applyBasicBuff(StatusEffect.Blink) {
            it.status.remainingDuration = Fps.secondsToFrames(300)
            it.status.counter = 12
        })

        // Tempestuous Upheaval
        spellMetadata[701] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 15)))
        SkillAppliers += SkillApplier(skillId = 701, skillType = SkillType.Spell,
            targetEvaluator = applyBasicOffenseMagic(attackStat = CombatStat.int, potency = 6f)
        )

        // Rending Deluge
        spellMetadata[702] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 20)))
        SkillAppliers += SkillApplier(skillId = 702, skillType = SkillType.Spell,
            targetEvaluator = applyBasicOffenseMagic(attackStat = CombatStat.int, potency = 6f, attackEffects = AttackEffects(dispelCount = 1))
        )

        // Thrashing Assault
        spellMetadata[709] = V0Skill(
            cost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = 15)),
            skillChainAttributes = listOf(SkillChainAttribute.Fusion, SkillChainAttribute.Impaction),
        )
        SkillAppliers += SkillApplier(skillId = 709, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(
            attackStat = CombatStat.str,
            defendStat = CombatStat.vit,
            numHits = 5,
        ))

        // Erratic Flutter
        spellMetadata[710] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 12)))
        SkillAppliers += SkillApplier(skillId = 710, skillType = SkillType.Spell, targetEvaluator = applyBasicBuff(StatusEffect.Haste, 10.minutes) {
            it.status.potency = 40
        })

        // Searing Tempest
        spellMetadata[719] = V0Skill(cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 20)))
        SkillAppliers += SkillApplier(skillId = 719, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, potency = 35f))

        // Droning Whirlwind
        spellMetadata[744] = V0Skill(
            cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 20)),
            rangeInfo = SkillRangeInfo(maxTargetDistance = 20f, effectRadius = 20f, AoeType.Source),
            recast = V0Recast(baseTime = 1.minutes, fixedTime = true),
        )
        SkillAppliers += SkillApplier(skillId = 744, skillType = SkillType.Spell,
            targetEvaluator = {
                val levelDelta = (it.sourceState.getMainJobLevel().level - it.targetState.getMainJobLevel().level).coerceAtLeast(0)
                val potency = 4f + levelDelta
                val attackEffects = AttackEffects(knockBackMagnitude = 4, dispelCount = 1)
                applyBasicOffenseMagic(context = it, attackStat = CombatStat.int, potency = potency, attackEffects = attackEffects)
            }
        )

        // Carcharian Verve
        spellMetadata[745] = V0Skill(
            cost = V0AbilityCost(AbilityCost(type = AbilityCostType.Mp, value = 18)),
            recast = V0Recast(baseTime = 1.minutes, fixedTime = true),
        )
        SkillAppliers += SkillApplier(skillId = 745, skillType = SkillType.Spell,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Aquaveil, duration = 10.minutes) { it.status.counter = 5 },
        )

        // Blizzara
        SkillAppliers += SkillApplier(skillId = 830, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 14f))

        // Watera
        SkillAppliers += SkillApplier(skillId = 838, skillType = SkillType.Spell, targetEvaluator = applyBasicOffenseMagic(CombatStat.int, powerOverride = 12f))
    }

    fun getCost(spellId: Int): V0AbilityCost {
        val customCost = spellMetadata[spellId]?.cost
        if (customCost != null) { return customCost }

        val basicCost = SpellInfoTable[spellId].mpCost
        return V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Mp, value = basicCost))
    }

    fun getRecast(spellId: Int): V0Recast? {
        return spellMetadata[spellId]?.recast
    }

    fun getSkillChainAttributes(spellId: Int): List<SkillChainAttribute> {
        return spellMetadata[spellId]?.skillChainAttributes ?: emptyList()
    }

    fun getRange(spellId: Int): SkillRangeInfo? {
        return spellMetadata[spellId]?.rangeInfo
    }

    fun basicHealingMagic(potency: Float, maxPercentage: Float = 1f, maxAmount: Int): TargetEvaluator {
        return TargetEvaluator {
            val cappedHealAmount = (it.sourceState.getMaxHp() * maxPercentage).roundToInt()
            val healAmount = DamageCalculator.getHealAmount(it.sourceState, it.targetState, potency, maxAmount).coerceAtMost(cappedHealAmount)
            val outputEvents = ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = healAmount, actionContext = it.context)
            listOf(outputEvents)
        }
    }

    private fun applyDia(statusEffect: StatusEffect, duration: Duration): TargetEvaluator {
        return TargetEvaluator {
            val attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = statusEffect, baseDuration =  duration) { se ->
                    se.statusState.linkedAbilityId = it.skillInfo.id
                }
            )

            spellResultToEvents(it, SpellDamageCalculator.computeDamage(
                skill = it.skill,
                attacker = it.sourceState,
                defender = it.targetState,
                originalTarget = it.primaryTargetState,
                attackStat = CombatStat.mnd,
                defendStat = CombatStat.mnd,
                powerOverride = 1f,
            ), attackEffects = attackEffects)
        }
    }

    fun applyBasicBuff(statusEffect: StatusEffect, duration: Duration? = null, statusStateSetter: (StatusEffectContext) -> Unit = { }): TargetEvaluator {
        return TargetEvaluator {
            val state = it.targetState.gainStatusEffect(statusEffect, duration)
            state.linkedAbilityId = it.skillInfo.id
            statusStateSetter.invoke(StatusEffectContext(state, it))
            AttackContext.compose(it.context) { ChatLog.statusEffectGained(it.targetState.name, statusEffect) }
            emptyList()
        }
    }

    private fun applyHeadButt(): TargetEvaluator {
        return TargetEvaluator {
            val attackEffects = singleStatus(
                knockBackMagnitude = 1,
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Stun, baseDuration = 3.seconds),
            )

            val spellResult = SpellDamageCalculator.computeDamage(
                skill = it.skill,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.str,
                defendStat = CombatStat.vit,
            )

            spellResultToEvents(
                context = it,
                spellResult = spellResult,
                attackEffects = attackEffects,
            )
        }
    }

    private fun applyBasicOffenseMagic(
        attackStat: CombatStat,
        defendStat: CombatStat = attackStat,
        numHits: Int = 1,
        potency: Float = 1f,
        powerOverride: Float? = null,
        attackEffects: AttackEffects = AttackEffects(),
    ): TargetEvaluator {
        return TargetEvaluator {
            applyBasicOffenseMagic(it, attackStat, defendStat, numHits, potency, powerOverride, attackEffects)
        }
    }

    private fun applyBasicOffenseMagic(
        context: TargetEvaluatorContext,
        attackStat: CombatStat,
        defendStat: CombatStat = attackStat,
        numHits: Int = 1,
        potency: Float = 1f,
        powerOverride: Float? = null,
        attackEffects: AttackEffects = AttackEffects(),
    ): List<Event> {
        return spellResultToEvents(
            context, SpellDamageCalculator.computeDamage(
                potency = potency,
                skill = context.skill,
                attacker = context.sourceState,
                defender = context.targetState,
                originalTarget = context.primaryTargetState,
                attackStat = attackStat,
                defendStat = defendStat,
                numHits = numHits,
                powerOverride = powerOverride,
            ), attackEffects = attackEffects
        )
    }

    fun spellResultToEvents(
        context: TargetEvaluatorContext,
        spellResult: OffenseMagicResult,
        attackEffects: AttackEffects = AttackEffects(),
    ): List<Event> {
        val outputEvents = ArrayList<Event>()

        context.context.magicBurst = spellResult.magicBurst

        outputEvents += ActorAttackedEvent(
            sourceId = context.sourceState.id,
            targetId = context.targetState.id,
            damageAmount = spellResult.damage,
            damageType = AttackDamageType.Magical,
            sourceTpGain = spellResult.sourceTpGained,
            targetTpGain = spellResult.targetTpGained,
            attackEffects = attackEffects,
            skill = context.skill,
            actionContext = context.context,
        )

        return outputEvents
    }

}