package xim.poc.game.configuration.v0.events

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.InternalItemId
import xim.poc.game.configuration.v0.GameV0Helpers
import xim.poc.game.event.ActorEquipEvent
import xim.poc.game.event.Event
import xim.resource.InventoryItems

class WeaponUpgradeEvent(
    val sourceId: ActorId,
    val weaponId: InternalItemId,
    val destinationWeaponId: Int,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()

        val sourceWeapon = source.inventory.getByInternalId(weaponId) ?: return emptyList()

        val equippedSlot = source.equipment.getEquippedSlot(sourceWeapon)
        source.inventory.discardItem(weaponId)

        val upgradedWeapon = GameV0Helpers.generateUpgradedWeapon(sourceWeapon, InventoryItems[destinationWeaponId])
        source.inventory.addItem(upgradedWeapon)

        val events = ArrayList<Event>()

        if (equippedSlot != null) {
            events += ActorEquipEvent(sourceId = sourceId, equipment = mapOf(equippedSlot to upgradedWeapon.internalId))
        }

        return events
    }

}