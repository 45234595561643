package xim.poc.game.configuration.v0

import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.ActorGainExpEvent
import xim.poc.game.event.ActorHealedEvent
import xim.poc.game.event.ActorResourceType

object V0ItemDefinitions {

    fun register() {

        // Potion
        SkillAppliers += SkillApplier(skillId = 4112, skillType = SkillType.Item, targetEvaluator = {
            listOf(ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = 50, actionContext = it.context))
        })

        // Ether
        SkillAppliers += SkillApplier(skillId = 4128, skillType = SkillType.Item, targetEvaluator = {
            listOf(ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = 20, actionContext = it.context, healType = ActorResourceType.MP))
        })

        // Dragon Chronicles
        SkillAppliers += SkillApplier(skillId = 4198, skillType = SkillType.Item, targetEvaluator = {
            listOf(ActorGainExpEvent(actorId = it.sourceState.id, expAmount = 10000, actionContext = it.context))
        })

        // Icarus Wing
        SkillAppliers += SkillApplier(skillId = 4213, skillType = SkillType.Item, targetEvaluator = {
            listOf(ActorHealedEvent(sourceId = it.sourceState.id, targetId = it.targetState.id, amount = 1000, actionContext = it.context, healType = ActorResourceType.TP))
        })

    }

}