package xim.poc.game.configuration.v0

import xim.poc.game.configuration.v0.tower.FloorConfiguration
import xim.poc.game.configuration.v0.tower.TowerConfiguration
import xim.poc.game.configuration.v0.tower.TowerState
import xim.poc.ui.ChatLog
import kotlin.math.max

object GameTower {

    private var towerState = TowerState()

    fun resetTowerState(startingFloor: Int) {
        towerState = TowerState()
        towerState.currentFloor = startingFloor
    }

    fun advanceTowerFloor(): Int {
        towerState.currentFloor += 1
        return towerState.currentFloor
    }

    fun getCurrentFloor(): Int {
        return towerState.currentFloor
    }

    fun emitFloorNumber() {
        ChatLog("=== Floor: ${towerState.currentFloor} ===")
    }

    fun getCurrentFloorDefinition(): FloorConfiguration {
        return TowerConfiguration[towerState.currentFloor]
    }

    fun getNextFloorDefinition(): FloorConfiguration {
        return TowerConfiguration[towerState.currentFloor + 1]
    }

    fun hasClearedFloor(floorNumber: Int): Boolean {
        return GameV0SaveStateHelper.getState().highestClearedFloor >= floorNumber
    }

    fun onClearedFloor(floorNumber: Int) {
        val state = GameV0SaveStateHelper.getState()
        state.highestClearedFloor = max(state.highestClearedFloor, floorNumber)
    }

}