package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.StatusEffect
import xim.poc.game.StatusEffectState
import xim.poc.ui.ChatLog

class StatusEffectLostEvent(
    val sourceId: ActorId,
    val statusEffectState: StatusEffectState,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        val events = ArrayList<Event>()

        if (statusEffectState.statusEffect == StatusEffect.Indicolure && sourceState.bubble != null) {
            events += BubbleReleaseEvent(sourceId)
        }

        if (!sourceState.isDead()) {
            ChatLog.statusEffectLost(sourceState.name, statusEffectState.statusEffect)
        }

        return events
    }

}