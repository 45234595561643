package xim.poc.game.configuration.v0.tower

import xim.poc.game.configuration.*
import xim.poc.game.configuration.WeightedTable.Companion.uniform
import xim.poc.game.configuration.v0.ChestSlot
import xim.poc.game.configuration.v0.tower.DropTables.basicMedicine
import xim.poc.game.configuration.v0.tower.DropTables.gilDropSlot
import xim.poc.game.configuration.v0.tower.DropTables.leafStoneSlot
import xim.poc.game.configuration.v0.tower.DropTables.lvlAccessory1
import xim.poc.game.configuration.v0.tower.DropTables.lvlAccessory10
import xim.poc.game.configuration.v0.tower.DropTables.lvlAccessory12
import xim.poc.game.configuration.v0.tower.DropTables.lvlAccessory5
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment1
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment10
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment12
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment3
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment5
import xim.poc.game.configuration.v0.tower.DropTables.lvlEquipment7
import xim.poc.game.configuration.v0.zones.BattleLocation
import xim.poc.game.configuration.v0.zones.BattleLocations
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlin.random.Random

class FloorConfiguration(
    val floorNumber: Int,
    val defeatRequirement: Int,
    val monsterProviderFactory: MonsterProviderFactory,
    val chestTable: List<ChestSlot>,
    val maxActive: Int = 8,
    val maxSpawnCount: Int? = 8,
    val battleLocation: BattleLocation,
    val blueMagicReward: List<Int> = emptyList(),
)

object TowerConfiguration {

    private val configurations = HashMap<Int, FloorConfiguration>()

    init {
        configurations[1] = FloorConfiguration(
            floorNumber = 1,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 8
            ),
            battleLocation = BattleLocations[2],
            blueMagicReward = listOf(623), // Head Butt
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment1(meanRank = 0)) },
                ChestSlot(2) { uniform(ItemDropSlot(9084, quantity = 1)) },
                ChestSlot(3) { uniform(basicMedicine) },
                ChestSlot(4) { uniform(lvlEquipment1(meanRank = 5)) },
                ChestSlot(5) { uniform(lvlAccessory1(meanRank = 10)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 2)) },
                ChestSlot(7) { uniform(ItemDropSlot(8930, quantity = 1), gilDropSlot(floor = 1)) },
                ChestSlot(8) { uniform(lvlEquipment1(meanRank = 10)) },
            )
        )

        configurations[2] = FloorConfiguration(
            floorNumber = 2,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 4,
                3 to 4,
            ),
            battleLocation = BattleLocations[10],
            blueMagicReward = listOf(549), // Pollen
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment1(meanRank = 1)) },
                ChestSlot(2) { uniform(ItemDropSlot(9084, quantity = 2)) },
                ChestSlot(3) { uniform(basicMedicine) },
                ChestSlot(4) { uniform(lvlEquipment1(meanRank = 6)) },
                ChestSlot(5) { uniform(ItemDropSlot(9084, quantity = 4)) },
                ChestSlot(6) { uniform(lvlAccessory1(meanRank = 11)) },
                ChestSlot(7) { uniform(ItemDropSlot(8930, quantity = 2), gilDropSlot(floor = 2)) },
                ChestSlot(8) { uniform(lvlEquipment1(meanRank = 11)) },
            ))

        configurations[3] = FloorConfiguration(
            floorNumber = 3,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                3 to 3,
                4 to 3,
                9 to 2,
            ),
            battleLocation = BattleLocations[1],
            blueMagicReward = listOf(517), // Metallic Body
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment1(meanRank = 2)) },
                ChestSlot(2) { uniform(ItemDropSlot(9084, quantity = 3)) },
                ChestSlot(3) { uniform(basicMedicine) },
                ChestSlot(4) { uniform(lvlEquipment1(meanRank = 7)) },
                ChestSlot(5) { uniform(lvlAccessory1(meanRank = 12)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 6)) },
                ChestSlot(7) { uniform(ItemDropSlot(8930, quantity = 2), gilDropSlot(floor = 3)) },
                ChestSlot(8) { uniform(lvlEquipment1(meanRank = 12)) },
            ))

        configurations[4] = FloorConfiguration(
            floorNumber = 4,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                4 to 3,
                9 to 3,
                12 to 2,
            ),
            battleLocation = BattleLocations[0],
            blueMagicReward = listOf(519), // Screwdriver
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment1(meanRank = 3)) },
                ChestSlot(1) { uniform(ItemDropSlot(17284)) },
                ChestSlot(2) { uniform(ItemDropSlot(9084, quantity = 4)) },
                ChestSlot(3) { uniform(basicMedicine) },
                ChestSlot(4) { uniform(lvlEquipment1(meanRank = 8)) },
                ChestSlot(5) { uniform(lvlAccessory1(meanRank = 12)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 8)) },
                ChestSlot(7) { uniform(ItemDropSlot(8930, quantity = 2), gilDropSlot(floor = 4)) },
                ChestSlot(8) { uniform(lvlEquipment1(meanRank = 16)) },
            ))

        configurations[5] = FloorConfiguration(
            floorNumber = 5,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                10 to 1
            ),
            battleLocation = BattleLocations[4],
            blueMagicReward = listOf(596), // Pinecone Bomb
            maxActive = 1,
            maxSpawnCount = 1,
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlAccessory1(meanRank = 16)) },
                ChestSlot(1) { uniform(ItemDropSlot(itemId = 4033)) },
                ChestSlot(1) { uniform(ItemDropSlot(itemId = 8933)) },
                ChestSlot(1) { uniform(ItemDropSlot(itemId = 8942)) },
                ChestSlot(1) { uniform(ItemDropSlot(itemId = 8951)) },
                ChestSlot(1) { uniform(ItemDropSlot(itemId = 8960)) },
            ),
        )

        configurations[6] = FloorConfiguration(
            floorNumber = 6,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                16 to 3,
                9 to 2,
                12 to 3,
            ),
            battleLocation = BattleLocations[6],
            blueMagicReward = listOf(521), // MP Drainkiss
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment3(meanRank = 0)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(8930, quantity = 2), gilDropSlot(floor = 6)) },
                ChestSlot(4) { uniform(ItemDropSlot(9084, quantity = 5)) },
                ChestSlot(5) { uniform(lvlEquipment3(meanRank = 5)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 10)) },
                ChestSlot(7) { uniform(leafStoneSlot(quantity = 2)) },
                ChestSlot(8) { uniform(lvlEquipment3(meanRank = 10)) },
            ))

        configurations[7] = FloorConfiguration(
            floorNumber = 7,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                17 to 2,
                16 to 2,
                15 to 2,
                5 to 2,
            ),
            battleLocation = BattleLocations[5],
            blueMagicReward = listOf(530, 577), // Refueling, Foot Kick
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment3(meanRank = 1)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(8930, quantity = 3), gilDropSlot(floor = 7)) },
                ChestSlot(4) { uniform(ItemDropSlot(9084, quantity = 5)) },
                ChestSlot(5) { uniform(lvlEquipment3(meanRank = 6)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 10)) },
                ChestSlot(7) { uniform(leafStoneSlot(quantity = 2)) },
                ChestSlot(8) { uniform(lvlEquipment3(meanRank = 11)) },
            ))

        configurations[8] = FloorConfiguration(
            floorNumber = 8,
            defeatRequirement = 1,
            battleLocation = BattleLocations[8],
            monsterProviderFactory = countProvider(
                13 to 2,
                5 to 2,
                14 to 2,
                15 to 2,
            ),
            blueMagicReward = listOf(584), // Sheep Song
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment3(meanRank = 2)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(9084, quantity = 5)) },
                ChestSlot(4) { uniform(ItemDropSlot(8930, quantity = 3), gilDropSlot(floor = 8)) },
                ChestSlot(5) { uniform(lvlEquipment3(meanRank = 7)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 10)) },
                ChestSlot(7) { uniform(leafStoneSlot(quantity = 2)) },
                ChestSlot(8) { uniform(lvlEquipment3(meanRank = 12)) },
            ))

        configurations[9] = FloorConfiguration(
            floorNumber = 9,
            defeatRequirement = 1,
            battleLocation = BattleLocations[9],
            monsterProviderFactory = countProvider(
                14 to 2,
                15 to 3,
                18 to 3,
            ),
            blueMagicReward = listOf(544), // Cursed Sphere
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment3(meanRank = 3)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(8930, quantity = 3), gilDropSlot(floor = 9)) },
                ChestSlot(4) { uniform(ItemDropSlot(9084, quantity = 5)) },
                ChestSlot(5) { uniform(lvlEquipment3(meanRank = 8)) },
                ChestSlot(6) { uniform(ItemDropSlot(9084, quantity = 10)) },
                ChestSlot(7) { uniform(leafStoneSlot(quantity = 3)) },
                ChestSlot(8) { uniform(lvlEquipment3(meanRank = 15)) },
            ))

        configurations[10] = FloorConfiguration(
            floorNumber = 10,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                7 to 1
            ),
            battleLocation = BattleLocations[3],
            blueMagicReward = listOf(744), // Droning Whirlwind
            maxActive = 1,
            maxSpawnCount = 1,
            chestTable = emptyList(),
        )

        configurations[11] = FloorConfiguration(
            floorNumber = 11,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                20 to 5,
                21 to 3,
            ),
            battleLocation = BattleLocations[11],
            blueMagicReward = listOf(605), // Geist Wall
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment5(meanRank = 0)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 11)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 1)) },
                ChestSlot(5) { uniform(lvlEquipment5(meanRank = 5)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 2)) },
                ChestSlot(7) { uniform(lvlAccessory5(meanRank = 10)) },
                ChestSlot(8) { uniform(lvlEquipment5(meanRank = 10)) },
            ))

        configurations[12] = FloorConfiguration(
            floorNumber = 12,
            defeatRequirement = 1,
            maxActive = 1,
            maxSpawnCount = 1,
            monsterProviderFactory = countProvider(
                19 to 1
            ),
            battleLocation = BattleLocations[12],
            blueMagicReward = listOf(664), // Regeneration
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlAccessory5(meanRank = 12)) },
                ChestSlot(1) { uniform(ItemDropSlot(9085, quantity = 5)) },
                ChestSlot(1) { uniform(ItemDropSlot(8930, quantity = 4), gilDropSlot(floor = 12)) },
                ChestSlot(1) { uniform(leafStoneSlot(quantity = 4)) },
                ChestSlot(1) { uniform(lvlEquipment5(meanRank = 12)) },
            ),
        )

        configurations[13] = FloorConfiguration(
            floorNumber = 13,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                22 to 3,
                24 to 3,
                21 to 2
            ),
            battleLocation = BattleLocations[13],
            blueMagicReward = listOf(513), // Venom Shell
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment5(meanRank = 2)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(8930, quantity = 3), gilDropSlot(floor = 13)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 2)) },
                ChestSlot(5) { uniform(lvlEquipment5(meanRank = 7)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 3)) },
                ChestSlot(7) { uniform(lvlAccessory5(meanRank = 12)) },
                ChestSlot(8) { uniform(lvlEquipment5(meanRank = 12)) },
            ))

        configurations[14] = FloorConfiguration(
            floorNumber = 14,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                22 to 2,
                23 to 2,
                28 to 3
            ),
            battleLocation = BattleLocations[14],
            blueMagicReward = listOf(542), // Digest
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment5(meanRank = 3)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(ItemDropSlot(8930, quantity = 3), gilDropSlot(floor = 9)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 4)) },
                ChestSlot(5) { uniform(lvlEquipment5(meanRank = 8)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 5)) },
                ChestSlot(7) { uniform(lvlAccessory5(meanRank = 16)) },
                ChestSlot(8) { uniform(lvlEquipment5(meanRank = 16)) },
            ))

        configurations[15] = FloorConfiguration(
            floorNumber = 15,
            defeatRequirement = 1,
            maxActive = 1,
            maxSpawnCount = 1,
            monsterProviderFactory = countProvider(
                25 to 1
            ),
            battleLocation = BattleLocations[15],
            blueMagicReward = listOf(618), // Blastbomb
            chestTable = emptyList(),
        )

        configurations[16] = FloorConfiguration(
            floorNumber = 16,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                33 to 4,
                34 to 2,
                35 to 2,
            ),
            battleLocation = BattleLocations[16],
            blueMagicReward = listOf(569), // Jet Stream
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment7(meanRank = 0)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 16)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 6)) },
                ChestSlot(5) { uniform(lvlEquipment7(meanRank = 5)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 8)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 3)) },
                ChestSlot(8) { uniform(lvlEquipment7(meanRank = 10)) },
            ))

        configurations[17] = FloorConfiguration(
            floorNumber = 17,
            defeatRequirement = 1,
            maxActive = 1,
            maxSpawnCount = 1,
            monsterProviderFactory = countProvider(
                27 to 1,
            ),
            battleLocation = BattleLocations[17],
            blueMagicReward = listOf(701), // Tempestuous Upheaval
            chestTable = listOf(
                ChestSlot(1) { uniform(ItemDropSlot(9085, quantity = 8)) },
                ChestSlot(1) { uniform(gilDropSlot(floor = 17)) },
                ChestSlot(1) { uniform(lvlEquipment7(meanRank = 6)) },
                ChestSlot(1) { uniform(ItemDropSlot(9085, quantity = 10)) },
                ChestSlot(1) { uniform(ItemDropSlot(8931, quantity = 3)) },
                ChestSlot(1) { uniform(lvlEquipment7(meanRank = 11)) },
            ))

        configurations[18] = FloorConfiguration(
            floorNumber = 18,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                30 to 2,
                37 to 3,
                36 to 3,
            ),
            battleLocation = BattleLocations[18],
            blueMagicReward = listOf(702), // Rending Deluge
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment7(meanRank = 2)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 18)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 10)) },
                ChestSlot(5) { uniform(lvlEquipment7(meanRank = 7)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 10)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 4)) },
                ChestSlot(8) { uniform(lvlEquipment7(meanRank = 12)) },
            ))

        configurations[19] = FloorConfiguration(
            floorNumber = 19,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                31 to 3,
                38 to 3,
                34 to 2,
            ),
            battleLocation = BattleLocations[19],
            blueMagicReward = listOf(651), // Corrosive Ooze
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment7(meanRank = 3)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 18)) },
                ChestSlot(4) { uniform(ItemDropSlot(9085, quantity = 10)) },
                ChestSlot(5) { uniform(lvlEquipment7(meanRank = 8)) },
                ChestSlot(6) { uniform(ItemDropSlot(9085, quantity = 10)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 4)) },
                ChestSlot(8) { uniform(lvlEquipment7(meanRank = 16)) },
            ))

        configurations[20] = FloorConfiguration(
            floorNumber = 20,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                29 to 1
            ),
            battleLocation = BattleLocations[20],
            maxActive = 1,
            maxSpawnCount = 1,
            blueMagicReward = listOf(745), // Carcharian Verve
            chestTable = emptyList(),
        )

        configurations[21] = FloorConfiguration(
            floorNumber = 21,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                39 to 7,
                40 to 1,
            ),
            battleLocation = BattleLocations[21],
            blueMagicReward = listOf(578), // Wild Carrot
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment10(meanRank = 0)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 21)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 2)) },
                ChestSlot(5) { uniform(lvlEquipment10(meanRank = 5)) },
                ChestSlot(6) { uniform(lvlAccessory10(meanRank = 10)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 5)) },
                ChestSlot(8) { uniform(lvlEquipment10(meanRank = 10)) },
            ))

        configurations[22] = FloorConfiguration(
            floorNumber = 22,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                39 to 3,
                40 to 1,
                41 to 2,
                43 to 2,
            ),
            battleLocation = BattleLocations[22],
            blueMagicReward = listOf(587), // Claw Cyclone
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment10(meanRank = 1)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 22)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 3)) },
                ChestSlot(5) { uniform(lvlEquipment10(meanRank = 6)) },
                ChestSlot(6) { uniform(lvlAccessory10(meanRank = 11)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 6)) },
                ChestSlot(8) { uniform(lvlEquipment10(meanRank = 11)) },
            ))

        configurations[23] = FloorConfiguration(
            floorNumber = 23,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                41 to 2,
                42 to 3,
                43 to 3,
            ),
            battleLocation = BattleLocations[23],
            blueMagicReward = listOf(608), // Frost Breath
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment10(meanRank = 2)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 23)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 4)) },
                ChestSlot(5) { uniform(lvlEquipment10(meanRank = 7)) },
                ChestSlot(6) { uniform(lvlAccessory10(meanRank = 12)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 6)) },
                ChestSlot(8) { uniform(lvlEquipment10(meanRank = 12)) },
            ))

        configurations[24] = FloorConfiguration(
            floorNumber = 24,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                40 to 2,
                42 to 2,
                44 to 4,
            ),
            battleLocation = BattleLocations[24],
            blueMagicReward = listOf(588),
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment10(meanRank = 3)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 24)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 5)) },
                ChestSlot(5) { uniform(lvlEquipment10(meanRank = 8)) },
                ChestSlot(6) { uniform(lvlAccessory10(meanRank = 16)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 8)) },
                ChestSlot(8) { uniform(lvlEquipment10(meanRank = 16)) },
            ))

        configurations[25] = FloorConfiguration(
            floorNumber = 25,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                45 to 1
            ),
            battleLocation = BattleLocations[25],
            maxActive = 1,
            maxSpawnCount = 1,
            blueMagicReward = listOf(535),
            chestTable = listOf(
                ChestSlot(1) { uniform(ItemDropSlot(9086, quantity = 10)) },
                ChestSlot(1) { uniform(ItemDropSlot(8931, quantity = 10)) },
            ),
        )

        configurations[26] = FloorConfiguration(
            floorNumber = 26,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                46 to 1,
                47 to 1,
            ),
            battleLocation = BattleLocations[26],
            blueMagicReward = emptyList(),
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment12(meanRank = 0)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 26)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 6)) },
                ChestSlot(5) { uniform(lvlEquipment12(meanRank = 5)) },
                ChestSlot(6) { uniform(lvlAccessory12(meanRank = 10)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 10)) },
                ChestSlot(8) { uniform(lvlEquipment12(meanRank = 10)) },
            ))

        configurations[27] = FloorConfiguration(
            floorNumber = 27,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 1
            ),
            battleLocation = BattleLocations[27],
            blueMagicReward = emptyList(),
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment12(meanRank = 1)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 27)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 7)) },
                ChestSlot(5) { uniform(lvlEquipment12(meanRank = 6)) },
                ChestSlot(6) { uniform(lvlAccessory12(meanRank = 11)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 10)) },
                ChestSlot(8) { uniform(lvlEquipment12(meanRank = 11)) },
            ))

        configurations[28] = FloorConfiguration(
            floorNumber = 28,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 1
            ),
            battleLocation = BattleLocations[28],
            blueMagicReward = emptyList(),
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment12(meanRank = 2)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 28)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 8)) },
                ChestSlot(5) { uniform(lvlEquipment12(meanRank = 7)) },
                ChestSlot(6) { uniform(lvlAccessory12(meanRank = 12)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 10)) },
                ChestSlot(8) { uniform(lvlEquipment12(meanRank = 12)) },
            ))

        configurations[29] = FloorConfiguration(
            floorNumber = 29,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 1
            ),
            battleLocation = BattleLocations[29],
            blueMagicReward = emptyList(),
            chestTable = listOf(
                ChestSlot(1) { uniform(lvlEquipment12(meanRank = 3)) },
                ChestSlot(2) { uniform(basicMedicine) },
                ChestSlot(3) { uniform(gilDropSlot(floor = 29)) },
                ChestSlot(4) { uniform(ItemDropSlot(9086, quantity = 10)) },
                ChestSlot(5) { uniform(lvlEquipment12(meanRank = 8)) },
                ChestSlot(6) { uniform(lvlAccessory12(meanRank = 16)) },
                ChestSlot(7) { uniform(ItemDropSlot(8931, quantity = 10)) },
                ChestSlot(8) { uniform(lvlEquipment12(meanRank = 16)) },
            ))

        configurations[30] = FloorConfiguration(
            floorNumber = 30,
            defeatRequirement = 1,
            monsterProviderFactory = countProvider(
                1 to 1
            ),
            battleLocation = BattleLocations[30],
            blueMagicReward = emptyList(),
            chestTable = listOf(
                ChestSlot(1) { uniform(ItemDropSlot(9086, quantity = 10)) },
                ChestSlot(1) { uniform(ItemDropSlot(8931, quantity = 10)) },
            ),
        )

        // Debug
        configurations[1000] = FloorConfiguration(
            floorNumber = 1000,
            defeatRequirement = 1,
            monsterProviderFactory = defaultProvider(uniform(
                MonsterDefinitions[1000]
            )),
            battleLocation = BattleLocations[1000],
            maxActive = 1,
            maxSpawnCount = 1,
            chestTable = emptyList()
        )

    }

    operator fun get(floor: Int): FloorConfiguration {
        return configurations[floor] ?: configurations[0]!!
    }

    fun getAll(): Map<Int, FloorConfiguration> {
        return configurations
    }

    fun defaultProvider(weightedTable: WeightedTable<MonsterDefinition>): MonsterProviderFactory {
        return MonsterProviderFactory.from(weightedTable)
    }

    fun countProvider(vararg monsterCounts: Pair<Int, Int>): MonsterProviderFactory {
        return countProvider(monsterCounts.toList())
    }

    fun countProvider(monsterCounts: List<Pair<Int, Int>>): MonsterProviderFactory {
        return CountMonsterProvider.factory(monsterCounts)
    }

}

object DropTables {

    val basicMedicine = listOf(4112, 4128)
        .map { ItemDropSlot(it, temporary = true) }

    fun lvlEquipment1(meanRank: Int): List<ItemDropSlot> {
        return listOf(12448, 12576, 12704, 12832, 12960)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlAccessory1(meanRank: Int): List<ItemDropSlot> {
        return listOf(13199, 13594)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment3(meanRank: Int): List<ItemDropSlot> {
        return listOf(12440, 12568, 12696, 12824, 12952)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment5(meanRank: Int): List<ItemDropSlot> {
        return listOf(12464, 12592, 12720, 12848, 12976)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlAccessory5(meanRank: Int): List<ItemDropSlot> {
        return listOf(13192, 13583)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment7(meanRank: Int): List<ItemDropSlot> {
        return listOf(27762, 27908, 28047, 28189, 28328)
            .map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment10(): List<Int> {
        return listOf(12467, 12595, 12730, 12851, 12979)
    }

    fun lvlAccessory10(meanRank: Int): List<ItemDropSlot> {
        return listOf(13193, 13592).map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment10(meanRank: Int): List<ItemDropSlot> {
        return lvlEquipment10().map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlEquipment12() = listOf(15165, 14426, 14858, 14327, 15315)

    fun lvlEquipment12(meanRank: Int): List<ItemDropSlot> {
        return lvlEquipment12().map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun lvlAccessory12() = listOf(15485, 15881)

    fun lvlAccessory12(meanRank: Int): List<ItemDropSlot> {
        return lvlAccessory12().map { ItemDropSlot(it, augmentRankMean = meanRank) }
    }

    fun gilDropSlot(floor: Int): ItemDropSlot {
        val amount = 100 * (1.33f).pow(floor / 2f) * Random.nextDouble(1.0, 2.0)
        return ItemDropSlot(itemId = 65535, quantity = amount.roundToInt())
    }

    fun leafStoneSlot(quantity: Int): List<ItemDropSlot> {
        return listOf(4033, 8933, 8942, 8951, 8960,)
            .map { ItemDropSlot(it, quantity = quantity) }
    }

}

private class CountMonsterProvider(val monsterCounts: List<Pair<Int, Int>>) : MonsterProvider {

    companion object {
        fun factory(monsterCounts: List<Pair<Int, Int>>): MonsterProviderFactory {
            return MonsterProviderFactory { CountMonsterProvider(monsterCounts) }
        }
    }

    private val next = ArrayList<MonsterDefinition>()

    init { populate() }

    override fun nextMonster(): MonsterDefinition {
        if (next.isEmpty()) { populate() }
        return next.removeLast()
    }

    private fun populate() {
        for ((monsterId, count) in monsterCounts) { repeat(count) { next += MonsterDefinitions[monsterId] } }
        next.shuffle()
    }

}