package xim.poc.game.configuration.v0.interactions

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.v0.GameV0Helpers
import xim.poc.game.configuration.v0.ItemDefinitions
import xim.poc.game.configuration.v0.events.InventoryItemMeldEvent
import xim.poc.game.configuration.v0.events.InventoryItemMeldWeaponEvent
import xim.poc.game.configuration.v0.interactions.MeltInteractionUiState.meldItemSelectContext
import xim.poc.game.configuration.v0.interactions.MeltInteractionUiState.meldUpgradeItemSelectContext
import xim.poc.ui.*
import xim.resource.DatId
import kotlin.math.ceil
import kotlin.math.roundToInt

private const val weaponMeldReq = 10

object MeldInteraction: NpcInteraction {

    private var describedSelf = false

    override fun onInteraction(npcId: ActorId) {
        if (!describedSelf) {
            describedSelf = true
            ChatLog("Upgrade materials can be applied to ${ShiftJis.colorItem}weapons${ShiftJis.colorClear}.\n${ShiftJis.colorAug}Rank ${weaponMeldReq}${ShiftJis.colorClear} ${ShiftJis.colorItem}weapons${ShiftJis.colorClear} can be used as upgrade materials!")
        }

        UiStateHelper.pushState(meldItemSelectContext, SystemSound.MenuSelect)
    }

}

object MeldEquipmentFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        return inventoryItem.fixedAugments != null
    }
}

object MeldUpgradeItemFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        if (ActorStateManager.player().isEquipped(inventoryItem)) { return false }
        if (inventoryItem.internalId == MeldInteractionUi.getSelectedUpgradeItem()?.internalId) { return false }
        return inventoryItem.info().isSword() || ItemDefinitions.getNullable(inventoryItem)?.capacityAugment != null
    }
}

private object MeltInteractionUiState {

    val meldItemSelectContext: UiState
    val meldUpgradeItemSelectContext: UiState
    val meldInputAmountContext: UiState

    init {
        meldInputAmountContext = UiState(
            focusMenu = "menu    itemctrl",
            drawParent = true,
            additionalDraw = { MeldInteractionUi.drawItemControl(it) }
        ) {
            MeldInteractionUi.quantityInput.refresh()

            if (UiStateHelper.isEnterPressed()) {
                UiStateHelper.popState(SystemSound.MenuSelect)
                MeldInteractionUi.openBasicMeldConfirmation()
                true
            } else if (MeldInteractionUi.quantityInput.processInput()) {
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        meldUpgradeItemSelectContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = MeldUpgradeItemFilter) },
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            focusMenu = "menu    inventor",
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = MeldUpgradeItemFilter).size },
            hideGauge = true,
        ) {
            if (UiStateHelper.isEnterPressed() && MeldInteractionUi.getMaxUpgradeMaterialQuantity() == 0) {
                AudioManager.playSystemSoundEffect(SystemSound.Invalid)
                true
            } else if (UiStateHelper.isEnterPressed()) {
                val upgradeMaterial = MeldInteractionUi.getSelectedUpgradeMaterial() ?: return@UiState true
                if (upgradeMaterial.info().isSword()) {
                    MeldInteractionUi.openWeaponMeldConfirmation(upgradeMaterial)
                } else {
                    UiStateHelper.pushState(meldInputAmountContext, SystemSound.MenuSelect)
                }
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        meldItemSelectContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = MeldEquipmentFilter, descriptionProvider = MeldInteractionUi::getDescriptionWithMaxes) },
            focusMenu = "menu    inventor",
            childStates = { listOf(meldUpgradeItemSelectContext) },
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = MeldEquipmentFilter).size },
            hideGauge = true,
        ) {
            if (UiStateHelper.isEnterPressed()) {
                UiStateHelper.pushState(meldUpgradeItemSelectContext, SystemSound.MenuSelect)
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }
    }

}

object MeldInteractionUi {

    val quantityInput = QuantityInputController(maxValueProvider = this::getMaxUpgradeMaterialQuantity)

    fun drawItemControl(uiState: UiState) {
        quantityInput.draw(uiState)
    }

    fun getSelectedUpgradeItem(): InventoryItem? {
        return InventoryUi.getSelectedItem(meldItemSelectContext, filter = MeldEquipmentFilter)
    }

    fun getSelectedUpgradeMaterial(): InventoryItem? {
        return InventoryUi.getSelectedItem(meldUpgradeItemSelectContext, filter = MeldUpgradeItemFilter)
    }

    fun getMaxUpgradeMaterialQuantity(): Int {
        val upgradeMaterial = getSelectedUpgradeMaterial() ?: return 0
        val targetItem = getSelectedUpgradeItem() ?: return 0

        val capacityRemaining = targetItem.fixedAugments?.capacityRemaining ?: return 0

        val itemInfo = upgradeMaterial.info()
        if (itemInfo.isSword()) {
            val rankLevel = upgradeMaterial.augments?.rankLevel ?: 0
            val augmentBonuses = GameV0Helpers.getWeaponMeldBonus(targetItem, upgradeMaterial)

            return if (capacityRemaining < rankLevel) {
                emitInsufficientCapacityMessage(targetItem, rankLevel)
                0
            } else if (augmentBonuses.isEmpty() || augmentBonuses.values.all { it == 0 }) {
                emitMaximumPotentialMessage(targetItem)
                0
            } else {
                1
            }
        }

        val upgradeInfo = ItemDefinitions[upgradeMaterial].capacityAugment ?: return 0

        val remainingPotential = GameV0Helpers.getRemainingWeaponMeldPotential(targetItem)
            .getOrElse(upgradeInfo.augmentId) { 0 }

        val potentialLimit = ceil(remainingPotential.toFloat() / upgradeInfo.potency).roundToInt()
        if (potentialLimit == 0) {
            emitMaximumPotentialMessage(targetItem)
            return 0
        }

        val capacityLimit = capacityRemaining / upgradeInfo.capacity
        if (capacityLimit == 0) {
            emitInsufficientCapacityMessage(targetItem, upgradeInfo.capacity)
            return 0
        }

        return minOf(upgradeMaterial.quantity, capacityLimit, potentialLimit)
    }

    fun openBasicMeldConfirmation() {
        val targetWeapon = getSelectedUpgradeItem() ?: return
        val material = getSelectedUpgradeMaterial() ?: return
        val quantity = quantityInput.value

        val upgradeBonus = ItemDefinitions[material].capacityAugment ?: return
        val upgradeBonuses = mapOf(upgradeBonus.augmentId to upgradeBonus.potency * quantity)
        val capacityConsumption = upgradeBonus.capacity * quantity

        val prompt = "Consume ${ShiftJis.colorAug}$capacityConsumption capacity${ShiftJis.colorClear}?"

        val options = listOf(
            QueryMenuOption(text = "No", value = 0),
            QueryMenuOption(text = "Yes", value = 1),
        )

        UiStateHelper.openQueryMode(
            prompt = prompt,
            options = options,
            closeable = true,
            callback = this::handleBasicMeldConfirmation,
            drawFn = { drawPreviewItemWithBonuses(targetWeapon, upgradeBonuses) }
        )
    }

    private fun handleBasicMeldConfirmation(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value == 0) { return QueryMenuResponse.pop }

        submitUpgrade()
        return QueryMenuResponse.pop
    }

    private fun submitUpgrade() {
        val itemToUpgrade = getSelectedUpgradeItem() ?: return
        val upgradeMaterial = getSelectedUpgradeMaterial() ?: return
        val quantity = quantityInput.value

        GameEngine.submitEvent(InventoryItemMeldEvent(ActorStateManager.playerId, itemToUpgrade.internalId, upgradeMaterial.internalId, quantity))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1346, DatId.synthesisNq)
    }

    fun openWeaponMeldConfirmation(upgradeMaterial: InventoryItem) {
        val targetWeapon = getSelectedUpgradeItem() ?: return

        val upgradeMaterialRank = upgradeMaterial.augments?.rankLevel ?: return
        if (upgradeMaterialRank < weaponMeldReq) {
            ChatLog("${ShiftJis.colorItem}${upgradeMaterial.info().name}${ShiftJis.colorClear} must be at least ${ShiftJis.colorAug}Rank ${weaponMeldReq}${ShiftJis.colorClear} to proceed.", ChatLogColor.Error)
            AudioManager.playSystemSoundEffect(SystemSound.Invalid)
            return
        }

        val bonuses = GameV0Helpers.getWeaponMeldBonus(targetWeapon, upgradeMaterial)
        val prompt = "Consume ${ShiftJis.colorAug}${upgradeMaterial.augments?.rankLevel} capacity${ShiftJis.colorClear}?"

        val options = listOf(
            QueryMenuOption(text = "No", value = 0),
            QueryMenuOption(text = "Yes", value = 1),
        )

        UiStateHelper.openQueryMode(
            prompt = prompt,
            options = options,
            closeable = true,
            callback = this::handleWeaponMeldConfirmation,
            drawFn = { drawPreviewItemWithBonuses(targetWeapon, bonuses) }
        )
    }

    private fun handleWeaponMeldConfirmation(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value == 0) { return QueryMenuResponse.pop }

        val itemToUpgrade = getSelectedUpgradeItem() ?: return QueryMenuResponse.pop
        val upgradeMaterial = getSelectedUpgradeMaterial() ?: return QueryMenuResponse.pop

        GameEngine.submitEvent(InventoryItemMeldWeaponEvent(
            sourceId = ActorStateManager.playerId,
            targetItemId = itemToUpgrade.internalId,
            upgradeItemId = upgradeMaterial.internalId,
        ))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1346, DatId.synthesisNq)

        return QueryMenuResponse.popAll
    }

    private fun emitInsufficientCapacityMessage(item: InventoryItem, needed: Int) {
        ChatLog("${ShiftJis.colorItem}${item.info().name}${ShiftJis.colorClear} has insufficient capacity (Needed: ${ShiftJis.colorAug}${needed}${ShiftJis.colorClear}).", ChatLogColor.Error)
    }

    private fun emitMaximumPotentialMessage(item: InventoryItem) {
        ChatLog("${ShiftJis.colorItem}${item.info().name}${ShiftJis.colorClear} would not gain any bonuses from this meld.", ChatLogColor.Error)
    }

    private fun drawPreviewItemWithBonuses(baseWeapon: InventoryItem, bonuses: Map<ItemAugmentId, Int>) {
        val description = GameV0Helpers.getItemDescriptionInternal(inventoryItem = baseWeapon, meldBonuses = bonuses)
        InventoryUi.drawSelectedInventoryItem(baseWeapon, itemDescription = description)
    }

    fun getDescriptionWithMaxes(inventoryItem: InventoryItem): InventoryItemDescription {
        return GameV0Helpers.getItemDescriptionInternal(inventoryItem = inventoryItem, includeAllMeldCaps = true)
    }

}