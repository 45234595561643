package xim.poc.game.configuration.assetviewer

import xim.poc.game.StatusEffect
import xim.poc.game.configuration.SkillApplier
import xim.poc.game.configuration.SkillAppliers
import xim.poc.game.configuration.SkillType
import kotlin.time.Duration.Companion.seconds

object AssetViewerItemDefinitions {

    fun register() {
        // Copse Candy
        SkillAppliers += SkillApplier(skillId = 6008, skillType = SkillType.Item, targetEvaluator = {
            val status = it.targetState.gainStatusEffect(StatusEffect.Costume, 60.seconds)
            status.counter = 0x9E2
            emptyList()
        })
    }

}