package xim.poc.game.configuration.v0

import xim.poc.game.ItemAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.bonusTpAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.conserveTpAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.critHitDamageAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.critHitRateAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.critHitTpAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.doubleAttackAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.doubleDamageAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.elementalWeaponSkillAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.followUpAttackAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.magicAttackBonusAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.quadrupleAttackAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.skillChainDamageAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.storeTpAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.tripleAttackAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.weaponDamageAugmentId
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.weaponSkillDamageAugmentId
import xim.poc.game.configuration.v0.ItemDefinitions.wdmg
import kotlin.math.floor
import kotlin.math.roundToInt

object ItemBuildUpDefinitions {

    fun buildUpRequirements(tier: Int, weaponPath: WeaponPath): ItemBuildUpDefinition {
        val importantAugments = when (weaponPath) {
            WeaponPath.WeaponSkill -> listOf(weaponDamageAugmentId, weaponSkillDamageAugmentId, doubleDamageAugmentId, followUpAttackAugmentId)
            WeaponPath.Crit -> listOf(weaponDamageAugmentId, critHitRateAugmentId, critHitDamageAugmentId, critHitTpAugmentId)
            WeaponPath.Magic -> listOf(weaponDamageAugmentId, magicAttackBonusAugmentId, skillChainDamageAugmentId, elementalWeaponSkillAugmentId)
            WeaponPath.MultiAttack -> listOf(weaponDamageAugmentId, doubleAttackAugmentId, tripleAttackAugmentId, quadrupleAttackAugmentId)
            WeaponPath.Tp -> listOf(weaponDamageAugmentId, storeTpAugmentId, conserveTpAugmentId, bonusTpAugmentId)
        }

        val requirements = meldCaps(tier - 1, weaponPath)
            .filterKeys { importantAugments.contains(it) }
            .mapValues { floor(it.value * 0.9f).roundToInt() }

        return ItemBuildUpDefinition(requirements)
    }

    fun meldCaps(tier: Int, weaponPath: WeaponPath): Map<ItemAugmentId, Int> {
        val damageCap = mapOf(weaponDamageAugmentId to 2 * wdmg(tier))

        return damageCap + when (weaponPath) {
            WeaponPath.WeaponSkill -> basicMeldCapsWs(tier) + advancedMeldCapsWs(tier)
            WeaponPath.Crit -> basicMeldCapsCrit(tier) + advancedMeldCapsCrit(tier)
            WeaponPath.Magic -> basicMeldCapsMagic(tier) + advancedMeldCapsMagic(tier)
            WeaponPath.MultiAttack -> basicMeldCapsMultiAttack(tier) + advancedMeldCapsMultiAttack(tier)
            WeaponPath.Tp -> basicMeldCapsTp(tier) + advancedMeldCapsTp(tier)
        }
    }

    private fun basicMeldCapsTp(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            1 -> mapOf(doubleAttackAugmentId to 3, storeTpAugmentId to 3, weaponSkillDamageAugmentId to 3, magicAttackBonusAugmentId to 3, critHitRateAugmentId to 3)
            2 -> mapOf(doubleAttackAugmentId to 5, storeTpAugmentId to 5, weaponSkillDamageAugmentId to 5, magicAttackBonusAugmentId to 5, critHitRateAugmentId to 5)
            3 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 10, weaponSkillDamageAugmentId to 5, magicAttackBonusAugmentId to 5, critHitRateAugmentId to 5)
            4 -> mapOf(doubleAttackAugmentId to 10, storeTpAugmentId to 15, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            5 -> mapOf(doubleAttackAugmentId to 12, storeTpAugmentId to 20, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            6 -> mapOf(doubleAttackAugmentId to 14, storeTpAugmentId to 25, weaponSkillDamageAugmentId to 7, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 7)
            7 -> mapOf(doubleAttackAugmentId to 14, storeTpAugmentId to 30, weaponSkillDamageAugmentId to 7, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 7)
            8 -> mapOf(doubleAttackAugmentId to 16, storeTpAugmentId to 35, weaponSkillDamageAugmentId to 8, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 8)
            9 -> mapOf(doubleAttackAugmentId to 16, storeTpAugmentId to 40, weaponSkillDamageAugmentId to 8, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 8)
            10 -> mapOf(doubleAttackAugmentId to 18, storeTpAugmentId to 45, weaponSkillDamageAugmentId to 9, magicAttackBonusAugmentId to 9, critHitRateAugmentId to 9)
            11 -> mapOf(doubleAttackAugmentId to 20, storeTpAugmentId to 50, weaponSkillDamageAugmentId to 10, magicAttackBonusAugmentId to 10, critHitRateAugmentId to 10)
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

    private fun advancedMeldCapsTp(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            4 -> mapOf(conserveTpAugmentId to 5)
            5 -> mapOf(conserveTpAugmentId to 11)
            6 -> mapOf(conserveTpAugmentId to 18)
            7 -> mapOf(conserveTpAugmentId to 24)
            8 -> mapOf(conserveTpAugmentId to 31, bonusTpAugmentId to 125)
            9 -> mapOf(conserveTpAugmentId to 37, bonusTpAugmentId to 250)
            10 -> mapOf(conserveTpAugmentId to 44, bonusTpAugmentId to 375)
            11 -> mapOf(conserveTpAugmentId to 50, bonusTpAugmentId to 500)
            else -> emptyMap()
        }
    }

    private fun basicMeldCapsWs(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            1 -> mapOf(doubleAttackAugmentId to 3, storeTpAugmentId to 3, weaponSkillDamageAugmentId to 3, magicAttackBonusAugmentId to 3, critHitRateAugmentId to 3)
            2 -> mapOf(doubleAttackAugmentId to 5, storeTpAugmentId to 5, weaponSkillDamageAugmentId to 5, magicAttackBonusAugmentId to 5, critHitRateAugmentId to 5)
            3 -> mapOf(doubleAttackAugmentId to 5, storeTpAugmentId to 5, weaponSkillDamageAugmentId to 10, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            4 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 15, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 10)
            5 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 20, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 12)
            6 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 25, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 14)
            7 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 30, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 14)
            8 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 35, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 16)
            9 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 40, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 16)
            10 -> mapOf(doubleAttackAugmentId to 9, storeTpAugmentId to 9, weaponSkillDamageAugmentId to 45, magicAttackBonusAugmentId to 9, critHitRateAugmentId to 18)
            11 -> mapOf(doubleAttackAugmentId to 10, storeTpAugmentId to 10, weaponSkillDamageAugmentId to 50, magicAttackBonusAugmentId to 10, critHitRateAugmentId to 20)
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

    private fun advancedMeldCapsWs(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            4 -> mapOf(doubleDamageAugmentId to 5)
            5 -> mapOf(doubleDamageAugmentId to 11)
            6 -> mapOf(doubleDamageAugmentId to 18)
            7 -> mapOf(doubleDamageAugmentId to 24)
            8 -> mapOf(doubleDamageAugmentId to 31, followUpAttackAugmentId to 25)
            9 -> mapOf(doubleDamageAugmentId to 37, followUpAttackAugmentId to 50)
            10 -> mapOf(doubleDamageAugmentId to 44, followUpAttackAugmentId to 75)
            11 -> mapOf(doubleDamageAugmentId to 50, followUpAttackAugmentId to 100)
            else -> emptyMap()
        }
    }

    private fun basicMeldCapsCrit(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            3 -> mapOf(doubleAttackAugmentId to 5, storeTpAugmentId to 5, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            4 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 10, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 15)
            5 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 12, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 20)
            6 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 14, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 25)
            7 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 14, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 30)
            8 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 16, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 35)
            9 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 16, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 40)
            10 -> mapOf(doubleAttackAugmentId to 9, storeTpAugmentId to 9, weaponSkillDamageAugmentId to 18, magicAttackBonusAugmentId to 9, critHitRateAugmentId to 45)
            11 -> mapOf(doubleAttackAugmentId to 10, storeTpAugmentId to 10, weaponSkillDamageAugmentId to 20, magicAttackBonusAugmentId to 10, critHitRateAugmentId to 50)
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

    private fun advancedMeldCapsCrit(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            4 -> mapOf(critHitDamageAugmentId to 12)
            5 -> mapOf(critHitDamageAugmentId to 25)
            6 -> mapOf(critHitDamageAugmentId to 37)
            7 -> mapOf(critHitDamageAugmentId to 50)
            8 -> mapOf(critHitDamageAugmentId to 62, critHitTpAugmentId to 25)
            9 -> mapOf(critHitDamageAugmentId to 75, critHitTpAugmentId to 50)
            10 -> mapOf(critHitDamageAugmentId to 87, critHitTpAugmentId to 75)
            11 -> mapOf(critHitDamageAugmentId to 100, critHitTpAugmentId to 100)
            else -> emptyMap()
        }
    }

    private fun basicMeldCapsMagic(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            3 -> mapOf(doubleAttackAugmentId to 5, storeTpAugmentId to 5, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            4 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 10, magicAttackBonusAugmentId to 15, critHitRateAugmentId to 6)
            5 -> mapOf(doubleAttackAugmentId to 6, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 12, magicAttackBonusAugmentId to 20, critHitRateAugmentId to 6)
            6 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 14, magicAttackBonusAugmentId to 25, critHitRateAugmentId to 7)
            7 -> mapOf(doubleAttackAugmentId to 7, storeTpAugmentId to 7, weaponSkillDamageAugmentId to 14, magicAttackBonusAugmentId to 30, critHitRateAugmentId to 7)
            8 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 16, magicAttackBonusAugmentId to 35, critHitRateAugmentId to 8)
            9 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 16, magicAttackBonusAugmentId to 40, critHitRateAugmentId to 8)
            10 -> mapOf(doubleAttackAugmentId to 9, storeTpAugmentId to 9, weaponSkillDamageAugmentId to 18, magicAttackBonusAugmentId to 45, critHitRateAugmentId to 9)
            11 -> mapOf(doubleAttackAugmentId to 10, storeTpAugmentId to 10, weaponSkillDamageAugmentId to 20, magicAttackBonusAugmentId to 50, critHitRateAugmentId to 10)
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

    private fun advancedMeldCapsMagic(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            4 -> mapOf(skillChainDamageAugmentId to 5)
            5 -> mapOf(skillChainDamageAugmentId to 11)
            6 -> mapOf(skillChainDamageAugmentId to 18)
            7 -> mapOf(skillChainDamageAugmentId to 24)
            8 -> mapOf(skillChainDamageAugmentId to 31, elementalWeaponSkillAugmentId to 25)
            9 -> mapOf(skillChainDamageAugmentId to 37, elementalWeaponSkillAugmentId to 50)
            10 -> mapOf(skillChainDamageAugmentId to 44, elementalWeaponSkillAugmentId to 75)
            11 -> mapOf(skillChainDamageAugmentId to 50, elementalWeaponSkillAugmentId to 100)
            else -> emptyMap()
        }
    }

    private fun basicMeldCapsMultiAttack(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            1 -> mapOf(doubleAttackAugmentId to 3, storeTpAugmentId to 3, weaponSkillDamageAugmentId to 3, magicAttackBonusAugmentId to 3, critHitRateAugmentId to 3)
            2 -> mapOf(doubleAttackAugmentId to 8, storeTpAugmentId to 6, weaponSkillDamageAugmentId to 4, magicAttackBonusAugmentId to 4, critHitRateAugmentId to 4)
            3 -> mapOf(doubleAttackAugmentId to 10, storeTpAugmentId to 8, weaponSkillDamageAugmentId to 5, magicAttackBonusAugmentId to 5, critHitRateAugmentId to 5)
            4 -> mapOf(doubleAttackAugmentId to 15, storeTpAugmentId to 10, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            5 -> mapOf(doubleAttackAugmentId to 20, storeTpAugmentId to 12, weaponSkillDamageAugmentId to 6, magicAttackBonusAugmentId to 6, critHitRateAugmentId to 6)
            6 -> mapOf(doubleAttackAugmentId to 25, storeTpAugmentId to 14, weaponSkillDamageAugmentId to 7, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 7)
            7 -> mapOf(doubleAttackAugmentId to 30, storeTpAugmentId to 14, weaponSkillDamageAugmentId to 7, magicAttackBonusAugmentId to 7, critHitRateAugmentId to 7)
            8 -> mapOf(doubleAttackAugmentId to 35, storeTpAugmentId to 16, weaponSkillDamageAugmentId to 8, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 8)
            9 -> mapOf(doubleAttackAugmentId to 40, storeTpAugmentId to 16, weaponSkillDamageAugmentId to 8, magicAttackBonusAugmentId to 8, critHitRateAugmentId to 8)
            10 -> mapOf(doubleAttackAugmentId to 45, storeTpAugmentId to 18, weaponSkillDamageAugmentId to 9, magicAttackBonusAugmentId to 9, critHitRateAugmentId to 9)
            11 -> mapOf(doubleAttackAugmentId to 50, storeTpAugmentId to 20, weaponSkillDamageAugmentId to 10, magicAttackBonusAugmentId to 10, critHitRateAugmentId to 10)
            else -> throw IllegalStateException("Undefined tier: $tier")
        }
    }

    private fun advancedMeldCapsMultiAttack(tier: Int): Map<ItemAugmentId, Int> {
        return when (tier) {
            4 -> mapOf(tripleAttackAugmentId to 4)
            5 -> mapOf(tripleAttackAugmentId to 7)
            6 -> mapOf(tripleAttackAugmentId to 10)
            7 -> mapOf(tripleAttackAugmentId to 13)
            8 -> mapOf(tripleAttackAugmentId to 16, quadrupleAttackAugmentId to 4)
            9 -> mapOf(tripleAttackAugmentId to 19, quadrupleAttackAugmentId to 6)
            10 -> mapOf(tripleAttackAugmentId to 22, quadrupleAttackAugmentId to 8)
            11 -> mapOf(tripleAttackAugmentId to 25, quadrupleAttackAugmentId to 10)
            else -> emptyMap()
        }
    }

}