package xim.poc.browser;

import xim.math.Vector2f

class TouchData(
    val startTime: Double,
    val startingX: Double,
    val startingY: Double,
    val normalizedStartingX: Double,
    val normalizedStartingY: Double,
    val mouseEvent: Boolean,
    val rightClick: Boolean = false,
) {

    var normalizedX = normalizedStartingX
    var normalizedY = normalizedStartingY

    private var prevNormalizedX = normalizedStartingX
    private var prevNormalizedY = normalizedStartingY

    private var frameDeltaX = 0.0
    private var frameDeltaY = 0.0

    fun getDeltaFromStart(): Pair<Double, Double> {
        return (normalizedX - normalizedStartingX to normalizedY - normalizedStartingY)
    }

    fun getFrameDelta(): Pair<Double, Double> {
        return (frameDeltaX to frameDeltaY)
    }

    fun onNewFrame() {
        frameDeltaX = normalizedX - prevNormalizedX
        frameDeltaY = normalizedY - prevNormalizedY

        prevNormalizedX = normalizedX
        prevNormalizedY = normalizedY
    }

    fun isScreenTouch(): Boolean {
        return !mouseEvent
    }

    fun isControlTouch(): Boolean {
        return isScreenTouch() && normalizedStartingX > 0.5
    }

}

data class ClickEvent(val clickTime: Double, val screenPosition: Vector2f, val normalizedScreenPosition: Vector2f, val rightClick: Boolean) {

    var consumed = false


    fun isLongClick(): Boolean {
        return clickTime > 250
    }

}

enum class WheelDirection {
    Up,
    Down,
}

data class WheelData(val direction: WheelDirection)

interface Keyboard {

    enum class KeyState {
        PRESSED,
        RELEASED,
        REPEATED,
        NONE;
    }

    enum class KeyModifier {
        Ctrl,
        Alt,
        Shift,
    }

    enum class Key {
        LEFT,
        RIGHT,
        UP,
        DOWN,

        W,
        A,
        S,
        D,
        C,
        H,
        K,
        M,
        F,
        G,
        P,
        Z,

        R,
        ZOOM_IN,
        ZOOM_OUT,
        SPACE,
        SHIFT,

        TAB,
        ESC,
        ENTER,

        MINUS,

        F1,
        F2,
        F3,
        F4,
        F5,
        F6,

        N0,
        N1,
        N2,
        N3,
        N4,
        N5,
        N6,
        N7,
        N8,
        N9,
        ;
    }

    fun poll()

    fun clear()

    fun getKeyState(key: Key): KeyState

    fun isKeyPressed(key: Key): Boolean

    fun isKeyPressedOrRepeated(key: Key): Boolean

    fun isKeyReleased(key: Key): Boolean

    fun isKeyRepeated(key: Key): Boolean

    fun getKeyModifiers(key: Key): List<KeyModifier>

    fun getTouchData(): Collection<TouchData>

    fun getClickEvents(): List<ClickEvent>

    fun getWheelEvents(): List<WheelData>

    fun getPointerPosition(): Vector2f?

}
