package xim.poc.game.configuration.v0

import xim.poc.CustomModelSettings
import xim.poc.ModelLook
import xim.poc.NoOpActorController
import xim.poc.game.AutoAttackEffect
import xim.poc.game.CombatStats
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.MonsterAggroConfig
import xim.poc.game.configuration.MonsterDefinition
import xim.poc.game.configuration.standardBlurConfig
import xim.poc.game.configuration.v0.behaviors.*
import xim.poc.game.event.AttackAddedEffectType
import xim.poc.gl.ByteColor
import kotlin.math.roundToInt
import kotlin.random.Random

object V0MonsterDefinitions {

    val definitions: List<MonsterDefinition>

    init {
        definitions = ArrayList()

        definitions += MonsterDefinition(id = 1, "Tiny Mandragora", ModelLook.npc(0x012C), mobSkills = listOf(44, 45, 46, 48, 49, 50),
            baseDamage = 30,
            baseDelay = 180,
            baseLevel = 1,
            baseCombatStats = CombatStats(maxHp = 16, maxMp = 0, str = 8, vit = 8, int = 8, mnd = 8, agi = 5, dex = 5, chr = 10),
        )

        definitions += MonsterDefinition(id = 3, "Bumblebee", ModelLook.npc(0x0110), mobSkills = listOf(78, 79),
            baseDamage = 40,
            baseDelay = 180,
            baseLevel = 2,
            baseCombatStats = CombatStats(maxHp = 40, maxMp = 0, str = 10, vit = 8, int = 10, mnd = 10, agi = 6, dex = 6, chr = 1),
        )

        definitions += MonsterDefinition(id = 4, "Carrion Crow", ModelLook.npc(0x01BD), mobSkills = listOf(366, 367),
            baseDamage = 60,
            baseDelay = 210,
            baseLevel = 2,
            baseCombatStats = CombatStats(maxHp = 50, maxMp = 0, str = 10, vit = 10, int = 10, mnd = 8, agi = 8, dex = 8, chr = 1),
        )

        definitions += MonsterDefinition(id = 5, "Cluster", ModelLook.npc(0x0123), mobSkills = listOf(311, 312, 313, 314, 315, 316, 317, 318, 319),
            behaviorId = ActorBehaviors.register(5) { MobClusterController(it) },
            baseDamage = 80,
            baseDelay = 240,
            baseLevel = 4,
            rewardScale = 2f,
            aggroConfig = MonsterAggroConfig.compose(MonsterAggroConfig.standardMagicAggro, MonsterAggroConfig.standardSightAggro),
            baseCombatStats = CombatStats(maxHp = 400, maxMp = 100, str = 12, vit = 10, int = 14, mnd = 10, agi = 10, dex = 10, chr = 1),
        )

        definitions += MonsterDefinition(id = 7, "Colkhab", ModelLook.npc(0x09C4),
            behaviorId = ActorBehaviors.register(7) { MobColkhabController(it) },
            baseDamage = 110,
            baseDelay = 180,
            baseLevel = 5,
            rewardScale = 10f,
            movementControllerFn = { MobColkhabMovementController() },
            baseCombatStats = CombatStats(maxHp = 1500, maxMp = 1000, str = 18, vit = 15, int = 15, mnd = 15, agi = 20, dex = 20, chr = 1),
        )

        definitions += MonsterDefinition(id = 8, "Waggling Wasp", ModelLook.npc(0x0111), mobSkills = listOf(80),
            behaviorId = ActorBehaviors.register(8) { MobColkhabBeeController(it) },
            baseDamage = 80,
            baseDelay = 210,
            baseLevel = 5,
            rewardScale = 0f,
            baseCombatStats = CombatStats(maxHp = 80, maxMp = 100, str = 14, vit = 14, int = 10, mnd = 10, agi = 20, dex = 20, chr = 1),
        )

        definitions += MonsterDefinition(id = 9, "River Crab", ModelLook.npc(0x0164), mobSkills = listOf(186, 187, 188, 189, 192),
            baseDamage = 60,
            baseDelay = 300,
            baseLevel = 2,
            aggroConfig = MonsterAggroConfig.standardSoundAggro,
            baseCombatStats = CombatStats(maxHp = 80, maxMp = 100, str = 10, vit = 12, int = 8, mnd = 12, agi = 5, dex = 8, chr = 1),
        )

        definitions += MonsterDefinition(id = 10, "Cherry Tree", ModelLook.npc(0x0186), mobSkills = emptyList(),
            behaviorId = ActorBehaviors.register(10) { MobCherryTreeBehavior(it) },
            baseDamage = 65,
            baseDelay = 300,
            baseLevel = 3,
            rewardScale = 5f,
            baseCombatStats = CombatStats(maxHp = 400, maxMp = 1000, str = 10, vit = 10, int = 14, mnd = 10, agi = 8, dex = 8, chr = 1),
            movementControllerFn = { NoOpActorController() }
        )

        definitions += MonsterDefinition(id = 11, "Cherry Sapling", ModelLook.npc(0x0188), mobSkills = listOf(430),
            behaviorId = ActorBehaviors.register(11) { MobCherrySaplingBehavior(it) },
            baseDamage = 50,
            baseDelay = 300,
            baseLevel = 2,
            rewardScale = 0f,
            baseCombatStats = CombatStats(maxHp = 40, maxMp = 0, str = 8, vit = 8, int = 8, mnd = 8, agi = 8, dex = 8, chr = 1),
        )

        definitions += MonsterDefinition(id = 12, "Pugil", ModelLook.npc(0x015C), mobSkills = listOf(193, 195, 196, 197),
            baseDamage = 65,
            baseDelay = 240,
            baseLevel = 2,
            aggroConfig = MonsterAggroConfig.standardSightAggro,
            baseCombatStats = CombatStats(maxHp = 80, maxMp = 0, str = 12, vit = 8, int = 12, mnd = 8, agi = 12, dex = 10, chr = 1),
        )

        definitions += MonsterDefinition(id = 13, "Wild Sheep", ModelLook.npc(0x0154), mobSkills = emptyList(),
            behaviorId = ActorBehaviors.register(13) { MobSheepBehavior(it) },
            baseDamage = 90,
            baseDelay = 300,
            baseLevel = 4,
            baseCombatStats = CombatStats(maxHp = 200, maxMp = 0, str = 14, vit = 12, int = 10, mnd = 10, agi = 10, dex = 10, chr = 1),
        )

        definitions += MonsterDefinition(id = 14, "Gadfly", ModelLook.npc(0x01C0), mobSkills = listOf(62, 403, 404),
            baseDamage = 75,
            baseDelay = 200,
            baseLevel = 4,
            baseCombatStats = CombatStats(maxHp = 135, maxMp = 100, str = 12, vit = 12, int = 14, mnd = 13, agi = 15, dex = 12, chr = 1),
        )

        definitions += MonsterDefinition(id = 15, "Lapinion", ModelLook.npc(0x0791), mobSkills = listOf(1, 2, 3),
            baseDamage = 70,
            baseDelay = 180,
            baseLevel = 4,
            baseCombatStats = CombatStats(maxHp = 110, maxMp = 0, str = 12, vit = 12, int = 12, mnd = 14, agi = 14, dex = 14, chr = 1),
        )

        definitions += MonsterDefinition(id = 16, "Leech", ModelLook.npc(0x0114), mobSkills = listOf(158, 159, 161, 162, 165),
            baseDamage = 70,
            baseDelay = 240,
            baseLevel = 3,
            baseCombatStats = CombatStats(maxHp = 100, maxMp = 100, str = 12, vit = 12, int = 12, mnd = 12, agi = 12, dex = 12, chr = 1),
        )

        definitions += MonsterDefinition(id = 17, "Blanched Mandy", ModelLook.npc(0x012C), mobSkills = listOf(44, 45, 46, 48, 49, 50),
            baseDamage = 65,
            baseDelay = 180,
            baseLevel = 4,
            baseCombatStats = CombatStats(maxHp = 120, maxMp = 0, str = 10, vit = 13, int = 10, mnd = 10, agi = 12, dex = 12, chr = 10),
            baseBonusApplier = { it.guardRate = 20 },
        )

        definitions += MonsterDefinition(id = 18, "Belaboring Wasp", ModelLook.npc(0x0111), mobSkills = listOf(78, 79, 80),
            baseDamage = 80,
            baseDelay = 210,
            baseLevel = 4,
            aggroConfig = MonsterAggroConfig.standardSightAggro,
            baseCombatStats = CombatStats(maxHp = 150, maxMp = 0, str = 12, vit = 12, int = 12, mnd = 14, agi = 18, dex = 16, chr = 1),
        )

        definitions += MonsterDefinition(id = 19, "Kraken", ModelLook.npc(0x0160),
            mobSkills = listOf(200, 202, 202, 204, 204, 205, 206, 206, 207, 207),
            baseDamage = 70,
            baseDelay = 240,
            baseLevel = 7,
            aggroConfig = MonsterAggroConfig.standardSoundAggro,
            rewardScale = 5f,
            baseBonusApplier = {
                it.doubleAttack += 100
                it.subtleBlow += 25
            },
            baseCombatStats = CombatStats(maxHp = 1250, maxMp = 100, str = 20, vit = 20, int = 20, mnd = 20, agi = 20, dex = 20, chr = 1),
        )

        definitions += MonsterDefinition(id = 20, "Eft", ModelLook.npc(0x0545), mobSkills = listOf(259, 260, 261, 262, 263),
            baseDamage = 90,
            baseDelay = 240,
            baseLevel = 6,
            baseCombatStats = CombatStats(maxHp = 300, maxMp = 100, str = 17, vit = 17, int = 15, mnd = 15, agi = 18, dex = 20, chr = 1),
        )

        definitions += MonsterDefinition(id = 21, "Jagil", ModelLook.npc(0x015D), mobSkills = listOf(193, 195, 197, 385),
            baseDamage = 100,
            baseDelay = 240,
            baseLevel = 6,
            aggroConfig = MonsterAggroConfig.standardSightAggro,
            baseCombatStats = CombatStats(maxHp = 300, maxMp = 0, str = 20, vit = 16, int = 18, mnd = 12, agi = 18, dex = 18, chr = 1),
        )

        definitions += MonsterDefinition(id = 22, "Opo-opo", ModelLook.npc(0x01A0), mobSkills = listOf(32,34,35,36,38,39),
            baseDamage = 105,
            baseDelay = 210,
            baseLevel = 7,
            baseCombatStats = CombatStats(maxHp = 380, maxMp = 0, str = 21, vit = 20, int = 15, mnd = 20, agi = 24, dex = 24, chr = 1),
        )

        definitions += MonsterDefinition(id = 23, "Clot", ModelLook.npc(0x0126), mobSkills = listOf(175, 177, 1061, 1063),
            baseDamage = 120,
            baseDelay = 300,
            baseLevel = 7,
            aggroConfig = MonsterAggroConfig.standardSoundAggro,
            baseCombatStats = CombatStats(maxHp = 350, maxMp = 100, str = 18, vit = 20, int = 22, mnd = 14, agi = 18, dex = 18, chr = 1),
            baseBonusApplier = {
                it.physicalDamageTaken -= 50
                it.autoAttackEffects += AutoAttackEffect(effectPower = 3, effectType = AttackAddedEffectType.Drain)
            },
        )

        definitions += MonsterDefinition(id = 24, "Uragnite", ModelLook.npc(0x0551), mobSkills = listOf(1316, 1318, 1319),
            behaviorId = ActorBehaviors.register(24) { MobUragniteController(it) },
            baseDamage = 110,
            baseDelay = 300,
            baseLevel = 7,
            rewardScale = 2f,
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 100, str = 18, vit = 20, int = 22, mnd = 16, agi = 16, dex = 16, chr = 1),
        )

        definitions += MonsterDefinition(id = 25, "Warmachine", ModelLook.npc(0x01AC), mobSkills = listOf(382, 382, 382, 381, 383),
            behaviorId = ActorBehaviors.register(25) { MobWarmachineController(it) },
            baseDamage = 100,
            baseDelay = 400,
            baseLevel = 8,
            rewardScale = 5f,
            baseCombatStats = CombatStats(maxHp = 3000, maxMp = 100, str = 24, vit = 24, int = 20, mnd = 20, agi = 23, dex = 23, chr = 1),
        )

        definitions += MonsterDefinition(id = 26, "Explosive", ModelLook.npc(0x0B63), mobSkills = listOf(),
            behaviorId = ActorBehaviors.register(26) { MobWarmachineExplosiveController(it) },
            movementControllerFn = { NoOpActorController() },
            baseDamage = 180,
            baseDelay = 400,
            baseLevel = 8,
            rewardScale = 0f,
            baseCombatStats = CombatStats(maxHp = 2500, maxMp = 100, str = 24, vit = 24, int = 20, mnd = 20, agi = 23, dex = 23, chr = 1),
        )

        definitions += MonsterDefinition(id = 27, "Knotted Roots", ModelLook.npc(0x0AEA), mobSkills = listOf(),
            behaviorId = ActorBehaviors.register(behaviorId = 27) { MobKnottedRootsBehavior(it) },
            movementControllerFn = { NoOpActorController() },
            staticPosition = true,
            baseDamage = 0,
            baseDelay = 400,
            baseLevel = 8,
            rewardScale = 0f,
            baseCombatStats = CombatStats(maxHp = 2000, maxMp = 100, str = 20, vit = 20, int = 20, mnd = 20, agi = 20, dex = 20, chr = 20),
        )

        definitions += MonsterDefinition(id = 28, "Alraune", ModelLook.npc(0x012D), mobSkills = listOf(44, 45, 46, 48, 49, 50),
            baseDamage = 100,
            baseDelay = 210,
            baseLevel = 7,
            baseCombatStats = CombatStats(maxHp = 360, maxMp = 0, str = 18, vit = 22, int = 16, mnd = 20, agi = 22, dex = 20, chr = 1),
            baseBonusApplier = { it.guardRate = 20 },
        )

        definitions += MonsterDefinition(id = 29, "Tchakka", ModelLook.npc(0x09C9), mobSkills = emptyList(),
            behaviorId = ActorBehaviors.register(29) { MobTchakkaController(it) },
            baseDamage = 140,
            baseDelay = 210,
            baseLevel = 10,
            rewardScale = 10f,
            baseCombatStats = CombatStats(maxHp = 4250, maxMp = 1000, str = 30, vit = 32, int = 28, mnd = 28, agi = 32, dex = 32, chr = 1),
        )

        definitions += MonsterDefinition(id = 30, "Hoary Craklaw", ModelLook.npc(0x09FB), mobSkills = listOf(2701, 2702, 2703, 2704),
            behaviorId = ActorBehaviors.register(30) { MobCraklawController(it) },
            baseDamage = 160,
            baseDelay = 300,
            baseLevel = 9,
            rewardScale = 2f,
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 100, str = 26, vit = 32, int = 22, mnd = 24, agi = 18, dex = 24, chr = 1),
        )

        definitions += MonsterDefinition(id = 31, "Careening Twitherym", ModelLook.npc(0x09E7), mobSkills = listOf(2694, 2695, 2696),
            baseDamage = 135,
            baseDelay = 210,
            baseLevel = 9,
            baseCombatStats = CombatStats(maxHp = 550, maxMp = 0, str = 24, vit = 22, int = 26, mnd = 22, agi = 26, dex = 24, chr = 1),
        )

        definitions += MonsterDefinition(id = 32, "Leafdancer Twitherym", ModelLook.npc(0x09E8), mobSkills = listOf(2694, 2695, 2696),
            baseDamage = 125,
            baseDelay = 210,
            baseLevel = 8,
            baseCombatStats = CombatStats(maxHp = 450, maxMp = 0, str = 22, vit = 20, int = 24, mnd = 20, agi = 24, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 33, name = "Duskprowlers", ModelLook.npc(0x0104), mobSkills = listOf(137, 139),
            baseDamage = 120,
            baseDelay = 180,
            baseLevel = 8,
            baseCombatStats = CombatStats(maxHp = 550, maxMp = 0, str = 22, vit = 20, int = 22, mnd = 18, agi = 24, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 34, "Blightdella", ModelLook.npc(0x078E), mobSkills = listOf(158, 159, 161, 162, 165),
            baseDamage = 140,
            baseDelay = 240,
            baseLevel = 8,
            baseCombatStats = CombatStats(maxHp = 625, maxMp = 100, str = 22, vit = 22, int = 22, mnd = 22, agi = 22, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 35, "Ripsaw Jagil", ModelLook.npc(0x015D), mobSkills = listOf(193, 195, 197, 385),
            baseDamage = 140,
            baseDelay = 240,
            baseLevel = 8,
            aggroConfig = MonsterAggroConfig.standardSightAggro,
            baseCombatStats = CombatStats(maxHp = 575, maxMp = 0, str = 24, vit = 20, int = 20, mnd = 18, agi = 22, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 36, "Tarichuk", ModelLook.npc(0x0546), mobSkills = listOf(259, 260, 261, 262, 263),
            baseDamage = 140,
            baseDelay = 240,
            baseLevel = 8,
            baseCombatStats = CombatStats(maxHp = 600, maxMp = 100, str = 23, vit = 22, int = 20, mnd = 20, agi = 22, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 37, "Perfidious Crab", ModelLook.npc(0x0164), mobSkills = listOf(186, 187, 188, 189, 192),
            baseDamage = 150,
            baseDelay = 300,
            baseLevel = 9,
            baseCombatStats = CombatStats(maxHp = 700, maxMp = 100, str = 24, vit = 26, int = 20, mnd = 26, agi = 16, dex = 22, chr = 1),
        )

        definitions += MonsterDefinition(id = 38, "Scummy Slug", ModelLook.npc(0x07E5), mobSkills = listOf(1927, 1928, 1929),
            baseDamage = 160,
            baseDelay = 300,
            baseLevel = 9,
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 0, str = 24, vit = 22, int = 26, mnd = 20, agi = 15, dex = 24, chr = 1),
        )

        definitions += MonsterDefinition(id = 39, "Polar Hare", ModelLook.npc(0x010E), mobSkills = listOf(1, 3, 67, 405),
            baseDamage = 130,
            baseDelay = 180,
            baseLevel = 10,
            baseCombatStats = CombatStats(maxHp = 600, maxMp = 0, str = 27, vit = 27, int = 25, mnd = 32, agi = 34, dex = 34, chr = 1),
        )

        definitions += MonsterDefinition(id = 40, "Ice Elemental", ModelLook.npc(0x01B5), mobSkills = emptyList(),
            behaviorId = ActorBehaviors.register(40) { MobElementalController(
                actorState = it,
                idleSpellList = listOf(250),
                engagedSpellList = listOf(149, 830),
            ) },
            baseDamage = 140,
            baseDelay = 240,
            baseLevel = 10,
            rewardScale = 2f,
            aggroConfig = MonsterAggroConfig.standardMagicAggro,
            baseCombatStats = CombatStats(maxHp = 400, maxMp = 400, str = 23, vit = 30, int = 40, mnd = 23, agi = 23, dex = 32, chr = 1),
            baseBonusApplier = {
                it.physicalDamageTaken -= 80
                it.regain += 10
            },
            onSpawn = {
                it.setTp(Random.nextDouble(200.0, 280.0).roundToInt())
            }
        )

        definitions += MonsterDefinition(id = 41, "Nival Raptor", ModelLook.npc(0x013C), mobSkills = listOf(118, 121, 121, 123, 124),
            baseDamage = 140,
            baseDelay = 210,
            baseLevel = 11,
            aggroConfig = MonsterAggroConfig.standardSoundAggro,
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 0, str = 33, vit = 27, int = 32, mnd = 28, agi = 36, dex = 32, chr = 1),
            baseBonusApplier = {
                it.movementSpeed += 25
            },
        )

        definitions += MonsterDefinition(id = 42, "Buffalo", ModelLook.npc(0x054D), mobSkills = listOf(237, 238, 239, 241),
            baseDamage = 180,
            baseDelay = 300,
            baseLevel = 11,
            rewardScale = 1.5f,
            aggroConfig = MonsterAggroConfig.standardSightAggro,
            baseCombatStats = CombatStats(maxHp = 1000, maxMp = 100, str = 30, vit = 34, int = 28, mnd = 32, agi = 24, dex = 28, chr = 1),
        )

        definitions += MonsterDefinition(id = 43, "Tiger", ModelLook.npc(0x0134), mobSkills = listOf(14, 15, 17),
            baseDamage = 160,
            baseDelay = 240,
            baseLevel = 11,
            aggroConfig = MonsterAggroConfig.standardSoundAggro,
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 0, str = 34, vit = 32, int = 30, mnd = 30, agi = 30, dex = 32, chr = 1),
            baseBonusApplier = {
                it.movementSpeed += 25
            },
        )

        definitions += MonsterDefinition(id = 44, "Snoll", ModelLook.npc(0x0116), mobSkills = listOf(270, 271, 272, 273),
            baseDamage = 140,
            baseDelay = 210,
            baseLevel = 11,
            aggroConfig = MonsterAggroConfig.compose(MonsterAggroConfig.standardMagicAggro, MonsterAggroConfig.standardSightAggro),
            baseCombatStats = CombatStats(maxHp = 800, maxMp = 100, str = 30, vit = 30, int = 36, mnd = 30, agi = 32, dex = 32, chr = 1),
        )

        definitions += MonsterDefinition(id = 45, "Snoll Tzar", ModelLook.npc(0x011B), mobSkills = listOf(),
            behaviorId = ActorBehaviors.register(45) { MobSnollTzarBehavior(it) },
            baseDamage = 160,
            baseDelay = 300,
            baseLevel = 12,
            rewardScale = 5f,
            aggroConfig = MonsterAggroConfig.compose(MonsterAggroConfig.standardMagicAggro, MonsterAggroConfig.standardSightAggro),
            baseCombatStats = CombatStats(maxHp = 5250, maxMp = 1000, str = 30, vit = 30, int = 36, mnd = 30, agi = 32, dex = 32, chr = 1),
        )

        definitions += MonsterDefinition(id = 46, "Wanderer", ModelLook.npc(0x0452), mobSkills = listOf(),
            behaviorId = ActorBehaviors.register(46) { MobWandererController(it) },
            baseDamage = 30,
            baseDelay = 180,
            baseLevel = 1,
            baseAppearanceState = 2,
            baseCombatStats = CombatStats(maxHp = 16, maxMp = 0, str = 8, vit = 8, int = 8, mnd = 8, agi = 5, dex = 5, chr = 10),
        )

        definitions += MonsterDefinition(id = 47, "Weeper", ModelLook.npc(0x0458), mobSkills = listOf(),
            behaviorId = ActorBehaviors.register(47) { MobWeeperController(it) },
            baseDamage = 30,
            baseDelay = 180,
            baseLevel = 1,
            baseAppearanceState = 2,
            baseCombatStats = CombatStats(maxHp = 1000, maxMp = 0, str = 32, vit = 32, int = 32, mnd = 32, agi = 32, dex = 32, chr = 32),
        )

        // === Debug
        definitions += MonsterDefinition(id = 1000, "Bob", ModelLook.npc(0x0B5C), mobSkills = emptyList(),
            behaviorId = ActorBehaviors.register(1000) { MobBobController(it) },
            baseDamage = 100,
            baseDelay = 240,
            baseCombatStats = CombatStats(maxHp = 1000000, maxMp = 100, str = 1500, vit = 1500, int = 1500, mnd = 1500, agi = 1500, dex = 1500, chr = 1500),
            customModelSettings = CustomModelSettings(blurConfig = standardBlurConfig(ByteColor(0x80, 0x00, 0x00, 0x20))),
            notoriousMonster = true,
        )

    }

}