package xim.poc.game.configuration.v0.interactions

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.v0.GameV0
import xim.poc.game.configuration.v0.GameV0SaveStateHelper
import xim.poc.game.configuration.v0.ItemAugmentDefinitions
import xim.poc.game.configuration.v0.ItemDropDefinition
import xim.resource.EquipSlot

object Debugger: NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        val cheatOptions = listOf(
            QueryMenuOption(text = "Level", value = 0),
            QueryMenuOption(text = "Armor", value = 1),
            QueryMenuOption(text = "Weapon", value = 2),
            QueryMenuOption(text = "Spells", value = 3),
            QueryMenuOption(text = "Forget spells", value = 4),
            QueryMenuOption(text = "Floor", value = 5),
        )

        UiStateHelper.openQueryMode(prompt = "Which cheat?", options = cheatOptions, callback = this::handleCheatResponse)
    }

    private fun handleCheatResponse(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null) { return QueryMenuResponse.pop }

        if (queryMenuOption.value == 0) {
            val levels = (1 until 51).map { QueryMenuOption("$it", it) }
            UiStateHelper.openQueryMode("What level?", options = levels, callback = this::handleLevelChoice)
        } else if (queryMenuOption.value == 1) {
            listOf(12523,12551,12679,12807,12935,13606,13215).map {
                    GameV0.generateItem(ItemDropDefinition(
                    itemId = it,
                    augmentRank = 30,
                    augmentRankMax = 30,
                ))
            }.forEach { ActorStateManager.player().inventory.addItem(it) }
            return QueryMenuResponse.pop
        } else if (queryMenuOption.value == 2) {
            generateWeapons()
            return QueryMenuResponse.pop
        } else if (queryMenuOption.value == 3) {
            learnSpells()
            return QueryMenuResponse.pop
        } else if (queryMenuOption.value == 4) {
            forgetSpells()
            return QueryMenuResponse.pop
        } else if (queryMenuOption.value == 5) {
            floorPrompt()
        }

        return QueryMenuResponse.noop
    }

    private fun handleLevelChoice(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null) { return QueryMenuResponse.pop }

        val mainJob = ActorStateManager.player().getMainJobLevel()
        val destination = queryMenuOption.value

        if (mainJob.level > destination) {
            MiscEffects.playEffect(ActorManager.player(), effect = MiscEffects.Effect.LevelDown)
        } else {
            MiscEffects.playEffect(ActorManager.player(), effect = MiscEffects.Effect.LevelUp)
        }

        mainJob.level = destination
        mainJob.exp = 0

        return QueryMenuResponse.popAll
    }

    private fun generateWeapons() {
        val inventory = ActorStateManager.player().inventory
        val weapons = ArrayList<InventoryItem>()

        // Tizona
        val tiz = GameV0.generateItem(ItemDropDefinition(itemId = 18986, augmentRank = 1, augmentRankMax = 30))
        val tizAugments = tiz.fixedAugments!!
        weapons += tiz

        tizAugments.getOrCreate(augmentId = 13, potency = 50)
        tizAugments.getOrCreate(augmentId = 31, potency = 25)
        tizAugments.getOrCreate(augmentId = 36, potency = 10)

        // Sequence
        val seq = GameV0.generateItem(ItemDropDefinition(itemId = 20695, augmentRank = 1, augmentRankMax = 30))
        val seqAugments = seq.fixedAugments!!
        weapons += seq

        seqAugments.getOrCreate(augmentId = 14, potency = 50)
        seqAugments.getOrCreate(augmentId = 33, potency = 50)
        seqAugments.getOrCreate(augmentId = 37, potency = 500)

        // Zomorrodnegar
        val zom = GameV0.generateItem(ItemDropDefinition(itemId = 21633, augmentRank = 1, augmentRankMax = 30))
        val zomAugments = zom.fixedAugments!!
        weapons += zom

        zomAugments.getOrCreate(augmentId = 16, potency = 50)
        zomAugments.getOrCreate(augmentId = 32, potency = 50)
        zomAugments.getOrCreate(augmentId = 38, potency = 50)

        // Fermion
        val fer = GameV0.generateItem(ItemDropDefinition(itemId = 20694, augmentRank = 1, augmentRankMax = 30))
        val ferAugments = fer.fixedAugments!!
        weapons += fer

        ferAugments.getOrCreate(augmentId = 30, potency = 50)
        ferAugments.getOrCreate(augmentId = 35, potency = 50)
        ferAugments.getOrCreate(augmentId = 39, potency = 100)

        // Almace
        val alm = GameV0.generateItem(ItemDropDefinition(itemId = 19399, augmentRank = 1, augmentRankMax = 30))
        val almAugments = alm.fixedAugments!!
        weapons += alm

        almAugments.getOrCreate(augmentId = 21, potency = 50)
        almAugments.getOrCreate(augmentId = 17, potency = 100)
        almAugments.getOrCreate(augmentId = 40, potency = 100)

        // Deduplicate
        weapons.filter { inventory.inventoryItems.none { w -> w.id == it.id } }
            .forEach { inventory.addItem(it) }
    }

    private fun learnSpells() {
        val player = ActorStateManager.player()

        listOf(549, 623, 521, 584, 596, 577, 519, 517, 530, 544, 605, 664, 513, 542, 618, 569, 701, 702, 651, 744, 745, 578, 587, 608, 535, 588)
            .forEach { player.learnedSpells.learnSpell(it) }
    }

    private fun forgetSpells() {
        val player = ActorStateManager.player()
        player.learnedSpells.spellIds.clear()
        for (i in player.learnedSpells.equippedSpells.indices) { player.learnedSpells.equippedSpells[i] = 0 }
    }

    private fun floorPrompt() {
        val floorOptions = (0 .. 20).map { it * 5 }.map { QueryMenuOption("$it", it) }

        UiStateHelper.openQueryMode("Which floor?", options = floorOptions, callback = {
            GameV0SaveStateHelper.getState().highestClearedFloor = it?.value ?: 0
            QueryMenuResponse.popAll
        })
    }

    private fun getSlotOptions(): List<QueryMenuOption> {
        return EquipSlot.values().map { QueryMenuOption(it.name, it.ordinal) }
    }

    private fun handleSlotResponse(option: QueryMenuOption?): QueryMenuResponse {
        if (option == null || option.value < 0) { return QueryMenuResponse.pop }

        val slot = EquipSlot.values()[option.value]

        UiStateHelper.openQueryMode(prompt = "Add which augment?", options = getOptions(slot), callback = { handleResponse(it, slot) })
        return QueryMenuResponse.noop
    }

    private fun getOptions(slot: EquipSlot): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        val equip = ActorStateManager.player().getEquipment(slot) ?: return emptyList()

        for ((key, value) in ItemAugmentDefinitions.definitions) {
            val bonus = value.valueFn.calculate(equip)
            options += QueryMenuOption("[$key]${value.attribute.toDescription(bonus)}", key)
        }

        return options
    }

    private fun handleResponse(option: QueryMenuOption?, equipSlot: EquipSlot): QueryMenuResponse {
        if (option == null || option.value < 0) { return QueryMenuResponse.pop }

        val player = ActorStateManager.player()
        val body = player.getEquipment(equipSlot) ?: return QueryMenuResponse.pop

        val current = body.augments ?: ItemAugment()

        if (current.augmentIds.size >= 3) {
            current.augmentIds[0] = option.value
        } else {
            current.augmentIds += option.value
        }

        body.augments = current
        return QueryMenuResponse.noop
    }

}