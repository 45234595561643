package xim.poc.game.configuration.v0

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.v0.events.InventoryItemScrapEvent
import xim.poc.ui.ChatLog
import xim.poc.ui.InventoryFilter
import xim.poc.ui.InventoryUi
import xim.poc.ui.ShiftJis
import xim.resource.DatId
import xim.resource.ItemListType

private object ScrapItemFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        val isArmor = inventoryItem.info().type == ItemListType.Armor
        return isArmor && inventoryItem.augments != null && !ActorStateManager.player().isEquipped(inventoryItem)
    }
}

object ScrapInventoryItemInteraction: NpcInteraction {

    private var describedSelf = false

    override fun onInteraction(npcId: ActorId) {
        if (!describedSelf) {
            describedSelf = true
            ChatLog("Equipment can be scrapped into upgrade-materials. The resulting quantity and potency of the upgrade-material depends on the item's level and ${ShiftJis.colorAug}rank${ShiftJis.colorClear}.")
        }

        UiStateHelper.openInventorySelectMode(ScrapItemFilter, this::handleSelectedItem)
    }

    private fun handleSelectedItem(inventoryItem: InventoryItem?): QueryMenuResponse {
        if (inventoryItem == null) { return QueryMenuResponse.popAll }

        val (itemInfo, itemQuantity) = GameV0Helpers.getScrapResult(inventoryItem)

        val itemName = if (itemQuantity > 1) { itemInfo.logNamePlural } else { itemInfo.logName }
        ChatLog("The ${ShiftJis.colorItem}${inventoryItem.info().logName}${ShiftJis.colorClear} ${ShiftJis.rightArrow} ${ShiftJis.colorItem}x${itemQuantity} ${itemName}${ShiftJis.colorClear}.")

        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption(text = "Yes.", 0)
        options += QueryMenuOption(text = "No.", -1)

        UiStateHelper.openQueryMode(
            prompt = "Scrap this item to receive upgrade-materials?",
            options = options,
            drawFn = { drawSelectedItem(inventoryItem) }
        ) { handleConfirmation(it, inventoryItem) }
        return QueryMenuResponse.noop
    }

    private fun drawSelectedItem(inventoryItem: InventoryItem) {
        InventoryUi.drawSelectedInventoryItem(inventoryItem)
    }

    private fun handleConfirmation(queryMenuOption: QueryMenuOption?, inventoryItem: InventoryItem): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value < 0)  { return QueryMenuResponse.pop }

        GameEngine.submitEvent(InventoryItemScrapEvent(ActorStateManager.playerId, inventoryItem.internalId))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1347, DatId.synthesisNq)

        return QueryMenuResponse.popAll
    }

}
