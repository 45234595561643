package xim.poc.game.configuration.v0.events

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.CapacityAugment
import xim.poc.game.InternalItemId
import xim.poc.game.configuration.v0.GameV0Helpers
import xim.poc.game.configuration.v0.ItemAugmentDefinitions.weaponDamageAugmentId
import xim.poc.game.event.Event

class InventoryItemMeldWeaponEvent(
    val sourceId: ActorId,
    val targetItemId: InternalItemId,
    val upgradeItemId: InternalItemId,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()

        val targetItem = source.inventory.getByInternalId(targetItemId) ?: return emptyList()
        val fixedAugments = targetItem.fixedAugments ?: return emptyList()

        // Validate resources
        val upgradeItem = source.inventory.getByInternalId(upgradeItemId) ?: return emptyList()
        val capacityConsumed = upgradeItem.augments?.rankLevel ?: return emptyList()
        if (fixedAugments.capacityRemaining < capacityConsumed) { return emptyList() }

        // Consume resources
        source.inventory.discardItem(upgradeItemId)
        fixedAugments.capacityRemaining -= capacityConsumed

        // Apply bonus
        GameV0Helpers.applyWeaponMeldBonus(targetWeapon = targetItem, weaponUpgradeMaterial = upgradeItem)

        return emptyList()
    }

}