package xim.poc.game

enum class AugmentId(val id: Int) {
    HP(9),
    MP(10),
    STR(11),
    DEX(12),
    VIT(13),
    AGI(14),
    INT(15),
    MND(16),
    CHR(17),

    CriticalHitRate(26),
    EnemyCriticalHitRate(27),

    WeaponDamage(30),

    Haste(32),

    SpellInterruptDown(36),
    PhysicalDamageTaken(37),
    MagicalDamageTaken(38),
    DamageTaken(41),

    TpBonus(53),
    ParryingRate(54),

    MagicAttackBonus(133),

    Regen(137),
    Refresh(138),

    FastCast(140),
    StoreTp(142),
    DoubleAttack(143),
    TripleAttack(144),
    DualWield(146),

    SubtleBlow(195),

    WeaponSkillDamage(327),
    CriticalHitDamage(328),
    SkillChainDamage(332),
    ConserveTp(333),
    MagicBurstDamage(334),
    QuadrupleAttack(354),

    Blank(1023),

    CriticalTpGain(1283),
    ElementalWeaponSkillDamage(1293),
    DoubleDamage(1296),
    FollowUpAttack(1298),

    AlterEgo(2042),
}

enum class StatusEffect(val id: Int, val canDispel: Boolean = false) {

    Sleep(2),
    Poison(3),
    Paralysis(4),
    Blind(5),
    Silence(6),
    Stun(10),
    Bind(11),
    Weight(12),
    Slow(13),
    Plague(31),

    Haste(33, canDispel = true),
    BlazeSpikes(34, canDispel = true),
    IceSpikes(35, canDispel = true),
    Blink(36, canDispel = true),
    Stoneskin(37, canDispel = true),
    Aquaveil(39, canDispel = true),
    Regen(42, canDispel = true),

    Berserk(56, canDispel = true),

    AttackBoost(91, canDispel = true),
    DefenseBoost(93, canDispel = true),
    Enfire(94, canDispel = true),
    Enblizzard(95, canDispel = true),

    Costume(127),

    Burn(128),
    Frost(129),
    Choke(130),

    Dia(134),
    Bio(135),

    StrDown(136),
    DexDown(137),
    VitDown(138),
    MndDown(141),
    HPDown(144),

    AttackDown(147),
    DefenseDown(149),

    MagicDefDown(167),
    MagicAtkDown(175),

    MagicAtkBoost(190, canDispel = true),
    MagicDefBoost(191, canDispel = true),

    Spontaneity(230),

    Mounted(252),

    Encumbrance(259),

    Visitant(285),

    DoubleUpChance(308),
    Bust(309),
    FightersRoll(310),

    Enaspir(488),

    Indicolure(612),
}

enum class AbilityId(val id: Int) {
    FastBlade(32),
    BurningBlade(33),
    RedLotusBlade(34),
    ShiningBlade(36),
    SeraphBlade(37),
    VorpalBlade(40),
    SwiftBlade(41),
    SavageBlade(42),
    KnightsOfTheRound(43),
    Expiacion(46),
    SanguineBlade(47),
    ChantDuCygne(225),
    Requiescat(226),
    UrielBlade(238),
    GlorySlash(239),

    Provoke(547),
    BloodPactRage(603),
    PhantomRoll(609),
    FightersRoll(610),
    DoubleUp(635),
    QuickDraw(636),
    BloodPactWard(684),
    Samba(694),
    Waltz(695),
    Step(710),
    FlourishI(711),
    Jig(712),
    FlourishII(725),
    FlourishIII(775),

    AutoRegen(1545),
    AutoRefresh(1546),
    FastCast(1548),
    StoreTp(1550),
    DoubleAttack(1551),
    DualWield(1554),
}

enum class ItemIds(val id: Int) {
    InstantWarp(4181),
    IcarusWing(4213),
    CopseCandy(6008),
}