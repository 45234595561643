package xim.poc.game.configuration.v0

import xim.poc.game.*
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.Skill
import xim.poc.game.configuration.SkillInfo
import xim.poc.game.configuration.SkillType
import xim.poc.game.configuration.v0.DamageCalculator.getWeaponPower
import xim.poc.game.configuration.v0.DamageCalculator.processAutoAttack
import xim.poc.game.configuration.v0.DamageCalculator.rollWeaponSkillRounds
import xim.poc.game.event.AutoAttackAddedEffect
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.AutoAttackType
import xim.resource.AbilityCostType
import xim.resource.AbilityType
import xim.resource.EquipSlot
import xim.resource.table.AbilityInfoTable
import kotlin.math.roundToInt
import kotlin.random.Random

data class TpScalingContext(
    val excessTp: Float,
    val bonus: CombatBonusAggregate,
)

fun interface TpScalingFn {
    fun invoke(context: TpScalingContext): Float
}

data class WeaponSkillDamageResult(
    val damage: List<Int>,
    val sourceTpGained: Int,
    val targetTpGained: Int,
)

data class OffenseMagicResult(
    val damage: List<Int>,
    val sourceTpGained: Int,
    val targetTpGained: Int,
    val magicBurst: Boolean,
)

object DamageCalculator {

    fun getWeaponPower(actor: ActorState, type: AutoAttackType): Float {
        val (power, _) = getWeaponPowerAndDelay(actor, type) ?: (1f to 0)
        return power
    }

    fun getWeaponPowerAndDelay(actor: ActorState, type: AutoAttackType): Pair<Float,Int>? {
        val (damage, delay) = if (actor.monsterId != null) {
            val monsterDefinition = MonsterDefinitions[actor.monsterId]
            monsterDefinition.baseDamage to monsterDefinition.baseDelay
        } else if (actor.trustId != null) {
            val trustDefinition = TrustDefinitions[actor.trustId]
            trustDefinition.weapon.damage to trustDefinition.weapon.delay
        } else if (actor.type == ActorType.Pc) {
            val item = getWeapon(actor, type)
            if (item != null) {
                val itemDefinition = ItemDefinitions[item]
                getWeaponDamage(item) to itemDefinition.delay
            } else if (type == AutoAttackType.Main) {
                30 to 240
            } else {
                return null
            }
        } else {
            return null
        }

        val bonuses = CombatBonusAggregator[actor]
        val damageMultiplier = if (rollAgainst(bonuses.doubleDamage)) { 2f } else { 1f }

        return (0.1f * damageMultiplier * damage to delay)
    }

    private fun getWeapon(actor: ActorState, type: AutoAttackType): InventoryItem? {
        return when (type) {
            AutoAttackType.Main -> actor.getEquipment(EquipSlot.Main)
            AutoAttackType.Sub -> actor.getEquipment(EquipSlot.Sub)
            AutoAttackType.H2H -> actor.getEquipment(EquipSlot.Main)
            AutoAttackType.Ranged -> actor.getEquipment(EquipSlot.Range)
        }
    }

    private fun getWeaponDamage(item: InventoryItem): Int {
        val fixedAugments = item.fixedAugments
        val itemDefinition = ItemDefinitions[item]

        val damageRange = itemDefinition.damageRange ?: (100 to 115)
        val multiplier = Random.nextDouble(damageRange.first.toDouble(), damageRange.second.toDouble()) / 100.0

        return (multiplier * if (fixedAugments != null) {
            fixedAugments.augments[ItemAugmentDefinitions.weaponDamageAugmentId]?.potency ?: 0
        } else {
            ItemDefinitions[item].damage
        }).roundToInt()
    }

    fun getAutoAttackDamage(attacker: ActorState, defender: ActorState, weaponPower: Float): Float {
        val ratio = (attacker.combatStats.str.toFloat() / defender.combatStats.vit.toFloat()).coerceIn(0.1f, 10f)

        val defenderBonus = CombatBonusAggregator[defender]
        val physDmgReduction = defenderBonus.physicalDamageTaken.toMultiplier(0f, 1f)

        return ratio * weaponPower * physDmgReduction
    }

    fun getHealAmount(caster: ActorState, target: ActorState, potency: Float, maxAmount: Int): Int {
        val (mainHand, _) = getWeaponPowerAndDelay(caster, AutoAttackType.Main) ?: Pair(1f, 0)
        return (mainHand * caster.combatStats.mnd * potency).roundToInt().coerceAtMost(maxAmount)
    }

    fun getCriticalDamageMultiplier(attacker: ActorState, defender: ActorState): Float {
        val bonuses = CombatBonusAggregator[attacker]
        val bonus = bonuses.criticalHitDamage.toMultiplier()
        val ratio = attacker.combatStats.dex.toFloat() / defender.combatStats.agi.toFloat()
        return 1.15f * bonus * ratio.coerceIn(1f, 2f)
    }

    fun rollWeaponSkillRounds(attacker: ActorState, numWsHits: Int): List<AutoAttackType> {
        val rounds = ArrayList<AutoAttackType>()

        for (i in 0 until numWsHits) {
            rounds += (0 until rollAutoAttackRounds(attacker)).map { AutoAttackType.Main }
        }

        if (attacker.isDualWield()) {
            rounds += (0 until rollAutoAttackRounds(attacker)).map { AutoAttackType.Sub }
        }

        return rounds.take(8)
    }

    fun getAutoAttackTypeResult(attacker: ActorState, defender: ActorState, type: AutoAttackType): List<AutoAttackResult> {
        val numHits = rollAutoAttackRounds(attacker)
        return (0 until numHits).map { processAutoAttack(attacker, defender, type ) }
    }

    fun rollAutoAttackRounds(attacker: ActorState): Int {
        val bonuses = CombatBonusAggregator[attacker]

        var numHits = if (rollAgainst(bonuses.quadrupleAttack)) {
            4
        } else if (rollAgainst(bonuses.tripleAttack)) {
            3
        } else if (rollAgainst(bonuses.doubleAttack)) {
            2
        } else {
            1
        }

        if (rollAgainst(bonuses.followUpAttack)) { numHits += 1 }

        return numHits
    }

    fun processAutoAttack(attacker: ActorState, defender: ActorState, type: AutoAttackType): AutoAttackResult {
        val context = AttackContext.from(attacker, defender)

        val attackerBonuses = CombatBonusAggregator[attacker]
        val defenderBonuses = CombatBonusAggregator[defender]

        val (power, _) = getWeaponPowerAndDelay(attacker, type) ?: Pair(0f, 240)

        val rawDamage = getAutoAttackDamage(attacker, defender, power)

        val criticalHit = rollCriticalHit(attacker, defender)
        val criticalBonus = if (criticalHit) {
            context.setCriticalHitFlag()
            getCriticalDamageMultiplier(attacker, defender)
        } else {
            1f
        }

        val damage = (rawDamage * criticalBonus).roundToInt()
        val (tpGained, targetTpGained) = computeTpGained(attacker, defender, type, criticalHit = criticalHit)

        // TODO - don't map effect-power directly to damage (same for retaliation)
        val addedEffects = attackerBonuses.autoAttackEffects
            .map { AutoAttackAddedEffect(it.effectPower, it.effectType) }

        return AutoAttackResult(targetId = defender.id,
            context = context,
            tpGained = tpGained,
            targetTpGained = targetTpGained,
            damageDone = damage,
            type = type,
            addedEffects = addedEffects,
            retaliationEffects = defenderBonuses.autoAttackRetaliationEffects,
        )
    }

    fun computeTpGained(attacker: ActorState, defender: ActorState, type: AutoAttackType, criticalHit: Boolean): Pair<Int, Int> {
        val (_, delay) = getWeaponPowerAndDelay(attacker, type) ?: Pair(0, 240)
        val baseTpGained = delay / 3.0f
        return computeTpGained(attacker, defender, baseTpGained, criticalHit)
    }

    fun computeTpGained(attacker: ActorState, defender: ActorState, baseTpGained: Float, criticalHit: Boolean): Pair<Int, Int> {
        val attackerBonuses = CombatBonusAggregator[attacker]
        val defenderBonuses = CombatBonusAggregator[defender]

        val storeTpPotency = attackerBonuses.storeTp.toMultiplier()
        val criticalHitGain = if (criticalHit) { attackerBonuses.criticalTpGain.toMultiplier() } else { 1f }
        val tpGained = (baseTpGained * criticalHitGain * storeTpPotency).roundToInt()

        val agiMitigation = computeAgiRatio(defender, attacker).coerceIn(0f, 1f)
        val defenderStoreTpPotency = defenderBonuses.storeTp.toMultiplier()
        val attackerSubtleBlow = attackerBonuses.subtleBlow.toPenaltyMultiplier()
        val targetTpGained = (0.5f * baseTpGained * agiMitigation * defenderStoreTpPotency * attackerSubtleBlow).roundToInt()

        return tpGained to targetTpGained
    }


    fun rollCriticalHit(attacker: ActorState, defender: ActorState): Boolean {
        val attackerBonus = CombatBonusAggregator[attacker]
        val criticalHitRateBonus = attackerBonus.criticalHitRate

        val defenderBonus = CombatBonusAggregator[defender]
        val criticalDodgeBonus = defenderBonus.enemyCriticalHitRate

        val ratio = attacker.combatStats.dex.toFloat() / defender.combatStats.agi.toFloat()
        val ratioBonus = (10f + ratio.coerceIn(0.5f, 2f)).roundToInt()

        val chance = ratioBonus + criticalHitRateBonus + criticalDodgeBonus
        return rollAgainst(chance)
    }

    fun computeIntRatio(attacker: ActorState, defender: ActorState): Float {
        return attacker.combatStats.int.toFloat() / defender.combatStats.int.toFloat()
    }

    fun computeMndRatio(attacker: ActorState, defender: ActorState): Float {
        return attacker.combatStats.mnd.toFloat() / defender.combatStats.mnd.toFloat()
    }

    fun computeAgiRatio(attacker: ActorState, defender: ActorState): Float {
        return attacker.combatStats.agi.toFloat() / defender.combatStats.agi.toFloat()
    }

    fun computeExcessTp(actorState: ActorState, skillInfo: SkillInfo): Float {
        val customCost = when (skillInfo.type) {
            SkillType.Spell -> return 1f
            SkillType.MobSkill -> MobSkills[skillInfo.id].cost
            SkillType.Ability -> V0AbilityDefinitions.getCost(skillInfo.id)
            SkillType.Item -> return 1f
        }

        if (!customCost.consumesAll || customCost.baseCost.value == 0) { return 1f }
        if (customCost.baseCost.type != AbilityCostType.Tp) { return 1f }

        val tpBonus = CombatBonusAggregator[actorState].tpBonus

        val calculationTp = (actorState.getTp() + tpBonus).coerceAtMost(GameV0.getMaxTp(actorState))
        val excess = (calculationTp - customCost.baseCost.value).coerceAtLeast(0)
        return (excess.toFloat() / customCost.baseCost.value)
    }

    fun computeRatio(attacker: ActorState, defender: ActorState, combatStat: CombatStat): Float {
        return attacker.combatStats[combatStat].toFloat() / defender.combatStats[combatStat].toFloat()
    }

    fun rollSpellInterrupted(attacker: ActorState, defender: ActorState): Boolean {
        val defenderBonus = CombatBonusAggregator[defender]
        val interruptMitigation = defenderBonus.spellInterruptDown.toPenaltyMultiplier()

        val interruptChance = 0.5f * computeAgiRatio(attacker, defender) * interruptMitigation
        return Random.nextFloat() <= interruptChance
    }

    fun rollConserveTp(actorState: ActorState, skillType: SkillType, skillId: Int): Boolean {
        if (skillType != SkillType.Ability) { return false }

        val abilityInfo = AbilityInfoTable[skillId]
        if (abilityInfo.type != AbilityType.WeaponSkill) { return false }

        val bonuses = CombatBonusAggregator[actorState]
        return rollAgainst(bonuses.conserveTp)
    }

    fun rollAgainst(odds: Int): Boolean {
        return Random.nextDouble(0.0, 100.0) < odds
    }

}

object WeaponSkillDamageCalculator {

    fun physicalWeaponSkill(
        skillInfo: SkillInfo,
        attacker: ActorState,
        defender: ActorState,
        numHits: Int = 1,
        ftpSpread: Boolean = false,
        ftp: TpScalingFn = TpScalingFn { 1f },
    ): WeaponSkillDamageResult {
        val excessTp = DamageCalculator.computeExcessTp(attacker, skillInfo)

        val rounds = rollWeaponSkillRounds(attacker, numHits)
        val hits = ArrayList<AutoAttackResult>()

        for (round in rounds.indices) {
            val roundType = rounds[round]

            hits += CombatBonusAggregator.shallowBonusScope(attacker) {
                val ftpResult = if (round == 0 || ftpSpread) {
                    ftp.invoke(TpScalingContext(excessTp = excessTp, bonus = it))
                } else {
                    1f
                }

                val baseResult = processAutoAttack(attacker, defender, roundType)

                val wsBonus = it.weaponSkillDamage.toMultiplier()
                val attackDamage = (baseResult.damageDone * ftpResult * wsBonus).roundToInt()

                baseResult.copy(damageDone = attackDamage)
            }
        }

        val firstHit = hits.first()
        val hitDamage = hits.map { it.damageDone }

        return WeaponSkillDamageResult(hitDamage, firstHit.tpGained, firstHit.targetTpGained)
    }

    fun magicalWeaponSkill(skillInfo: SkillInfo, attacker: ActorState, defender: ActorState, attackStat: CombatStat, defendStat: CombatStat, ftp: (Float) -> Float = { 1f }): WeaponSkillDamageResult {
        val (power, _) = DamageCalculator.getWeaponPowerAndDelay(attacker, AutoAttackType.Main) ?: (1f to 0)
        val statRatio = attacker.combatStats[attackStat].toFloat() / defender.combatStats[defendStat].toFloat()

        val excessTp = DamageCalculator.computeExcessTp(attacker, skillInfo)
        val tpMultiplier = ftp.invoke(excessTp)

        val attackerBonus = CombatBonusAggregator[attacker]
        val wsBonus = attackerBonus.weaponSkillDamage.toMultiplier()
        val magBonus = attackerBonus.magicAttackBonus.toMultiplier()
        val elemWsBonus = attackerBonus.elementalWeaponSkillDamage.toMultiplier()

        val defenderBonuses = CombatBonusAggregator[defender]

        val defenderMdt = defenderBonuses.magicalDamageTaken.toMultiplier(0f, 1f)

        val damage = (power * statRatio * tpMultiplier * wsBonus * magBonus * elemWsBonus * defenderMdt).roundToInt()

        val (tpGained, targetTpGained) = DamageCalculator.computeTpGained(attacker, defender, AutoAttackType.Main, criticalHit = false)
        return WeaponSkillDamageResult(listOf(damage), tpGained, targetTpGained)
    }

}

object SpellDamageCalculator {

    fun computeDamage(skill: Skill?,
                      attacker: ActorState,
                      defender: ActorState,
                      originalTarget: ActorState = defender,
                      attackStat: CombatStat,
                      defendStat: CombatStat,
                      numHits: Int = 1,
                      potency: Float = 1f,
                      powerOverride: Float? = null,
    ): OffenseMagicResult {
        val statRatio = attacker.combatStats[attackStat].toFloat() / defender.combatStats[defendStat].toFloat()

        val attackBonuses = CombatBonusAggregator[attacker]
        val magBonus = attackBonuses.magicAttackBonus.toMultiplier()

        val defenderBonuses = CombatBonusAggregator[defender]
        val defenderMdt = defenderBonuses.magicalDamageTaken.toMultiplier()

        val baseDamage = potency * magBonus * statRatio * defenderMdt
        val (_, targetTpGained) = DamageCalculator.computeTpGained(attacker, defender, baseTpGained = 50f, criticalHit = false)

        val magicBurstBonus = skill?.let { getMagicBurstBonus(attacker, attackBonuses, originalTarget, it) } ?: 1f

        val hitDamage = (0 until numHits).map {
            val power = powerOverride ?: getWeaponPower(attacker, AutoAttackType.Main)
            (power * baseDamage * magicBurstBonus).roundToInt()
        }

        return OffenseMagicResult(
            damage = hitDamage,
            sourceTpGained = 0,
            targetTpGained = targetTpGained,
            magicBurst = magicBurstBonus > 1f,
        )
    }

    private fun getMagicBurstBonus(attacker: ActorState, attackBonuses: CombatBonusAggregate, originalTarget: ActorState, skill: Skill): Float? {
        val baseBonus = GameEngine.getMagicBurstBonus(attacker, originalTarget, skill.id) ?: return null
        return baseBonus * attackBonuses.magicBurstDamage.toMultiplier()

    }

}