package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.EffectAssociation
import xim.poc.EffectManager
import xim.poc.ScreenFader
import xim.poc.browser.DatLoader
import xim.poc.browser.DatWrapper
import xim.poc.game.ActorStateManager
import xim.poc.tools.ZoneChangeOptions
import xim.poc.tools.ZoneChanger
import xim.poc.tools.ZoneConfig
import xim.resource.DatId
import xim.resource.EffectRoutineInstance
import xim.resource.EffectRoutineResource
import xim.resource.table.FileTableManager
import kotlin.time.Duration

interface EventItem {
    fun start() { }
    fun update(elapsedFrames: Float) { }
    fun isComplete(): Boolean
}

class RunOnceEventItem(val onStarted: () -> Unit): EventItem {

    override fun start() {
        onStarted.invoke()
    }

    override fun isComplete(): Boolean {
        return true
    }

}

class EffectRoutineEventItem(val fileTableIndex: Int, val effectAssociation: EffectAssociation, val routineId: DatId = DatId.main, val eagerlyComplete: Boolean = false): EventItem {

    private var resourceWrapper: DatWrapper? = null
    private var effectRoutineInstance: EffectRoutineInstance? = null

    override fun start() {
        val resourcePath = FileTableManager.getFilePath(fileTableIndex) ?: return
        resourceWrapper = DatLoader.load(resourcePath).onReady {
            val routine = it.getAsResource().getNullableChildRecursivelyAs(routineId, EffectRoutineResource::class) ?: return@onReady
            effectRoutineInstance = EffectManager.registerRoutine(effectAssociation, routine)
        }
    }

    override fun isComplete(): Boolean {
        if (eagerlyComplete) { return true }

        val wrapper = resourceWrapper ?: return true
        if (!wrapper.isReady()) { return false }
        return effectRoutineInstance?.isComplete() == true
    }

}

class WaitRoutine(val waitFrames: Float): EventItem {

    private var frames = 0f

    override fun update(elapsedFrames: Float) {
        frames += elapsedFrames
    }

    override fun isComplete(): Boolean {
        return frames >= waitFrames
    }

}

class FadeOutEvent(val duration: Duration): EventItem {

    private var complete = false

    override fun start() {
        ScreenFader.fadeOut(duration) { complete = true }
    }

    override fun isComplete(): Boolean {
        return complete
    }

}

class FadeInEvent(val duration: Duration): EventItem {

    private var complete = false

    override fun start() {
        ScreenFader.fadeIn(duration) { complete = true }
    }

    override fun isComplete(): Boolean {
        return complete
    }

}

class WarpSameZoneEventItem(val destination: Vector3f): EventItem {

    override fun start() {
        ActorStateManager.player().position.copyFrom(destination)
    }

    override fun isComplete(): Boolean {
        return true
    }

}

class WarpZoneEventItem(val destination: ZoneConfig, val fade: Boolean = true): EventItem {

    override fun start() {
        ZoneChanger.beginChangeZone(destination, options = ZoneChangeOptions(fade = fade))
    }

    override fun isComplete(): Boolean {
        return !ZoneChanger.isChangingZones()
    }

}


class EventScript(val items: List<EventItem>)

object EventScriptRunner {

    private val events: ArrayList<EventItem> = ArrayList()
    private var currentItem: EventItem? = null

    fun runScript(script: EventScript) {
        if (isRunningScript()) { return }
        events += ArrayList(script.items)
    }

    fun isRunningScript(): Boolean {
        return events.isNotEmpty() || currentItem != null
    }

    fun update(elapsedFrames: Float) {
        if (!isRunningScript()) { return }

        val current = currentItem
        current?.update(elapsedFrames)

        if (current != null && !current.isComplete()) { return }

        currentItem = events.removeFirstOrNull()
        currentItem?.start()
    }

}