package xim.poc.game.configuration.v0.behaviors

import xim.math.Vector3f
import xim.poc.SceneManager
import xim.poc.game.ActorPromise
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.v0.V0MonsterHelper
import xim.poc.game.configuration.v0.tower.TowerConfiguration.countProvider
import xim.poc.game.event.BattleEngageEvent
import xim.poc.game.event.DoorOpenEvent
import xim.poc.game.event.Event
import xim.resource.DatId
import xim.util.RandHelper

class MobKnottedRootsBehavior(val actorState: ActorState): ActorBehaviorController {

    private val doorIds = listOf(DatId("_7k0"), DatId("_7k1"))

    private val monsterProvider = countProvider(listOf(
        32 to 1,
        33 to 1,
    )).getMonsterProvider()

    private var nextSpawnThreshold = 1.00f
    private val spawned = ArrayList<ActorPromise>()

    override fun update(elapsedFrames: Float): List<Event> {
        if (actorState.isDead()) { return emptyList() }

        if (actorState.getHpp() < nextSpawnThreshold) {
            nextSpawnThreshold -= 0.20f
            spawnMonsters(1)
        }

        val targetId = actorState.getTargetId() ?: return emptyList()

        return spawned.mapNotNull { ActorStateManager[it.getIfReady()] }
            .filter { it.isIdle() }
            .map { BattleEngageEvent(sourceId = it.id, targetId = targetId) }
    }

    override fun onDefeated(): List<Event> {
        return doorIds.map { DoorOpenEvent(sourceId = null, doorId = it) }
    }

    private fun spawnMonsters(count: Int) {
        (0 until count).map { spawnMonster() }
    }

    private fun spawnMonster() {
        val next = monsterProvider.nextMonster()
        spawned += V0MonsterHelper.spawnMonster(
            monsterDefinition = next,
            position = getRandomSpawnPosition(),
        )
    }

    private fun getRandomSpawnPosition(): Vector3f {
        val basePosition = actorState.position + Vector3f(0f, -5f, -4f)
        val position = basePosition + Vector3f(2f * RandHelper.rand(), 0f, 2f * RandHelper.rand())
        return SceneManager.getCurrentScene().getNearestFloor(position) ?: position
    }

}