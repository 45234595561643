package xim.poc.game.configuration.v0

import xim.poc.game.configuration.ItemDropSlot
import xim.poc.game.configuration.MonsterId
import xim.poc.game.configuration.WeightedTable

class MonsterDropTable(val dropTable: List<DropTableProvider> = emptyList())

object V0MonsterDropTables {

    private val dropTables: Map<MonsterId, MonsterDropTable>

    init {
        dropTables = HashMap()

        // Cherry Tree
        dropTables[10] = MonsterDropTable(listOf(
            genderedDropSlot(
                male = WeightedTable.single(ItemDropSlot(11316)),
                female = WeightedTable.single(ItemDropSlot(11317))
            ),
        ))

        // Colkhab
        dropTables[7] = MonsterDropTable(listOf(
            basic(WeightedTable.single(ItemDropSlot(itemId = 27781, augmentRankMean = 12))),
            basic(WeightedTable.single(ItemDropSlot(itemId = 28201, augmentRankMean = 12))),
        ))

        // Kraken
        dropTables[19] = MonsterDropTable(listOf(
            genderedDropSlot(
                male = WeightedTable.single(ItemDropSlot(23871)),
                female = WeightedTable.single(ItemDropSlot(23873))
            ),
            genderedDropSlot(
                male = WeightedTable.single(ItemDropSlot(23873)),
                female = WeightedTable.single(ItemDropSlot(23874))
            ),
        ))

        // Tchakka
        dropTables[29] = MonsterDropTable(listOf(
            basic(WeightedTable.single(ItemDropSlot(itemId = 27780, augmentRankMean = 12))),
            basic(WeightedTable.single(ItemDropSlot(itemId = 28343, augmentRankMean = 12))),
        ))

    }

    operator fun get(monsterId: MonsterId): MonsterDropTable {
        return dropTables[monsterId] ?: MonsterDropTable()
    }

    private fun basic(table: WeightedTable<ItemDropSlot>): DropTableProvider {
        return DropTableProvider { table }
    }

    private fun genderedDropSlot(male: WeightedTable<ItemDropSlot>, female: WeightedTable<ItemDropSlot>): DropTableProvider {
        return DropTableProvider.genderDropTable(male, female)
    }


}