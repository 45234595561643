package xim.poc.game.configuration.v0

import xim.poc.game.CombatStat
import xim.poc.game.SkillChainAttribute
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.*
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluatorContext
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.basicMagicalDamage
import xim.poc.game.configuration.v0.V0MobSkillDefinitions.basicPhysicalDamage
import xim.poc.game.configuration.v0.V0SpellDefinitions.applyBasicBuff
import xim.poc.game.event.ActorAttackedEvent
import xim.poc.game.event.AttackDamageType
import xim.poc.game.event.AttackEffects
import xim.poc.game.event.Event
import xim.resource.AbilityCost
import xim.resource.AbilityCostType
import xim.util.interpolate
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

data class V0AbilityCost(val baseCost: AbilityCost, val consumesAll: Boolean = false)

data class V0Recast(val baseTime: Duration, val fixedTime: Boolean = false)

data class V0Skill(
    val cost: V0AbilityCost? = null,
    val skillChainAttributes: List<SkillChainAttribute> = emptyList(),
    val rangeInfo: SkillRangeInfo? = null,
    val recast: V0Recast? = null,
)

object V0AbilityDefinitions {

    private val abilityMetadata = HashMap<Int, V0Skill>()

    fun register() {
        // Fast Blade
        SkillAppliers += SkillApplier(skillId = 32, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyFastBlade())
        abilityMetadata[32] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 300)),
            skillChainAttributes = listOf(SkillChainAttribute.Scission),
        )

        // Burning Blade
        SkillAppliers += SkillApplier(skillId = 33, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyBurningBlade())
        abilityMetadata[33] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 300)),
            skillChainAttributes = listOf(SkillChainAttribute.Liquefaction),
        )

        // Red Lotus Blade
        SkillAppliers += SkillApplier(skillId = 34, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyRedLotusBlade())
        abilityMetadata[34] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 600), consumesAll = true),
            skillChainAttributes = listOf(SkillChainAttribute.Detonation, SkillChainAttribute.Liquefaction),
        )

        // Shining Blade
        SkillAppliers += SkillApplier(skillId = 36, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyShiningBlade())
        abilityMetadata[36] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 300)),
            skillChainAttributes = listOf(SkillChainAttribute.Liquefaction),
        )

        // Seraph Blade
        SkillAppliers += SkillApplier(skillId = 37, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicMagicalDamage(attackStat = CombatStat.mnd) { extraTp -> 5f + 2.5f * extraTp }
        )

        abilityMetadata[37] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 600), consumesAll = true),
            skillChainAttributes = listOf(SkillChainAttribute.Scission),
        )

        // Vorpal Blade
        SkillAppliers += SkillApplier(skillId = 40, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage(numHits = 4, ftpSpread = true) { 1.25f }
        )
        abilityMetadata[40] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 600)),
            skillChainAttributes = listOf(SkillChainAttribute.Scission, SkillChainAttribute.Liquefaction),
        )

        // Swift Blade
        SkillAppliers += SkillApplier(skillId = 41, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { 1.33f }
        )
        abilityMetadata[41] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 600)),
            skillChainAttributes = listOf(SkillChainAttribute.Scission, SkillChainAttribute.Liquefaction),
        )

        // Savage Blade
        SkillAppliers += SkillApplier(skillId = 42, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage(numHits = 2, ftpSpread = false, ftp = this::savageBladeScalingFn)
        )
        abilityMetadata[42] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = true),
            skillChainAttributes = listOf(SkillChainAttribute.Fragmentation, SkillChainAttribute.Scission),
        )

        // Knights of the Round
        SkillAppliers += SkillApplier(skillId = 43, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage { 12f }
        )
        abilityMetadata[43] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = false),
            skillChainAttributes = listOf(SkillChainAttribute.Light, SkillChainAttribute.Fusion),
        )

        // Expiacion
        SkillAppliers += SkillApplier(skillId = 46, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            additionalSelfEvaluator = applyBasicBuff(statusEffect = StatusEffect.Enaspir, duration = 1.minutes) {
                val tpScale = (it.context.sourceState.getTp() / 1000f).roundToInt()
                it.status.potency = tpScale
            },
            targetEvaluator = basicPhysicalDamage(numHits = 2, ftpSpread = false, ftp = this::savageBladeScalingFn)
        )
        abilityMetadata[46] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = true),
            skillChainAttributes = listOf(SkillChainAttribute.Distortion, SkillChainAttribute.Scission),
        )

        // Sanguine Blade
        SkillAppliers += SkillApplier(skillId = 47, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator, targetEvaluator = applyDummy())
        abilityMetadata[47] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 600)),
            skillChainAttributes = emptyList(),
        )

        // Chant Du Cygne
        SkillAppliers += SkillApplier(skillId = 225, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { tpContext ->
                tpContext.bonus.criticalHitRate += 50
                2.25f
            },
        )
        abilityMetadata[225] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = false),
            skillChainAttributes = listOf(SkillChainAttribute.Light, SkillChainAttribute.Distortion),
        )

        // Requiescat
        SkillAppliers += SkillApplier(skillId = 226, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage(numHits = 5, ftpSpread = true) { 1.25f }
        )
        abilityMetadata[226] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = false),
            skillChainAttributes = listOf(SkillChainAttribute.Gravitation, SkillChainAttribute.Scission),
        )

        // Uriel Blade
        SkillAppliers += SkillApplier(skillId = 238, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            additionalSelfEvaluator = applyBasicBuff(statusEffect = StatusEffect.Spontaneity, duration = 8.seconds) { it.status.counter = 1 },
            targetEvaluator = basicMagicalDamage { 12f }
        )
        abilityMetadata[238] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = false),
            skillChainAttributes = listOf(SkillChainAttribute.Light, SkillChainAttribute.Fragmentation, SkillChainAttribute.Scission),
        )

        // Glory Slash
        SkillAppliers += SkillApplier(skillId = 239, skillType = SkillType.Ability, validEvaluator = CommonSkillAppliers.weaponSkillValidator,
            targetEvaluator = basicPhysicalDamage { 12f }
        )
        abilityMetadata[239] = V0Skill(
            cost = V0AbilityCost(AbilityCost(AbilityCostType.Tp, 1000), consumesAll = false),
            skillChainAttributes = listOf(SkillChainAttribute.Light, SkillChainAttribute.Fusion),
        )
    }

    fun getCost(abilityId: Int): V0AbilityCost {
        return abilityMetadata[abilityId]?.cost ?: V0AbilityCost(AbilityCost(AbilityCostType.Tp, 0))
    }

    fun getSkillChainAttributes(abilityId: Int): List<SkillChainAttribute> {
        return abilityMetadata[abilityId]?.skillChainAttributes ?: emptyList()
    }

    private fun applyFastBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.physicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                numHits = 2,
            )

            weaponSkillToEvents(it, wsResult, AttackDamageType.Physical)
        }
    }

    private fun applyDummy(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.physicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
            )

            weaponSkillToEvents(it, wsResult, AttackDamageType.Physical)
        }
    }

    private fun applyBurningBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.int,
                defendStat = CombatStat.int
            ) { 2.5f }

            weaponSkillToEvents(it, wsResult, AttackDamageType.Magical)
        }
    }

    private fun applyRedLotusBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.int,
                defendStat = CombatStat.int
            ) { extraTp -> 5f + 2.5f * extraTp }

            weaponSkillToEvents(it, wsResult, AttackDamageType.Magical)
        }
    }

    private fun applyShiningBlade(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = CombatStat.mnd,
                defendStat = CombatStat.mnd
            ) { 2.5f }

            weaponSkillToEvents(it, wsResult, AttackDamageType.Magical)
        }
    }

    private fun savageBladeScalingFn(context: TpScalingContext): Float {
        return 8f + if (context.excessTp <= 1f) {
            0f.interpolate(12f, context.excessTp)
        } else if (context.excessTp <= 2f) {
            12f + 0f.interpolate(5f, context.excessTp - 1f)
        } else {
            17f
        }
    }

    fun weaponSkillToEvents(
        context: TargetEvaluatorContext,
        wsResult: WeaponSkillDamageResult,
        damageType: AttackDamageType,
        attackEffects: AttackEffects = AttackEffects(),
    ): List<Event> {
        return listOf(ActorAttackedEvent(
            sourceId = context.sourceState.id,
            targetId = context.targetState.id,
            damageAmount = wsResult.damage,
            damageType = damageType,
            sourceTpGain = wsResult.sourceTpGained,
            targetTpGain = wsResult.targetTpGained,
            attackEffects = attackEffects,
            skill = context.skill,
            actionContext = context.context,
        ))
    }

}