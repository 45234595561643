package xim.poc.game.configuration.v0.zones

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.configuration.SpawnArea
import xim.poc.game.configuration.v0.tower.Boundary
import xim.poc.game.configuration.v0.tower.EncompassingSphere
import xim.poc.tools.ZoneConfig
import xim.resource.DatId
import xim.resource.EffectRoutineResource
import xim.resource.table.MusicSettings

data class BattleTimeOfDay(
    val hour: Int,
    val minute: Int,
)

data class BattleLocation(
    val startingPosition: ZoneConfig,
    val treasureChestPosition: Vector3f,
    val entrancePosition: Vector3f,
    val entranceLook: ModelLook,
    val exitPosition: Vector3f,
    val exitLook: ModelLook,
    val spawnerArea: SpawnArea,
    val bossArea: Boolean = false,
    val boundaries: List<Boundary> = emptyList(),
    val shipRoute: DatId? = null,
    val musicSettings: MusicSettings? = null,
    val timeOfDay: BattleTimeOfDay? = null,
    val onCleared: (() -> Unit)? = null,
    val onSetup: (() -> Unit)? = null
)

object BattleLocations {

    private val locations = HashMap<Int, BattleLocation>()

    init {
        // Misareaux Coast: Waterfall
        locations[0] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 25, startPosition = Vector3f(x=-36.71f,y=-24.20f,z=683.13f)),
            treasureChestPosition = Vector3f(x=-65.83f,y=-23.41f,z=665.54f),
            entrancePosition = Vector3f(x=-35.25f,y=-24.27f,z=684.56f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-68.74f,y=-23.53f,z=666.95f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=-64.36f,y=-23.22f,z=668.26f), Vector3f(18f, 0f, 15f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-56.37f,y=-23.34f,z=658.09f), radius = 38f),
            ))

        // Misareaux Coast: River
        locations[1] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 25, startPosition = Vector3f(x=266.26f,y=-15.54f,z=360.67f)),
            treasureChestPosition = Vector3f(x=246.34f,y=-15.53f,z=392.43f),
            entrancePosition = Vector3f(x=266.26f,y=-15.54f,z=358.67f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=242.92f,y=-15.60f,z=392.43f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=259.36f,y=-15.55f,z=388.50f), Vector3f(8.5f, 0f, 16f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=260.69f,y=-15.50f,z=384.36f), radius = 38f),
            ))

        // Lufaise Meadows: Lake
        locations[2] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 24, startPosition = Vector3f(x=42.29f,y=-8.06f,z=46.80f)),
            treasureChestPosition = Vector3f(x=66.27f,y=-7.68f,z=9.35f),
            entrancePosition = Vector3f(x=42.35f,y=-8.06f,z=49.07f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=69.24f,y=-7.61f,z=8.20f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=56.36f,y=-7.34f,z=6.30f), Vector3f(10f, 0f, 13f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=54.40f,y=-7.37f,z=27.60f), radius = 38f),
            ))

        // Ceizak Battlegrounds: Wildskeeper Reive
        locations[3] = BattleLocation(
            bossArea = true,
            startingPosition = ZoneConfig(zoneId = 261, startPosition = Vector3f(x=-100.57f,y=0.44f,z=364.31f)),
            treasureChestPosition = Vector3f(x=44.48f,y=-8.31f,z=44.44f),
            entrancePosition = Vector3f(x=-99.76f,y=0.46f,z=361.69f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-98.86f,y=0.57f,z=373.46f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=-135.12f,y=0.57f,z=423.66f), Vector3f(10f, 0f, 10f)),
            musicSettings = MusicSettings(musicId = null, battleSoloMusicId = 62, battlePartyMusicId = 62),
            boundaries = listOf(
            ))

        // Lufaise Meadows: Cherry Tree
        locations[4] = BattleLocation(
            bossArea = true,
            startingPosition = ZoneConfig(zoneId = 24, startPosition = Vector3f(x=-183.20f,y=-15.45f,z=306.01f)),
            entrancePosition = Vector3f(x=-187.54f,y=-15.18f,z=304.81f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-164.53f,y=-15.77f,z=290.58f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-166.68f,y=-15.66f,z=289.04f),
            spawnerArea = SpawnArea(Vector3f(x=-162.95f,y=-16.08f,z=314.59f), Vector3f(x=4.00f,y=0.00f,z=4.00f)),
            musicSettings = MusicSettings(battleSoloMusicId = 102, battlePartyMusicId = 102),
            timeOfDay = BattleTimeOfDay(hour = 19, minute = 0),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-162.95f,y=-16.08f,z=314.59f), radius = 30f)
            ),
        )

        // Ceizak Battlegrounds: Beach
        locations[5] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 261, startPosition = Vector3f(x=273.21f,y=-0.00f,z=-281.52f)),
            treasureChestPosition = Vector3f(x=282.15f,y=0.42f,z=-250.54f),
            entrancePosition = Vector3f(x=270.86f,y=0.00f,z=-283.10f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=283.35f,y=0.33f,z=-248.77f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=298.02f,y=1.67f,z=-263.82f), Vector3f(15f, 0f, 15f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=298.02f,y=1.67f,z=-263.82f), radius = 40f)
            ))

        // Sih Gates: Lake
        locations[6] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 268, startPosition = Vector3f(x=166.15f,y=-2.49f,z=-250.10f)),
            treasureChestPosition = Vector3f(x=158.21f,y=-0.00f,z=-280.47f),
            entrancePosition = Vector3f(x=170.94f,y=-0.85f,z=-251.01f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=156.09f,y=-0.36f,z=-282.35f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=157.94f,y=0.63f,z=-269.78f), Vector3f(10f, 0f, 10f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=157.94f,y=0.63f,z=-269.78f), radius = 30f)
            ))

        // Abyssea - Misareaux: Riverne Entrance
        locations[7] = BattleLocation(
            bossArea = true,
            startingPosition = ZoneConfig(zoneId = 216, startPosition = Vector3f(x=-443.00f,y=-32.04f,z=359.23f)),
            treasureChestPosition = Vector3f(x=-446.25f,y=-32.05f,z=357.36f),
            entrancePosition = Vector3f(x=-438.89f,y=-32.07f,z=358.73f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-498.53f,y=-31.08f,z=360.67f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=-481.37f,y=-32.00f,z=358.71f), Vector3f(15f, 0f, 20f)),
            boundaries = listOf(),
        )

        // Ceizak Battlegrounds: Inner Area 1
        locations[8] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 261, startPosition = Vector3f(x=35.15f,y=-0.00f,z=126.51f)),
            treasureChestPosition = Vector3f(x=84.28f,y=0.00f,z=80.09f),
            entrancePosition = Vector3f(x=33.69f,y=0.18f,z=128.06f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=83.43f,y=0.00f,z=77.09f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=60.50f,y=0.51f,z=99.13f), Vector3f(20f, 0f, 20f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=60.50f,y=0.51f,z=99.13f), radius = 40f)
            ))

        // Ceizak Battlegrounds: Inner Area 2
        locations[9] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 261, startPosition = Vector3f(x=-54.77f,y=0.33f,z=267.55f)),
            treasureChestPosition = Vector3f(x=-67.27f,y=0.55f,z=298.99f),
            entrancePosition = Vector3f(x=-58.78f,y=0.26f,z=266.74f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-69.34f,y=0.50f,z=298.69f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=-41.35f,y=-0.00f,z=282.42f), Vector3f(9.25f, 0f, 9.25f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-41.35f,y=-0.00f,z=282.42f), radius = 35f)
            ))

        // Misareaux Coast: Coast
        locations[10] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 25, startPosition = Vector3f(x=714.91f,y=0.69f,z=-483.28f)),
            treasureChestPosition = Vector3f(x=677.19f,y=0.45f,z=-467.39f),
            entrancePosition = Vector3f(x=721.75f,y=0.84f,z=-483.58f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=673.17f,y=0.47f,z=-468.95f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=689.19f,y=0.48f,z=-468.86f), Vector3f(14f, 0f, 12f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=689.19f,y=0.48f,z=-468.86f), radius = 40f),
            ))

        // Bibiki Bay: Town
        locations[11] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 4, startPosition = Vector3f(x=519.32f,y=-8.00f,z=758.77f)),
            entrancePosition = Vector3f(x=520.72f,y=-8.00f,z=760.82f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=502.37f,y=-3.50f,z=736.24f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=501.69f,y=-3.50f,z=738.18f),
            spawnerArea = SpawnArea(Vector3f(x=509.99f,y=-5.00f,z=743.04f), Vector3f(10f, 0f, 7f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=511.02f,y=-5.00f,z=747.16f), radius = 20f)
            ),
        )

        // Manaclipper
        locations[12] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 3, startPosition = Vector3f(x=17.13f,y=-3.00f,z=2.18f)),
            treasureChestPosition = Vector3f(x=-16.41f,y=-3.00f,z=3.74f),
            entrancePosition = Vector3f(x=20.26f,y=-3.00f,z=2.17f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-18.95f,y=-3.00f,z=1.78f),
            exitLook = ModelLook.npc(0x9BC),
            spawnerArea = SpawnArea(Vector3f(x=0.00f,y=-3.00f,z=-6.50f), Vector3f(9f, 0f, 10f)),
            shipRoute = DatId("c002"),
            boundaries = emptyList(),
        ) {
            val mainArea = SceneManager.getCurrentScene().getMainArea()
            mainArea.root.getNullableChildRecursivelyAs(DatId("eved"), EffectRoutineResource::class)?.let {
                EffectManager.registerRoutine(ZoneAssociation(mainArea), it)
            }
        }

        // Bibiki Bay: Island Entrance
        locations[13] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 4, startPosition = Vector3f(x=-388.90f,y=-2.95f,z=-432.22f)),
            entrancePosition = Vector3f(x=-392.30f, y=-3.01f, z=-431.72f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-353.89f, y=-2.85f, z=-430.57f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-354.89f,y=-2.68f,z=-429.03f),
            spawnerArea = SpawnArea(Vector3f(x=-364.44f, y=-2.95f, z=-438.79f), Vector3f(x=11.00f, y=0.00f, z=13.00f)),
            musicSettings = MusicSettings(musicId = 229, battleSoloMusicId = 101, battlePartyMusicId = 219),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-372.44f, y=-2.95f, z=-438.79f), radius = 27f)
            ),
        )

        // Bibiki Bay: Shimmery
        locations[14] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 4, startPosition = Vector3f(x=-644.24f,y=0.30f,z=-679.23f)),
            entrancePosition = Vector3f(x=-644.09f,y=0.30f,z=-676.15f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-650.66f,y=0.30f,z=-718.15f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-647.72f,y=0.30f,z=-717.53f),
            spawnerArea = SpawnArea(Vector3f(x=-637.57f,y=0.30f,z=-708.27f), Vector3f(x=10.00f,y=0.00f,z=15.00f)),
            timeOfDay = BattleTimeOfDay(hour = 17, minute = 15),
            musicSettings = MusicSettings(musicId = 229, battleSoloMusicId = 101, battlePartyMusicId = 219),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-648.57f,y=0.30f,z=-704.27f), radius = 33f)
            ),
        )

        // Bibiki Bay: Orcish Warmachine
        locations[15] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 4, startPosition = Vector3f(x=-359.15f,y=-3.40f,z=-881.89f)),
            entrancePosition = Vector3f(x=-361.99f,y=-3.40f,z=-879.40f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-339.11f,y=-2.54f,z=-883.24f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-340.66f,y=-2.47f,z=-881.85f),
            spawnerArea = SpawnArea(Vector3f(x=-347.26f,y=-0.16f,z=-903.08f), Vector3f(x=1.00f,y=0.00f,z=1.00f)),
            musicSettings = MusicSettings(battleSoloMusicId = 219, battlePartyMusicId = 219),
            timeOfDay = BattleTimeOfDay(hour = 15, minute = 30),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-347.26f,y=-0.16f,z=-901.08f), radius = 28f)
            ),
        )

        // Dho Gates: River
        locations[16] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 272, startPosition = Vector3f(x=-238.96f,y=-20.30f,z=162.84f)),
            entrancePosition = Vector3f(x=-238.30f,y=-19.00f,z=167.06f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-242.57f,y=-18.96f,z=113.36f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-244.34f,y=-19.32f,z=115.58f),
            spawnerArea = SpawnArea(Vector3f(x=-241.50f,y=-19.19f,z=136.95f), Vector3f(x=9.00f,y=0.00f,z=13.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-241.50f,y=-19.19f,z=136.95f), radius = 35f)
            ),
        )

        // Dho Gates: Root
        locations[17] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 272, startPosition = Vector3f(x=-61.44f,y=-9.94f,z=60.95f)),
            entrancePosition = Vector3f(x=-61.60f,y=-10.16f,z=58.15f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-65.74f,y=-10.01f,z=89.01f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-63.75f,y=-10.29f,z=88.86f),
            spawnerArea = SpawnArea(Vector3f(x=-59.81f,y=-10.99f,z=79.2f), Vector3f(x=0.00f,y=0.00f,z=0.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-59.81f,y=-9.99f,z=77.76f), radius = 20f)
            ),
        )

        // Foret de Hennetiel: Tree Bridge
        locations[18] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 262, startPosition = Vector3f(x=-415.59f,y=-5.30f,z=56.89f)),
            entrancePosition = Vector3f(x=-417.36f,y=-5.97f,z=59.44f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-413.79f,y=-1.44f,z=-27.43f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-412.62f,y=-1.16f,z=-25.64f),
            spawnerArea = SpawnArea(Vector3f(x=-400.73f,y=-1.82f,z=34.16f), Vector3f(x=11.00f,y=0.00f,z=11.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-411.84f,y=2.47f,z=12.19f), radius = 50f)
            ),
            onCleared = {
                openDoor(DatId("_7a0"))
                openDoor(DatId("_7aa"))
            }
        )

        // Foret de Hennetiel: Wildskeeper Entrance
        locations[19] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 262, startPosition = Vector3f(x=-256.42f,y=-5.57f,z=-182.76f)),
            entrancePosition = Vector3f(x=-258.68f,y=-5.97f,z=-180.17f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-225.09f,y=-4.91f,z=-215.08f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-227.43f,y=-4.46f,z=-214.51f),
            spawnerArea = SpawnArea(Vector3f(x=-236.00f,y=-2.00f,z=-197.78f), Vector3f(x=10.00f,y=0.00f,z=10.00f)),
            timeOfDay = BattleTimeOfDay(hour = 20, minute = 0),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-236.00f,y=-2.00f,z=-197.78f), radius = 30f)
            ),
        )

        // Foret de Hennetiel: Wildskeeper Reive
        locations[20] = BattleLocation(
            bossArea = true,
            startingPosition = ZoneConfig(zoneId = 262, startPosition = Vector3f(x=-202.82f,y=-0.50f,z=-438.61f)),
            entrancePosition = Vector3f(x=-207.74f,y=-0.73f,z=-438.03f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-198.78f,y=-0.78f,z=-430.74f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-200.13f,y=-0.76f,z=-431.43f),
            spawnerArea = SpawnArea(Vector3f(x=-161.80f,y=-0.45f,z=-444.94f), Vector3f(x=10.00f,y=0.00f,z=10.00f)),
            musicSettings = MusicSettings(musicId = null, battleSoloMusicId = 62, battlePartyMusicId = 62),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-161.80f,y=-0.45f,z=-444.94f), radius = 55f)
            ),
        )

        // Uleguerand Range: Cliff Foot
        locations[21] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 5, startPosition = Vector3f(x=-297.64f,y=-40.11f,z=-343.78f)),
            entrancePosition = Vector3f(x=-297.55f,y=-39.83f,z=-346.32f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-285.71f,y=-39.89f,z=-289.81f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-288.35f,y=-40.14f,z=-291.19f),
            spawnerArea = SpawnArea(Vector3f(x=-286.49f,y=-39.79f,z=-313.99f), Vector3f(x=14.00f,y=0.00f,z=14.00f)),
            timeOfDay = BattleTimeOfDay(hour = 17, minute = 15),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-304.03f,y=-39.75f,z=-312.34f), radius = 38f)
            ),
            onSetup = { setWeather(Weather.snow) }
        )

        // Uleguerand Range: Forest
        locations[22] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 5, startPosition = Vector3f(x=150.45f,y=-0.04f,z=-329.97f)),
            entrancePosition = Vector3f(x=146.68f,y=-0.03f,z=-332.68f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=185.12f,y=0.27f,z=-295.69f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=184.50f,y=0.09f,z=-299.05f),
            spawnerArea = SpawnArea(Vector3f(x=173.46f,y=0.54f,z=-316.65f), Vector3f(x=14.00f,y=0.00f,z=14.00f)),
            timeOfDay = BattleTimeOfDay(hour = 17, minute = 15),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=173.46f,y=0.54f,z=-316.65f), radius = 35f)
            ),
            onSetup = { setWeather(Weather.bliz) }
        )

        // Uleguerand Range: Cliff Top
        locations[23] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 5, startPosition = Vector3f(x=-284.47f,y=-176.01f,z=-122.57f)),
            entrancePosition = Vector3f(x=-284.54f,y=-176.01f,z=-126.59f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-282.62f,y=-176.00f,z=-40.38f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-281.09f,y=-176.00f,z=-42.23f),
            spawnerArea = SpawnArea(Vector3f(x=-277.20f,y=-176.00f,z=-81.65f), Vector3f(x=18.00f,y=0.00f,z=18.00f)),
            timeOfDay = BattleTimeOfDay(hour = 17, minute = 15),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-277.20f,y=-176.00f,z=-81.65f), radius = 48f)
            ),
            onSetup = { setWeather(Weather.suny) }
        )

        // Uleguerand Range: Frozen Lake
        locations[24] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 5, startPosition = Vector3f(x=-37.92f,y=-176.00f,z=78.53f)),
            entrancePosition = Vector3f(x=-41.01f,y=-176.00f,z=80.26f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=14.31f,y=-177.12f,z=73.75f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=13.41f,y=-177.33f,z=72.11f),
            spawnerArea = SpawnArea(Vector3f(x=-22.33f,y=-175.00f,z=51.58f), Vector3f(x=15.00f,y=0.00f,z=15.00f)),
            timeOfDay = BattleTimeOfDay(hour = 17, minute = 15),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-22.33f,y=-175.00f,z=51.58f), radius = 44f)
            ),
            onCleared = { openDoor(DatId("_058")); setWeather(Weather.suny) },
            onSetup = { setWeather(Weather.snow) }
        )

        // Bearclaw Pinnacle
        locations[25] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 6, startPosition = Vector3f(x=-199.97f,y=-0.34f,z=433.62f)),
            entrancePosition = Vector3f(x=-197.23f,y=-0.10f,z=436.05f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-219.34f,y=-1.08f,z=533.49f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-221.49f,y=-0.71f,z=531.83f),
            spawnerArea = SpawnArea(Vector3f(x=-222.97f,y=0.45f,z=500.14f), Vector3f(x=14.00f,y=0.00f,z=14.00f)),
            boundaries = emptyList(),
        )

        // Promy. Holla: Warp 06
        locations[26] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 16, startPosition = Vector3f(x=-176.33f,y=0.00f,z=-144.65f)),
            entrancePosition = Vector3f(x=-180.02f,y=0.00f,z=-140.29f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-160.01f,y=-0.50f,z=-200.20f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-157.32f,y=-0.05f,z=-192.99f),
            spawnerArea = SpawnArea(Vector3f(x=-163.12f,y=-0.00f,z=-168.61f), Vector3f(x=9.00f,y=0.00f,z=15.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-163.12f,y=-0.00f,z=-168.61f), radius = 38f)
            ),
            onCleared = { openDoor(DatId("_0g6")) }
        )

        // Promy. Dem: Warp 05
        locations[27] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 18, startPosition = Vector3f(x=-127.93f,y=-0.30f,z=-57.80f)),
            entrancePosition = Vector3f(x=-130.37f,y=-0.31f,z=-54.13f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-80.01f,y=-0.50f,z=-79.97f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-85.00f,y=-0.30f,z=-77.18f),
            spawnerArea = SpawnArea(Vector3f(x=-110.83f,y=0.00f,z=-80.73f), Vector3f(x=14.00f,y=0.00f,z=10.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-110.83f,y=0.00f,z=-80.73f), radius = 38f)
            ),
            onCleared = { openDoor(DatId("_0i5")) }
        )

        // Promy. Mea: Warp 09
        locations[28] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 20, startPosition = Vector3f(x=-77.21f,y=-0.00f,z=-280.52f)),
            entrancePosition = Vector3f(x=-80.69f,y=0.00f,z=-281.81f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-39.99f,y=-0.49f,z=-319.99f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=-37.43f,y=-0.30f,z=-314.89f),
            spawnerArea = SpawnArea(Vector3f(x=-50.50f,y=0.00f,z=-287.42f), Vector3f(x=14.00f,y=0.00f,z=11.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=-50.50f,y=0.00f,z=-287.42f), radius = 38f)
            ),
            onCleared = { openDoor(DatId("_0k9")) }
        )

        // Promy. Vahzl: Warp 09
        locations[29] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 22, startPosition = Vector3f(x=342.01f,y=-0.20f,z=-127.74f)),
            entrancePosition = Vector3f(x=338.11f,y=-0.84f,z=-132.06f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=360.00f,y=-0.50f,z=-79.99f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=357.44f,y=-0.30f,z=-84.70f),
            spawnerArea = SpawnArea(Vector3f(x=354.78f,y=-0.00f,z=-103.25f), Vector3f(x=14.00f,y=0.00f,z=11.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=354.78f,y=-0.00f,z=-103.25f), radius = 35f)
            ),
            onCleared = { openDoor(DatId("_0m9")) }
        )

        // Spire of Vahzl
        locations[30] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 23, startPosition = Vector3f(x=0.09f,y=-1.99f,z=33.81f)),
            entrancePosition = Vector3f(x=0.01f,y=-1.99f,z=39.04f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=-0.25f,y=-0.00f,z=-22.98f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=0.58f,y=-0.00f,z=-21.63f),
            spawnerArea = SpawnArea(Vector3f(x=0.00f,y=0.00f,z=0.00f), Vector3f(x=11.00f,y=0.00f,z=11.00f)),
        )

        // Debug
        locations[1000] = BattleLocation(
            startingPosition = ZoneConfig(zoneId = 36, startPosition = Vector3f(x=502.45f,y=-0.00f,z=501.38f)),
            entrancePosition = Vector3f(x=500.52f,y=-0.00f,z=498.73f),
            entranceLook = ModelLook.npc(0x975),
            exitPosition = Vector3f(x=498.89f,y=-0.00f,z=506.15f),
            exitLook = ModelLook.npc(0x9BC),
            treasureChestPosition = Vector3f(x=498.81f,y=0.00f,z=504.42f),
            spawnerArea = SpawnArea(Vector3f(x=513.08f,y=0.00f,z=514.06f), Vector3f(x=5.00f,y=0.00f,z=5.00f)),
            boundaries = listOf(
                EncompassingSphere(center = Vector3f(x=513.08f,y=0.00f,z=514.06f), radius = 30f)
            ),
        )
    }

    operator fun get(index: Int): BattleLocation {
        return locations[index] ?: throw IllegalStateException("No such battle location: $index")
    }

    fun getAll(): Collection<BattleLocation> {
        return locations.values
    }

    private fun openDoor(datId: DatId) {
        val scene = SceneManager.getCurrentScene()
        scene.openDoor(datId)
    }

    private fun setWeather(weather: Weather) {
        EnvironmentManager.switchWeather(DatId(weather.id))
    }

}