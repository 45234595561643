package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.FrameCoherence
import xim.poc.SceneManager
import xim.poc.camera.CameraReference
import xim.poc.game.*
import xim.poc.game.configuration.MonsterAggroHelper
import xim.poc.tools.ZoneChanger
import xim.util.Fps
import kotlin.time.Duration.Companion.seconds

class TickActorEvent(
    val elapsedFrames: Float,
    val sourceId: ActorId,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()

        if (actorState.isDoor()) { return updateDoorState(actorState) }

        val outputEvents = ArrayList<Event>()

        outputEvents += updateDeathStatus(actorState)

        outputEvents += updateEffectTickTimer(actorState)

        updateSkillChainState(actorState)

        if (actorState.isMount || actorState.isBubble) { return outputEvents }

        outputEvents += updateVelocity(actorState)

        updateRecastState(actorState)

        outputEvents += updateCastingState(actorState)

        outputEvents += updateSynthesisState(actorState)

        updateRestingState(actorState)

        outputEvents += updateEngageState(actorState)

        outputEvents += updateBehavior(actorState)

        outputEvents += updateStatusStatus(actorState)

        updateGatheringState(actorState)

        updateCombatStats(actorState)

        moveActor(actorState)

        updateRotation(actorState)

        actorState.effectVelocity.copyFrom(Vector3f.ZERO)

        outputEvents += checkAggro(actorState)

        syncDummyDependents(actorState)

        return outputEvents
    }

    private fun updateCastingState(actorState: ActorState): List<Event> {
        val castingState = actorState.getCastingState() ?: return emptyList()

        val shouldInterrupt = actorState.velocity.magnitudeSquare() > 0f || actorState.isDead() || actorState.isResting() || actorState.hasStatusActionLock()
        if (shouldInterrupt) { castingState.interrupted = true }

        castingState.update(elapsedFrames)

        if (castingState.isDoneCharging()) {
            return listOf(CastingChargeCompleteEvent(sourceId))
        }

        if (castingState.isComplete()) {
            actorState.setCastingState(null)
        }

        return emptyList()
    }

    private fun updateRecastState(actorState: ActorState) {
        actorState.getRecastStates().update(elapsedFrames)
    }

    private fun updateRestingState(actorState: ActorState) {
        actorState.getRestingState().update(elapsedFrames)
    }

    private fun updateSynthesisState(actorState: ActorState): List<Event> {
        val synthesisStateMachine = actorState.getSynthesisState() ?: return emptyList()
        synthesisStateMachine.update(elapsedFrames)

        return if (synthesisStateMachine.isReadyForResult() && synthesisStateMachine.result == null) {
            listOf(SynthesisCompleteEvent(sourceId, SynthesisState.getRandomSuccessResult()))
        } else if (synthesisStateMachine.isComplete()) {
            actorState.setSynthesisState(null)
            emptyList()
        } else {
            emptyList()
        }
    }

    private fun updateBehavior(actorState: ActorState): List<Event> {
        return actorState.behaviorController.update(elapsedFrames)
    }

    private fun updateVelocity(actorState: ActorState): List<Event> {
        val newVelocity = Vector3f(actorState.getControllerVelocity(elapsedFrames))
        val outputEvents = ArrayList<Event>()

        if (actorState.getRestingState().isMovementLocked() && newVelocity.magnitudeSquare() > 0f) {
            outputEvents += RestingEndEvent(sourceId)
            newVelocity.copyFrom(Vector3f.ZERO)
        } else if (actorState.hasStatusMovementLock()) {
            newVelocity.copyFrom(Vector3f.ZERO)
        } else if (!actorState.isPlayer() && actorState.isCasting()) {
            newVelocity.copyFrom(Vector3f.ZERO)
        } else if (actorState.isSynthesizing()) {
            newVelocity.copyFrom(Vector3f.ZERO)
        } else if (actorState.isDead()) {
            newVelocity.copyFrom(Vector3f.ZERO)
        } else if (ZoneChanger.isChangingZones()) {
            newVelocity.copyFrom(Vector3f.ZERO)
        }

        newVelocity += actorState.effectVelocity
        actorState.velocity.copyFrom(newVelocity)

        return outputEvents
    }

    private fun updateDeathStatus(actorState: ActorState): List<Event> {
        actorState.updateDeathTimer(elapsedFrames)
        if (!actorState.isDead()) { return emptyList() }

        val outputEvents = ArrayList<Event>()

        if (actorState.isPlayer() && actorState.hasBeenDeadFor(60.seconds)) {
            ZoneChanger.returnToHomePoint(restore = true)
        } else if (!actorState.isPlayer() && actorState.hasBeenDeadFor(15.seconds)) {
            outputEvents += ActorDeleteEvent(sourceId)
        } else if ((actorState.isEnemy() || actorState.isDependent()) && actorState.isDead() && actorState.hasBeenDeadFor(10.seconds)) {
            ActorManager[actorState.id]?.fadeAway()
        }

        return outputEvents
    }

    private fun moveActor(actor: ActorState) {
        val currentScene = SceneManager.getCurrentScene()

        val collisionResults = if (actor.isStaticNpc()) {
            FrameCoherence.getNpcCollision(actor.id) { ActorCollision(currentScene.moveActor(actor, elapsedFrames)) }
        } else {
            ActorCollision(currentScene.moveActor(actor, elapsedFrames))
        }

        actor.lastCollisionResult = if (collisionResults.collisionsByArea.entries.any { it.value.isNotEmpty() }) {
            collisionResults
        } else if (Fps.framesToSeconds(actor.lastCollisionResult.freeFallDuration + elapsedFrames) > 1.seconds) {
            ActorCollision(
                collisionsByArea = emptyMap(),
                freeFallDuration = actor.lastCollisionResult.freeFallDuration + elapsedFrames)
        } else {
            ActorCollision(
                collisionsByArea = actor.lastCollisionResult.collisionsByArea,
                freeFallDuration = actor.lastCollisionResult.freeFallDuration + elapsedFrames
            )
        }
    }

    private fun syncDummyDependents(actorState: ActorState) {
        val bubble = actorState.bubble
        val mount = actorState.mountedState?.id
        if (bubble == null && mount == null) { return }

        val dependents = listOfNotNull(actorState.bubble, actorState.mountedState?.id)
        for (dependentId in dependents) {
            val dependent = ActorStateManager[dependentId] ?: continue
            dependent.position.copyFrom(actorState.position)
            dependent.rotation = actorState.rotation
            dependent.lastCollisionResult = actorState.lastCollisionResult
            dependent.velocity.copyFrom(actorState.velocity)
        }
    }

    private fun updateRotation(actorState: ActorState) {
        val strafing = ActorManager[actorState.id]?.isStrafing() ?: false

        val targetState = actorState.targetState
        if (targetState.locked && targetState.targetId != null) {
            val target = ActorStateManager[targetState.targetId] ?: return
            actorState.setRotation(target.position - actorState.position)
        } else if (strafing) {
            actorState.setRotation(CameraReference.getInstance().getViewVector())
        } else if (actorState.velocity.magnitudeSquare() > 1e-5f) {
            actorState.setRotation(actorState.velocity)
        }
    }

    private fun updateStatusStatus(actorState: ActorState): List<Event> {
        return actorState.updateStatusEffects(elapsedFrames)
            .map { StatusEffectLostEvent(sourceId = sourceId, statusEffectState = it) }
    }

    private fun updateEngageState(actorState: ActorState): List<Event> {
        val target = ActorManager[actorState.targetState.targetId]

        return if (actorState.isEngaged() && (target == null || target.isDisplayedDead())) {
            if (actorState.type == ActorType.Pc) {
                listOf(ActorTargetEvent(sourceId = sourceId, targetId = null))
            } else {
                listOf(BattleDisengageEvent(sourceId))
            }
        } else {
            return emptyList()
        }
    }

    private fun updateDoorState(actorState: ActorState): List<Event> {
        val doorState = actorState.doorState
        doorState.update(elapsedFrames)

        return if (doorState.isReadyToAutoClose()) {
            val doorId = actorState.getNpcInfo()?.datId ?: return emptyList()
            listOf(DoorCloseEvent(doorId))
        } else {
            emptyList()
        }
    }

    private fun updateGatheringState(actorState: ActorState) {
        actorState.gatheringState.update(elapsedFrames)
    }

    private fun updateSkillChainState(actorState: ActorState) {
        actorState.skillChainTargetState.update(elapsedFrames)
    }

    private fun updateCombatStats(actorState: ActorState) {
        if (actorState.isStaticNpc()) { return }
        actorState.updateCombatStats(GameEngine.computeCombatStats(actorState.id))
    }

    private fun updateEffectTickTimer(actorState: ActorState): List<Event> {
        if (actorState.isStaticNpc()) { return emptyList() }

        return if (actorState.effectTickTimer.update(elapsedFrames)) {
            listOf(ActorEffectTick(sourceId))
        } else {
            emptyList()
        }
    }

    private fun checkAggro(actorState: ActorState): List<Event> {
        val targetId = MonsterAggroHelper.getAggroTarget(actorState) ?: return emptyList()
        return listOf(BattleEngageEvent(sourceId = actorState.id, targetId = targetId))
    }

}