package xim.poc.ui

import xim.math.Vector3f
import xim.poc.*
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.GameEngine
import xim.poc.game.configuration.ActorBehaviors
import xim.poc.game.configuration.SkillRangeInfo
import xim.poc.game.event.InitialActorState
import xim.poc.gl.Color
import xim.resource.AoeType
import xim.resource.DatId
import xim.resource.Particle

enum class AoeIndicatorColor {
    Normal,
    Red,
}

private class ClaimedIndicator(val indicator: AoeIndicator, val completionFn: () -> Boolean)

object AoeIndicators {

    private val indicators = ArrayList<AoeIndicator>(8)
    private val claimed = ArrayList<ClaimedIndicator>()

    init {
        for (i in 0 until 8) { indicators += AoeIndicator() }
    }

    fun update() {
        val itr = claimed.iterator()
        while (itr.hasNext()) {
            val it = itr.next()
            it.indicator.update()

            if (!it.completionFn.invoke()) { continue }

            it.indicator.hide()
            indicators += it.indicator
            itr.remove()
        }
    }

    fun claim(sourceId: ActorId, targetId: ActorId, aoeSettings: SkillRangeInfo, colorMode: AoeIndicatorColor = AoeIndicatorColor.Normal, completionFn: () -> Boolean) {
        if (aoeSettings.type == AoeType.None) { return }
        val indicator = indicators.removeLastOrNull() ?: return
        indicator.configure(sourceId, targetId, aoeSettings, colorMode)
        indicator.show()
        claimed += ClaimedIndicator(indicator, completionFn)
    }

}

class AoeIndicator {

    private var id: ActorId? = null

    private var shouldShow = false
    private var sourceId: ActorId? = null
    private var targetId: ActorId? = null

    private var aoeSettings = SkillRangeInfo(0f, 0f, type = AoeType.None)
    private val scale = Vector3f(10f, 1f, 10f)
    private var colorMode = AoeIndicatorColor.Normal

    fun update() {
        val indicator = ActorStateManager[id] ?: return makeActor()

        val sourceState = ActorStateManager[sourceId] ?: return hide()
        val targetState = ActorStateManager[targetId] ?: return hide()

        indicator.rotation = sourceState.rotation
        indicator.position.copyFrom(if (sourceCentered()) { sourceState.position } else { targetState.position})
        indicator.position.y -= 0.05f

        ActorManager[id]?.syncFromState()
    }

    fun configure(sourceId: ActorId, targetId: ActorId, aoeSettings: SkillRangeInfo, colorMode: AoeIndicatorColor = AoeIndicatorColor.Normal) {
        this.sourceId = sourceId
        this.targetId = targetId
        this.colorMode = colorMode

        val previousSettings = this.aoeSettings
        this.aoeSettings = aoeSettings

        scale.x = aoeSettings.effectRadius
        scale.z = aoeSettings.effectRadius

        if (previousSettings.type != aoeSettings.type) {
            hide()
            show()
        }
    }

    fun hide() {
        shouldShow = false
        val currentActor = ActorManager[id] ?: return
        EffectManager.removeEffectsForAssociation(ActorAssociation(currentActor))
    }

    fun show() {
        shouldShow = true
        val currentActor = ActorManager[id] ?: return
        val context = ActorContext(originalActor = currentActor.id, effectScaleMultiplier = scale, effectColorOverride = this::colorFn)
        currentActor.onReadyToDraw { it.enqueueModelRoutine(routineId(), context) }
    }

    private fun routineId(): DatId {
        return if (aoeSettings.type == AoeType.Cone) { DatId("con1") } else { DatId("con0") }
    }

    private fun makeActor() {
        GameEngine.submitCreateActorState(
            InitialActorState(
                name = "",
                type = ActorType.Effect,
                position = Vector3f(),
                modelLook = ModelLook.npc(0x990),
                movementController = NoOpActorController(),
                behaviorController = ActorBehaviors.NoAction,
            )
        ).onReady {
            id = it.id
            val actor = ActorManager.getOrCreate(it)
            actor.onReadyToDraw { showIfNeeded() }
        }
    }

    private fun showIfNeeded() {
        if (shouldShow) { show() }
    }

    private fun sourceCentered(): Boolean {
        return aoeSettings.type == AoeType.Cone || aoeSettings.type == AoeType.Source
    }

    private fun colorFn(particle: Particle, input: Color): Color {
        return when (colorMode) {
            AoeIndicatorColor.Normal -> input
            AoeIndicatorColor.Red -> Color(input.b(), input.g(), input.r(), input.a())
        }
    }

}