package xim.poc.game.configuration

import xim.poc.ActorId
import xim.poc.SynthesisType
import xim.poc.audio.BackgroundMusicResponse
import xim.poc.game.*
import xim.poc.game.event.ActorEffectTickResult
import xim.poc.game.event.AutoAttackResult
import xim.poc.game.event.Event
import xim.poc.game.event.InitialActorState
import xim.poc.tools.ZoneConfig
import xim.poc.ui.InventoryItemDescription
import xim.resource.AbilityCost
import xim.resource.AbilityInfo
import xim.resource.AbilityType
import xim.resource.SpellInfo

typealias GameModeId = String

class GameConfiguration(
    val gameModeId: GameModeId,
    val startingZoneConfig: ZoneConfig,
    val debugControlsEnabled: Boolean,
)

interface GameLogic {

    val configuration: GameConfiguration

    fun setup(): List<Event>

    fun update(elapsedFrames: Float)

    fun onChangedZones(zoneConfig: ZoneConfig)

    fun getNpcInteraction(actorId: ActorId): NpcInteraction?

    fun getActorSpellList(actorId: ActorId): List<SpellInfo>

    fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo>

    fun canDualWield(actorState: ActorState): Boolean

    fun getActorCombatStats(actorId: ActorId): CombatStats

    fun generateItem(itemId: Int, quantity: Int = 1, temporary: Boolean = false): InventoryItem

    fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription

    fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult>

    fun getAutoAttackInterval(attacker: ActorState): Float

    fun getRangedAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult>

    fun getRangedAttackInterval(attacker: ActorState): Float

    fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int

    fun getAugmentRankPointsNeeded(augment: ItemAugment): Int

    fun onAugmentRankUp(actorState: ActorState, inventoryItem: InventoryItem) { }

    fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int

    fun getExperiencePointsNeeded(currentLevel: Int): Int

    fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult

    fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int>

    fun getSkillRangeInfo(actorState: ActorState, skillType: SkillType, skillId: Int): SkillRangeInfo

    fun getSkillBaseCost(skillType: SkillType, skillId: Int): AbilityCost

    fun getSkillUsedCost(actorState: ActorState, skillType: SkillType, skillId: Int): AbilityCost

    fun getSkillCastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float

    fun getSkillRecastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float

    fun getSkillChainAttributes(attacker: ActorState, defender: ActorState, skillType: SkillType, skillId: Int): List<SkillChainAttribute>

    fun getSkillChainDamage(attacker: ActorState, defender: ActorState, skillChain: SkillChain, closingWeaponSkillDamage: Int): Int

    fun getActorCombatBonuses(actorState: ActorState): CombatBonusAggregate

    fun getCurrentBackgroundMusic(): BackgroundMusicResponse

    fun rollParry(attacker: ActorState, defender: ActorState): Boolean

    fun rollGuard(attacker: ActorState, defender: ActorState): Boolean

    fun rollSpellInterrupted(attacker: ActorState, defender: ActorState): Boolean

    fun getMaxTp(actor: ActorState): Int {
        return 3000
    }

    fun spawnMonster(monsterId: MonsterId, initialActorState: InitialActorState): ActorPromise

    fun getKnownSynthesisRecipes(actorState: ActorState, type: SynthesisType): List<SynthesisRecipe>

}