package xim.poc.game.configuration.v0.behaviors

import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.configuration.Skill
import xim.poc.game.configuration.SkillApplierHelper
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.Event
import xim.poc.gl.ByteColor
import xim.util.Fps
import xim.util.addInPlace
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

class MobSnollTzarBehavior(actorState: ActorState): BasicMonsterController(actorState) {

    private var framesSinceEngaged = 0f
    private var timeSinceEngaged = Duration.ZERO

    override fun update(elapsedFrames: Float): List<Event> {
        if (actorState.isEngaged()) {
            framesSinceEngaged += elapsedFrames
            timeSinceEngaged = Fps.framesToSeconds(framesSinceEngaged)
            adjustSize()
        }

        return super.update(elapsedFrames)
    }

    override fun shouldStartUsingSkill(): Boolean {
        return if (timeSinceEngaged > 65.seconds) {
            return true
        } else {
            super.shouldStartUsingSkill()
        }
    }

    override fun selectSkill(): Skill? {
        val model = ActorManager[actorState.id]
        if (model != null && model.isMovementOrAnimationLocked()) { return null }

        if (actorState.appearanceState == 3) {
            return Skill(SkillType.MobSkill, 346)
        }

        val skills = if (!actorState.hasStatusEffect(StatusEffect.Berserk)) {
            listOf(342)
        } else if (actorState.appearanceState <= 1) {
            listOf(343, 344, 345)
        } else {
            listOf(344, 345)
        }

        val skill = skills.filter { GameEngine.canBeginUseMobSkill(actorState.id, it) }.randomOrNull() ?: return null
        return Skill(SkillType.MobSkill, skill)
    }

    override fun onSkillExecuted(primaryTargetContext: SkillApplierHelper.TargetEvaluatorContext): List<Event> {
        if (primaryTargetContext.skill.id != 346) { return emptyList() }

        primaryTargetContext.sourceState.setHp(primaryTargetContext.sourceState.getMaxHp())
        primaryTargetContext.targetState.setTargetState(null, false)

        AttackContext.compose(primaryTargetContext.context) {
            ActorManager[primaryTargetContext.sourceState.id]?.renderState?.effectColor = ByteColor.zero
        }

        return emptyList()
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.statusResistances.addInPlace(StatusEffect.Sleep, 100)

        if (actorState.appearanceState == 3) {
            aggregate.knockBackResistance += 100
            aggregate.statusResistances.addInPlace(StatusEffect.Stun, 100)
            aggregate.statusResistances.addInPlace(StatusEffect.Sleep, 100)
            aggregate.statusResistances.addInPlace(StatusEffect.Paralysis, 100)

            val cleanseEffects = setOf(StatusEffect.Stun, StatusEffect.Paralysis, StatusEffect.Sleep)

            actorState.getStatusEffects()
                .filter { cleanseEffects.contains(it.statusEffect) }
                .forEach { actorState.expireStatusEffect(it.statusEffect) }
        }

    }

    private fun adjustSize() {
        if (!actorState.isIdleOrEngaged()) { return }

        actorState.appearanceState = if (timeSinceEngaged > 60.seconds) {
            3
        } else if (timeSinceEngaged > 40.seconds) {
            2
        } else if (timeSinceEngaged > 20.seconds) {
            1
        } else {
            0
        }
    }

}