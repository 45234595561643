package xim.poc.game.event

import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.*
import xim.poc.game.configuration.SkillRangeInfo
import xim.poc.game.configuration.SkillType
import xim.poc.ui.AoeIndicatorColor
import xim.poc.ui.AoeIndicators
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.resource.AoeType
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import xim.util.Fps

class CastSpellStart(
    val sourceId: ActorId,
    val targetId: ActorId,
    val spellId: Int,
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        val targetState = ActorStateManager[targetId] ?: return emptyList()

        val spellInfo = SpellInfoTable[spellId]

        if (!GameEngine.canBeginCastSpell(sourceId, spellInfo)) { return emptyList() }

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castTime = GameState.getGameMode().getSkillCastTime(actorState, SkillType.Spell, spellId)
        val rangeInfo = GameState.getGameMode().getSkillRangeInfo(actorState, SkillType.Spell, spellId)

        val castingState = CastingState.spell(castTime = castTime, sourceId = sourceId, targetId = targetId, spellInfo = spellInfo)
        actorState.setCastingState(castingState)

        ActorManager[sourceId]?.startCasting(spellInfo, targetId)

        val spellName = SpellNameTable.first(spellInfo.index)
        ChatLog.addLine("${actorState.name} starts casting $spellName on ${targetState.name}.", ChatLogColor.Action)

        if (castTime > Fps.millisToFrames(100) && rangeInfo.type != AoeType.None) {
            maybeClaimAoeIndicator(actorState, rangeInfo)
        }

        return emptyList()
    }

    private fun maybeClaimAoeIndicator(actorState: ActorState, rangeInfo: SkillRangeInfo) {
        if (actorState.isPlayer()) {
            AoeIndicators.claim(sourceId, targetId, rangeInfo, AoeIndicatorColor.Normal) { !actorState.isCasting() }
            return
        }

        val isPlayerEnemy = ActionTargetFilter.areEnemies(ActorStateManager.player(), actorState)
        if (isPlayerEnemy && rangeInfo.type != AoeType.None) {
            AoeIndicators.claim(sourceId, targetId, rangeInfo, AoeIndicatorColor.Red) { !actorState.isCasting() }
        }
    }

}