package xim.poc.game.configuration.assetviewer

import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.ActorBehaviors.register
import xim.poc.game.configuration.AutoAttackController
import xim.poc.game.event.CastAbilityStart
import xim.poc.game.event.CastRangedAttackStart
import xim.poc.game.event.CastSpellStart
import xim.poc.game.event.Event
import xim.resource.table.AbilityInfoTable
import xim.resource.table.SpellInfoTable

object AssetViewerTrustBehaviors {

    fun register() {
        register(898) { HealerBehavior(it) }
        register(902) { CurillaBehavior(it) }
        register(911) { RangedAttackerBehavior(it) }
        register(940) { RangedAttackerBehavior(it) }
        register(1014) { RangedAttackerBehavior(it) }
    }

}

interface TrustBehaviorController: ActorBehaviorController {

    fun getEngagedDistance(): Pair<Float, Float>? {
        return null
    }

}

class HealerBehavior(val actor: ActorState): TrustBehaviorController {

    private val healingSpell = 1

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actor.isIdleOrEngaged()) { return emptyList() }

        val spellInfo = SpellInfoTable[healingSpell]
        if (!GameEngine.canBeginCastSpell(actor.id, spellInfo)) { return emptyList() }

        val party = PartyManager[actor.id]

        val members = party.getAllState()
        val lowestHealthMember = members.minByOrNull { it.getHpp() } ?: return emptyList()

        if (lowestHealthMember.getHpp() > 0.5f) { return emptyList() }
        return listOf(CastSpellStart(actor.id, lowestHealthMember.id, spellInfo.index))
    }

    override fun getEngagedDistance(): Pair<Float, Float> {
        return Pair(8f, 9f)
    }

}

class RangedAttackerBehavior(val actor: ActorState): TrustBehaviorController {

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actor.isIdleOrEngaged()) { return emptyList() }
        if (!actor.isEngaged()) { return emptyList() }

        val (canBegin, _) = GameEngine.canBeginRangedAttack(actor.id)
        if (!canBegin) { return emptyList() }

        val targetId = actor.targetState.targetId ?: return emptyList()
        return listOf(CastRangedAttackStart(actor.id, targetId))
    }

    override fun getEngagedDistance(): Pair<Float, Float> {
        return Pair(8f, 9f)
    }

}

class CurillaBehavior(val actor: ActorState): TrustBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actor)

    override fun update(elapsedFrames: Float): List<Event> {
        val output = useProvokeIfNeeded()
        if (output.isNotEmpty()) { return output }

        return autoAttackDelegate.update(elapsedFrames)
    }

    private fun useProvokeIfNeeded(): List<Event> {
        if (!actor.isIdleOrEngaged()) { return emptyList() }
        if (!actor.isEngaged()) { return emptyList() }

        val abilityInfo = AbilityInfoTable[AbilityId.Provoke]
        if (!GameEngine.canBeginUseAbility(actor.id, abilityInfo)) { return emptyList() }

        val target = ActorStateManager[actor.targetState.targetId] ?: return emptyList()
        if (!abilityInfo.targetFilter.targetFilter(actor, target)) { return emptyList() }

        if (target.targetState.targetId == actor.id) { return emptyList() }

        return listOf(CastAbilityStart(actor.id, target.id, abilityInfo.index))
    }

}
