package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.game.ActorState
import xim.poc.game.CombatStat
import xim.poc.game.StatusEffect
import xim.poc.game.configuration.*
import xim.poc.game.configuration.SkillApplierHelper.TargetEvaluator.Companion.compose
import xim.poc.game.configuration.v0.MobSkill.Companion.zeroCost
import xim.poc.game.configuration.v0.V0AbilityDefinitions.weaponSkillToEvents
import xim.poc.game.configuration.v0.V0SpellDefinitions.applyBasicBuff
import xim.poc.game.configuration.v0.V0SpellDefinitions.basicHealingMagic
import xim.poc.game.configuration.v0.behaviors.MobClusterBehavior
import xim.poc.game.configuration.v0.behaviors.MobLimuleAbilities
import xim.poc.game.event.*
import xim.resource.AbilityCost
import xim.resource.AbilityCostType
import xim.resource.AoeType
import xim.util.Fps
import kotlin.math.ceil
import kotlin.math.roundToInt
import kotlin.time.Duration
import kotlin.time.Duration.Companion.ZERO
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

class MobSkill(
    val id: Int,
    val rangeInfo: SkillRangeInfo,
    val castTime: Duration = 2.seconds,
    val cost: V0AbilityCost = defaultCost,
) {
    companion object {
        val zeroCost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Tp, value = 0), consumesAll = false)
        val defaultCost = V0AbilityCost(baseCost = AbilityCost(type = AbilityCostType.Tp, value = 300), consumesAll = true)
    }
}

object MobSkills {

    private val definitions = HashMap<Int, MobSkill>()

    fun register(mobSkill: MobSkill) {
        definitions[mobSkill.id] = mobSkill
    }

    operator fun plusAssign(mobSkill: MobSkill) = register(mobSkill)

    operator fun get(id: Int): MobSkill {
        return definitions[id] ?: throw IllegalStateException("Mob skill $id was not defined")
    }

}

object V0MobSkillDefinitions {

    fun register() {
        // Rabbit: Foot Kick
        MobSkills += MobSkill(id = 1, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 1, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp },
        )

        // Rabbit: Dust Cloud
        MobSkills += MobSkill(id = 2, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Blind, baseDuration = 15.seconds) { it.statusState.potency = 25 })
            ) { 2f + it.excessTp },
        )

        // Rabbit: Whirl Claw
        MobSkills += MobSkill(id = 3, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 3, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.5f + it.excessTp },
        )

        // Rabbit: Wild Carrot
        MobSkills += MobSkill(id = 67, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 67, skillType = SkillType.MobSkill,
            targetEvaluator = basicHealingMagic(potency = 1f, maxAmount = 300),
        )

        // Rabbit: Snow Cloud
        MobSkills += MobSkill(id = 405, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 405, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage(
            attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Paralysis, baseDuration = 15.seconds) { it.statusState.potency = 25 })
            ) { 2f + it.excessTp },
        )

        // Sheep: Lamb Chop
        MobSkills += MobSkill(id = 4, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 4, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.5f + it.excessTp },
        )

        // Sheep: Rage
        MobSkills += MobSkill(id = 5, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 5, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Berserk, duration = 3.minutes),
        )

        // Sheep: Sheep Charge
        MobSkills += MobSkill(id = 6, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 6, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(
                attackEffects = AttackEffects(knockBackMagnitude = 2)
            ) { 2f + it.excessTp },
        )

        // Sheep: Sheep Song
        MobSkills += MobSkill(id = 8, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 8, skillType = SkillType.MobSkill, targetEvaluator = basicDebuff(
            attackStatusEffect = AttackStatusEffect(StatusEffect.Sleep, baseDuration = 18.seconds)
        ))

        // Tiger: Roar
        MobSkills += MobSkill(id = 14, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 14, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Paralysis, baseDuration = 15.seconds) {
                it.statusState.potency = 50
            }),
        )

        // Tiger: Razor Fang
        MobSkills += MobSkill(id = 15, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 15, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp }
        )

        // Tiger: Claw Cyclone
        MobSkills += MobSkill(id = 17, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 15, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.5f + it.excessTp }
        )

        // Opo-opo: Vicious Claw
        MobSkills += MobSkill(id = 32, castTime = 3.seconds, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 32, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.25f + it.excessTp * 1f }
        )

        // Opo-opo: Spinning Claw
        MobSkills += MobSkill(id = 34, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 34, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.5f + it.excessTp * 1f }
        )

        // Opo-opo: Claw Storm
        MobSkills += MobSkill(id = 35, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 35, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage(
                attackEffects = singleStatus(spellDamageDoT(StatusEffect.Poison, potency = 0.5f, duration = 15.seconds))
            ) { 1.75f + it.excessTp * 0.75f }
        )

        // Opo-opo: Blank Gaze
        MobSkills += MobSkill(id = 36, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 36, skillType = SkillType.MobSkill, targetEvaluator = basicDebuff(
            attackEffects = AttackEffects(gazeAttack = true, dispelCount = 2)
        ))

        // Opo-opo: Eye Scratch
        MobSkills += MobSkill(id = 38, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 38, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage (
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Blind, baseDuration = 15.seconds) { it.statusState.potency = 25 })
            ) { 1f + 0.5f * it.excessTp },
        )

        // Opo-opo: Magic Fruit
        MobSkills += MobSkill(id = 39, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 39, skillType = SkillType.MobSkill,
            targetEvaluator = basicHealingMagic(potency = 1f, maxAmount = 200),
        )

        // Mandragora: Head Butt
        MobSkills += MobSkill(id = 44, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 44, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 1.75f + it.excessTp },
        )

        // Mandragora: Dream Flower
        MobSkills += MobSkill(id = 45, rangeInfo = SkillRangeInfo( 6f, 6f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 45, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Sleep, baseDuration = 18.seconds))
        )

        // Mandragora: Wild Oats
        MobSkills += MobSkill(id = 46, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 46, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.VitDown, baseDuration = 15.seconds) {
                    it.statusState.secondaryPotency = 0.8f
                })
            ) { 1f + it.excessTp },
        )

        // Mandragora: Photosynthesis
        MobSkills += MobSkill(id = 48, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 48, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Regen, duration = 18.seconds) {
                it.status.potency = (it.context.sourceState.getMaxHp() / 20f).roundToInt().coerceAtLeast(1)
            }
        )

        // Mandragora: Leaf Dagger
        MobSkills += MobSkill(id = 49, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 49, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(spellDamageDoT(StatusEffect.Poison, potency = 0.5f, duration = 15.seconds))
            ) { 1f + it.excessTp },
        )

        // Mandragora: Scream
        MobSkills += MobSkill(id = 50, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 50, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.MndDown, baseDuration = 15.seconds) {
                it.statusState.secondaryPotency = 0.8f
            }),
        )

        // Fly: Somersault
        MobSkills += MobSkill(id = 62, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 62, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp },
        )

        // Treant: Drillbranch
        MobSkills += MobSkill(id = 72, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 72, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp },
        )

        // Treant: Leafstorm
        MobSkills += MobSkill(id = 75, castTime = 5.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 75, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 2f + it },
        )

        // Treant: Entangle
        MobSkills += MobSkill(id = 76, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 76, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Bind, baseDuration = 5.seconds)
            )) { 1.5f + it },
        )

        // Bee: Sharp sting
        MobSkills += MobSkill(id = 78, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 78, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp },
        )

        // Bee: Pollen
        MobSkills += MobSkill(id = 79, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 79, skillType = SkillType.MobSkill,
            targetEvaluator = basicHealingMagic(potency = 0.5f, maxAmount = 30),
        )

        // Bee: Final Sting
        MobSkills += MobSkill(id = 80, castTime = 5.seconds, rangeInfo = SkillRangeInfo(32f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 80, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = {
                it.sourceState.appearanceState = 1
                listOf(ActorAttackedEvent(it.sourceState.id, it.sourceState.id, damageAmount = listOf(it.sourceState.getHp()), damageType = AttackDamageType.Physical, skill = it.skill, actionContext = it.context))
            },
            targetEvaluator = {
                listOf(ActorAttackedEvent(it.sourceState.id, it.targetState.id, damageAmount = listOf(it.sourceState.getHp()/2), damageType = AttackDamageType.Physical, skill = it.skill, actionContext = it.context))
            }
        )

        // Raptor: Ripper Fang
        MobSkills += MobSkill(id = 118, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 118, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(
                AttackStatusEffect(statusEffect = StatusEffect.Slow, baseDuration = 6.seconds) { it.statusState.potency = 25 })
            ) { 2f + it.excessTp },
        )

        // Raptor: Frost Breath
        MobSkills += MobSkill(id = 121, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 121, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackStat = CombatStat.int, defendStat = CombatStat.int, attackEffects = singleStatus(
                AttackStatusEffect(statusEffect = StatusEffect.Paralysis, baseDuration = 15.seconds) { it.statusState.potency = 50 })
            ) { 2f },
        )

        // Raptor: Chomp Rush
        MobSkills += MobSkill(id = 123, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 123, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true,
                attackEffects = singleStatus(AttackStatusEffect(statusEffect = StatusEffect.Slow, baseDuration = 6.seconds) { it.statusState.potency = 25 })
            ) { 0.8f + 0.2f * it.excessTp },
        )

        // Raptor: Scythe Tail
        MobSkills += MobSkill(id = 124, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 124, skillType = SkillType.MobSkill, targetEvaluator =
            basicPhysicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 2)) { 3f + 1f * it.excessTp },
        )

        // Flock Bats: Sonic Boom
        MobSkills += MobSkill(id = 137, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 137, skillType = SkillType.MobSkill, targetEvaluator = basicDebuff(attackEffects = singleStatus(
            attackStatusEffect = AttackStatusEffect(StatusEffect.AttackDown, baseDuration = 15.seconds) {
                it.statusState.potency = 50
            }
        )))

        // Flock Bats: Jet Stream
        MobSkills += MobSkill(id = 139, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 139, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { 0.8f + 0.66f * it.excessTp },
        )

        // Leech: Suction
        MobSkills += MobSkill(id = 158, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 158, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage { 1.5f + it.excessTp })

        // Leech: Acid Mist
        MobSkills += MobSkill(id = 159, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 159, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(attackEffects = singleStatus(
            attackStatusEffect = AttackStatusEffect(StatusEffect.StrDown, baseDuration = 18.seconds) {
                it.statusState.secondaryPotency = 0.25f
            }
        )) { 1.5f + 0.5f * it })

        // Leech: Sand Breath
        MobSkills += MobSkill(id = 160, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 160, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage { 2f + it.excessTp })

        // Leech: Drain Kiss
        MobSkills += MobSkill(id = 161, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 161, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(attackEffects = AttackEffects(
            absorption = 1f,
        )) { 1.5f + 0.5f * it })

        // Leech: Regeneration
        MobSkills += MobSkill(id = 162, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 162, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Regen, duration = 18.seconds) {
                it.status.potency = (it.context.sourceState.getMaxHp() / 20f).roundToInt().coerceAtLeast(1)
            }
        )

        // Leech: MP Drain Kiss
        MobSkills += MobSkill(id = 165, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 165, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(attackEffects = AttackEffects(
            absorption = 1f,
            damageResource = ActorResourceType.MP,
        )) { 1.5f + 0.5f * it })

        // Slime: Fluid Spread
        MobSkills += MobSkill(id = 175, castTime = 3.seconds, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 175, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage{ 3f + it },
        )

        // Slime: Digest
        MobSkills += MobSkill(id = 177, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 177, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackEffects = AttackEffects(absorption = 1f)) { 1.5f + it },
        )

        // Crab: Bubble Shower
        MobSkills += MobSkill(id = 186, rangeInfo = SkillRangeInfo(10f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 186, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.StrDown, baseDuration = 15.seconds) {
                it.statusState.secondaryPotency = 0.5f
            })
        ) { 1f })

        // Crab: Bubble Curtain
        MobSkills += MobSkill(id = 187, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 187, skillType = SkillType.MobSkill, targetEvaluator = applyBasicBuff(StatusEffect.MagicDefBoost, 30.seconds) {
            it.status.potency = 50
        })

        // Crab: Big Scissors
        MobSkills += MobSkill(id = 188, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 188, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage { 1.5f })

        // Crab: Scissor Guard
        MobSkills += MobSkill(id = 189, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 189, skillType = SkillType.MobSkill, targetEvaluator = applyBasicBuff(StatusEffect.DefenseBoost, 30.seconds) {
            it.status.potency = 50
        })

        // Crab: Metallic Body
        MobSkills += MobSkill(id = 192, rangeInfo = SkillRangeInfo(32f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 192, skillType = SkillType.MobSkill, targetEvaluator = applyBasicBuff(StatusEffect.Stoneskin, 5.minutes) {
            it.status.counter = it.context.sourceState.getMaxHp() / 4
        })

        // Pugil: Intimidate
        MobSkills += MobSkill(id = 193, rangeInfo = SkillRangeInfo( 12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 193, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Slow, baseDuration = 30.seconds) { it.statusState.potency = -30 })
        )

        // Pugil: Splash Breath
        MobSkills += MobSkill(id = 195, rangeInfo = SkillRangeInfo( 12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 195, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 2.5f + it * 1.5f }
        )

        // Pugil: Screwdriver
        MobSkills += MobSkill(id = 196, castTime = 3.seconds, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 196, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2.5f + it.excessTp }
        )

        // Pugil: Water Wall
        MobSkills += MobSkill(id = 197, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 197, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(StatusEffect.DefenseBoost, duration = 18.seconds) { it.status.potency = 50 }
        )

        // Sea Monk: Tentacle
        MobSkills += MobSkill(id = 200, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 200, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 1.5f + it.excessTp },
        )

        // Sea Monk: Ink Jet
        MobSkills += MobSkill(id = 202, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 202, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Blind, baseDuration = 15.seconds) { it.statusState.potency = 20 })
            ) { 1f + 0.5f * it },
        )

        // Sea Monk: Cross Attack
        MobSkills += MobSkill(id = 204, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 204, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 2) { 1f + it.excessTp },
        )

        // Sea Monk: Regeneration
        MobSkills += MobSkill(id = 205, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 205, skillType = SkillType.MobSkill, targetEvaluator = applyBasicBuff(StatusEffect.Regen, 18.seconds) {
            it.status.potency = (it.context.sourceState.getMaxHp() * 0.05f).roundToInt()
        })

        // Sea Monk: Maelstrom
        MobSkills += MobSkill(id = 206, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 206, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.StrDown, baseDuration = 15.seconds) { it.statusState.secondaryPotency = 0.8f })
            ) { 2.5f + it },
        )

        // Sea Monk: Whirlwind
        MobSkills += MobSkill(id = 207, rangeInfo = SkillRangeInfo(16f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 207, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.VitDown, baseDuration = 15.seconds) { it.statusState.secondaryPotency = 0.8f })
            ) { 1.5f + 0.5f * it },
        )

        // Buffalo Rampant Gnaw
        MobSkills += MobSkill(id = 237, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 237, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(
                AttackStatusEffect(statusEffect = StatusEffect.Paralysis, baseDuration = 6.seconds) { it.statusState.potency = 25 })
            ) { 2f + it.excessTp },
        )

        // Buffalo: Big Horn
        MobSkills += MobSkill(id = 238, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 238, skillType = SkillType.MobSkill, targetEvaluator =
            basicPhysicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 6)) { 2f + 1f * it.excessTp },
        )

        // Buffalo: Snort
        MobSkills += MobSkill(id = 239, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 239, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage (
            attackEffects = AttackEffects(knockBackMagnitude = 8)) { 3f + 1f * it },
        )

        // Buffalo: Lowing
        MobSkills += MobSkill(id = 241, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 241, skillType = SkillType.MobSkill, targetEvaluator = basicDebuff(attackEffects = singleStatus(
            attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Plague, baseDuration = 18.seconds) {
                it.statusState.potency = 5
            }),
        ))

        // Snoll: Berserk
        MobSkills += MobSkill(id = 270, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 270, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Berserk, duration = 3.minutes),
        )

        // Snoll: Freeze Rush
        MobSkills += MobSkill(id = 271, rangeInfo = SkillRangeInfo(12f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 271, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage { 2f + it } )

        // Snoll: Coldwave
        MobSkills += MobSkill(id = 272, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 272, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Frost, baseDuration = 15.seconds) {
                    it.statusState.potency = intDoTPotency(it, 0.2f)
                    it.statusState.secondaryPotency = 0.8f
                }
            ))
        )

        // Snoll: Hypothermal Combustion
        MobSkills += MobSkill(id = 273, castTime = 3.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 273, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = killSelf(),
            targetEvaluator = sourceCurrentHpDamage(0.33f, damageType = AttackDamageType.Magical)
        )

        // Snoll Tzar: Berserk
        MobSkills += MobSkill(id = 342, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 342, skillType = SkillType.MobSkill,
            targetEvaluator = applyBasicBuff(statusEffect = StatusEffect.Berserk, duration = 3.minutes),
        )

        // Snoll Tzar: Arctic Rush
        MobSkills += MobSkill(id = 343, rangeInfo = SkillRangeInfo(12f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 343, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage { 3f + it } )

        // Snoll Tzar: Coldwave
        MobSkills += MobSkill(id = 344, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 344, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Frost, baseDuration = 15.seconds) {
                    it.statusState.potency = intDoTPotency(it, 0.5f)
                    it.statusState.secondaryPotency = 0.8f
                }
            ))
        )

        // Snoll Tzar: Hiemal Storm
        MobSkills += MobSkill(id = 345, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 345, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage { 4f + it } )

        // Snoll Tzar: Hypothermal Combustion
        MobSkills += MobSkill(id = 346, castTime = 10.seconds, rangeInfo = SkillRangeInfo(100f, 100f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 346, skillType = SkillType.MobSkill,
            targetEvaluator = staticDamage(99999)
        )

        // Warmachine: Burst
        MobSkills += MobSkill(id = 379, rangeInfo = SkillRangeInfo( 30f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 379, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp }
        )

        // Warmachine: Flame Arrow
        MobSkills += MobSkill(id = 380, rangeInfo = SkillRangeInfo( 30f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 380, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 2f + it * 1f }
        )

        // Warmachine: Fire Bomb
        MobSkills += MobSkill(id = 381, rangeInfo = SkillRangeInfo( 30f, 6f, AoeType.Target))
        SkillAppliers += SkillApplier(skillId = 381, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 1.5f + it * 1f }
        )

        // Warmachine: Blast Bomb
        MobSkills += MobSkill(id = 382, rangeInfo = SkillRangeInfo( 30f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 382, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 1.5f + it * 1f }
        )

        // Warmachine: Fountain
        MobSkills += MobSkill(id = 383, rangeInfo = SkillRangeInfo( 10f, 10f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 383, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 2f + it * 1f }
        )

        // Urganite: Gas Shell
        MobSkills += MobSkill(id = 1315, castTime = 3.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1315, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(
                attackEffects = singleStatus(attackStatusEffect = spellDamageDoT(StatusEffect.Poison, potency = 2f, duration = 30.seconds))
            ),
        )

        // Urganite: Venom Shell
        MobSkills += MobSkill(id = 1316, castTime = 3.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1316, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(
                attackEffects = singleStatus(attackStatusEffect = spellDamageDoT(StatusEffect.Poison, potency = 0.75f, duration = 15.seconds))
            ),
        )

        // Urganite: Painful Whip
        MobSkills += MobSkill(id = 1318, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 1318, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 3) { 0.6f + 0.5f * it.excessTp },
        )

        // Urganite: Suct.
        MobSkills += MobSkill(id = 1319, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 1319, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.Bind, baseDuration = 12.seconds),
                spellDamageDoT(StatusEffect.Poison, potency = 0.5f, duration = 12.seconds),
            ))) { 2f + it.excessTp },
        )

        // Eft: Toxic Spit
        MobSkills += MobSkill(id = 259, rangeInfo = SkillRangeInfo( 10f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 259, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(spellDamageDoT(StatusEffect.Poison, potency = 0.5f, duration = 15.seconds))
            ) { 1f + 0.5f * it },
        )

        // Eft: Geist Wall
        MobSkills += MobSkill(id = 260, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 260, skillType = SkillType.MobSkill, targetEvaluator = basicDebuff(dispelCount = 2))

        // Eft: Numbing Noise
        MobSkills += MobSkill(id = 261, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 261, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Stun, baseDuration = 5.seconds)),
        )

        // Eft: Nimble Snap
        MobSkills += MobSkill(id = 262, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 262, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { 0.8f + 0.66f * it.excessTp },
        )

        // Eft: Cyclotail
        MobSkills += MobSkill(id = 263, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 263, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 2f + it.excessTp },
        )

        // Cluster: Sling Bomb
        MobSkills += MobSkill(id = 311, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 311, skillType = SkillType.MobSkill, targetEvaluator = MobClusterBehavior.slingBomb())

        // Cluster: Formation Attack
        MobSkills += MobSkill(id = 312, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 312, skillType = SkillType.MobSkill, targetEvaluator = MobClusterBehavior.formationAttack())

        // Cluster: Refueling
        MobSkills += MobSkill(id = 313, castTime = ZERO, rangeInfo = SkillRangeInfo(1f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 313, skillType = SkillType.MobSkill, targetEvaluator = applyBasicBuff(StatusEffect.Haste) {
            it.status.remainingDuration = Fps.secondsToFrames(300)
            it.status.potency = 30
        })

        // Cluster: Circle of Flames
        MobSkills += MobSkill(id = 314, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 314, skillType = SkillType.MobSkill, targetEvaluator = MobClusterBehavior.circleOfFlames())

        // Cluster: Self-Destruct (3 bombs -> 2 bombs)
        MobSkills += MobSkill(id = 315, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 10f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestructSingle(id = 315, destState = 1, hppUsed = 1/3f)

        // Cluster: Self-Destruct (3 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 316, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 10f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestructFull(id = 316)

        // Cluster: Self-Destruct (2 bombs -> 1 bombs)
        MobSkills += MobSkill(id = 317, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 10f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestructSingle(id = 317, destState = 2, hppUsed = 1/2f)

        // Cluster: Self-Destruct (2 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 318, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 10f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestructFull(id = 318)

        // Cluster: Self-Destruct (1 bombs -> 0 bombs)
        MobSkills += MobSkill(id = 319, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 10f, AoeType.Source))
        SkillAppliers += MobClusterBehavior.selfDestructFull(id = 319)

        // Vulture: Hell Dive
        MobSkills += MobSkill(id = 366, castTime = 3.seconds, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 366, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 1)) { 2f + it.excessTp },
        )

        // Vulture: Wing Cutter
        MobSkills += MobSkill(id = 367, rangeInfo = SkillRangeInfo( 4f, 4f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 367, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 3f + it.excessTp },
        )

        // Pugil: Recoil Dive
        MobSkills += MobSkill(id = 385, rangeInfo = SkillRangeInfo( 16f, 10f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 385, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(2, ftpSpread = true) { 1f + 0.5f * it.excessTp }
        )

        // Slime: Mucus Spread
        MobSkills += MobSkill(id = 1061, castTime = 3.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1061, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Slow, baseDuration = 15.seconds) { it.statusState.potency = 30 })
            ) { 2.5f + it },
        )

        // Slime: Epoxy Spread
        MobSkills += MobSkill(id = 1063, castTime = 3.seconds, rangeInfo = SkillRangeInfo(10f, 10f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1063, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.Bind, baseDuration = 15.seconds))
            ) { 2f + it },
        )

        // Limule: Blazing Bound
        MobSkills += MobSkill(id = 2308, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2308, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = { it.sourceState.appearanceState = 1; emptyList() },
            targetEvaluator = MobLimuleAbilities.blazingBound(),
        )

        // Limule: Molting Burst
        MobSkills += MobSkill(id = 2309, rangeInfo = SkillRangeInfo( 8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2309, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = { it.sourceState.appearanceState = 2; emptyList() },
            targetEvaluator = MobLimuleAbilities.moltingBurst(),
        )

        // Fly: Cursed Sphere
        MobSkills += MobSkill(id = 403, rangeInfo = SkillRangeInfo( 24f, 8f, AoeType.Target))
        SkillAppliers += SkillApplier(skillId = 403, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage { 2.5f }
        )

        // Fly: Venom
        MobSkills += MobSkill(id = 404, rangeInfo = SkillRangeInfo( 10f, 10f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 404, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = spellDamageDoT(StatusEffect.Poison, potency = 1f, duration = 15.seconds))
        )

        // Sapling: Slumber Powder
        MobSkills += MobSkill(id = 430, rangeInfo = SkillRangeInfo( 6f, 6f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 430, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Sleep, baseDuration = 18.seconds))
        )

        // Weeper: Memories of Elements
        listOf(965, 966, 967, 968, 969, 970, 971, 972).forEach {
            MobSkills += MobSkill(id = it, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
            SkillAppliers += SkillApplier(skillId = it, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage { tp -> 2.5f + tp })
        }

        // Mine: Mineblast
        MobSkills += MobSkill(id = 1582, castTime = ZERO, rangeInfo = SkillRangeInfo( 20f, 20f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1582, skillType = SkillType.MobSkill,
            additionalSelfEvaluator = killSelf(),
            targetEvaluator = basicMagicalDamage { 2.5f }
        )

        // Slug: Fuscous Ooze
        MobSkills += MobSkill(id = 1927, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 1927, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.Weight, baseDuration = 15.seconds) { it.statusState.potency = 50 },
            )),
        ) { 2f + it })

        // Slug: Purulent Ooze
        MobSkills += MobSkill(id = 1928, rangeInfo = SkillRangeInfo(12f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 1928, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.HPDown, baseDuration = 15.seconds) { it.statusState.secondaryPotency = 0.75f },
                AttackStatusEffect(statusEffect = StatusEffect.Bio, baseDuration = 15.seconds) {
                    it.statusState.potency = intDoTPotency(it, scale = 0.2f)
                    it.statusState.secondaryPotency = 0.8f },
            )),
        ) { 2f + it })

        // Slug: Corrosive Ooze
        MobSkills += MobSkill(id = 1929, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 1929, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.AttackDown, baseDuration = 15.seconds) { it.statusState.potency = 33 },
                AttackStatusEffect(statusEffect = StatusEffect.DefenseDown, baseDuration = 15.seconds) { it.statusState.potency = 33 },
            )),
        ) { 2f + it })

        // Twitherym: Tempestuous Upheaval
        MobSkills += MobSkill(id = 2694, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2694, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 4)) { 1.5f + it }
        )

        // Twitherym: Slice'n'Dice
        MobSkills += MobSkill(id = 2695, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2695, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.Choke, baseDuration = 15.seconds) {
                    it.statusState.potency = intDoTPotency(it, 0.2f)
                    it.statusState.secondaryPotency = 0.8f
                }
            )) { 2f + it.excessTp }
        )

        // Twitherym: Black Out
        MobSkills += MobSkill(id = 2696, castTime = 3.seconds, rangeInfo = SkillRangeInfo(8f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2696, skillType = SkillType.MobSkill,
            targetEvaluator = basicDebuff(attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.Paralysis, baseDuration = 10.seconds) { it.statusState.potency = 50 },
                AttackStatusEffect(statusEffect = StatusEffect.Blind, baseDuration = 10.seconds) { it.statusState.potency = 50 },
                AttackStatusEffect(statusEffect = StatusEffect.Silence, baseDuration = 10.seconds) { it.statusState.potency = 50 },
            )))
        )

        // Craklaw: Auto-attack Triple Snip
        MobSkills += MobSkill(id = 2698, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2698, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage(numHits = 3, ftpSpread = true) { 0.4f })

        // Craklaw: Auto-attack Swipe
        MobSkills += MobSkill(id = 2699, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 8f, AoeType.Cone), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2699, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage(
            attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(
                statusEffect = StatusEffect.VitDown, baseDuration = 9.seconds) { it.statusState.secondaryPotency = 0.8f },
            )
        ) { 1f })

        // Craklaw: Auto-attack Spin
        MobSkills += MobSkill(id = 2700, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 8f, AoeType.Source), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2700, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage { 1f })

        // Craklaw: Impenetrable Carapce
        MobSkills += MobSkill(id = 2701, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2701, skillType = SkillType.MobSkill, targetEvaluator = compose(
            applyBasicBuff(statusEffect = StatusEffect.MagicDefBoost, duration = 18.seconds) { it.status.potency = 50 },
            applyBasicBuff(statusEffect = StatusEffect.DefenseBoost, duration = 18.seconds) { it.status.potency = 50 },
        ))

        // Craklaw: Rending Deluge
        MobSkills += MobSkill(id = 2702, rangeInfo = SkillRangeInfo( 16f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2702, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = AttackEffects(dispelCount = 2)
        ) { 3f + it })

        // Craklaw: Sundering Snip
        MobSkills += MobSkill(id = 2703, rangeInfo = SkillRangeInfo( 16f, 8f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2703, skillType = SkillType.MobSkill, targetEvaluator = maxHpDamage(
            percent = 0.5f,
            damageType = AttackDamageType.Physical,
            attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(statusEffect = StatusEffect.MagicDefDown, baseDuration = 18.seconds) { it.statusState.potency = 50 }),
        ))

        // Craklaw: Viscid Spindrift
        MobSkills += MobSkill(id = 2704, rangeInfo = SkillRangeInfo( 16f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2704, skillType = SkillType.MobSkill, targetEvaluator = basicMagicalDamage(
            attackEffects = AttackEffects(attackStatusEffects = listOf(
                AttackStatusEffect(statusEffect = StatusEffect.AttackDown, baseDuration = 15.seconds) { it.statusState.potency = 33 },
                AttackStatusEffect(statusEffect = StatusEffect.MagicAtkDown, baseDuration = 15.seconds) { it.statusState.potency = 33 },
                AttackStatusEffect(statusEffect = StatusEffect.Weight, baseDuration = 15.seconds) { it.statusState.potency = 33 }
            )),
        ) { 2f + it })

        // Bztavian: Auto-attack Bite
        MobSkills += MobSkill(id = 2743, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2743, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage())

        // Bztavian: Auto-attack Scratch
        MobSkills += MobSkill(id = 2744, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 0f, AoeType.None), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2744, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 2, ftpSpread = true, attackEffects = AttackEffects(knockBackMagnitude = 2)) { 0.5f },
        )

        // Bztavian: Auto-attack Spray
        MobSkills += MobSkill(id = 2745, castTime = ZERO, rangeInfo = SkillRangeInfo( 16f, 16f, AoeType.Source), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2745, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 0.8f }
        )

        // Bztavian: Mandibular Lashing
        MobSkills += MobSkill(id = 2746, rangeInfo = SkillRangeInfo( 16f, 16f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2746, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(
                attackStatusEffect = AttackStatusEffect(StatusEffect.Stun, 3.seconds)
            )) { 2.5f },
        )

        // Bztavian: Vespine Hurricane
        MobSkills += MobSkill(id = 2747, rangeInfo = SkillRangeInfo( 16f, 16f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2747, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackEffects = singleStatus(
                knockBackMagnitude = 2,
                attackStatusEffect = AttackStatusEffect(StatusEffect.Stun, 3.seconds)
            )) { 2f },
        )

        // Bztavian: Stinger Volley
        MobSkills += MobSkill(id = 2748, rangeInfo = SkillRangeInfo( 16f, 16f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2748, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = singleStatus(
                attackStatusEffect = spellDamageDoT(StatusEffect.Poison, potency = 0.5f, duration = 15.seconds)
            )) { 2f },
        )

        // Bztavian: Droning Whirlwind
        MobSkills += MobSkill(id = 2749, castTime = 3.seconds, rangeInfo = SkillRangeInfo(100f, 100f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2749, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 8)) { 1.5f }
        )

        // Bztavian: Incisive Denouement
        MobSkills += MobSkill(id = 2750, rangeInfo = SkillRangeInfo( 80f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2750, skillType = SkillType.MobSkill, targetEvaluator = basicPhysicalDamage { 1.25f })

        // Rockfin: Auto-attack Bite
        MobSkills += MobSkill(id = 2752, castTime = ZERO, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2752, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(numHits = 2, ftpSpread = true) { 0.65f },
        )

        // Rockfin: Auto-attack Charge
        MobSkills += MobSkill(id = 2753, castTime = ZERO, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2753, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 1)) { 1.15f }
        )

        // Rockfin: Auto-attack Spin
        MobSkills += MobSkill(id = 2754, castTime = ZERO, rangeInfo = SkillRangeInfo(16f, 16f, AoeType.Source), cost = zeroCost)
        SkillAppliers += SkillApplier(skillId = 2754, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(attackEffects = AttackEffects(knockBackMagnitude = 2)) { 1.15f }
        )

        // Rockfin: Protolithic Puncture
        MobSkills += MobSkill(id = 2755, castTime = 5.seconds, rangeInfo = SkillRangeInfo(50f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2755, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 4f }
        )

        // Rockfin: Aquatic Lance
        MobSkills += MobSkill(id = 2756, rangeInfo = SkillRangeInfo(50f, 12f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2756, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.VitDown, baseDuration = 15.seconds) { it.statusState.secondaryPotency = 0.75f })
            ) { 3f }
        )

        // Rockfin: Pelagic Cleaver
        MobSkills += MobSkill(id = 2757, rangeInfo = SkillRangeInfo(50f, 8f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2757, skillType = SkillType.MobSkill,
            targetEvaluator = basicMagicalDamage(
                attackEffects = singleStatus(attackStatusEffect = AttackStatusEffect(StatusEffect.HPDown, baseDuration = 9.seconds) { it.statusState.secondaryPotency = 0.75f })
            ) { 2.5f }
        )

        // Rockfin: Carcharian Verve
        MobSkills += MobSkill(id = 2758, rangeInfo = SkillRangeInfo(16f, 0f, AoeType.None))
        SkillAppliers += SkillApplier(skillId = 2758, skillType = SkillType.MobSkill,
            targetEvaluator = compose(
                applyBasicBuff(statusEffect = StatusEffect.MagicAtkBoost, duration = 1.minutes) { it.status.potency = 50 },
                applyBasicBuff(statusEffect = StatusEffect.AttackBoost, duration = 1.minutes) { it.status.potency = 50 },
            )
        )

        // Rockfin: Tidal Guillotine
        MobSkills += MobSkill(id = 2759, rangeInfo = SkillRangeInfo(50f, 20f, AoeType.Cone))
        SkillAppliers += SkillApplier(skillId = 2759, skillType = SkillType.MobSkill,
            targetEvaluator = basicPhysicalDamage { 4f }
        )

        // Rockfin: Marine Mayhem
        MobSkills += MobSkill(id = 2760, rangeInfo = SkillRangeInfo(50f, 50f, AoeType.Source))
        SkillAppliers += SkillApplier(skillId = 2760, skillType = SkillType.MobSkill, targetEvaluator = marineMayhem())

    }

    fun basicPhysicalDamage(numHits: Int = 1,
                            ftpSpread: Boolean = false,
                            attackEffects: AttackEffects = AttackEffects(),
                            ftp: TpScalingFn = TpScalingFn { 1f },
    ): SkillApplierHelper.TargetEvaluator {
         return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.physicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                numHits = numHits,
                ftpSpread = ftpSpread,
                ftp = ftp,
            )

            weaponSkillToEvents(
                context = it,
                wsResult = wsResult,
                damageType = AttackDamageType.Physical,
                attackEffects = attackEffects,
            )
        }
    }

    fun basicDebuff(attackStatusEffect: AttackStatusEffect? = null, dispelCount: Int = 0): SkillApplierHelper.TargetEvaluator {
        return basicDebuff(attackEffects = AttackEffects(attackStatusEffects = listOfNotNull(attackStatusEffect), dispelCount = dispelCount))
    }

    fun basicDebuff(attackEffects: AttackEffects): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            listOf(ActorAttackedEvent(
                sourceId = it.sourceState.id,
                targetId = it.targetState.id,
                damageAmount = listOf(0),
                damageType = AttackDamageType.StatusOnly,
                attackEffects = attackEffects,
                skill = it.skill,
                actionContext = it.context,
            ))
        }
    }

    fun basicMagicalDamage(attackStat: CombatStat = CombatStat.int,
                           defendStat: CombatStat = attackStat,
                           attackEffects: AttackEffects = AttackEffects(),
                           ftp: (Float) -> Float = { 1f },
    ): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val wsResult = WeaponSkillDamageCalculator.magicalWeaponSkill(
                skillInfo = it.skillInfo,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = attackStat,
                defendStat = defendStat,
                ftp = ftp,
            )

            weaponSkillToEvents(
                context = it,
                wsResult = wsResult,
                damageType = AttackDamageType.Magical,
                attackEffects = attackEffects,
            )
        }
    }

    fun killSelf(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            listOf(ActorDamagedEvent(
                sourceId = it.sourceState.id,
                targetId = it.sourceState.id,
                amount = it.sourceState.getMaxHp(),
                actionContext = it.context,
                skill = it.skill,
                damageType = AttackDamageType.Static,
                emitDamageText = false,
            ))
        }
    }

    fun sourceCurrentHpDamage(percent: Float, damageType: AttackDamageType, attackEffects: AttackEffects = AttackEffects()): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val damage = (it.sourceState.getHp() * percent).roundToInt()

            listOf(ActorAttackedEvent(
                sourceId = it.sourceState.id,
                targetId = it.targetState.id,
                damageAmount = listOf(damage),
                damageType = damageType,
                actionContext = it.context,
                skill = it.skill,
                attackEffects = attackEffects,
            ))
        }
    }

    fun maxHpDamage(percent: Float, damageType: AttackDamageType, attackEffects: AttackEffects = AttackEffects()): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val damage = (it.targetState.getMaxHp() * percent).roundToInt()

            listOf(ActorAttackedEvent(
                sourceId = it.sourceState.id,
                targetId = it.targetState.id,
                damageAmount = listOf(damage),
                damageType = damageType,
                actionContext = it.context,
                skill = it.skill,
                attackEffects = attackEffects,
            ))
        }
    }

    private fun marineMayhem(): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
            val distance = Vector3f.distance(it.sourceState.position, it.targetState.position)
            if (distance > 8f) {
                listOf(ActorDamagedEvent(
                    sourceId = it.sourceState.id,
                    targetId = it.targetState.id,
                    amount = 99999,
                    actionContext = it.context,
                    skill = it.skill,
                    damageType = AttackDamageType.Static,
                ))
            } else {
                basicMagicalDamage { 3f }.getEvents(it)
            }
        }
    }

    fun staticDamage(amount: Int): SkillApplierHelper.TargetEvaluator {
        return SkillApplierHelper.TargetEvaluator {
                listOf(ActorDamagedEvent(
                    sourceId = it.sourceState.id,
                    targetId = it.targetState.id,
                    amount = amount,
                    actionContext = it.context,
                    skill = it.skill,
                    damageType = AttackDamageType.Static,
                ))
        }
    }

    fun singleStatus(attackStatusEffect: AttackStatusEffect, knockBackMagnitude: Int = 0): AttackEffects {
        return AttackEffects(knockBackMagnitude = knockBackMagnitude, attackStatusEffects = listOf(attackStatusEffect))
    }

    fun intDoTPotency(context: AttackStatusEffectContext, scale: Float): Int {
        return ceil(context.sourceState.combatStats[CombatStat.int] * scale).roundToInt()
    }

    fun intPotency(attacker: ActorState, scale: Float): Int {
        return ceil(attacker.combatStats[CombatStat.int] * scale).roundToInt()
    }

    fun spellDamageDoT(statusEffect: StatusEffect, potency: Float, duration: Duration, attackStat: CombatStat = CombatStat.int, defendStat: CombatStat = attackStat, secondaryPotency: Float = 0f): AttackStatusEffect {
        return AttackStatusEffect(statusEffect = statusEffect, baseDuration = duration) {
            val spellResult = SpellDamageCalculator.computeDamage(
                skill = null,
                attacker = it.sourceState,
                defender = it.targetState,
                attackStat = attackStat,
                defendStat = defendStat,
                potency = potency
            )

            it.statusState.potency = spellResult.damage.firstOrNull() ?: 0
            it.statusState.secondaryPotency = secondaryPotency
        }
    }

}