package xim.poc.game.configuration.v0.events

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.event.Event
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.resource.MagicType
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable

class ActorLearnSpellEvent(
    val actorId: ActorId,
    val spellId: Int,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[actorId] ?: return emptyList()
        if (actorState.learnedSpells.spellIds.contains(spellId)) { return emptyList() }

        actorState.learnedSpells.learnSpell(spellId)
        ChatLog("${actorState.name} learned ${SpellNameTable[spellId].first()}!", ChatLogColor.Info)

        val spellInfo = SpellInfoTable[spellId]
        if (spellInfo.magicType != MagicType.BlueMagic) { return emptyList() }

        val equippedSpells = actorState.learnedSpells.equippedSpells
        for (i in equippedSpells.indices) {
            if (equippedSpells[i] != 0) { continue }
            equippedSpells[i] = spellId
            break
        }

        return emptyList()
    }

}