package xim.poc.game.configuration.v0

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.event.AttackAddedEffectType
import xim.poc.game.event.AttackRetaliationEffectType
import xim.poc.game.event.AttackStatusEffect
import xim.poc.game.event.AutoAttackRetaliationEffect
import xim.util.multiplyInPlace
import kotlin.math.roundToInt
import kotlin.time.Duration.Companion.seconds

object CombatBonusAggregator {

    private val computed = HashMap<ActorId, CombatBonusAggregate>()

    fun clear() {
        computed.clear()
    }

    operator fun get(actorState: ActorState): CombatBonusAggregate {
        return computed.getOrPut(actorState.id) { computeActorCombatBonuses(actorState) }
    }

    fun <T> shallowBonusScope(actorState: ActorState, scope: (CombatBonusAggregate) -> T): T {
        val original = get(actorState)

        val scopeBonus = original.copy()
        computed[actorState.id] = scopeBonus

        val output = scope.invoke(scopeBonus)
        computed[actorState.id] = original

        return output
    }

    private fun computeActorCombatBonuses(actor: ActorState): CombatBonusAggregate {
        val aggregate = CombatBonusAggregate()
        aggregateStatusEffects(actor, aggregate)
        aggregateEquipmentStats(actor, aggregate)
        aggregateEquipmentAugmentEffects(actor, aggregate, typeFilter = AugmentSubType.None)

        val owner = ActorStateManager[actor.owner]
        if (actor.trustId != null && owner != null) {
            aggregateEquipmentAugmentEffects(owner, aggregate, typeFilter = AugmentSubType.Trust)
        }

        val restingTicks = (actor.type == ActorType.Pc && actor.isResting()) || (actor.isEnemy() && actor.isIdle())
        if (restingTicks) {
            aggregate.regen += (actor.getMaxHp() * 0.1f).roundToInt()
            aggregate.refresh += (actor.getMaxMp() * 0.1f).roundToInt()
        }

        if (actor.isPlayer()) {
            aggregate.regen += 1
            aggregate.refresh += 1
        }

        if (actor.monsterId != null) {
            val defn = MonsterDefinitions[actor.monsterId]
            defn.baseBonusApplier.invoke(aggregate)
        }

        actor.behaviorController.applyBehaviorBonuses(aggregate)
        return aggregate
    }

    private fun aggregateEquipmentAugmentEffects(actorState: ActorState, aggregate: CombatBonusAggregate, typeFilter: AugmentSubType) {
        for ((_, item) in actorState.getEquipment()) {
            if (item == null) { continue }
            addEquipmentAugmentEffects(item, aggregate, typeFilter)
            addEquipmentCapacityAugments(item, aggregate, typeFilter)
        }
    }

    private fun addEquipmentAugmentEffects(item: InventoryItem, aggregate: CombatBonusAggregate, typeFilter: AugmentSubType) {
        val augments = item.augments ?: return
        for (augmentId in augments.augmentIds) {
            val def = ItemAugmentDefinitions[augmentId]
            if (def.attribute.type != typeFilter) { continue }
            aggregate.aggregate(def.attribute.augment, def.valueFn.calculate(item))
        }
    }

    private fun addEquipmentCapacityAugments(item: InventoryItem, aggregate: CombatBonusAggregate, typeFilter: AugmentSubType) {
        val augments = item.fixedAugments ?: return
        for ((augmentId, augment) in augments.augments) {
            val def = ItemAugmentDefinitions[augmentId]
            if (def.attribute.type != typeFilter) { continue }

            if (augmentId == ItemAugmentDefinitions.weaponDamageAugmentId) { continue }
            aggregate.aggregate(def.attribute.augment, augment.potency)
        }
    }

    private fun aggregateEquipmentStats(actor: ActorState, aggregate: CombatBonusAggregate) {
        val equipment = actor.getEquipment()

        for ((_, item) in equipment) {
            item ?: continue
            val itemDefinition = ItemDefinitions[item]
            aggregate.aggregate(itemDefinition.combatStats)
        }
    }

    private fun aggregateStatusEffects(actor: ActorState, aggregate: CombatBonusAggregate) {
        val statuses = actor.getStatusEffects()
        statuses.forEach { aggregate.aggregate(it) }
    }

    private fun CombatBonusAggregate.aggregate(augmentId: AugmentId, value: Int) {
        when (augmentId) {
            AugmentId.HP -> additiveStats.maxHp += value
            AugmentId.MP -> additiveStats.maxMp += value
            AugmentId.STR -> additiveStats.str += value
            AugmentId.DEX -> additiveStats.dex += value
            AugmentId.VIT -> additiveStats.vit += value
            AugmentId.AGI -> additiveStats.agi += value
            AugmentId.INT -> additiveStats.int += value
            AugmentId.MND -> additiveStats.mnd += value
            AugmentId.CHR -> additiveStats.chr += value
            AugmentId.CriticalHitRate -> criticalHitRate += value
            AugmentId.EnemyCriticalHitRate -> enemyCriticalHitRate += value
            AugmentId.Haste -> haste += value
            AugmentId.SpellInterruptDown -> spellInterruptDown += value
            AugmentId.PhysicalDamageTaken -> physicalDamageTaken += value
            AugmentId.MagicalDamageTaken -> magicalDamageTaken += value
            AugmentId.DamageTaken -> { physicalDamageTaken += value; magicalDamageTaken += value }
            AugmentId.TpBonus -> tpBonus += value
            AugmentId.ParryingRate -> parryRate += value
            AugmentId.Regen -> regen += value
            AugmentId.Refresh -> refresh += value
            AugmentId.MagicAttackBonus -> magicAttackBonus += value
            AugmentId.FastCast -> fastCast += value
            AugmentId.StoreTp -> storeTp += value
            AugmentId.DoubleAttack -> doubleAttack += value
            AugmentId.TripleAttack -> tripleAttack += value
            AugmentId.QuadrupleAttack -> quadrupleAttack += value
            AugmentId.DualWield -> dualWield += value
            AugmentId.SubtleBlow -> subtleBlow += value
            AugmentId.WeaponSkillDamage -> weaponSkillDamage += value
            AugmentId.CriticalHitDamage -> criticalHitDamage += value
            AugmentId.SkillChainDamage -> skillChainDamage += value
            AugmentId.ConserveTp -> conserveTp += value
            AugmentId.MagicBurstDamage -> magicBurstDamage += value
            AugmentId.CriticalTpGain -> criticalTpGain += value
            AugmentId.ElementalWeaponSkillDamage -> elementalWeaponSkillDamage += value
            AugmentId.DoubleDamage -> doubleDamage += value
            AugmentId.FollowUpAttack -> followUpAttack += value
            AugmentId.WeaponDamage -> Unit
            AugmentId.AlterEgo -> Unit
            AugmentId.Blank -> Unit
        }
    }

    private fun CombatBonusAggregate.aggregate(abilityId: AbilityId, value: Int) {
        when (abilityId) {
            AbilityId.AutoRegen -> regen += value
            AbilityId.AutoRefresh -> refresh += value
            AbilityId.FastCast -> fastCast += value
            AbilityId.StoreTp -> storeTp += value
            AbilityId.DoubleAttack -> doubleAttack += value
            AbilityId.DualWield -> { dualWield += value; canDualWield = true }
            else -> Unit
        }
    }

    private fun CombatBonusAggregate.aggregate(combatStats: CombatStats) {
        additiveStats.add(combatStats)
    }

    private fun CombatBonusAggregate.aggregate(statusEffectState: StatusEffectState) {
        when (statusEffectState.statusEffect) {
            StatusEffect.Poison -> regen -= statusEffectState.potency
            StatusEffect.Weight -> movementSpeed -= statusEffectState.potency
            StatusEffect.Slow -> haste -= statusEffectState.potency
            StatusEffect.Dia -> handleDia(statusEffectState)
            StatusEffect.Bio -> handleBio(statusEffectState)
            StatusEffect.Plague -> {
                refresh -= statusEffectState.potency
                regain -= statusEffectState.potency * 10
            }
            StatusEffect.Haste -> haste += statusEffectState.potency
            StatusEffect.Burn -> handleElementalDoT(statusEffectState, CombatStat.int)
            StatusEffect.Frost -> handleElementalDoT(statusEffectState, CombatStat.agi)
            StatusEffect.Choke -> handleElementalDoT(statusEffectState, CombatStat.vit)
            StatusEffect.Mounted -> movementSpeed += 100
            StatusEffect.StrDown -> multiplicativeStats.multiplyInPlace(CombatStat.str, statusEffectState.secondaryPotency)
            StatusEffect.DexDown -> multiplicativeStats.multiplyInPlace(CombatStat.dex, statusEffectState.secondaryPotency)
            StatusEffect.VitDown -> multiplicativeStats.multiplyInPlace(CombatStat.vit, statusEffectState.secondaryPotency)
            StatusEffect.MndDown -> multiplicativeStats.multiplyInPlace(CombatStat.mnd, statusEffectState.secondaryPotency)
            StatusEffect.HPDown -> multiplicativeStats.multiplyInPlace(CombatStat.maxHp, statusEffectState.secondaryPotency)
            StatusEffect.Regen -> regen += statusEffectState.potency
            StatusEffect.Berserk -> {
                multiplicativeStats.multiplyInPlace(CombatStat.str, 1.5f)
                multiplicativeStats.multiplyInPlace(CombatStat.vit, 0.5f)
            }
            StatusEffect.AttackBoost -> multiplicativeStats.multiplyInPlace(CombatStat.str, statusEffectState.potency.toMultiplier())
            StatusEffect.DefenseBoost -> physicalDamageTaken -= statusEffectState.potency
            StatusEffect.AttackDown -> multiplicativeStats.multiplyInPlace(CombatStat.str, statusEffectState.potency.toPenaltyMultiplier())
            StatusEffect.DefenseDown -> multiplicativeStats.multiplyInPlace(CombatStat.vit, statusEffectState.potency.toPenaltyMultiplier())
            StatusEffect.MagicDefDown -> magicalDamageTaken += statusEffectState.potency
            StatusEffect.MagicAtkDown -> magicAttackBonus -= statusEffectState.potency
            StatusEffect.MagicAtkBoost -> magicAttackBonus += statusEffectState.potency
            StatusEffect.MagicDefBoost -> magicalDamageTaken -= statusEffectState.potency
            StatusEffect.Enaspir -> autoAttackEffects += AutoAttackEffect(statusEffectState.potency, AttackAddedEffectType.Aspir)
            StatusEffect.IceSpikes -> handleIceSpikes(statusEffectState)

            else -> {}
        }
    }

    private fun CombatBonusAggregate.handleDia(statusEffectState: StatusEffectState) {
        regen -= statusEffectState.potency
        multiplicativeStats.multiplyInPlace(CombatStat.vit, statusEffectState.secondaryPotency)
    }

    private fun CombatBonusAggregate.handleBio(statusEffectState: StatusEffectState) {
        regen -= statusEffectState.potency
        multiplicativeStats.multiplyInPlace(CombatStat.str, statusEffectState.secondaryPotency)
    }

    private fun CombatBonusAggregate.handleElementalDoT(statusEffectState: StatusEffectState, combatStat: CombatStat) {
        regen -= statusEffectState.potency
        multiplicativeStats.multiplyInPlace(combatStat, statusEffectState.secondaryPotency)
    }

    private fun CombatBonusAggregate.handleIceSpikes(statusEffectState: StatusEffectState) {
        autoAttackRetaliationEffects += AutoAttackRetaliationEffect(
            damage = statusEffectState.potency,
            type = AttackRetaliationEffectType.IceSpikes,
            statusEffect = AttackStatusEffect(StatusEffect.Paralysis, baseDuration = 3.seconds, baseChance = 0.3f) {
                it.statusState.potency = 50
            }
        )
    }

}

