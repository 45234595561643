package xim.poc

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.browser.DatLoader
import xim.poc.browser.Keyboard
import xim.poc.browser.ParserContext
import xim.poc.camera.Camera
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.event.DoorOpenEvent
import xim.poc.game.event.InitialActorState
import xim.poc.tools.ZoneConfig
import xim.poc.tools.ZoneObjectTool
import xim.poc.tools.isCheckBox
import xim.resource.*
import xim.resource.table.*
import xim.util.OnceLogger.error

data class ModelTransform(val translation: Vector3f = Vector3f(), val rotation: Vector3f = Vector3f())

/* accessCount is used to distinguish between left/right panels for a given door */
class ModelTransforms(val transforms: HashMap<Int, ModelTransform> = HashMap(), var accessCount: Int = 0)

class AreaTransform (val transform: Matrix4f, val inverseTransform: Matrix4f)

class Area(val resourceId: String, val root: DirectoryResource, val id: Int) {

    val modelTransforms = HashMap<DatId, ModelTransforms>()

    private val zoneResource = root.getFirstChildByTypeRecursively(ZoneResource::class) ?: throw IllegalStateException("[$resourceId][${root.id}] Failed to find a ZoneResource")
    private val interactions = root.collectByTypeRecursive(ZoneInteractionResource::class).flatMap { it.interactions }

    val transform = Matrix4f()
    var invTransform = Matrix4f()

    fun getZoneResource(): ZoneResource {
        return zoneResource
    }

    fun getInteractions(): List<ZoneInteraction> {
        return interactions
    }

    fun getVisibleZoneObjects(camera: Camera, cullContext: CullContext, collisionProperties: List<CollisionProperty>): Set<ZoneObjId>? {
        val zoneResource = getZoneResource()

        val debugOverride = ZoneObjectTool.cullingGroupOverride()
        val collisionCullingTables = collisionProperties.mapNotNull { debugOverride ?: it.cullingTableIndex }
            .mapNotNull { zoneResource.zoneCullingTables.getOrNull(it) }
            .firstOrNull() ?: emptySet()

        val visibleIds = Culler.getZoneObjects(camera, zoneResource, cullContext, collisionCullingTables)
        return visibleIds.ifEmpty { null }
    }

    fun getModelTransform(datId: DatId): ModelTransforms? {
        return modelTransforms[datId]
    }

    fun getModelTransformForRendering(datId: DatId): ModelTransform? {
        val transforms = modelTransforms[datId] ?: return null

        val access = transforms.accessCount
        transforms.accessCount += 1

        return transforms.transforms[access]
    }

    fun updateModelTransform(datId: DatId, index: Int, updater: (ModelTransform) -> Unit) {
        val transforms = modelTransforms.getOrPut(datId) { ModelTransforms() }
        val transform = transforms.transforms.getOrPut(index) { ModelTransform() }
        updater.invoke(transform)
    }

    fun registerEffects() {
        if (root.hasSubDirectory(DatId.effect)) {
            val effectDir = root.getSubDirectory(DatId.effect)
            registerEffectsRecursively(effectDir)
        }

        if (root.hasSubDirectory(DatId.model)) {
            val modelDir = root.getSubDirectory(DatId.model)
            registerEffectsRecursively(modelDir)
        }
    }

    private fun registerEffectsRecursively(directoryResource: DirectoryResource) {
        directoryResource.collectByTypeRecursive(EffectResource::class)
            .filter { it.particleGenerator.autoRun }
            .forEach { effect -> EffectManager.registerEffect(ZoneAssociation(this), effect) }

        directoryResource.collectByTypeRecursive(EffectRoutineResource::class)
            .filter { it.effectRoutineDefinition.autoRunHeuristic }
            .forEach { EffectManager.registerRoutine(ZoneAssociation(this), it) }
    }

}

private class SubAreaManager(ids: List<Int>) {

    val subAreaIds = ids.filter { it != 0 }.toSet()
    val subAreas = HashMap<Int, Area>()

    private var loadedSubArea: Area? = null

    init {
        subAreaIds.forEach { fetchSubArea(it) }
    }

    fun isFullyLoaded(): Boolean {
        return subAreaIds.size == subAreas.size
    }

    fun releaseAll() {
        subAreas.values.forEach { it.root.release(); DatLoader.release(it.resourceId) }
    }

    fun getLoadedSubArea(): Area? {
        return loadedSubArea
    }

    fun update(subAreaId: Int?) {
        if (loadedSubArea?.id == subAreaId) { return }
        unloadCurrent()

        if (subAreaId == null) { return }

        println("Activating sub-area (0x${subAreaId.toString(0x10)})")
        loadedSubArea = subAreas[subAreaId]?.also { it.registerEffects() }

        // This is necessary for NPC lighting, since lighting-links may come from the newly loaded sub-area collision
        FrameCoherence.clearCachedNpcCollision()
    }

    private fun unloadCurrent() {
        val current = loadedSubArea ?: return
        loadedSubArea = null

        println("Deactivating sub-area (0x${current.id.toString(0x10)})")
        EffectManager.clearEffects(ZoneAssociation(current))
    }

    private fun fetchSubArea(subAreaId: Int) {
        // Everything except [Escha - Ru'Aun] fits in the first section of the file-table. Not sure if these ranges are exact.
        val fileTableOffset = if (subAreaId < 0x271) { 0x64 } else { 0x14768 - 0x271 }

        val fileTableIndex = subAreaId + fileTableOffset
        val path = FileTableManager.getFilePath(fileTableIndex) ?: throw IllegalStateException("No such sub-area: $fileTableIndex")

        println("Fetching sub-area (0x${subAreaId.toString(0x10)}) -> $path")

        DatLoader.load(path, parserContext = ParserContext(zoneResource = true)).onReady {
            subAreas[subAreaId] = Area(path, it.getAsResource(), subAreaId)
        }
    }

}

class Scene(val config: ZoneConfig, initialSubArea: Int?) {

    companion object {
        val collisionBoxSize = Vector3f(0.5f, 1f, 0.5f)
        val npcCollisionSize = Vector3f(0.1f, 1f, 0.1f)
        val interactionBoxSize = Vector3f(0.05f, 1f, 0.05f)
    }

    private lateinit var mainArea: Area

    private var shipArea: Area? = null
    private var shipRoute: Route? = null
    private var shipRouteProgress = 0f

    private lateinit var zoneNpcList: ZoneNpcList
    private lateinit var subAreaManager: SubAreaManager
    private val zoneNpcState = HashMap<ActorId, ActorPromise>()

    private var currentInteractions: Set<ZoneInteraction> = emptySet()
    private var subAreaId: Int? = initialSubArea

    init {
        val resource = ZoneIdToResourceId.map(config) ?: throw IllegalStateException("$config doesn't have a resource?")

        DatLoader.load(resource, parserContext = ParserContext(zoneResource = true)).onReady {
            mainArea = Area(resource, it.getAsResource(), config.zoneId).also { area -> area.registerEffects() }
            maybeRegisterShipArea()
            subAreaManager = SubAreaManager(mainArea.getInteractions().filter { z -> z.isSubArea() }.map { z -> z.param })

        }

        ZoneNpcTableProvider.fetchNpcDat(config) {
            zoneNpcList = it
            populateNpcActors()
        }
    }

    fun isFullyLoaded(): Boolean {
        return this::mainArea.isInitialized && this::zoneNpcList.isInitialized
                && this::subAreaManager.isInitialized && subAreaManager.isFullyLoaded()
    }

    fun release() {
        mainArea.root.release()
        DatLoader.release(mainArea.resourceId)
        subAreaManager.releaseAll()
    }

    fun getMainArea() = mainArea

    fun getSubArea() = subAreaManager.getLoadedSubArea()

    fun getAreas() = listOfNotNull(mainArea, shipArea, getSubArea())

    fun isCurrentSubArea(subAreaId: Int): Boolean {
        return this.subAreaId == subAreaId
    }

    fun getMainAreaRootDirectory(): DirectoryResource {
        return mainArea.root
    }

    fun getNpcs(): ZoneNpcList {
        return zoneNpcList
    }

    fun getNpc(npcDatId: DatId): ActorPromise? {
        val npc = zoneNpcList.npcsByDatId[npcDatId] ?: return null
        return getNpc(npc.actorId)
    }

    fun getNpc(actorId: ActorId): ActorPromise? {
        return zoneNpcState[actorId]
    }

    private fun populateNpcActors() {
        for (npc in zoneNpcList.npcs) {
            try {
                createNpc(npc)
            } catch (e: Exception) {
                error("Failed to create npc $npc\n${e.message}")
            }
        }
    }

    private fun createNpc(npc: Npc) {
        zoneNpcState[npc.actorId] = GameEngine.submitCreateActorState(InitialActorState(
            name = npc.name,
            type = ActorType.Object,
            position = npc.info.position,
            modelLook = npc.info.look,
            rotation = npc.info.rotation,
            presetId = npc.actorId,
            npcInfo = npc.info,
            appearanceState = NpcTable.getDefaultAppearanceState(npc.info.look) ?: 0,
            targetable = npc.name.isNotBlank(),
        ))
    }

    fun update(elapsedFrames: Float) {
        subAreaManager.update(subAreaId)

        mainArea.modelTransforms.forEach { it.value.accessCount = 0 }

        if (!isCheckBox("pauseShip")) { updateShipRoute(elapsedFrames) }
    }

    fun getZoneInteractions(): List<ZoneInteraction> {
        return mainArea.getInteractions()
    }

    fun moveActor(actorState: ActorState, elapsedFrames: Float) : Map<Area, List<CollisionProperty>> {
        if (!actorState.shouldApplyMovement()) {
            return emptyMap()
        }

        if (GameState.isDebugMode() && actorState.isPlayer() && MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(Keyboard.Key.C)) {
            actorState.position += actorState.velocity + Vector3f(0f, 0.1f * elapsedFrames, 0f)
            return emptyMap()
        }

        val areas = getAreas()

        val npcBoxes = if (actorState.isPlayer()) { getInteractionCollision() } else { emptyList() }

        val collisionSize = if (actorState.isStaticNpc()) { npcCollisionSize } else { collisionBoxSize }

        if (actorState.velocity.magnitudeSquare() > 1e-5f) {
            Collider.updatePosition(areas, actorState, actorState.velocity, collisionSize, npcBoxes)
        }

        if (GameState.isDebugMode() && actorState.isPlayer() && MainTool.platformDependencies.keyboard.isKeyPressedOrRepeated(Keyboard.Key.SPACE)) {
            return emptyMap()
        }

        if (actorState.lastCollisionResult.isInFreeFall()) {
            val offsetPosition = Vector3f(actorState.position).also { it.y -= 1f }
            if (getNearestFloor(offsetPosition) == null) { return emptyMap() }
        }

        val vertical = Vector3f(0f, 0.33f * elapsedFrames, 0f)
        return Collider.updatePosition(areas, actorState, vertical, collisionSize, npcBoxes)
    }

    fun getNearestFloor(position: Vector3f): Vector3f? {
        return Collider.nearestFloor(position + Vector3f(0f, -0.01f, 0f), getAreas())
    }

    fun getVisibleZoneObjects(camera: Camera, cullContext: CullContext, viewer: ActorState? = null) : Map<Area, Set<ZoneObjId>?> {
        return getAreas().associateWith {
            val perAreaCollisionProperties = viewer?.lastCollisionResult?.collisionsByArea?.get(it) ?: emptyList()

            val areaTransform = getAreaTransform(it)

            val cullingCamera = if (areaTransform != null) { camera.transform(areaTransform) } else { camera }
            it.getVisibleZoneObjects(cullingCamera, cullContext, perAreaCollisionProperties)
        }
    }

    fun checkInteractions(): ZoneInteraction? {
        val player = ActorStateManager.player()
        val playerBox = BoundingBox.from(player.position, Vector3f(0f, player.rotation, 0f), interactionBoxSize, verticallyCentered = false)

        val interactions = getZoneInteractions()
            .filter { interactsWith(playerBox, it) }
            .toSet()

        val newInteractions = interactions - currentInteractions
        currentInteractions = interactions

        manageSubArea(newInteractions)
        player.zone = player.zone?.copy(subAreaId = subAreaId)

        return shouldZone()
    }

    fun getModelTransformForRendering(area: Area, datId: DatId): ModelTransform? {
        val areaTransform = area.getModelTransformForRendering(datId)
        if (area == mainArea || areaTransform != null) { return areaTransform }
        return mainArea.getModelTransformForRendering(datId)
    }

    private fun shouldZone(): ZoneInteraction? {
        return currentInteractions.firstOrNull { it.isZoneLine() }
    }

    private fun manageSubArea(newInteractions: Set<ZoneInteraction>) {
        val subAreas = newInteractions.filter { it.isSubArea() }

        // Some zones have a large "clear" interaction that intersects with everything
        // There should be at most one intersection with a non-zero param
        val nonZeroSubAreaCount = subAreas.distinctBy { it.param }.count { it.param != 0 }
        if (nonZeroSubAreaCount > 1) { throw IllegalStateException("How can this be") }
        if (subAreas.isEmpty()) { return }

        if (subAreas.all { it.param == 0 }) {
            subAreaId = null
            return
        }

        val subAreaInteraction = if (subAreas.size == 1) { subAreas.first() } else { subAreas.first { it.param != 0 } }
        subAreaId = subAreaInteraction.param
    }

    private fun interactsWith(actorBox: BoundingBox, zoneInteraction: ZoneInteraction): Boolean {
        return SatCollider.boxBoxOverlap(zoneInteraction.boundingBox, actorBox)
    }

    private fun maybeRegisterShipArea() {
        val modelDir = mainArea.root.getSubDirectory(DatId.model)

        val shipDir = modelDir.getNullableSubDirectory(DatId.ship) ?: return
        shipArea = Area(mainArea.resourceId, shipDir, 0)

        shipRoute = getMainAreaRootDirectory().getSubDirectory(DatId.model)
            .collectByType(RouteResource::class)
            .firstOrNull()
            ?.route

        if (shipRoute == null) { error("Ship area has no routes?") }

        println("Registered ship area! -> ${shipArea!!.getZoneResource().id}")
    }

    fun getAreaTransform(area: Area? = null): AreaTransform? {
        val ship = shipArea ?: return null
        if (area == shipArea) { return null }

        return AreaTransform(ship.transform, ship.invTransform)
    }

    fun setShipRoute(id: DatId) {
        shipRoute = getMainAreaRootDirectory().getNullableChildRecursivelyAs(id, RouteResource::class)?.route ?: return
        shipRouteProgress = 0f
    }

    fun setShipRouteProgress(progress: Float) {
        val route = shipRoute ?: return
        shipRouteProgress = route.totalLength * progress
        updateShipRoute(0f)
    }

    private fun updateShipRoute(elapsedFrames: Float) {
        val ship = shipArea ?: return
        val route = shipRoute ?: return

        shipRouteProgress += elapsedFrames

        val position = route.getPosition(shipRouteProgress)
        val yRotation = route.getFacingDir(shipRouteProgress)

        ship.transform.identity()
            .translateInPlace(position)
            .rotateYInPlace(yRotation)

        ship.invTransform.identity()
            .rotateYInPlace(-yRotation)
            .translateInPlace(position * -1f)
    }

    private fun getInteractionCollision(): List<Box> {
        return getZoneInteractions().filter { needsCollision(it) }.map { it.boundingBox }
    }

    private fun needsCollision(interaction: ZoneInteraction): Boolean {
        if (!interaction.isDoor()) { return false }
        val actor = getInteractionActor(interaction) ?: return true
        return !actor.doorState.open
    }

    fun getNpcInteraction(id: ActorId): NpcInteraction? {
        return GameState.getInteraction(id)
    }

    fun getInteractionActor(interaction: ZoneInteraction): ActorState? {
        val npc = zoneNpcList.npcsByDatId[interaction.sourceId] ?: return null
        return ActorStateManager[npc.actorId]
    }

    fun openDoor(doorId: DatId, requester: ActorId? = null) {
        val doorState = getNpc(doorId)
        doorState?.onReady { GameEngine.submitEvent(DoorOpenEvent(sourceId = requester, doorId = doorId)) }
    }

}

object SceneManager {

    private var currentScene: Scene? = null

    fun getCurrentScene(): Scene {
        return currentScene ?: throw IllegalStateException("Scene is not initialized")
    }

    fun getNullableCurrentScene(): Scene? {
        return currentScene
    }

    fun loadScene(config: ZoneConfig, initialSubArea: Int? = null) {
        if (currentScene != null) { throw IllegalStateException("Need to unload current scene first") }
        currentScene = Scene(config, initialSubArea)
    }

    fun isFullyLoaded(): Boolean {
        return currentScene?.isFullyLoaded() == true
    }

    fun unloadScene() {
        val current = currentScene
        currentScene = null

        if (current != null) {
            current.release()
            DatLoader.release(current.getNpcs().resourceId)
        }
    }

}