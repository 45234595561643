package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ActionTargetFilter
import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.configuration.SkillTargetEvaluation.Companion.failure
import xim.poc.game.configuration.SkillFailureReason.OutOfRange
import xim.resource.AoeType
import xim.resource.TargetFlag
import xim.util.PI_f

data class SkillTargetEvaluation(val primaryTarget: ActorId, val allTargetIds: List<ActorId>, val contexts: AttackContexts, val failureReason: SkillFailureReason? = null) {
    companion object {
        fun failure(primaryTarget: ActorId, reason: SkillFailureReason): SkillTargetEvaluation {
            return SkillTargetEvaluation(primaryTarget, emptyList(), AttackContexts.noop(), reason)
        }
    }

    fun primaryTargetContext(): AttackContext {
        return contexts[primaryTarget]
    }

}

object SkillTargetEvaluator {

    fun evaluateTargets(skillInfo: SkillInfo, sourceState: ActorState, primaryTargetState: ActorState): SkillTargetEvaluation {
        val rangeInfo = GameEngine.getRangeInfo(sourceState, skillInfo)
        if (!isInSkillRange(rangeInfo, sourceState, primaryTargetState) && (rangeInfo.type == AoeType.None || rangeInfo.type == AoeType.Target)) {
            return failure(primaryTargetState.id, OutOfRange)
        }

        val allTargets = getNearbyTargets(skillInfo, rangeInfo, sourceState, primaryTargetState)
        val allTargetIds = allTargets.map { it.id }

        val targetContexts = allTargetIds.associateWith { AttackContext(
            appearanceState = sourceState.appearanceState,
        ) }

        return SkillTargetEvaluation(primaryTargetState.id, allTargetIds, AttackContexts(targetContexts))
    }

    private fun getNearbyTargets(skillInfo: SkillInfo, rangeInfo: SkillRangeInfo, sourceState: ActorState, targetState: ActorState): List<ActorState> {
        // The target-filter and effect-filter aren't necessarily the same, but the effect-filter isn't available client-side (?)
        val targetFilter = if (rangeInfo.type != AoeType.None && skillInfo.targetFlags == TargetFlag.Self.flag) {
            ActionTargetFilter(TargetFlag.Self.flag or TargetFlag.Party.flag)
        } else {
            skillInfo.targetFilter
        }

        return ActorStateManager.filter { isInEffectRange(rangeInfo, source = sourceState, primaryTarget = targetState, additionalTarget = it) }
            .filter { targetFilter.targetFilter(sourceState, it) }
    }

    private fun isInSkillRange(rangeInfo: SkillRangeInfo, sourceState: ActorState, targetState: ActorState): Boolean {
        return Vector3f.distance(sourceState.position, targetState.position) <= rangeInfo.maxTargetDistance
    }

    private fun isInEffectRange(rangeInfo: SkillRangeInfo, source: ActorState, primaryTarget: ActorState, additionalTarget: ActorState): Boolean {
        if (rangeInfo.type == AoeType.None) {
            return primaryTarget.id == additionalTarget.id
        }

        val effectPosition = when (rangeInfo.type) {
            AoeType.None -> throw IllegalStateException()
            AoeType.Target -> primaryTarget.position
            AoeType.Cone, AoeType.Source -> source.position
        }

        return if (Vector3f.distance(effectPosition, additionalTarget.position) > rangeInfo.effectRadius) {
            false
        } else if (rangeInfo.type == AoeType.Cone) {
            source.isFacingTowards(other = additionalTarget, halfAngle = PI_f /4f)
        } else {
            true
        }
    }

}
