package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.*
import xim.poc.game.GameEngine.rollStatusEffect
import xim.poc.game.configuration.Skill
import xim.poc.game.event.ActorAttackedHelper.attemptCastingInterrupt
import xim.poc.game.event.ActorAttackedHelper.blockedByBlink
import xim.poc.game.event.ActorAttackedHelper.consumeStoneskin
import xim.poc.game.event.ActorAttackedHelper.engageTargetAndDependents
import xim.poc.game.event.ActorAttackedHelper.missedDueToBlind
import xim.poc.ui.ChatLog
import xim.poc.ui.ShiftJis
import kotlin.math.roundToInt
import kotlin.time.Duration

enum class AttackDamageType {
    Physical,
    Magical,
    SkillChain,
    Static,
    StatusOnly,
}

enum class ActorResourceType {
    HP,
    MP,
    TP,
}

data class AttackStatusEffectContext(
    val sourceState: ActorState,
    val targetState: ActorState,
    val statusState: StatusEffectState,
    val skill: Skill?,
)

data class AttackStatusEffect(
    val statusEffect: StatusEffect,
    val baseDuration: Duration,
    val baseChance: Float = 1f,
    val decorator: (AttackStatusEffectContext) -> Unit = { }
)

data class AttackEffects(
    val damageResource: ActorResourceType = ActorResourceType.HP,
    val absorption: Float = 0f,
    val knockBackMagnitude: Int = 0,
    val dispelCount: Int = 0,
    val gazeAttack: Boolean = false,
    val attackStatusEffects: List<AttackStatusEffect> = emptyList(),
)

class ActorAutoAttackedEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val damageAmount: Int,
    val damageType: AttackDamageType,
    val sourceTpGain: Int = 0,
    val targetTpGain: Int = 0,
    val autoAttackEffects: List<AutoAttackAddedEffect>,
    val retaliationEffects: List<AutoAttackRetaliationEffect>,
    val actionContext: AttackContext,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        val outputEvents = ArrayList<Event>()
        outputEvents += engageTargetAndDependents(sourceState, targetState)

        if (missedDueToBlind(damageType, sourceState)) {
            actionContext.setMissFlag()
            AttackContext.compose(actionContext) { ChatLog("${sourceState.name}${ShiftJis.rightArrow}${targetState.name} miss!") }
            return outputEvents
        }

        if (blockedByBlink(targetState)) {
            actionContext.setMissFlag()
            AttackContext.compose(actionContext) { ChatLog("1 of ${targetState.name}'s shadows absorbs the damage and disappears.") }
            return outputEvents
        }

        var adjustedDamage = damageAmount

        if (targetState.isEngaged() && targetState.isFacingTowards(sourceState)) {
            if (GameEngine.rollParry(sourceState, targetState)) {
                actionContext.setParryFlag()
                AttackContext.compose(actionContext) { ChatLog("${targetState.name} parries the attack.") }
                return outputEvents
            } else if (GameEngine.rollGuard(sourceState, targetState)) {
                actionContext.setGuardFlag()
                adjustedDamage /= 4
            }
        }

        val finalDamage = consumeStoneskin(targetState, adjustedDamage, ActorResourceType.HP)
        outputEvents += ActorDamagedEvent(
            sourceId = sourceId,
            targetId = targetId,
            type = ActorResourceType.HP,
            amount = finalDamage,
            actionContext = actionContext,
            damageType = damageType,
        )

        if (finalDamage > 0) {
            sourceState.gainTp(sourceTpGain)
            targetState.gainTp(targetTpGain)
            targetState.expireStatusEffect(StatusEffect.Sleep)
            attemptCastingInterrupt(sourceState, targetState, damageType)
        }

        for (autoAttackEffect in autoAttackEffects) {
            outputEvents += processAutoAttackEffect(autoAttackEffect = autoAttackEffect, targetState = targetState)
        }

        for (retaliationEffect in retaliationEffects) {
            outputEvents += processRetaliationEffect(autoAttackEffect = retaliationEffect, sourceState = sourceState, targetState = targetState)
        }

        actionContext.onHitEffect = autoAttackEffects.mapNotNull { it.type.contextDisplay }.randomOrNull() ?: 0
        actionContext.retaliationFlag = retaliationEffects.mapNotNull { it.type.contextDisplay }.randomOrNull() ?: 0

        return outputEvents
    }

    private fun processAutoAttackEffect(autoAttackEffect: AutoAttackAddedEffect, targetState: ActorState): List<Event> {
        val addedEffectEvents = ArrayList<Event>()
        val finalAddedEffectDamage = consumeStoneskin(targetState, autoAttackEffect.damage, autoAttackEffect.type.damageResource)

        val isDrainingEffect = autoAttackEffect.type == AttackAddedEffectType.Drain || autoAttackEffect.type == AttackAddedEffectType.Aspir

        addedEffectEvents += ActorDamagedEvent(
            sourceId = sourceId,
            targetId = targetId,
            type = autoAttackEffect.type.damageResource,
            amount = finalAddedEffectDamage,
            actionContext = actionContext,
            damageType = AttackDamageType.Magical,
            additionalEffect = true,
            emitDamageText = !isDrainingEffect,
        )

        if (isDrainingEffect) {
            addedEffectEvents += ActorHealedEvent(
                sourceId = targetId,
                targetId = sourceId,
                amount = finalAddedEffectDamage,
                actionContext = actionContext,
                healType = autoAttackEffect.type.damageResource,
            )
        }

        return addedEffectEvents
    }

    private fun processRetaliationEffect(autoAttackEffect: AutoAttackRetaliationEffect, sourceState: ActorState, targetState: ActorState): List<Event> {
        val addedEffectEvents = ArrayList<Event>()
        val finalAddedEffectDamage = consumeStoneskin(sourceState, autoAttackEffect.damage, autoAttackEffect.type.damageResource)

        addedEffectEvents += ActorDamagedEvent(
            sourceId = targetId,
            targetId = sourceId,
            type = autoAttackEffect.type.damageResource,
            amount = finalAddedEffectDamage,
            actionContext = actionContext,
            damageType = AttackDamageType.Magical,
            additionalEffect = true,
        )

        val statusEffect = autoAttackEffect.statusEffect
        if (statusEffect != null && rollStatusEffect(targetState = sourceState, attackStatusEffect = statusEffect)) {
            val statusEffectState = sourceState.gainStatusEffect(status = statusEffect.statusEffect, duration = statusEffect.baseDuration, sourceId = targetId)
            statusEffect.decorator.invoke(AttackStatusEffectContext(sourceState, targetState, statusEffectState, skill = null))
        }

        return addedEffectEvents
    }

}

class ActorAttackedEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val damageAmount: List<Int>,
    val damageType: AttackDamageType,
    val sourceTpGain: Int = 0,
    val targetTpGain: Int = 0,
    val attackEffects: AttackEffects = AttackEffects(),
    val actionContext: AttackContext,
    val skill: Skill,
): Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()

        val targetState = ActorStateManager[targetId] ?: return emptyList()
        if (targetState.isDead()) { return emptyList() }

        val outputEvents = ArrayList<Event>()
        outputEvents += engageTargetAndDependents(sourceState, targetState)

        if (attackEffects.gazeAttack && !targetState.isFacingTowards(sourceState)) {
            actionContext.setMissFlag()
            return outputEvents
        }

        var landedHits = damageAmount.filter { !missedDueToBlind(damageType, sourceState) }
        if (damageAmount.isNotEmpty() && landedHits.isEmpty()) {
            actionContext.setMissFlag()
            AttackContext.compose(actionContext) { ChatLog("${sourceState.name}${ShiftJis.rightArrow}${targetState.name} miss!") }
            return outputEvents
        }

        landedHits = landedHits.dropWhile { blockedByBlink(targetState) }
        if (damageAmount.isNotEmpty() && landedHits.isEmpty()) {
            actionContext.setMissFlag()
            AttackContext.compose(actionContext) { ChatLog("${damageAmount.size} of ${targetState.name}'s shadows absorbs the damage and disappears.") }
            return outputEvents
        }

        val landedDamage = landedHits.sum()

        val attackStatusEffects = attackEffects.attackStatusEffects.filter { rollStatusEffect(targetState, it) }
        for (attackStatusEffect in attackStatusEffects) {
            val state = targetState.gainStatusEffect(status = attackStatusEffect.statusEffect, duration = attackStatusEffect.baseDuration, sourceId = sourceId)
            AttackContext.compose(actionContext) { ChatLog.statusEffectGained(targetState.name, attackStatusEffect.statusEffect) }
            attackStatusEffect.decorator.invoke(AttackStatusEffectContext(sourceState, targetState, state, skill))
        }

        if (landedHits.isNotEmpty() && attackEffects.dispelCount > 0) {
            dispelEffects(targetState, attackEffects.dispelCount)
        }

        if (damageType == AttackDamageType.StatusOnly) {
            return outputEvents
        }

        if (landedHits.isNotEmpty() && sourceTpGain > 0) {
            sourceState.gainTp(sourceTpGain + 10 * (landedHits.size - 1))
        }

        if (landedHits.isNotEmpty() && targetTpGain > 0) {
            targetState.gainTp(targetTpGain + 10 * (landedHits.size - 1))
        }

        val finalDamage = consumeStoneskin(targetState, landedDamage, attackEffects.damageResource)

        if (finalDamage > 0) {
            targetState.expireStatusEffect(StatusEffect.Sleep)
            attemptCastingInterrupt(sourceState, targetState, damageType)

            val targetBonuses = GameEngine.getCombatBonusAggregate(targetState)
            actionContext.knockBackMagnitude = (attackEffects.knockBackMagnitude * targetBonuses.knockBackResistance.toPenaltyMultiplier()).roundToInt()
        }

        outputEvents += ActorDamagedEvent(
            sourceId = sourceId,
            targetId = targetId,
            type = attackEffects.damageResource,
            amount = finalDamage,
            actionContext = actionContext,
            skill = skill,
            damageType = damageType,
        )

        val absorption = (finalDamage * attackEffects.absorption).roundToInt()
        if (absorption > 0) { outputEvents += processDrainingEffect(targetState, absorption) }

        outputEvents += getSkillChainEvents(landedDamage, sourceState, targetState)

        return outputEvents
    }

    private fun getSkillChainEvents(landedDamage: Int, sourceState: ActorState, targetState: ActorState): List<Event> {
        val skillChainAttributes = GameState.getGameMode().getSkillChainAttributes(
            attacker = sourceState,
            defender = targetState,
            skillType = skill.skillType,
            skillId = skill.id
        )

        if (skillChainAttributes.isEmpty()) { return emptyList() }
        val skillChainResult = targetState.skillChainTargetState.applyState(skillChainAttributes) ?: return emptyList()

        return listOf(ActorSkillChainEvent(
                sourceId = sourceId,
                targetId = targetId,
                skillChain = skillChainResult,
                closingWeaponSkillDamage = landedDamage,
                context = actionContext,
            ))
    }

    private fun dispelEffects(targetState: ActorState, count: Int) {
        targetState.getStatusEffects()
            .filter { it.statusEffect.canDispel }
            .take(count)
            .forEach { targetState.expireStatusEffect(it.statusEffect) }
    }

    private fun processDrainingEffect(targetState: ActorState, amount: Int): Event {
        val resource = targetState.getResource(attackEffects.damageResource)
        val cappedAmount = amount.coerceAtMost(resource)

        return ActorHealedEvent(
            sourceId = targetId,
            targetId = sourceId,
            amount = cappedAmount,
            actionContext = actionContext,
            healType = attackEffects.damageResource,
        )
    }

}

class ActorSkillChainEvent(
    val sourceId: ActorId,
    val targetId: ActorId,
    val skillChain: SkillChain,
    val closingWeaponSkillDamage: Int,
    val context: AttackContext,
) : Event {

    override fun apply(): List<Event> {
        val sourceState = ActorStateManager[sourceId] ?: return emptyList()
        val targetState = ActorStateManager[targetId] ?: return emptyList()

        val damage = GameState.getGameMode().getSkillChainDamage(
            attacker = sourceState,
            defender = targetState,
            skillChain = skillChain,
            closingWeaponSkillDamage = closingWeaponSkillDamage,
        )

        val finalDamage = consumeStoneskin(targetState, damage, ActorResourceType.HP)

        val event = ActorDamagedEvent(
            sourceId = sourceId,
            targetId = targetId,
            type = ActorResourceType.HP,
            amount = finalDamage,
            actionContext = context,
            damageType = AttackDamageType.SkillChain,
        )

        AttackContext.compose(context) {
            ChatLog("Skillchain! ${skillChain.attribute} [${skillChain.numSteps}]")
            MiscEffects.playSkillChain(sourceId, targetId, skillChain.attribute)
        }

        return listOf(event)
    }

}

private object ActorAttackedHelper {

    fun engageTargetAndDependents(sourceState: ActorState, targetState: ActorState): List<Event> {
        val outputEvents = ArrayList<Event>()

        sourceState.pet?.let { outputEvents += BattleEngageEvent(it, targetState.id) }
        GameEngine.getTrusts(sourceState.id).forEach { outputEvents += BattleEngageEvent(it.id, targetState.id) }

        if (targetState.isEnemy() && !targetState.isEngaged()) {
            outputEvents += ActorDismountEvent(sourceState.id)
            outputEvents += BattleEngageEvent(targetState.id, sourceState.id)
        } else if (!targetState.isEnemy() && targetState.targetState.targetId == null) {
            outputEvents += ActorTargetEvent(targetState.id, sourceState.id)
        }

        return outputEvents
    }

    fun missedDueToBlind(damageType: AttackDamageType, actorState: ActorState): Boolean {
        if (damageType != AttackDamageType.Physical) { return false }
        return GameEngine.checkBlindProc(actorState)
    }

    fun blockedByBlink(targetState: ActorState): Boolean {
        return targetState.consumeStatusEffectCharge(StatusEffect.Blink)
    }

    fun consumeStoneskin(targetState: ActorState, incomingDamage: Int, incomingDamageType: ActorResourceType): Int {
        if (incomingDamageType != ActorResourceType.HP) { return incomingDamage }
        val stoneskin = targetState.getStatusEffect(StatusEffect.Stoneskin) ?: return incomingDamage

        val originalValue = stoneskin.counter

        stoneskin.counter -= incomingDamage
        if (stoneskin.counter <= 0) { targetState.expireStatusEffect(StatusEffect.Stoneskin) }

        return (incomingDamage - originalValue).coerceAtLeast(0)
    }

    fun attemptCastingInterrupt(sourceState: ActorState, targetState: ActorState, damageType: AttackDamageType) {
        val targetCastingState = targetState.getCastingState() ?: return
        if (!targetCastingState.canInterrupt()) { return }

        if (damageType != AttackDamageType.Physical) { return }
        if (!GameEngine.rollCastingInterrupt(sourceState, targetState)) { return }

        if (targetState.consumeStatusEffectCharge(StatusEffect.Aquaveil)) { return }

        targetCastingState.interrupted = true
    }

}