package xim.poc.game.configuration.v0.behaviors

import xim.math.Vector3f
import xim.poc.game.*
import xim.poc.game.configuration.ActorBehaviorController
import xim.poc.game.configuration.AutoAttackController
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.v0.V0MonsterHelper
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.CastSpellStart
import xim.poc.game.event.Event
import xim.resource.table.SpellInfoTable
import xim.util.Fps

class MobCherryTreeBehavior(val actorState: ActorState): ActorBehaviorController {

    companion object {
        private val abilityWaitTime = Fps.secondsToFrames(1)
        private val spawnWaitTime = Fps.secondsToFrames(90)
        private val castSpacing = Fps.secondsToFrames(8)
    }

    private val autoAttackDelegate = AutoAttackController(actorState, attackRange = (0f to 10f))
    private var spawnedSapling: ActorPromise? = null

    private var abilityWait: Float? = null
    private var spawnWait = 0f

    private var currentCastSpacing = castSpacing

    override fun update(elapsedFrames: Float): List<Event> {
        if (!actorState.isIdleOrEngaged()) {
            return emptyList()
        }

        if (actorState.isIdle()) {
            abilityWait = null
            return emptyList()
        }

        spawnWait -= elapsedFrames
        if (shouldSpawnSapling(elapsedFrames)) {
            spawnWait = spawnWaitTime
            spawnSapling()
        }

        val currentWait = abilityWait
        return if (currentWait != null && currentWait > 0f) {
            abilityWait = currentWait - elapsedFrames
            emptyList()
        } else if (currentWait != null && currentWait <= 0f) {
            abilityWait = null
            useComboLeafStorm()
        } else {
            handleDefaultState(elapsedFrames)
        }
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.knockBackResistance += 100
        aggregate.magicAttackBonus += 100
        aggregate.refresh += 10
    }

    private fun useComboLeafStorm(): List<Event> {
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = 75))
    }

    private fun handleDefaultState(elapsedFrames: Float): List<Event> {
        if (!GameEngine.canBeginAction(actorState)) { return emptyList() }

        val targetState = ActorStateManager[actorState.targetState.targetId] ?: return emptyList()

        if (Vector3f.distance(actorState.position, targetState.position) > 10f) {
            return castSpell(elapsedFrames, targetState)
        }

        if (actorState.getTpp() < 0.5f) {
            return autoAttackDelegate.update(elapsedFrames)
        }

        if (!isSaplingActive()) {
            return useWeaponSkill(targetState)
        }

        triggerSaplings()
        abilityWait = abilityWaitTime
        return emptyList()
    }

    private fun castSpell(elapsedFrames: Float, targetState: ActorState): List<Event> {
        currentCastSpacing -= elapsedFrames
        if (currentCastSpacing > 0f) {
            return emptyList()
        }

        currentCastSpacing = castSpacing

        val spellId = listOf(160)
            .firstOrNull { GameEngine.canBeginCastSpell(actorState.id, SpellInfoTable[it]) }
            ?: return emptyList()

        return listOf(CastSpellStart(sourceId = actorState.id, targetId = targetState.id, spellId = spellId))
    }

    private fun useWeaponSkill(targetState: ActorState): List<Event> {
        val ws = listOf(72, 76).shuffled()
            .firstOrNull { GameEngine.canBeginUseMobSkill(actorState.id, it) }
            ?: return emptyList()

        actorState.setTp(300)
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetState.id, mobSkillId = ws))
    }

    private fun triggerSaplings() {
        spawnedSapling?.ifReady {
            if (it.behaviorController is MobCherrySaplingBehavior) {
                it.behaviorController.forceUseAbility()
            }
        }
    }

    private fun shouldSpawnSapling(elapsedFrames: Float): Boolean {
        spawnedSapling ?: return true
        val saplingId = spawnedSapling?.getIfReady() ?: return false

        val sapling = ActorStateManager[saplingId]
        if (sapling != null && !sapling.isDead()) { return false }

        spawnWait -= elapsedFrames
        return spawnWait <= 0f
    }

    private fun isSaplingActive(): Boolean {
        val sapling = ActorStateManager[spawnedSapling?.getIfReady()] ?: return false
        return !sapling.isDead()
    }

    private fun spawnSapling() {
        val monsterDefinition = MonsterDefinitions[11]

        spawnedSapling = V0MonsterHelper.spawnMonster(
            monsterDefinition = monsterDefinition,
            position = Vector3f(x=-161.34f,y=-15.61f,z=308.55f),
        ).onReady {
            it.behaviorController as MobCherrySaplingBehavior
            it.behaviorController.parent = actorState
            it.setTargetState(actorState.getTargetId(), locked = true)
            it.setEngagedState(EngagedState.State.Engaged)
        }
    }

}

class MobCherrySaplingBehavior(val actorState: ActorState) : ActorBehaviorController {

    private var triggerSyncedAbility = false

    var parent: ActorState? = null

    override fun update(elapsedFrames: Float): List<Event> {
        if (triggerSyncedAbility) {
            triggerSyncedAbility = false
            return useAbility()
        }

        if (parent?.isDead() == true) {
            actorState.setHp(0)
        }

        return emptyList()
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.mobSkillFastCast += 300
        aggregate.movementSpeed -= 25
    }

    fun forceUseAbility() {
        triggerSyncedAbility = true
    }

    private fun useAbility(): List<Event> {
        if (!GameEngine.canBeginAction(actorState)) { return emptyList() }
        val targetId = parent?.targetState?.targetId ?: return emptyList()

        val mobSkills = MonsterDefinitions[actorState.monsterId]?.mobSkills ?: return emptyList()
        actorState.setTp(300)

        val randomSkillId = mobSkills
            .filter { GameEngine.canBeginUseMobSkill(actorState.id, it) }
            .randomOrNull() ?: return emptyList()

        return listOf(CastMobSkillStart(actorState.id, targetId, randomSkillId))
    }

}
