package xim.poc.game.configuration.v0.behaviors

import xim.poc.ModelLook
import xim.poc.game.ActorState
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.configuration.MonsterDefinitions
import xim.poc.game.configuration.Skill
import xim.poc.game.configuration.SkillType
import xim.poc.game.event.Event

private enum class WeeperForm(val look: Int, val appearanceState: Int, val skill: Int) {
    Dark(0x458, 1, 972),
    Water(0x458, 2, 971),
    Thunder(0x459, 1, 970),
    Earth(0x459, 2, 969),
    Light(0x45A, 1, 968),
    Fire(0x45A, 2, 965),
    Ice(0x45B, 1, 966),
    Wind(0x45B, 2, 967),
}

class MobWeeperController(actorState: ActorState): BasicMonsterController(actorState) {

    private val form = WeeperForm.values().random()

    override fun onInitialized(): List<Event> {
        actorState.setBaseLook(ModelLook.npc(form.look))
        actorState.appearanceState = form.appearanceState
        return emptyList()
    }

    override fun selectSkill(): Skill? {
        return Skill(SkillType.MobSkill, form.skill)
    }

}