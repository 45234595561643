package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.DefaultEnemyController
import xim.poc.SceneManager
import xim.poc.game.*
import xim.poc.game.configuration.v0.SpawnerChestDefinition
import xim.poc.game.event.InitialActorState
import xim.util.Fps
import xim.util.PI_f
import xim.util.RandHelper.rand
import kotlin.random.Random

data class SpawnArea(val position: Vector3f, val size: Vector3f)

fun interface MonsterProviderFactory {
    fun getMonsterProvider(): MonsterProvider

    companion object {
        fun from(table: WeightedTable<MonsterDefinition>): MonsterProviderFactory {
            return MonsterProviderFactory { MonsterProvider { table.getRandom() } }
        }
    }
}

fun interface MonsterProvider {
    fun nextMonster(): MonsterDefinition
}

data class MonsterSpawnerDefinition(
    val spawnArea: SpawnArea,
    val providerFactory: MonsterProviderFactory,
    val maxMonsters: Int,
    val spawnDelay: Float,
    val maxSpawnCount: Int? = null,
    val chestDefinition: SpawnerChestDefinition? = null,
)

class MonsterSlot {
    var spawnDelay = 0f
    var monster: MonsterInstance? = null
    var wanderDelay = 0f

    init {
        resetWanderDelay()
    }

    fun resetWanderDelay() {
        wanderDelay = Random.nextDouble(Fps.secondsToFrames(45).toDouble()).toFloat()
    }

}

class MonsterSpawnerInstance(val definition: MonsterSpawnerDefinition) {

    private val slots = Array(definition.maxMonsters) { MonsterSlot() }
    private val provider = definition.providerFactory.getMonsterProvider()

    private var totalSpawned = 0
    private var totalDefeated = 0

    fun update(elapsed: Float) {
        refreshMonsters(elapsed)
    }

    fun clear() {
        slots.mapNotNull { it.monster }.forEach { GameEngine.submitDeleteActor(it.actorId) }
        slots.forEach { it.monster = null }
    }

    fun getTotalDefeated(): Int {
        return totalDefeated
    }

    fun consumeProgress(amount: Int) {
        totalDefeated -= amount
        totalDefeated = totalDefeated.coerceAtLeast(0)
    }

    fun getProgress(): Pair<Int, Int>? {
        if (definition.maxSpawnCount == null) { return null }
        return Pair(totalDefeated, definition.maxSpawnCount)
    }

    fun hasDefeatedAllMonsters(): Boolean {
        val progress = getProgress() ?: return false
        return progress.first == progress.second
    }

    private fun refreshMonsters(elapsedFrames: Float) {
        for (slot in slots) {
            val currentMonster = slot.monster

            // Empty slot - waiting for spawn delay
            if (currentMonster == null) {
                slot.spawnDelay -= elapsedFrames
                if (slot.spawnDelay < 0f && slot.monster == null) { spawnMonster(slot) }
                continue
            }

            // Active monster
            val monsterState = ActorStateManager[currentMonster.actorId]
            if (monsterState != null && !monsterState.isDead()) {
                maybeWander(monsterState, slot, elapsedFrames)
                continue
            }

            // Defeat/deleted monster - reset for respawn
            totalDefeated += 1
            slot.monster = null
            slot.spawnDelay = definition.spawnDelay
        }
    }

    private fun spawnMonster(slot: MonsterSlot) {
        if (definition.maxSpawnCount != null && totalSpawned >= definition.maxSpawnCount) { return }
        totalSpawned += 1

        val monsterDefinition = provider.nextMonster()

        GameState.getGameMode().spawnMonster(monsterDefinition.id, InitialActorState(
            monsterId = monsterDefinition.id,
            name = monsterDefinition.name,
            type = ActorType.Enemy,
            position = getRandomSpawnPosition(monsterDefinition),
            modelLook = monsterDefinition.look,
            rotation = 2 * PI_f * rand(),
            movementController = monsterDefinition.movementControllerFn.invoke(),
            behaviorController = monsterDefinition.behaviorId,
            appearanceState = monsterDefinition.baseAppearanceState,
            staticPosition = monsterDefinition.staticPosition,
        )).onReady {
            slot.monster = MonsterInstance(monsterDefinition, it.id)
            monsterDefinition.onSpawn?.invoke(it)
        }
    }

    private fun maybeWander(monsterState: ActorState, slot: MonsterSlot, elapsedFrames: Float) {
        val movementController = monsterState.movementController
        if (movementController !is DefaultEnemyController) { return }

        val definition = slot.monster?.monsterDefinition ?: return

        if (!movementController.isWandering()) {
            slot.wanderDelay -= elapsedFrames
        }

        if (slot.wanderDelay <= 0f) {
            movementController.setWanderDestination(getRandomSpawnPosition(definition))
            slot.resetWanderDelay()
        }
    }

    private fun getRandomSpawnPosition(monsterDefinition: MonsterDefinition): Vector3f {
        val size = definition.spawnArea.size

        val position = definition.spawnArea.position + Vector3f(size.x * rand(), size.y * rand(), size.z * rand())
        if (monsterDefinition.staticPosition) { return position }

        position.y -= 5f
        return SceneManager.getCurrentScene().getNearestFloor(position) ?: position
    }

}