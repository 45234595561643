package xim.poc.game

import xim.math.Vector2f
import xim.poc.*
import xim.poc.browser.ClickEvent
import xim.poc.camera.CameraReference

private class UiClickListener(val position: Vector2f, val size: Vector2f, val handler: (ClickEvent) -> Boolean)
private class UiHoverListener(val position: Vector2f, val size: Vector2f, val handler: () -> Boolean)

object ClickHandler {

    private val listeners = ArrayList<UiClickListener>()

    private val hoverListeners = ArrayList<UiHoverListener>()

    fun registerUiClickHandler(position: Vector2f, size: Vector2f, handler: (ClickEvent) -> Boolean) {
        listeners += UiClickListener(position, size, handler)
    }

    fun registerUiHoverListener(position: Vector2f, size: Vector2f, handler: () -> Boolean) {
        hoverListeners += UiHoverListener(position, size, handler)
    }

    fun handleClickEvents() {
        handleHoverListeners()
        hoverListeners.clear()

        handleInternal()
        listeners.clear()
    }

    private fun handleHoverListeners() {
        val mousePosition = MainTool.platformDependencies.keyboard.getPointerPosition() ?: return

        for (listener in hoverListeners) {
            if (checkListener(listener, mousePosition)) {
                return
            }
        }
    }

    private fun handleInternal() {
        val clickEvent = MainTool.platformDependencies.keyboard.getClickEvents().lastOrNull() ?: return

        for (listener in listeners) {
            if (checkListener(listener, clickEvent)) {
                clickEvent.consumed = true
                break
            }
        }

        if (! clickEvent.rightClick && clickEvent.isLongClick()) {
            PlayerTargetSelector.tryDirectlyTarget(null)
        }

        val ray = CameraReference.getInstance().getWorldSpaceRay(clickEvent.normalizedScreenPosition) ?: return

        if (checkDoors(ray, clickEvent)) { return }
        if (checkActors(ray, clickEvent)) { return }
    }

    private fun checkDoors(ray: Ray, clickEvent: ClickEvent): Boolean {
        if (clickEvent.rightClick) { return false }

        val doors = SceneManager.getCurrentScene().getZoneInteractions().filter { it.isDoor() }
        for (door in doors) {
            val doorActor = SceneManager.getCurrentScene().getInteractionActor(door) ?: continue
            if (doorActor.doorState.open) { continue }

            val doorActorDisplay = ActorManager[doorActor.id] ?: continue
            if (!RayBoxCollider.slowIntersect(ray, door.boundingBox)) { continue }
            if (!PlayerTargetSelector.tryDirectlyTarget(doorActorDisplay)) { continue }
            clickEvent.consumed = true
            return true
        }
        return false
    }

    private fun checkActors(ray: Ray, clickEvent: ClickEvent): Boolean {
        val visibleActors = ActorManager.getVisibleActors()
        for (actor in visibleActors) {
            if (actor.isPlayer()) { continue }
            val boundingBox = actor.getSkeletonBoundingBox(index = 0) ?: continue
            if (!RayBoxCollider.slowIntersect(ray, boundingBox)) { continue }

            val handled = if (clickEvent.rightClick) {
                PlayerTargetSelector.tryDirectlyEngage(actor)
            } else {
                PlayerTargetSelector.tryDirectlyTarget(actor)
            }

            if (!handled) { continue }

            clickEvent.consumed = true
            return true
        }
        return false
    }

    private fun checkListener(listener: UiClickListener, clickEvent: ClickEvent): Boolean {
        val eventPosition = Vector2f(clickEvent.screenPosition.x, clickEvent.screenPosition.y)
        val collides = checkBoundingBox(listener.position, listener.size, eventPosition)
        return if (collides) { listener.handler(clickEvent) } else { false }
    }

    private fun checkListener(listener: UiHoverListener, mousePosition: Vector2f): Boolean {
        val collides = checkBoundingBox(listener.position, listener.size, mousePosition)
        return if (collides) { listener.handler() } else { false }
    }

    private fun checkBoundingBox(boxPosition: Vector2f, boxSize: Vector2f, eventPosition: Vector2f): Boolean {
        val position = boxPosition * UiElementHelper.globalUiScale
        val size = boxSize * UiElementHelper.globalUiScale

        return position.x < eventPosition.x && (position.x + size.x) >= eventPosition.x
                && position.y < eventPosition.y && (position.y + size.y) >= eventPosition.y
    }

}