package xim.poc.game.configuration.assetviewer

import xim.poc.ActorId
import xim.poc.SceneManager
import xim.poc.SynthesisType
import xim.poc.audio.BackgroundMusicResponse
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.assetviewer.DebugHelpers.getAbilityRange
import xim.poc.game.configuration.assetviewer.DebugHelpers.getAbilityRecast
import xim.poc.game.configuration.assetviewer.DebugHelpers.getCastingRangeInfo
import xim.poc.game.configuration.assetviewer.DebugHelpers.getItemRange
import xim.poc.game.event.*
import xim.poc.tools.CustomZoneConfig
import xim.poc.tools.ZoneConfig
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.ui.InventoryItemDescription
import xim.resource.*
import xim.resource.table.*
import xim.util.Fps
import kotlin.math.floor
import kotlin.math.pow
import kotlin.math.roundToInt
import kotlin.random.Random

private val assetViewerConfiguration = GameConfiguration(
    gameModeId = "AssetViewer",
    startingZoneConfig = CustomZoneConfig.Sarutabartua_East.config,
    debugControlsEnabled = true
)

object AssetViewer: GameLogic {

    override val configuration = assetViewerConfiguration

    private var zoneLogic: ZoneLogic? = null

    override fun setup(): List<Event> {
        MonsterDefinitions.registerAll(AssetViewerMonsterDefinitions.definitions)

        CommonSkillAppliers.registerTrustAppliers()

        AssetViewerAbilitySkills.register()
        AssetViewerSpellAppliers.register()
        AssetViewerItemDefinitions.register()
        AssetViewerTrustBehaviors.register()

        return emptyList()
    }

    override fun update(elapsedFrames: Float) {
        zoneLogic?.update(elapsedFrames)
    }

    override fun onChangedZones(zoneConfig: ZoneConfig) {
        zoneLogic?.cleanUp()

        zoneLogic = when (zoneConfig.zoneId) {
            EastSaru.zoneId -> EastSaru.zoneLogic
            else -> null
        }

        val zoneName = ZoneNameTable.first(zoneConfig)
        ChatLog.addLine("=== Area: $zoneName ===")
        ChatLog.addLine("The maximum number of people are already participating in Unity chat. Please try again later.", chatLogColor = ChatLogColor.Info)
    }

    override fun getNpcInteraction(actorId: ActorId): NpcInteraction? {
        val actor = ActorStateManager[actorId] ?: return null

        if (actor.name.startsWith("Home Point")) {
            return HomePointInteraction
        }

        return null
    }

    override fun getActorSpellList(actorId: ActorId): List<SpellInfo> {
        return DebugHelpers.allValidSpellIds.map { SpellInfoTable[it] }
    }

    override fun getActorAbilityList(actorId: ActorId, abilityType: AbilityType): List<AbilityInfo> {
        return DebugHelpers.abilitiesByType[abilityType] ?: emptyList()
    }

    override fun canDualWield(actorState: ActorState): Boolean {
        return true
    }

    override fun getActorCombatStats(actorId: ActorId): CombatStats {
        val actor = ActorStateManager[actorId] ?: return CombatStats.defaultBaseStats

        val monsterDefinition = MonsterDefinitions[actor.monsterId]
        if (monsterDefinition?.baseCombatStats != null) { return monsterDefinition.baseCombatStats }

        return CombatStats.defaultBaseStats.copy(maxMp = 999)
    }

    override fun generateItem(itemId: Int, quantity: Int, temporary: Boolean): InventoryItem {
        return InventoryItem(id = itemId, quantity = quantity, temporary = temporary)
    }

    override fun getItemDescription(actorId: ActorId, inventoryItem: InventoryItem): InventoryItemDescription {
        return InventoryItemDescription.toDescription(inventoryItem)
    }

    override fun getAutoAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        val attacks = ArrayList<AutoAttackResult>()

        attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.Main)
        if (attacker.isDualWield()) {
            attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.Sub)
        } else if (attacker.isHandToHand()) {
            attacks += DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.H2H)
        }

        return attacks
    }

    override fun getAutoAttackInterval(attacker: ActorState): Float {
        return DebugHelpers.getMainDelayInFrames(attacker) + DebugHelpers.getSubDelayInFrames(attacker)
    }

    override fun getRangedAttackResult(attacker: ActorState, defender: ActorState): List<AutoAttackResult> {
        return listOf(DebugHelpers.getAutoAttackResult(attacker, defender, AutoAttackType.Ranged))
    }

    override fun getRangedAttackInterval(attacker: ActorState): Float {
        val range = attacker.getEquipment(EquipSlot.Range)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
        if (range != null) { return range }

        val ammo = attacker.getEquipment(EquipSlot.Ammo)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
        if (ammo != null) { return ammo }

        return Fps.secondsToFrames(3f)
    }

    override fun getAugmentRankPointGain(attacker: ActorState, defender: ActorState, inventoryItem: InventoryItem): Int {
        return 0
    }

    override fun getAugmentRankPointsNeeded(augment: ItemAugment): Int {
        return AbilityInfoTable.getReinforcementPointsTable().table.entries.getOrNull(augment.rankLevel) ?: 0
    }

    override fun getExperiencePointGain(attacker: ActorState, defender: ActorState): Int {
        return 500
    }

    override fun getExperiencePointsNeeded(currentLevel: Int): Int {
        return 10 * AbilityInfoTable.getLevelTable().table.entries[currentLevel]
    }

    override fun getActorEffectTickResult(actorState: ActorState): ActorEffectTickResult {
        return ActorEffectTickResult(hpDelta = 0, mpDelta = 0, tpDelta = 0)
    }

    override fun getItemReinforcementValues(targetItem: InventoryItem): Map<Int, Int> {
        return emptyMap()
    }

    override fun getSkillRangeInfo(actorState: ActorState, skillType: SkillType, skillId: Int): SkillRangeInfo {
        return when (skillType) {
            SkillType.Spell -> getCastingRangeInfo(skillId)
            SkillType.MobSkill -> SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
            SkillType.Ability -> getAbilityRange(skillId)
            SkillType.Item -> getItemRange(skillId)
        }
    }

    override fun getSkillBaseCost(skillType: SkillType, skillId: Int): AbilityCost {
        return when (skillType) {
            SkillType.Spell -> AbilityCost(AbilityCostType.Mp, SpellInfoTable[skillId].mpCost)
            SkillType.MobSkill -> AbilityCost(AbilityCostType.Tp, 0)
            SkillType.Ability -> AbilityCost(AbilityCostType.Tp, AbilityInfoTable[skillId].cost)
            SkillType.Item -> AbilityCost(AbilityCostType.Tp, 0)
        }
    }

    override fun getSkillUsedCost(actorState: ActorState, skillType: SkillType, skillId: Int): AbilityCost {
        return when (skillType) {
            SkillType.Spell -> getSkillBaseCost(skillType, skillId)
            SkillType.MobSkill -> AbilityCost(AbilityCostType.Tp, actorState.getTp())
            SkillType.Item -> getSkillBaseCost(skillType, skillId)
            SkillType.Ability -> {
                val info = AbilityInfoTable[skillId]
                if (info.type == AbilityType.WeaponSkill) {
                    AbilityCost(AbilityCostType.Tp, actorState.getTp())
                } else {
                    getSkillBaseCost(skillType, skillId)
                }
            }
        }
    }

    override fun getSkillCastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float {
        return when (skillType) {
            SkillType.Spell -> getSpellCastTime(skillId)
            SkillType.MobSkill -> Fps.secondsToFrames(2)
            SkillType.Ability -> 0f
            SkillType.Item -> getItemCastTime(skillId)
        }
    }

    override fun getSkillRecastTime(caster: ActorState, skillType: SkillType, skillId: Int): Float {
        return when (skillType) {
            SkillType.Ability -> getAbilityRecast(AbilityInfoTable[skillId])
            SkillType.Spell -> SpellInfoTable[skillId].recastDelayInFrames()
            SkillType.MobSkill -> 0f
            SkillType.Item -> 0f
        }
    }

    override fun getSkillChainAttributes(attacker: ActorState, defender: ActorState, skillType: SkillType, skillId: Int): List<SkillChainAttribute> {
        return if (skillType == SkillType.Ability) {
            AbilityInfoTable.getApproximateSkillChainAttributes(skillId)
        } else {
            emptyList()
        }
    }

    override fun getSkillChainDamage(attacker: ActorState, defender: ActorState, skillChain: SkillChain, closingWeaponSkillDamage: Int): Int {
        val damageMultiplier = (0.5f * skillChain.attribute.level) * (1.25f.pow(skillChain.numSteps)).coerceAtMost(3f)
        return (closingWeaponSkillDamage * damageMultiplier).roundToInt()
    }

    override fun getActorCombatBonuses(actorState: ActorState): CombatBonusAggregate {
        val aggregate = CombatBonusAggregate()

        if (actorState.mountedState != null) {
            aggregate.movementSpeed += 100
        }

        val restingTicks = (actorState.type == ActorType.Pc && actorState.isResting()) || (actorState.isEnemy() && actorState.isIdle())
        if (restingTicks) {
            aggregate.regen += (actorState.getMaxHp() * 0.25f).roundToInt()
            aggregate.refresh += (actorState.getMaxHp() * 0.25f).roundToInt()
        }

        return aggregate
    }

    override fun getCurrentBackgroundMusic(): BackgroundMusicResponse {
        val zoneSettings = ZoneSettingsTable[SceneManager.getCurrentScene().config]
        return GameEngine.selectBgm(zoneSettings.musicSettings)
    }

    private fun getSpellCastTime(spellId: Int): Float {
        val spellInfo = SpellInfoTable[spellId]
        return spellInfo.castTimeInFrames()
    }

    private fun getItemCastTime(itemId: Int): Float {
        return InventoryItems[itemId].usableItemInfo?.castTimeInFrames() ?: 0f
    }

    override fun rollParry(attacker: ActorState, defender: ActorState): Boolean {
        return Random.nextFloat() <= 0.05f
    }

    override fun rollGuard(attacker: ActorState, defender: ActorState): Boolean {
        return false
    }

    override fun rollSpellInterrupted(attacker: ActorState, defender: ActorState): Boolean {
        return Random.nextFloat() > 0.5f
    }

    override fun spawnMonster(monsterId: MonsterId, initialActorState: InitialActorState): ActorPromise {
        return GameEngine.submitCreateActorState(initialActorState)
    }

    override fun getKnownSynthesisRecipes(actorState: ActorState, type: SynthesisType): List<SynthesisRecipe> {
        return AssetViewerSynthesisRecipes.recipes.filter { it.synthesisType == type }
    }

}

private object DebugHelpers {
    val allValidSpellIds: List<Int> by lazy {
        (1 until 1024)
            .filter { SpellInfoTable.hasInfo(it) }
            .filter { SpellNameTable.first(it) != "." }
            .filter { SpellNameTable.first(it) != "jet-stream-attack" }
            .filter { SpellNameTable.first(it) != "dummy" }
    }

    val abilitiesByType: Map<AbilityType, List<AbilityInfo>> by lazy {
        (1 until 0x1000)
            .filter { AbilityInfoTable.hasInfo(it) }
            .filter { AbilityNameTable.first(it) != "." }
            .map { AbilityInfoTable[it] }
            .groupBy { it.type }
    }

    fun getMainHandDamage(actorState: ActorState): Int {
        return delayToDamage(getMainDelayInFrames(actorState))
    }

    fun getSubHandDamage(actorState: ActorState): Int {
        return delayToDamage(getSubDelayInFrames(actorState))
    }

    fun getMainDelayInFrames(actorState: ActorState): Float {
        return actorState.getEquipment(EquipSlot.Main)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
            ?: Fps.secondsToFrames(3)
    }

    fun getSubDelayInFrames(actorState: ActorState): Float {
        return actorState.getEquipment(EquipSlot.Sub)?.info()?.equipmentItemInfo?.weaponInfo?.delay?.toFloat()
            ?: 0f
    }

    fun delayToDamage(delay: Float): Int {
        val base = delay / 60f
        val remainder = (base % 1f)
        val roundUp = if (Random.nextFloat() < remainder) { 1 } else { 0 }
        return floor(base).toInt() + roundUp
    }

    fun getAutoAttackResult(attacker: ActorState, defender: ActorState, type: AutoAttackType): AutoAttackResult {
        val damage = when (type) {
            AutoAttackType.Main -> getMainHandDamage(attacker)
            AutoAttackType.Sub -> getSubHandDamage(attacker)
            AutoAttackType.H2H -> getMainHandDamage(attacker)
            AutoAttackType.Ranged -> 1
        }

        val context = AttackContext.from(attacker, defender)
        context.setSourceFlag(attacker)

        if (Random.nextDouble() < 0.15) {
            context.setCriticalHitFlag()
        }

        return AutoAttackResult(context = context, tpGained = 500, damageDone = damage, targetId = defender.id, targetTpGained = 0, type = type)
    }

    fun getCastingRangeInfo(spellId: Int): SkillRangeInfo {
        val spellInfo = SpellInfoTable[spellId]
        return SkillRangeInfo(maxTargetDistance = 24f, effectRadius = spellInfo.aoeSize.toFloat(), type = spellInfo.aoeType)
    }

    fun getItemRange(itemId: Int): SkillRangeInfo {
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = 0f, type = AoeType.None)
    }

    fun getAbilityRange(abilityId: Int): SkillRangeInfo {
        val abilityInfo = AbilityInfoTable[abilityId]
        return SkillRangeInfo(maxTargetDistance = 10f, effectRadius = abilityInfo.aoeSize.toFloat(), type = abilityInfo.aoeType)
    }

    fun getAbilityRecast(abilityInfo: AbilityInfo): Float {
        val delayInSeconds = if (abilityInfo.type == AbilityType.WeaponSkill) {
            2.5f
        } else if (abilityInfo.type == AbilityType.PhantomRoll) {
            2.5f
        } else {
            5f
        }

        return Fps.secondsToFrames(delayInSeconds)
    }

}