package xim.poc.game.configuration.v0.behaviors

import xim.math.Matrix4f
import xim.math.Vector3f
import xim.poc.ActorController
import xim.poc.DefaultEnemyController
import xim.poc.EnvironmentManager
import xim.poc.game.*
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.V0MonsterHelper
import xim.poc.game.event.*
import xim.poc.ui.ChatLog
import xim.poc.ui.ChatLogColor
import xim.poc.velocityVectorTo
import xim.resource.DatId
import xim.util.Fps
import xim.util.PI_f
import xim.util.RandHelper
import xim.util.addInPlace

private val droningPosition = Vector3f(x=-143.68f,y=0.35f,z=419.17f)

private val beeSpawnPositions = listOf(
    Vector3f(x = -127.28f, y = 0.28f, z = 432.12f),
    Vector3f(x = -142.94f, y = 0.50f, z = 406.27f),
    Vector3f(x = -150.89f, y = 0.55f, z = 429.68f),
)

class MobColkhabController(val actorState: ActorState) : ActorBehaviorController {

    private val autoAttackState = AutoAttackState(actorState)

    private val spawnedBees = ArrayList<ActorPromise>()
    private var droningCount = 3

    private val spellCooldown = Fps.secondsToFrames(10)
    private var currentSpellCooldown = spellCooldown

    override fun update(elapsedFrames: Float): List<Event> {
        adjustWeather()

        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        if (wantsToUseDroningWhirlwind()) {
            return if (Vector3f.distance(actorState.position, droningPosition) > 1f) {
                actorState.setTargetState(actorState.targetState.targetId, locked = false)
                emptyList()
            } else {
                actorState.setTargetState(actorState.targetState.targetId, locked = true)
                useDroningWhirlwind()
            }
        }

        actorState.appearanceState = if (spawnedBeesAreObsolete()) { 0 } else { 1 }

        val events = ArrayList<Event>()
        events += syncBees()

        events += if (actorState.appearanceState == 1) {
            handleAuraState(elapsedFrames)
        } else {
            handleDefaultState(elapsedFrames)
        }

        return events
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.movementSpeed += 25
        aggregate.knockBackResistance += 100
        aggregate.statusResistances.addInPlace(StatusEffect.Stun, 100)
        aggregate.statusResistances.addInPlace(StatusEffect.Sleep, 100)
        aggregate.statusResistances.addInPlace(StatusEffect.Bind, 100)

        if (wantsToUseDroningWhirlwind() || actorState.appearanceState == 1) {
            aggregate.physicalDamageTaken = -100
            aggregate.magicalDamageTaken = -100
        }
    }

    override fun onSkillExecuted(primaryTargetContext: SkillApplierHelper.TargetEvaluatorContext): List<Event> {
        val skill = primaryTargetContext.skill
        if (skill.skillType != SkillType.MobSkill || skill.id != 2749) { return emptyList() }

        spawnBees()
        return emptyList()
    }

    override fun onDefeated(): List<Event> {
        actorState.appearanceState = 0
        adjustWeather()
        return emptyList()
    }

    fun getDesiredPosition(): Vector3f? {
        return if (actorState.appearanceState == 1 || wantsToUseDroningWhirlwind()) {
            Vector3f(x=-143.68f,y=0.35f,z=419.17f)
        } else {
            null
        }
    }

    private fun adjustWeather() {
        if (actorState.appearanceState == 0) {
            EnvironmentManager.switchWeather(DatId.weatherSunny)
        } else if (actorState.appearanceState == 1) {
            EnvironmentManager.switchWeather(DatId.weatherWindy)
        }
    }

    private fun handleAuraState(elapsedFrames: Float): List<Event> {
        currentSpellCooldown -= elapsedFrames
        if (currentSpellCooldown > 0f) { return emptyList() }

        val targetState = ActorStateManager[actorState.targetState.targetId] ?: return emptyList()
        if (Vector3f.distance(actorState.position, targetState.position) > 50f) {
            // FIXME -> event
            ChatLog("${targetState.name} is drawn in!", ChatLogColor.Action)
            drawIn(targetState)
            spawnedBees.forEach { it.onReady { bee -> drawIn(bee) } }
        }

        currentSpellCooldown = spellCooldown
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetState.id, mobSkillId = 2750))
    }

    private fun drawIn(targetState: ActorState) {
        val offset = Matrix4f().rotateYInPlace(RandHelper.posRand(PI_f * 2f)).transformInPlace(Vector3f(2f, 0f,0f))
        targetState.position.copyFrom(actorState.position + offset)
    }

    private fun handleDefaultState(elapsedFrames: Float): List<Event> {
        if (actorState.getTp() >= 500) {
            actorState.setTp(0)
            return useWeaponSkillAbility()
        }

        autoAttackState.update(elapsedFrames)
        if (autoAttackState.isReadyToAutoAttack()) {
            autoAttackState.resetAutoAttack()
            return useAutoAttackAbility()
        }

        return emptyList()
    }

    private fun useAutoAttackAbility(): List<Event> {
        val aa = listOf(2743, 2744, 2745).random()
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = aa))
    }

    private fun useWeaponSkillAbility(): List<Event> {
        val ws = listOf(2746, 2747, 2748).random()
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = ws))
    }

    private fun wantsToUseDroningWhirlwind(): Boolean {
        val threshold = actorState.getHpp() / 0.25f
        return threshold < droningCount
    }

    private fun useDroningWhirlwind(): List<Event> {
        val targetId = actorState.targetState.targetId ?: return emptyList()
        currentSpellCooldown = spellCooldown
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = 2749))
    }

    private fun syncBees(): List<Event> {
        val targetId = actorState.targetState.targetId ?: return emptyList()

        val events = ArrayList<Event>()

        spawnedBees.forEach { it.onReady { bee ->
            if (!bee.isEngaged()) { events += BattleEngageEvent(bee.id, targetId) }
        } }

        return events
    }

    private fun spawnedBeesAreObsolete(): Boolean {
        if (spawnedBees.any { it.getIfReady() == null }) { return false }
        return spawnedBees.map { ActorStateManager[it.getIfReady()] }.all { it == null || it.isDead() }
    }

    private fun spawnBees() {
        val count = when (droningCount) {
            3 -> 1
            2 -> 2
            1 -> 3
            else -> 0
        }

        droningCount -= 1
        (0 until count).forEach { spawnBee(it) }
    }

    private fun spawnBee(index: Int) {
        val monsterDefinition = MonsterDefinitions[8]
        val position = beeSpawnPositions[index]

        spawnedBees += V0MonsterHelper.spawnMonster(
            monsterDefinition = monsterDefinition,
            position = position,
        )
    }

}

class MobColkhabMovementController: ActorController {

    private val defaultDelegate = DefaultEnemyController()

    override fun getVelocity(actorState: ActorState, elapsedFrames: Float): Vector3f {
        val behaviorController = actorState.behaviorController
        val defaultBehavior = defaultDelegate.getVelocity(actorState, elapsedFrames)

        if (behaviorController !is MobColkhabController) { return defaultBehavior }

        val desiredPosition = behaviorController.getDesiredPosition() ?: return defaultBehavior

        if (Vector3f.distance(desiredPosition, actorState.position) < 1f) { return Vector3f.ZERO }

        return velocityVectorTo(actorState.position, desiredPosition, actorState.getMovementSpeed(), elapsedFrames)
    }

}

class MobColkhabBeeController(val actorState: ActorState): ActorBehaviorController {

    private val autoAttackDelegate = AutoAttackController(actorState)

    override fun update(elapsedFrames: Float): List<Event> {
        if (actorState.getHpp() <= 0.5f) {
            return useFinalSting()
        }

        return autoAttackDelegate.update(elapsedFrames)
    }

    override fun applyBehaviorBonuses(aggregate: CombatBonusAggregate) {
        aggregate.movementSpeed -= 75
    }

    private fun useFinalSting(): List<Event> {
        val target = actorState.targetState.targetId ?: return emptyList()

        if (!GameEngine.canBeginAction(actorState)) { return emptyList() }

        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = target, mobSkillId = 80))
    }

}