package xim.poc.game.configuration.v0.behaviors

import xim.poc.game.ActorState
import xim.poc.game.configuration.BasicMonsterController
import xim.poc.game.event.CastMobSkillStart
import xim.poc.game.event.Event

class MobCraklawController(actorState: ActorState): BasicMonsterController(actorState) {

    override fun onReadyToAutoAttack(): List<Event> {
        val aa = listOf(2698, 2699, 2700).random()
        val targetId = actorState.targetState.targetId ?: return emptyList()
        return listOf(CastMobSkillStart(sourceId = actorState.id, targetId = targetId, mobSkillId = aa))
    }

}