package xim.poc.game.configuration

import xim.math.Vector3f
import xim.poc.ModelLook
import xim.poc.game.ActorPromise
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.GameEngine
import xim.poc.game.event.InitialActorState
import kotlin.random.Random
import kotlin.time.Duration.Companion.milliseconds

enum class GatheringType {
    Harvesting,
    Logging,
    Mining,
}

class GatheringNodeItem(
    val itemId: Int,
    val weight: Double,
)

class GatheringConfiguration(
    val type: GatheringType,
    val positions: List<Vector3f>,
    val items: List<GatheringNodeItem>,
) {

    private val sum = items.sumOf { it.weight }

    fun getRandomItem(): GatheringNodeItem {
        val rand = Random.nextDouble(sum)
        var cumulativeSum = 0.0

        for (item in items) {
            cumulativeSum += item.weight
            if (rand < cumulativeSum) {
                return item
            }
        }

        return items.last()
    }

}

class GatheringNodeSpawner(val configuration: GatheringConfiguration) {

    private var promise: ActorPromise? = null

    fun update(elapsedFrames: Float) {
        val currentPromise = promise

        if (currentPromise == null) {
            submitCreate()
            return
        }

        val currentId = currentPromise.getIfReady() ?: return

        val currentState = ActorStateManager[currentId]
        if (currentState == null || currentState.hasBeenDeadFor(500.milliseconds)) {
            submitCreate()
        }
    }

    private fun submitCreate() {
        promise = GameEngine.submitCreateActorState(InitialActorState(
            name = getName(),
            position = Vector3f(configuration.positions.random()),
            modelLook = getModelLook(),
            type = ActorType.Effect,
            gatheringConfiguration = configuration,
        ))
    }

    private fun getName(): String {
        return when (configuration.type) {
            GatheringType.Harvesting -> "Harvesting point"
            GatheringType.Logging -> "Logging point"
            GatheringType.Mining -> "Mining point"
        }
    }

    private fun getModelLook(): ModelLook {
        return when (configuration.type) {
            GatheringType.Harvesting -> ModelLook.npc(0x976)
            GatheringType.Logging -> ModelLook.npc(0x977)
            GatheringType.Mining -> ModelLook.npc(0x978)
        }
    }

    fun clear() {
        val current = promise ?: return
        current.onReady { GameEngine.submitDeleteActor(it.id) }
    }

}
