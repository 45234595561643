package xim.poc.game.event

import xim.math.Vector3f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.ModelLook
import xim.poc.game.ActorPromise
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.StatusEffect
import xim.poc.gl.ByteColor
import xim.resource.DatId
import xim.resource.table.SpellInfoTable
import kotlin.random.Random
import kotlin.time.Duration.Companion.seconds

class BubbleGainEvent(
    val sourceId: ActorId,
    val spellId: Int,
): Event {

    override fun apply(): List<Event> {
        val actor = ActorStateManager[sourceId] ?: return emptyList()
        val spellInfo = SpellInfoTable[spellId]

        actor.gainStatusEffect(StatusEffect.Indicolure, 30.seconds)

        val position = Vector3f().copyFrom(actor.position)

        val offenseOffset = if (Random.nextBoolean()) { 0x8 } else { 0x0 } // TODO: split Indi spells
        val lookId = 0x77C + spellInfo.element.index + offenseOffset
        val npcLook = ModelLook.npc(lookId)

        val initialActorState = InitialActorState(
            name = "(Bubble)",
            type = ActorType.Object,
            position = position,
            modelLook = npcLook,
            bubbleSettings = BubbleSettings(actor.id)
        )

        val promise = ActorPromise().onReady {
            val actorDisplay = ActorManager.getOrCreate(it)
            actorDisplay.renderState.effectColor = ByteColor.zero
            actorDisplay.onReadyToDraw {
                actorDisplay.transitionToIdle(0f)
                actorDisplay.playRoutine(DatId.pop)
            }
        }

        return listOf(ActorCreateEvent(initialActorState, promise))
    }

}