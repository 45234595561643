package xim.poc.game.configuration.v0

import xim.math.Vector3f
import xim.poc.ActorAssociation
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.QueryMenuOption
import xim.poc.game.QueryMenuResponse
import xim.poc.game.UiStateHelper
import xim.poc.game.configuration.*
import xim.poc.game.configuration.v0.tower.TowerConfiguration
import xim.util.Fps
import kotlin.time.Duration.Companion.seconds

class EntranceInteraction(val destination: Vector3f): NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        UiStateHelper.openQueryMode("What will you do?", getOptions(), callback = this::handleQueryResponse)
    }

    private fun getOptions(): List<QueryMenuOption> {
        val options = ArrayList<QueryMenuOption>()
        options += QueryMenuOption("Do not use.", value = -1)
        options += QueryMenuOption("Tower!", value = 1)

        return options
    }

    private fun handleQueryResponse(response: QueryMenuOption?): QueryMenuResponse {
        if (response == null || response.value < 0) { return QueryMenuResponse.pop }

        val options = ArrayList<QueryMenuOption>()

        val floors = TowerConfiguration.getAll().entries.sortedBy { it.key }
            .filter { GameTower.hasClearedFloor(it.value.floorNumber - 1) }

        for ((floor, _) in floors) {
            options += QueryMenuOption("$floor", floor)
        }

        UiStateHelper.openQueryMode("Which floor?", options, callback = { enterBattle(it) })
        return QueryMenuResponse.noop
    }

    private fun enterBattle(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null) { return QueryMenuResponse.pop }

        val floor = TowerConfiguration[queryMenuOption.value]
        val battleLocation = floor.battleLocation

        val playerAssociation = ActorAssociation(ActorManager.player())

        val script = EventScript(
            listOf(
                EffectRoutineEventItem(fileTableIndex = 0x113c8, playerAssociation, eagerlyComplete = true),
                WaitRoutine(Fps.secondsToFrames(1.9f)),
                FadeOutEvent(1.seconds),
                WarpZoneEventItem(battleLocation.startingPosition, fade = false),
                RunOnceEventItem {
                    GameTower.resetTowerState(startingFloor = queryMenuOption.value)
                    GameTower.emitFloorNumber()
                    GameV0.setupBattle(floor = queryMenuOption.value)
                },
                WaitRoutine(Fps.secondsToFrames(0.2f)),
                FadeInEvent(1.seconds),
            )
        )

        EventScriptRunner.runScript(script)
        return QueryMenuResponse.popAll
    }

}