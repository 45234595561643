package xim.poc.game.configuration.v0.interactions

import xim.math.Vector2f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.UiElementHelper
import xim.poc.audio.SystemSound
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.SkillType
import xim.poc.game.configuration.v0.GameV0
import xim.poc.game.configuration.v0.interactions.BlueMagicUi.drawBlueEquip
import xim.poc.game.configuration.v0.interactions.BlueMagicUi.drawBlueHelp
import xim.poc.game.configuration.v0.interactions.BlueMagicUi.drawBlueInventory
import xim.poc.game.configuration.v0.interactions.BlueMagicUi.equipSpell
import xim.poc.game.configuration.v0.interactions.BlueMagicUi.getLearnedSpells
import xim.poc.game.configuration.v0.interactions.BlueMagicUiState.blueEquip
import xim.poc.game.configuration.v0.interactions.BlueMagicUiState.blueHelp
import xim.poc.game.configuration.v0.interactions.BlueMagicUiState.blueInventory
import xim.poc.gl.Color
import xim.poc.ui.ShiftJis
import xim.poc.ui.SpellSelectUi
import xim.resource.DatId
import xim.resource.SpellInfo
import xim.resource.table.SpellInfoTable
import xim.resource.table.SpellNameTable
import xim.util.Fps
import kotlin.time.DurationUnit

object BlueMagicInteraction: NpcInteraction {

    override fun onInteraction(npcId: ActorId) {
        ActorManager[npcId]?.playRoutine(DatId("job0"))
        UiStateHelper.pushState(blueEquip, SystemSound.MenuSelect)
    }

}

private object BlueMagicUiState {

    lateinit var blueHelp: UiState
    lateinit var blueInventory: UiState
    lateinit var blueEquip: UiState

    init {
        blueHelp = UiState(
            focusMenu = "menu    bluehelp",
            additionalDraw = { drawBlueHelp() }
        ) {
            false
        }

        blueInventory = UiState(
            focusMenu = "menu    bluinven",
            additionalDraw = { drawBlueInventory() },
            scrollSettings = ScrollSettings(10) { getLearnedSpells().size },
            resetCursorIndexOnPush = false,
            childStates = { listOf(blueHelp) },
            drawParent = true,
            locksMovement = true,
        ) {
            if (UiStateHelper.isEnterPressed()) {
                equipSpell()
                UiStateHelper.popState(SystemSound.MenuSelect)
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        blueEquip = UiState(
            focusMenu = "menu    bluequip",
            additionalDraw = { drawBlueEquip() },
            childStates = { listOf(blueInventory, blueHelp) },
            scrollSettings = ScrollSettings(10) { 20 },
            locksMovement = true,
        ) {
            if (UiStateHelper.isEnterPressed()) {
                UiStateHelper.pushState(blueInventory, SystemSound.MenuSelect)
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

    }

}

private object BlueMagicUi {

    fun getLearnedSpells(): List<SpellInfo> {
        return ActorStateManager.player().learnedSpells.spellIds
            .map { SpellInfoTable[it] }
    }

    fun getEquippedSpells(): List<SpellInfo> {
        return ActorStateManager.player().learnedSpells.equippedSpells.map { SpellInfoTable[it] }
    }

    fun equipSpell() {
        val actorSpells = ActorStateManager.player().learnedSpells

        val equipSlot = getSelectedIndex(blueEquip)
        if (equipSlot >= actorSpells.equippedSpells.size) { return }

        val inventorySlot = getSelectedIndex(blueInventory)
        val inventorySpell = actorSpells.spellIds.getOrNull(inventorySlot) ?: return

        val currentlyEquippedSlot = actorSpells.equippedSpells.indexOfFirst { it == inventorySpell }

        if (currentlyEquippedSlot == equipSlot) {
            actorSpells.equippedSpells[equipSlot] = 0
        } else {
            if (currentlyEquippedSlot >= 0) {
                actorSpells.equippedSpells[currentlyEquippedSlot] = actorSpells.equippedSpells[equipSlot]
            }
            actorSpells.equippedSpells[equipSlot] = inventorySpell
        }
    }

    fun drawBlueEquip() {
        val scrollSettings = blueEquip.scrollSettings ?: return
        val stackPos = blueEquip.latestPosition ?: return

        val offset = Vector2f()

        for (i in 0 until 10) {
            val itemIndex = scrollSettings.lowestViewableItemIndex + i

            val current = i == blueEquip.cursorIndex
            val mask = if (current) { Color(1f, 0.75f, 0f, 1f) } else { Color.NO_MASK }
            UiElementHelper.drawUiElement(lookup = "menu    keytops3", index = 400+itemIndex, color = mask, position = offset + stackPos + Vector2f(4f, 4f))

            val spellInfo = getEquippedSpells().getOrNull(itemIndex)

            if (spellInfo != null && spellInfo.index != 0) {
                val (spellIcon, spellIndex) = SpellSelectUi.getSpellIcon(spellInfo)
                UiElementHelper.drawUiElement(lookup = spellIcon, index = spellIndex, position = offset + stackPos + Vector2f(22f, 4f))

                val spellName = SpellNameTable[spellInfo.index].first()
                UiElementHelper.drawString(text = spellName, offset = offset + stackPos + Vector2f(42f, 8f))
            }

            registerHoverListener(blueEquip, stackPos + offset, i - scrollSettings.lowestViewableItemIndex)
            offset.y += 18f
        }
    }

    fun drawBlueInventory() {
        val scrollSettings = blueInventory.scrollSettings ?: return
        val stackPos = blueInventory.latestPosition ?: return

        val offset = Vector2f()

        for (i in 0 until 10) {
            val itemIndex = scrollSettings.lowestViewableItemIndex + i
            val spellInfo = getLearnedSpells().getOrNull(itemIndex)

            if (spellInfo != null) {
                val (spellIcon, spellIndex) = SpellSelectUi.getSpellIcon(spellInfo)
                UiElementHelper.drawUiElement(lookup = spellIcon, index = spellIndex, position = offset + stackPos + Vector2f(4f, 4f))

                val textColor = if (isEquipped(spellInfo)) { ShiftJis.colorItem } else { ShiftJis.colorWhite }
                val spellName = "$textColor${SpellNameTable[spellInfo.index].first()}${ShiftJis.colorClear}"

                UiElementHelper.drawString(text = spellName, offset = offset + stackPos + Vector2f(22f, 8f))
            }

            registerHoverListener(blueInventory, stackPos + offset, i - scrollSettings.lowestViewableItemIndex)
            offset.y += 18f
        }
    }

    fun drawBlueHelp() {
        val stackPos = blueHelp.latestPosition ?: return

        val spellInfo = if (UiStateHelper.isFocus(blueEquip)) {
            getEquippedSpells().getOrNull(getSelectedIndex(blueEquip)) ?: return
        } else {
            getLearnedSpells().getOrNull(getSelectedIndex(blueInventory)) ?: return
        }

        if (spellInfo.index == 0) { return }

        val baseCost = GameV0.getSkillBaseCost(SkillType.Spell, spellInfo.index)

        val recastTime = GameV0.getSkillRecastTime(ActorStateManager.player(), SkillType.Spell, spellInfo.index)
        val recastTimeDuration = Fps.framesToSeconds(recastTime)

        val spellName = SpellNameTable[spellInfo.index].first()
        val message = "$spellName\nDummy text\n${baseCost.type.name.uppercase()} cost: ${baseCost.value}    Recast: ${recastTimeDuration.toString(DurationUnit.SECONDS, decimals = 0)}"

        val offset = stackPos + Vector2f(6f, 6f)
        UiElementHelper.drawString(text = message, offset = offset)
    }

    private fun registerHoverListener(uiState: UiState, position: Vector2f, cursorIndex: Int) {
        if (!UiStateHelper.isFocus(uiState)) { return }

        ClickHandler.registerUiHoverListener(position, Vector2f(160f, 18f)) {
            uiState.cursorIndex = cursorIndex
            true
        }
    }

    private fun getSelectedIndex(uiState: UiState): Int {
        val scroll = uiState.scrollSettings ?: return 0
        return scroll.lowestViewableItemIndex + uiState.cursorIndex
    }

    private fun isEquipped(spellInfo: SpellInfo): Boolean {
        return getEquippedSpells().any { it.index == spellInfo.index }
    }

}
