package xim.poc.game.configuration.assetviewer

import xim.math.Vector3f
import xim.poc.game.configuration.*
import xim.util.Fps

object EastSaru {

    const val zoneId = 116

    private val varietyTable = WeightedTable.uniform(
        MonsterDefinitions[0x001],
        MonsterDefinitions[0x002],
        MonsterDefinitions[0x003],
        MonsterDefinitions[0x004],
        MonsterDefinitions[0x005],
        MonsterDefinitions[0x006],
    )

    private val spawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(-76f, -5f, -520f), size = Vector3f(20f, 0f, 20f)),
        spawnDelay = Fps.secondsToFrames(10f),
        maxMonsters = 8,
        providerFactory = MonsterProviderFactory.from(varietyTable),
    )

    private val boxSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(x = -99.91f, y = -5.00f, z = -528.85f), size = Vector3f(1f, 0f, 1f)),
        spawnDelay = Fps.secondsToFrames(10f),
        maxMonsters = 3,
        providerFactory = MonsterProviderFactory.from(WeightedTable.single(MonsterDefinitions[0x00A])),
    )

    private val elemSpawner = MonsterSpawnerDefinition(
        spawnArea = SpawnArea(position = Vector3f(-76f, -5f, -520f), size = Vector3f(0f, 0f, 0f)),
        spawnDelay = Fps.secondsToFrames(10f),
        maxMonsters = 1,
        providerFactory = MonsterProviderFactory.from(
            WeightedTable.uniform(MonsterDefinitions[0x007], MonsterDefinitions[0x008], MonsterDefinitions[0x009]
        )),
    )

    val zoneLogic = ZoneLogic(
        monsterSpawnerDefinitions = listOf(spawner, elemSpawner, boxSpawner),
        gatheringPoints = emptyList(),
    )

}