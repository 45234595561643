package xim.poc.game.configuration.v0.interactions

import xim.math.Vector2f
import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.UiElementHelper
import xim.poc.audio.AudioManager
import xim.poc.audio.SystemSound
import xim.poc.game.*
import xim.poc.game.configuration.NpcInteraction
import xim.poc.game.configuration.v0.GameV0Helpers
import xim.poc.game.configuration.v0.ItemDefinitions
import xim.poc.game.configuration.v0.events.WeaponUpgradeEvent
import xim.poc.game.configuration.v0.interactions.UpgradeWeaponUiState.upgradeWeaponDestinationSelectContext
import xim.poc.game.configuration.v0.interactions.UpgradeWeaponUiState.upgradeWeaponSelectContext
import xim.poc.ui.*
import xim.resource.DatId
import xim.resource.InventoryItemInfo
import xim.resource.InventoryItems

object UpgradeWeaponInteraction : NpcInteraction {

    private var describedSelf = false

    override fun onInteraction(npcId: ActorId) {
        if (!describedSelf) {
            describedSelf = true
            ChatLog("Weapons can be upgraded once they have become strong enough.")
        }

        UiStateHelper.pushState(upgradeWeaponSelectContext, SystemSound.MenuSelect)
    }

}

object UpgradeWeaponEquipmentFilter: InventoryFilter {
    override fun apply(inventoryItem: InventoryItem): Boolean {
        return ItemDefinitions[inventoryItem].upgradeOptions.isNotEmpty()
    }
}

private object UpgradeWeaponUiState {

    val upgradeWeaponSelectContext: UiState
    val upgradeWeaponDestinationSelectContext: UiState

    init {
        upgradeWeaponDestinationSelectContext = UiState(
            additionalDraw = { UpgradeWeaponInteractionUi.drawUpgradeOptions(it) },
            drawParent = true,
            parentRelative = ParentRelative.RightOfParent,
            focusMenu = "menu    inventor",
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { UpgradeWeaponInteractionUi.getUpgradeOptions().size },
            hideGauge = true,
        ) {
            if (UiStateHelper.isEnterPressed()) {
                UpgradeWeaponInteractionUi.openUpgradeConfirmation()
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

        upgradeWeaponSelectContext = UiState(
            additionalDraw = { InventoryUi.drawInventoryItems(it, filter = UpgradeWeaponEquipmentFilter, descriptionProvider = UpgradeWeaponInteractionUi::getDescriptionWithMaxes) },
            focusMenu = "menu    inventor",
            childStates = { listOf(upgradeWeaponDestinationSelectContext) },
            resetCursorIndexOnPush = false,
            scrollSettings = ScrollSettings(numElementsInPage = 10) { InventoryUi.getItems(filter = UpgradeWeaponEquipmentFilter).size },
            hideGauge = true,
        ) {
            if (UiStateHelper.isEnterPressed()) {
                UiStateHelper.pushState(upgradeWeaponDestinationSelectContext, SystemSound.MenuSelect)
                true
            } else if (UiStateHelper.isEscPressed()) {
                UiStateHelper.popState(SystemSound.MenuClose)
                true
            } else {
                false
            }
        }

    }

}

object UpgradeWeaponInteractionUi {

    fun getSelectedUpgradeItem(): InventoryItem? {
        return InventoryUi.getSelectedItem(upgradeWeaponSelectContext, filter = UpgradeWeaponEquipmentFilter)
    }

    fun drawUpgradeOptions(uiState: UiState) {
        val stackPos = uiState.latestPosition ?: return
        val offset = Vector2f(0f, 0f)

        val options = getUpgradeOptions()

        val scrollSettings = uiState.scrollSettings!!
        for (i in scrollSettings.lowestViewableItemIndex until scrollSettings.lowestViewableItemIndex + scrollSettings.numElementsInPage) {
            if (i >= options.size) { break }

            val itemInfo = options[i]

            val color = if (canUpgrade(itemInfo)) {
                UiElementHelper.getStandardTextColor(1)
            } else {
                UiElementHelper.getStandardTextColor(0)
            }

            UiElementHelper.drawInventoryItemIcon(itemInfo = itemInfo, position = offset + stackPos + Vector2f(2f, 4f), scale = Vector2f(0.5f, 0.5f))
            UiElementHelper.drawString(text = itemInfo.name, offset = offset + stackPos + Vector2f(22f, 8f), color = color)

            if (UiStateHelper.isFocus(uiState)) {
                ClickHandler.registerUiHoverListener(offset + stackPos, Vector2f(160f, 18f)) {
                    uiState.cursorIndex = i - scrollSettings.lowestViewableItemIndex
                    true
                }
            }

            offset.y += 18f
        }

        if (UiStateHelper.isFocus(uiState)) {
            drawItemPreview()
        }
    }

    fun openUpgradeConfirmation() {
        val selectedItem = getSelectedUpgradeItem() ?: return
        val selectedDestination = getSelectedDestinationItemInfo() ?: return
        val melds = getLackingMelds(selectedDestination) ?: return

        if (melds.isNotEmpty()) {
            ChatLog("${ShiftJis.colorItem}${selectedItem.info().name}${ShiftJis.colorClear} needs further enhancement to transform into ${ShiftJis.colorItem}${selectedDestination.name}${ShiftJis.colorClear}.", ChatLogColor.Error)
            AudioManager.playSystemSoundEffect(SystemSound.Invalid)
            return
        }

        val prompt = "Upgrade ${ShiftJis.colorItem}${selectedItem.info().name}${ShiftJis.colorClear} into ${ShiftJis.colorItem}${selectedDestination.name}${ShiftJis.colorClear}?"

        val options = listOf(
            QueryMenuOption(text = "No", value = 0),
            QueryMenuOption(text = "Yes", value = 1),
        )

        UiStateHelper.openQueryMode(
            prompt = prompt,
            options = options,
            closeable = true,
            systemSound = SystemSound.MenuSelect,
            drawFn = { drawItemPreview() },
            callback = this::handleWeaponUpgradeConfirmation,
        )
    }

    private fun handleWeaponUpgradeConfirmation(queryMenuOption: QueryMenuOption?): QueryMenuResponse {
        if (queryMenuOption == null || queryMenuOption.value == 0) { return QueryMenuResponse.pop }

        val sourceItem = getSelectedUpgradeItem() ?: return QueryMenuResponse.pop
        val destinationItemInfo = getSelectedDestinationItemInfo() ?: return QueryMenuResponse.pop

        GameEngine.submitEvent(WeaponUpgradeEvent(
            sourceId = ActorStateManager.playerId,
            weaponId = sourceItem.internalId,
            destinationWeaponId = destinationItemInfo.itemId,
        ))

        val player = ActorManager.player()
        MiscEffects.playEffect(player, player, 0x1346, DatId.synthesisHq)

        return QueryMenuResponse.popAll
    }

    fun getUpgradeOptions(): List<InventoryItemInfo> {
        val item = getSelectedUpgradeItem() ?: return emptyList()
        return ItemDefinitions[item].upgradeOptions.map { InventoryItems[it] }
    }

    private fun getSelectedDestinationItemInfo(): InventoryItemInfo? {
        return getUpgradeOptions().getOrNull(getSelectedDestinationIndex())
    }

    private fun getSelectedDestinationIndex(): Int {
        val context = upgradeWeaponDestinationSelectContext
        return context.cursorIndex + context.scrollSettings!!.lowestViewableItemIndex
    }

    private fun drawItemPreview() {
        val sourceItem = getSelectedUpgradeItem() ?: return
        val destinationItemInfo = getSelectedDestinationItemInfo() ?: return

        val fakeItem = GameV0Helpers.generateUpgradedWeapon(sourceItem, destinationItemInfo)
        val description = GameV0Helpers.getItemDescriptionInternal(fakeItem, includeNeededBuildUp = true, includeAllMeldCaps = true)
        InventoryUi.drawSelectedInventoryItem(fakeItem, itemDescription = description)
    }

    private fun canUpgrade(destinationItemInfo: InventoryItemInfo): Boolean {
        val needed = getLackingMelds(destinationItemInfo) ?: return false
        return needed.isEmpty()
    }

    private fun getLackingMelds(destinationItemInfo: InventoryItemInfo): Map<ItemAugmentId, Int>? {
        val sourceItem = getSelectedUpgradeItem() ?: return null
        return GameV0Helpers.calculateNeededMeldsForWeaponUpgrade(sourceItem, destinationItemInfo)
            .filterValues { it > 0 }
    }

    fun getDescriptionWithMaxes(inventoryItem: InventoryItem): InventoryItemDescription {
        return GameV0Helpers.getItemDescriptionInternal(inventoryItem = inventoryItem, includeAllMeldCaps = true)
    }

}