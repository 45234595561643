package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.ActorManager
import xim.poc.game.ActorStateManager
import xim.poc.game.ActorType
import xim.poc.game.CastingState
import xim.poc.game.GameEngine

class CastRangedAttackStart(
    val sourceId: ActorId,
    val targetId: ActorId,
) : Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[sourceId] ?: return emptyList()
        if (!actorState.isIdleOrEngaged()) { return emptyList() }

        if (actorState.type == ActorType.Pc && actorState.getRangedAttackItems() == null) { return emptyList() }

        val (canBegin, _) = GameEngine.canBeginRangedAttack(sourceId)
        if (!canBegin) { return emptyList() }

        val filter = GameEngine.getRangedAttackTargetFilter()
        if (!filter.isValidTarget(sourceId, targetId)) { return emptyList() }

        val current = actorState.getCastingState()
        if (current != null && !current.isComplete()) { return emptyList() }

        val castingTime = GameEngine.getRangedAttackTime(actorState)
        val castingState = CastingState.rangedAttack(castTime = castingTime, sourceId = sourceId, targetId = targetId)
        actorState.setCastingState(castingState)

        ActorManager[sourceId]?.startRangedAttack(targetId)

        return emptyList()
    }

}