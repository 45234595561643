package xim.poc.game.configuration.v0.events

import xim.poc.ActorId
import xim.poc.game.ActorState
import xim.poc.game.ActorStateManager
import xim.poc.game.CapacityAugment
import xim.poc.game.InternalItemId
import xim.poc.game.configuration.v0.ItemCapacityAugment
import xim.poc.game.configuration.v0.ItemDefinitions
import xim.poc.game.event.Event

class InventoryItemMeldEvent(
    val sourceId: ActorId,
    val targetItemId: InternalItemId,
    val upgradeItemId: InternalItemId,
    val upgradeItemQuantity: Int,
): Event {

    override fun apply(): List<Event> {
        val source = ActorStateManager[sourceId] ?: return emptyList()

        val inventoryItem = source.inventory.getByInternalId(targetItemId) ?: return emptyList()
        val fixedAugments = inventoryItem.fixedAugments ?: return emptyList()

        val upgradeAugment = getUpgradeAugment(source) ?: return emptyList()

        // Validate resources
        val upgradeItem = source.inventory.getByInternalId(upgradeItemId) ?: return emptyList()
        if (upgradeItem.quantity < upgradeItemQuantity) { return emptyList() }

        val capacityConsumed = upgradeAugment.capacity * upgradeItemQuantity
        if (fixedAugments.capacityRemaining < capacityConsumed) { return emptyList() }

        // Consume resources
        source.inventory.discardItem(upgradeItemId, upgradeItemQuantity)
        fixedAugments.capacityRemaining -= capacityConsumed

        // Apply bonus
        val augmentId = upgradeAugment.augmentId
        val augmentPotency = upgradeAugment.potency * upgradeItemQuantity

        val fixedAugment = fixedAugments.getOrCreate(augmentId)
        fixedAugment.potency += augmentPotency

        return emptyList()
    }

    private fun getUpgradeAugment(source: ActorState): ItemCapacityAugment? {
        val item = source.inventory.getByInternalId(upgradeItemId) ?: return null
        val itemDefinition = ItemDefinitions[item]
        return itemDefinition.capacityAugment
    }

}