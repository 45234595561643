package xim.poc.game.event

import xim.poc.ActorId
import xim.poc.game.ActorStateManager
import xim.poc.game.GameEngine

class ActorEffectTickResult(
    val hpDelta: Int,
    val mpDelta: Int,
    val tpDelta: Int,
)

class ActorEffectTick(
    val actorId: ActorId,
): Event {

    override fun apply(): List<Event> {
        val actorState = ActorStateManager[actorId] ?: return emptyList()
        if (actorState.isDead()) { return emptyList() }

        val result = GameEngine.getActorEffectTickResult(actorState)
        actorState.gainHp(result.hpDelta)
        actorState.gainMp(result.mpDelta)
        actorState.gainTp(result.tpDelta)

        if (actorState.isDead()) {
            return listOf(ActorDefeatedEvent(defeated = actorId, defeatedBy = null, actionContext = null))
        }

        return emptyList()
    }

}